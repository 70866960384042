export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Currency type scalar */
  Currency: { input: string; output: string; }
  /** Date type scalar */
  Date: { input: string; output: string; }
  /** Date time type scalar */
  DateTime: { input: string; output: string; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

/** An access permission */
export type AccessPermission = Node & Timestamps & {
  /** The code of this permission */
  code: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The name of this permission */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** An access tier (a collection of access permissions) */
export type AccessTier = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The name of this tier */
  name: Scalars['String']['output'];
  /** The permissions included in this tier */
  permissions: Array<AccessPermission>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of AccountActivation */
export type AccountActivationError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AccountActivation */
export type AccountActivationInput = {
  activate: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AccountActivation. */
export type AccountActivationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AccountActivationError;
  migrationStatus?: Maybe<WellevatePatientMigrationStatus>;
};

/** The account type for a store */
export type AccountType = {
  /** Human readable version of the account type (eg. Individual, S Corporation, etc.) */
  presentation: Scalars['String']['output'];
  /** The account type (eg. individual, s corporation, etc.) */
  value: Scalars['String']['output'];
};

/** Arguments for filtering account types */
export type AccountTypeFilterObject = {
  /** Selects which subset of account types that are returned. */
  subset?: InputMaybe<AccountTypeFilters>;
};

/** Account type filters */
export const AccountTypeFilters = {
  /** Filters for classification account types */
  Classification: 'CLASSIFICATION'
} as const;

export type AccountTypeFilters = typeof AccountTypeFilters[keyof typeof AccountTypeFilters];
/** Steps where the onboarding checklist is clicked or dismissed */
export const ActionSteps = {
  /** Practitioner completes onboarding steps */
  Completed: 'COMPLETED',
  /** Practitioner creates a patient */
  CreatesPatient: 'CREATES_PATIENT',
  /** Practitioner creates a rx */
  CreatesRx: 'CREATES_RX'
} as const;

export type ActionSteps = typeof ActionSteps[keyof typeof ActionSteps];
/** The actionable classes that can be associated to treatment plan log actions */
export type Actionable = {
  id: Scalars['ID']['output'];
  /** Associated image for the object */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** The type of object. (aka the object's class name) */
  type: Scalars['String']['output'];
};

/** Logged user actions on the onboarding checklist */
export const Actions = {
  /** Practitioner clicked step */
  ClickedStep: 'CLICKED_STEP',
  /** Practitioner dismisses checklist */
  Dismiss: 'DISMISS'
} as const;

export type Actions = typeof Actions[keyof typeof Actions];
/** Arguments for filtering active variants */
export type ActiveVariantFilterObject = {
  /** Filters by past ordered NP Script variants to active variants set */
  pastNpScript?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filters by past ordered variants to active variants set */
  pastOrdered?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filters by past recommended variants to active variants set */
  pastRecommended?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of AddDynamicDailyPackTemplateToPlan */
export type AddDynamicDailyPackTemplateToPlanError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AddDynamicDailyPackTemplateToPlan */
export type AddDynamicDailyPackTemplateToPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId?: InputMaybe<Scalars['ID']['input']>;
  templatePackId: Scalars['ID']['input'];
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of AddDynamicDailyPackTemplateToPlan. */
export type AddDynamicDailyPackTemplateToPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: AddDynamicDailyPackTemplateToPlanError;
};

/** Autogenerated error type of AddDynamicDailyPackToTreatmentPlan */
export type AddDynamicDailyPackToTreatmentPlanError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AddDynamicDailyPackToTreatmentPlan */
export type AddDynamicDailyPackToTreatmentPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of AddDynamicDailyPackToTreatmentPlan. */
export type AddDynamicDailyPackToTreatmentPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: AddDynamicDailyPackToTreatmentPlanError;
};

/** Address details */
export type Address = Timestamps & {
  /** Address line 1 */
  address1: Scalars['String']['output'];
  /** Address line 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** Full address name */
  addressName?: Maybe<Scalars['String']['output']>;
  /** AVR for the address */
  avr?: Maybe<Avr>;
  /** Name of city */
  city: Scalars['String']['output'];
  /** Full name of country */
  country: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** A value indicating whether the address is set as default for the store */
  default?: Maybe<Scalars['Boolean']['output']>;
  /** First name for address */
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** A value indicating whether the address is a dropship address */
  isDropship?: Maybe<Scalars['Boolean']['output']>;
  /** Last name for address */
  lastname: Scalars['String']['output'];
  /** PAVR for the address */
  pavr?: Maybe<Pavr>;
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Full name of state or province */
  state: Scalars['String']['output'];
  /** Id of the state or province of the address. */
  stateId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Zipcode or postal code */
  zipcode: Scalars['String']['output'];
};

/** Attributes for adding an address to an order */
export type AddressAttributes = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  isDropship?: InputMaybe<Scalars['Boolean']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  stateId?: InputMaybe<Scalars['ID']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** Arguments for addresses */
export type AddressFilterObject = {
  addressType?: InputMaybe<Scalars['String']['input']>;
};

/** Details of patient feedback on why they haven't filled a treatment plan */
export type AdherenceFeedback = {
  /** ID */
  id: Scalars['ID']['output'];
  /** When the patient responded to the adherence form email */
  respondedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The patient's chosen response */
  response?: Maybe<AdherenceFeedbackResponse>;
  /**
   * When the adherence form was sent out to the patient or approximately when it's
   * expected to be sent. Will be null if treatment is filled or too old
   */
  sendAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Adherence feedback response type */
export const AdherenceFeedbackResponse = {
  /** Found products elsewhere */
  Elsewhere: 'ELSEWHERE',
  /** Not planning to order */
  NotPlanning: 'NOT_PLANNING',
  /** Feeling overwhelmed */
  Overwhelmed: 'OVERWHELMED',
  /** Planning to order */
  Planning: 'PLANNING',
  /** Treatment plan is out of their budget. */
  Price: 'PRICE'
} as const;

export type AdherenceFeedbackResponse = typeof AdherenceFeedbackResponse[keyof typeof AdherenceFeedbackResponse];
/** Details for an admin */
export type Admin = Node & Roleable & Timestamps & {
  /**
   * Returns true if the owner practitioner's health care credentials or provider
   * id (NPI number) is valid and has been approved
   */
  anyCredentialApproved: Scalars['Boolean']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Email the admin uses to login */
  email: Scalars['String']['output'];
  /** The full name used for the role */
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Whether the role's user account is locked */
  isLocked: Scalars['Boolean']['output'];
  /** Full name of the admin */
  name: Scalars['String']['output'];
  /** The id of the store the role belongs to */
  storeId?: Maybe<Scalars['ID']['output']>;
  /** The name of the store the role belongs to */
  storeName?: Maybe<Scalars['String']['output']>;
  /** The role type: Admin, Patient, Practitioner, Clerk, or OrganizationManager */
  type: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

export type AgeRestrictedProductState = {
  /** Age associated to the restriction */
  age: Scalars['Int']['output'];
  /** Product associated to the restriction */
  product?: Maybe<Product>;
  /** State associated to the restriction */
  state?: Maybe<State>;
};

/** MAP agreement types */
export const AgreementTypes = {
  /** A simple MAP agreement that encapsulates MAP agreements for several brands. */
  Basic: 'BASIC',
  /** A stricter MAP agreement for a single brand. */
  Strict: 'STRICT'
} as const;

export type AgreementTypes = typeof AgreementTypes[keyof typeof AgreementTypes];
/** Alert notification for users */
export type Alert = Timestamps & {
  /** When the alert was activated */
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Type of alert */
  alertType: AlertTypeEnum;
  /** Entities affected by this alert */
  alertables: Array<Alertable>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Date when the alert ends (if applicable) */
  endOn?: Maybe<Scalars['Date']['output']>;
  /** When the alert expired */
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Additional data related to the alert */
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** Message displayed to patients */
  patientMessage: Scalars['String']['output'];
  /** Message displayed to practitioners */
  practitionerMessage: Scalars['String']['output'];
  /** When the alert was resolved */
  resolvedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the alert was scheduled */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Severity level of the alert */
  severity: SeverityEnum;
  /** Date when the alert starts */
  startOn: Scalars['Date']['output'];
  /** Current status of the alert */
  status: StatusEnum;
  /** Alert title */
  title: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Filter options for alerts */
export type AlertFilterObject = {
  /** Filter by alert type */
  alertType?: InputMaybe<AlertTypeEnum>;
  /** Filter by alert status */
  status?: InputMaybe<Array<StatusEnum>>;
};

/** Alert types */
export const AlertTypeEnum = {
  /** All alert types */
  All: 'ALL',
  /** Results delayed alert */
  ResultsDelayed: 'RESULTS_DELAYED',
  /** Shipping delayed alert */
  ShippingDelayed: 'SHIPPING_DELAYED',
  /** Shipping delays in context of current lab orders */
  ShippingDelayedOrders: 'SHIPPING_DELAYED_ORDERS'
} as const;

export type AlertTypeEnum = typeof AlertTypeEnum[keyof typeof AlertTypeEnum];
/** An entity that can receive alerts */
export type Alertable = Timestamps & {
  /** ID of the associated alert */
  alertId: Scalars['ID']['output'];
  /** ID of the alertable entity */
  alertableId: Scalars['String']['output'];
  /** Type of the alertable entity */
  alertableType: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Alerts banner */
export type AlertsBanner = {
  /** Alerts banner end date. */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Alerts banner message content. */
  message: Scalars['String']['output'];
  /** Alerts banner start date. */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** Alerts banner title. */
  title: Scalars['String']['output'];
  /** Level of urgency on alerts banner. */
  urgency: Scalars['String']['output'];
};

/** Allergen */
export type Allergen = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Number of search results that have this allergen type */
  esResultCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** Name of the allergen */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Arguments for sorting allergens. */
export type AllergenSort = {
  direction: SortDirection;
  field: AllergenSortField;
};

/** Allergen sort field */
export const AllergenSortField = {
  /** How often an allergen is used as a filter during search */
  SearchPopularity: 'SEARCH_POPULARITY'
} as const;

export type AllergenSortField = typeof AllergenSortField[keyof typeof AllergenSortField];
/** Alternative variant attributes */
export type AlternativeAttributes = {
  algorithmVersion?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  smartSuggestion: Scalars['Boolean']['input'];
  variantId: Scalars['ID']['input'];
};

/** Autogenerated error type of AnalyticsLogEvent */
export type AnalyticsLogEventError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AnalyticsLogEvent */
export type AnalyticsLogEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventName?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
};

/** Autogenerated return type of AnalyticsLogEvent. */
export type AnalyticsLogEventPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AnalyticsLogEventError;
};

/** Autogenerated error type of AnalyticsStartExperiment */
export type AnalyticsStartExperimentError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AnalyticsStartExperiment */
export type AnalyticsStartExperimentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  experiment: Scalars['String']['input'];
  experimentVariant: Scalars['String']['input'];
};

/** Autogenerated return type of AnalyticsStartExperiment. */
export type AnalyticsStartExperimentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AnalyticsStartExperimentError;
};

/** Autogenerated error type of AnalyticsUpdateProfile */
export type AnalyticsUpdateProfileError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AnalyticsUpdateProfile */
export type AnalyticsUpdateProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
};

/** Autogenerated return type of AnalyticsUpdateProfile. */
export type AnalyticsUpdateProfilePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AnalyticsUpdateProfileError;
};

/** Autogenerated error type of AndroidAddToWaitlist */
export type AndroidAddToWaitlistError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AndroidAddToWaitlist */
export type AndroidAddToWaitlistInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AndroidAddToWaitlist. */
export type AndroidAddToWaitlistPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The errors if there was a problem adding a patient to the waitlist */
  errors: AndroidAddToWaitlistError;
  /**
   * Whether or not the patient was just added to the waitlist. True if they just
   * signed up, false if they were already on the list
   */
  newlyAdded?: Maybe<Scalars['Boolean']['output']>;
};

/** Represents a store's external EHR integrations */
export type ApiExternalMetadatum = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** When the external EHR integration was deactivated */
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** External ID of the object that the external EHR integration is linked to */
  externalId: Scalars['String']['output'];
  /** External type of the object that the external EHR integration is linked to */
  externalIdType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** the global ID of the object that the external EHR integration is linked to */
  metadatableId: Scalars['ID']['output'];
  /** Type of the object that the external EHR integration is linked to */
  metadatableType: Scalars['String']['output'];
  /** Name of the external EHR integration */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of AppointmentCreate */
export type AppointmentCreateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AppointmentCreateIcs */
export type AppointmentCreateIcsError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AppointmentCreateIcs */
export type AppointmentCreateIcsInput = {
  appointmentId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AppointmentCreateIcs. */
export type AppointmentCreateIcsPayload = {
  calendarInviteFile?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AppointmentCreateIcsError;
};

/** Autogenerated input type of AppointmentCreate */
export type AppointmentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  labRecommendationId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  timeSlot: Scalars['String']['input'];
};

/** Autogenerated return type of AppointmentCreate. */
export type AppointmentCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AppointmentCreateError;
  labAppointment?: Maybe<LabsAppointment>;
  redirectToClient?: Maybe<Scalars['Boolean']['output']>;
};

/** Lab appointment mutations */
export type AppointmentMutation = {
  /** Cancel a lab appointment */
  cancelLabAppointment?: Maybe<CancelLabAppointmentPayload>;
  /** Cancel a mobile phlebotomy booking appointment */
  cancelMobileLabAppointment?: Maybe<MobileAppointmentCancelPayload>;
  /** Find details on sample collection providers for a lab recommendation and zipcode */
  collectionProvidersForZipcode?: Maybe<CollectionProvidersForZipcodePayload>;
  /** Creates a phlebotomy booking appointment given a lab recommendation and location */
  create?: Maybe<AppointmentCreatePayload>;
  /** Creates an ICS file for a given phlebotomy booking appointment */
  createIcs?: Maybe<AppointmentCreateIcsPayload>;
  /** Creates a mobile phlebotomy booking appointment given a lab recommendation and location */
  createMobileLabAppointment?: Maybe<MobileAppointmentCreatePayload>;
  /** Find available lab appointment time slots */
  findLabAppointmentTimeSlots?: Maybe<FindLabAppointmentTimeSlotsPayload>;
  /** Find available mobile lab appointment time slots */
  findMobileLabAppointmentTimeSlots?: Maybe<FindMobileLabAppointmentTimeSlotsPayload>;
  /** Validate whether zipcode is serviceable by mobile sample collection provider */
  mobileCollectionProviderValidateZipcode?: Maybe<MobileCollectionProviderValidateZipcodePayload>;
  /** Modify a lab appointment */
  modifyLabAppointment?: Maybe<ModifyLabAppointmentPayload>;
  /** Modify a mobile phlebotomy booking appointment */
  modifyMobileLabAppointment?: Maybe<MobileAppointmentModifyPayload>;
};


/** Lab appointment mutations */
export type AppointmentMutationCancelLabAppointmentArgs = {
  input: CancelLabAppointmentInput;
};


/** Lab appointment mutations */
export type AppointmentMutationCancelMobileLabAppointmentArgs = {
  input: MobileAppointmentCancelInput;
};


/** Lab appointment mutations */
export type AppointmentMutationCollectionProvidersForZipcodeArgs = {
  input: CollectionProvidersForZipcodeInput;
};


/** Lab appointment mutations */
export type AppointmentMutationCreateArgs = {
  input: AppointmentCreateInput;
};


/** Lab appointment mutations */
export type AppointmentMutationCreateIcsArgs = {
  input: AppointmentCreateIcsInput;
};


/** Lab appointment mutations */
export type AppointmentMutationCreateMobileLabAppointmentArgs = {
  input: MobileAppointmentCreateInput;
};


/** Lab appointment mutations */
export type AppointmentMutationFindLabAppointmentTimeSlotsArgs = {
  input: FindLabAppointmentTimeSlotsInput;
};


/** Lab appointment mutations */
export type AppointmentMutationFindMobileLabAppointmentTimeSlotsArgs = {
  input: FindMobileLabAppointmentTimeSlotsInput;
};


/** Lab appointment mutations */
export type AppointmentMutationMobileCollectionProviderValidateZipcodeArgs = {
  input: MobileCollectionProviderValidateZipcodeInput;
};


/** Lab appointment mutations */
export type AppointmentMutationModifyLabAppointmentArgs = {
  input: ModifyLabAppointmentInput;
};


/** Lab appointment mutations */
export type AppointmentMutationModifyMobileLabAppointmentArgs = {
  input: MobileAppointmentModifyInput;
};

export type AtRiskVariant = {
  /** Number of active plans that include this variant. */
  activePlansCount: Scalars['Int']['output'];
  /** The variant ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Datetime of last prescription of this variant */
  lastPlanUpdatedAt: Scalars['DateTime']['output'];
  /** Variant */
  variant: Variant;
};


export type AtRiskVariantActivePlansCountArgs = {
  planType?: InputMaybe<PlanTypes>;
};


export type AtRiskVariantLastPlanUpdatedAtArgs = {
  planType?: InputMaybe<PlanTypes>;
};

/** The connection type for AtRiskVariant. */
export type AtRiskVariantConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AtRiskVariantEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AtRiskVariant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AtRiskVariantEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AtRiskVariant>;
};

export type AtRiskVariantsCountByPlanType = {
  /** Number of multi_patient_plan variants */
  multiPatientPlanVariants: Scalars['Int']['output'];
  /** Number of treatment_plan variants */
  treatmentPlanVariants: Scalars['Int']['output'];
};

export type AtRiskVariantsCountByStatus = {
  /** Total number of at risk variants */
  allVariants: Scalars['Int']['output'];
  /** Number of backordered variants */
  backorderedVariants: Scalars['Int']['output'];
  /** Number of discontinued variants */
  discontinuedVariants: Scalars['Int']['output'];
  /** Number of out of stock variants */
  outOfStockVariants: Scalars['Int']['output'];
  /** Number of to be discontinued variants */
  toBeDiscontinuedVariants: Scalars['Int']['output'];
};

/** Arguments for filtering at risk variants. */
export type AtRiskVariantsFilterObject = {
  /** At-risk variants associated with this plan type. */
  planType?: InputMaybe<PlanTypes>;
  /** Id of practitioner a clerk wants to filter by */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Option for filtering at-risk variants by their availability statuses. */
  statuses?: InputMaybe<Array<AtRiskVariantsStatusFilters>>;
};

/** Arguments for sorting at risk variants. */
export type AtRiskVariantsSort = {
  direction: SortDirection;
  field?: InputMaybe<AtRiskVariantsSortOptions>;
};

/** Options for sorting at risk variants. */
export const AtRiskVariantsSortOptions = {
  /** Sorts by the number of active plans per variant. */
  ActivePlansCount: 'ACTIVE_PLANS_COUNT',
  /** Sorts by last plan updated_at */
  LastPlanUpdatedAt: 'LAST_PLAN_UPDATED_AT'
} as const;

export type AtRiskVariantsSortOptions = typeof AtRiskVariantsSortOptions[keyof typeof AtRiskVariantsSortOptions];
/** At risk variants status filters. */
export const AtRiskVariantsStatusFilters = {
  /** Filters only backordered variants. */
  Backordered: 'BACKORDERED',
  /** Filters only discontinued variants. */
  Discontinued: 'DISCONTINUED',
  /** Filters only out of stock variants. */
  OutOfStock: 'OUT_OF_STOCK',
  /** Filters only variants that are to be discontinued. */
  ToBeDiscontinued: 'TO_BE_DISCONTINUED'
} as const;

export type AtRiskVariantsStatusFilters = typeof AtRiskVariantsStatusFilters[keyof typeof AtRiskVariantsStatusFilters];
/** The attachable classes that are attachable to document attachments. */
export type Attachable = {
  id: Scalars['ID']['output'];
  /** Type of plan the document was attached to */
  type: Scalars['String']['output'];
};

/** Piece of a call containing transcript information */
export type AudioChunk = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Time in seconds from the beginning of the call that this chunk was recorded */
  recordingTime: Scalars['Int']['output'];
  /** Results from AWS Transcribe (used to create the summary) in JSON format */
  results: Scalars['String']['output'];
  /** Summary of the transcription */
  summary: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Full audio session from a call */
export type AudioSession = Node & Timestamps & {
  /** AWS token for uploading audio (when call or in-person) */
  awsToken: AwsToken;
  /** Audio chunks */
  copilotAudioChunks: Array<AudioChunk>;
  /** Audio summaries */
  copilotAudioSummaries?: Maybe<Array<AudioSummary>>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Most recent summary */
  currentSummary?: Maybe<AudioSummary>;
  /** End time of the audio */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  /** Transcript of the full audio session */
  fullTranscript: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Patient for this audio session */
  patient: Patient;
  /** Practitioner that created this audio session */
  practitioner: Practitioner;
  /** Type of recording (upload | call | in-person) */
  recordingType: Scalars['String']['output'];
  /** Start time of the audio */
  startTime: Scalars['DateTime']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for AudioSession. */
export type AudioSessionConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AudioSessionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AudioSession>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AudioSessionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AudioSession>;
};

/** Summary (SOAP notes) from a transcript */
export type AudioSummary = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Summary kind (standard | integrative) */
  kind: Scalars['String']['output'];
  /** Patient friendly summary generated */
  patientMessage?: Maybe<Scalars['String']['output']>;
  /** FS/Imat Protocols in JSON format */
  protocols?: Maybe<Scalars['String']['output']>;
  /** FS/Imat Resources in JSON format */
  resources?: Maybe<Scalars['String']['output']>;
  /** Summary text in JSON format */
  summary?: Maybe<Scalars['String']['output']>;
  /** Supplement list in JSON format */
  supplementList?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Version of this summary (default to '1') */
  version?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AuthChangeRole */
export type AuthChangeRoleError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthChangeRole */
export type AuthChangeRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roleId: Scalars['ID']['input'];
};

/** Autogenerated return type of AuthChangeRole. */
export type AuthChangeRolePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The errors if there was a problem changing roles */
  errors: AuthChangeRoleError;
  /** The redirect path for the new role */
  redirectPath?: Maybe<Scalars['String']['output']>;
  /** The role that was changed to */
  role?: Maybe<RoleUnion>;
  /** The updated JWT token */
  token?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AuthClearCurrentRole */
export type AuthClearCurrentRoleError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthClearCurrentRole */
export type AuthClearCurrentRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  storePlatformName?: InputMaybe<StorePlatformType>;
};

/** Autogenerated return type of AuthClearCurrentRole. */
export type AuthClearCurrentRolePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The errors if there was a problem clearing the current role */
  errors: AuthClearCurrentRoleError;
  /** The redirect path after clearing the current role */
  redirectPath?: Maybe<Scalars['String']['output']>;
  /** The updated JWT token */
  token?: Maybe<Scalars['String']['output']>;
};

/** Attributes for collecting data on the office (sub-practitioner and clerk) sign up process */
export type AuthLoggersOfficeSignUpAttributes = {
  eventAggregateId: Scalars['String']['input'];
  eventType: Scalars['String']['input'];
  field?: InputMaybe<Scalars['String']['input']>;
  pageVersion: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
};

/** Autogenerated error type of AuthLoggersOfficeSignUp */
export type AuthLoggersOfficeSignUpError = {
  /** Field errors */
  fields?: Maybe<AuthLoggersOfficeSignUpFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthLoggersOfficeSignUp */
export type AuthLoggersOfficeSignUpFieldError = {
  eventAggregateId?: Maybe<Array<Scalars['String']['output']>>;
  eventType?: Maybe<Array<Scalars['String']['output']>>;
  field?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthLoggersOfficeSignUp */
export type AuthLoggersOfficeSignUpInput = {
  attributes: AuthLoggersOfficeSignUpAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthLoggersOfficeSignUp. */
export type AuthLoggersOfficeSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthLoggersOfficeSignUpError;
};

/** Attributes for collecting data on the patient sign up process */
export type AuthLoggersPatientSignUpAttributes = {
  eventAggregateId: Scalars['String']['input'];
  eventType: Scalars['String']['input'];
  field?: InputMaybe<Scalars['String']['input']>;
  pageVersion: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
};

/** Autogenerated error type of AuthLoggersPatientSignUp */
export type AuthLoggersPatientSignUpError = {
  /** Field errors */
  fields?: Maybe<AuthLoggersPatientSignUpFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthLoggersPatientSignUp */
export type AuthLoggersPatientSignUpFieldError = {
  eventAggregateId?: Maybe<Array<Scalars['String']['output']>>;
  eventType?: Maybe<Array<Scalars['String']['output']>>;
  field?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthLoggersPatientSignUp */
export type AuthLoggersPatientSignUpInput = {
  attributes: AuthLoggersPatientSignUpAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthLoggersPatientSignUp. */
export type AuthLoggersPatientSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthLoggersPatientSignUpError;
};

/** Attributes for collecting data on the practitioner sign up process */
export type AuthLoggersPractitionerSignUpAttributes = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  eventAggregateId: Scalars['String']['input'];
  eventType: Scalars['String']['input'];
  field?: InputMaybe<Scalars['String']['input']>;
  pageVersion: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of AuthLoggersPractitionerSignUp */
export type AuthLoggersPractitionerSignUpError = {
  /** Field errors */
  fields?: Maybe<AuthLoggersPractitionerSignUpFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthLoggersPractitionerSignUp */
export type AuthLoggersPractitionerSignUpFieldError = {
  apiClientId?: Maybe<Array<Scalars['String']['output']>>;
  eventAggregateId?: Maybe<Array<Scalars['String']['output']>>;
  eventType?: Maybe<Array<Scalars['String']['output']>>;
  field?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthLoggersPractitionerSignUp */
export type AuthLoggersPractitionerSignUpInput = {
  attributes: AuthLoggersPractitionerSignUpAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthLoggersPractitionerSignUp. */
export type AuthLoggersPractitionerSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthLoggersPractitionerSignUpError;
};

/** Mutations for authentication */
export type AuthMutation = {
  /** Authenticate's a Wellevate Loyalist's credentials */
  authenticateWellevatePractitioner?: Maybe<AuthenticateWellevatePractitionerPayload>;
  /** Changes the current role for the given user */
  changeRole?: Maybe<AuthChangeRolePayload>;
  /** Clears the currently selected role for the given user */
  clearCurrentRole?: Maybe<AuthClearCurrentRolePayload>;
  /** Allows a user to sign in to either eev2 or emerson classic. Optionally upgrades emerson classic accounts to eev2 */
  emersonSignIn?: Maybe<EmersonAuthUserSignInPayload>;
  /** Account activation mutation for sub-practitioners and clerks */
  officeActivateAccount?: Maybe<AuthOfficeActivateAccountPayload>;
  /** Sign up mutation for sub-practitioners and clerks */
  officeSignUp?: Maybe<AuthOfficeSignUpPayload>;
  /** AuthLoggersOfficeSignUp */
  officeSignUpLogger?: Maybe<AuthLoggersOfficeSignUpPayload>;
  /** Checks for existing patient account on invite */
  patientInvitationCheck?: Maybe<AuthPatientInvitationCheckPayload>;
  /** Returns a signal indicating if an email belongs to a User, a Patient and if password is set. */
  patientNextLoginStep?: Maybe<AuthPatientNextLoginStepPayload>;
  /** Signs a patient up for a Fullscript account */
  patientSignUp?: Maybe<AuthPatientSignUpPayload>;
  /** AuthPatientSignUpFromRx */
  patientSignUpFromRx?: Maybe<AuthPatientSignUpFromRxPayload>;
  /** AuthLoggersPatientSignUp */
  patientSignUpLogger?: Maybe<AuthLoggersPatientSignUpPayload>;
  /** Signs a practitioner up for a Fullscript account */
  practitionerSignUp?: Maybe<AuthPractitionerSignUpPayload>;
  /** AuthLoggersPractitionerSignUp */
  practitionerSignUpLogger?: Maybe<AuthLoggersPractitionerSignUpPayload>;
  /** Request a harmonized migration verification email for a practitioner */
  requestHarmonizedMigrationVerificationEmail?: Maybe<PractitionerRequestHarmonizedMigrationVerificationEmailPayload>;
  /** Request a shared account verification email for a practitioner */
  requestSharedAccountVerificationEmail?: Maybe<PractitionerRequestSharedAccountVerificationEmailPayload>;
  /** Resends the account consolidation verification email */
  resendAccountConsolidationEmail?: Maybe<PractitionerResendAccountConsolidationEmailPayload>;
  /** Post processing after BAA signed. */
  smartUserPostBaa?: Maybe<AuthSmartUserPostBaaPayload>;
  /** Allows a smart user to sign in. */
  smartUserSignIn?: Maybe<AuthSmartUserSignInPayload>;
  /** Updates a user's password and starts the migration process.  */
  updateMigrationPassword?: Maybe<UpdateMigrationPasswordPayload>;
  /** Creates universal link */
  userCreateUniversalLink?: Maybe<AuthUserCreateUniversalLinkPayload>;
  /** Allows a user to disable two factor authentication. */
  userDisableTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationDisablePayload>;
  /** Allows a user to edit their two factor authentication. */
  userEditTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationEditPayload>;
  /** Allows a user to enable two factor authentication. */
  userEnableTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationEnablePayload>;
  /** Checks if an email address belongs to a User */
  userExistingAccountCheck?: Maybe<AuthUserExistingAccountCheckPayload>;
  /** Send user reset password email */
  userForgotPasswordEmail?: Maybe<UserForgotPasswordEmailPayload>;
  /** Get an external token for a User */
  userGetExternalToken?: Maybe<AuthUserGetExternalTokenPayload>;
  /** Extends the user's sign in session. */
  userLogChallengePassed?: Maybe<AuthUserLogChallengePassedPayload>;
  /** Sign in with Apple ID token */
  userMobileSignInWithApple?: Maybe<UserMobileSignInWithApplePayload>;
  /** Sign in with Google */
  userMobileSignInWithGoogle?: Maybe<UserMobileSignInWithGooglePayload>;
  /** Allows a user to sign in via magic link */
  userMobileSignInWithMagicLink?: Maybe<AuthUserMobileSignInWithMagicLinkPayload>;
  /** Signs a user out (mobile) */
  userMobileSignOut?: Maybe<AuthUserMobileSignOutPayload>;
  /** Regenerates a new 2FA backup code */
  userRegenerateBackupCode?: Maybe<AuthUserRegenerateBackupCodePayload>;
  /** Allows a user to resend the code for editing their two factor authentication. */
  userResendEditTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationResendEditPayload>;
  /** Allows a user to resend the code for setting up two factor authentication. */
  userResendEmailSetupTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationResendEmailSetupPayload>;
  /** Sends a new two factor authentication code during signin. */
  userResendTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationResendPayload>;
  /** Allows a user to reset their password. */
  userResetPassword?: Maybe<AuthUserResetPasswordPayload>;
  /** Sends magic link via email to an existing user account */
  userSendMagicLink?: Maybe<AuthUserSendMagicLinkPayload>;
  /** Allows a user to setup two factor authentication. */
  userSetupTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationSetupPayload>;
  /** Allows a user to sign in. */
  userSignIn?: Maybe<AuthUserSignInPayload>;
  /** Allows a user to verify their code and unlock the 2FA settings page */
  userVerifyEditTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationVerifyEditPayload>;
  /** Checks if the current user's password matches the password provided */
  userVerifyPassword?: Maybe<AuthUserVerifyPasswordPayload>;
  /** Allows a user with 2FA enabled to verify their code and complete signin */
  userVerifyTwoFactorAuthentication?: Maybe<AuthUserTwoFactorAuthenticationVerifyPayload>;
  /** Signs a practitioner up for a Wellevate loyalist */
  wellevatePractitionerSignUp?: Maybe<AuthWellevatePractitionerSignUpPayload>;
};


/** Mutations for authentication */
export type AuthMutationAuthenticateWellevatePractitionerArgs = {
  input: AuthenticateWellevatePractitionerInput;
};


/** Mutations for authentication */
export type AuthMutationChangeRoleArgs = {
  input: AuthChangeRoleInput;
};


/** Mutations for authentication */
export type AuthMutationClearCurrentRoleArgs = {
  input: AuthClearCurrentRoleInput;
};


/** Mutations for authentication */
export type AuthMutationEmersonSignInArgs = {
  input: EmersonAuthUserSignInInput;
};


/** Mutations for authentication */
export type AuthMutationOfficeActivateAccountArgs = {
  input: AuthOfficeActivateAccountInput;
};


/** Mutations for authentication */
export type AuthMutationOfficeSignUpArgs = {
  input: AuthOfficeSignUpInput;
};


/** Mutations for authentication */
export type AuthMutationOfficeSignUpLoggerArgs = {
  input: AuthLoggersOfficeSignUpInput;
};


/** Mutations for authentication */
export type AuthMutationPatientInvitationCheckArgs = {
  input: AuthPatientInvitationCheckInput;
};


/** Mutations for authentication */
export type AuthMutationPatientNextLoginStepArgs = {
  input: AuthPatientNextLoginStepInput;
};


/** Mutations for authentication */
export type AuthMutationPatientSignUpArgs = {
  input: AuthPatientSignUpInput;
};


/** Mutations for authentication */
export type AuthMutationPatientSignUpFromRxArgs = {
  input: AuthPatientSignUpFromRxInput;
};


/** Mutations for authentication */
export type AuthMutationPatientSignUpLoggerArgs = {
  input: AuthLoggersPatientSignUpInput;
};


/** Mutations for authentication */
export type AuthMutationPractitionerSignUpArgs = {
  input: AuthPractitionerSignUpInput;
};


/** Mutations for authentication */
export type AuthMutationPractitionerSignUpLoggerArgs = {
  input: AuthLoggersPractitionerSignUpInput;
};


/** Mutations for authentication */
export type AuthMutationRequestHarmonizedMigrationVerificationEmailArgs = {
  input: PractitionerRequestHarmonizedMigrationVerificationEmailInput;
};


/** Mutations for authentication */
export type AuthMutationRequestSharedAccountVerificationEmailArgs = {
  input: PractitionerRequestSharedAccountVerificationEmailInput;
};


/** Mutations for authentication */
export type AuthMutationResendAccountConsolidationEmailArgs = {
  input: PractitionerResendAccountConsolidationEmailInput;
};


/** Mutations for authentication */
export type AuthMutationSmartUserPostBaaArgs = {
  input: AuthSmartUserPostBaaInput;
};


/** Mutations for authentication */
export type AuthMutationSmartUserSignInArgs = {
  input: AuthSmartUserSignInInput;
};


/** Mutations for authentication */
export type AuthMutationUpdateMigrationPasswordArgs = {
  input: UpdateMigrationPasswordInput;
};


/** Mutations for authentication */
export type AuthMutationUserCreateUniversalLinkArgs = {
  input: AuthUserCreateUniversalLinkInput;
};


/** Mutations for authentication */
export type AuthMutationUserDisableTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationDisableInput;
};


/** Mutations for authentication */
export type AuthMutationUserEditTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationEditInput;
};


/** Mutations for authentication */
export type AuthMutationUserEnableTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationEnableInput;
};


/** Mutations for authentication */
export type AuthMutationUserExistingAccountCheckArgs = {
  input: AuthUserExistingAccountCheckInput;
};


/** Mutations for authentication */
export type AuthMutationUserForgotPasswordEmailArgs = {
  input: UserForgotPasswordEmailInput;
};


/** Mutations for authentication */
export type AuthMutationUserGetExternalTokenArgs = {
  input: AuthUserGetExternalTokenInput;
};


/** Mutations for authentication */
export type AuthMutationUserLogChallengePassedArgs = {
  input: AuthUserLogChallengePassedInput;
};


/** Mutations for authentication */
export type AuthMutationUserMobileSignInWithAppleArgs = {
  input: UserMobileSignInWithAppleInput;
};


/** Mutations for authentication */
export type AuthMutationUserMobileSignInWithGoogleArgs = {
  input: UserMobileSignInWithGoogleInput;
};


/** Mutations for authentication */
export type AuthMutationUserMobileSignInWithMagicLinkArgs = {
  input: AuthUserMobileSignInWithMagicLinkInput;
};


/** Mutations for authentication */
export type AuthMutationUserRegenerateBackupCodeArgs = {
  input: AuthUserRegenerateBackupCodeInput;
};


/** Mutations for authentication */
export type AuthMutationUserResendEditTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationResendEditInput;
};


/** Mutations for authentication */
export type AuthMutationUserResendEmailSetupTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationResendEmailSetupInput;
};


/** Mutations for authentication */
export type AuthMutationUserResendTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationResendInput;
};


/** Mutations for authentication */
export type AuthMutationUserResetPasswordArgs = {
  input: AuthUserResetPasswordInput;
};


/** Mutations for authentication */
export type AuthMutationUserSendMagicLinkArgs = {
  input: AuthUserSendMagicLinkInput;
};


/** Mutations for authentication */
export type AuthMutationUserSetupTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationSetupInput;
};


/** Mutations for authentication */
export type AuthMutationUserSignInArgs = {
  input: AuthUserSignInInput;
};


/** Mutations for authentication */
export type AuthMutationUserVerifyEditTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationVerifyEditInput;
};


/** Mutations for authentication */
export type AuthMutationUserVerifyPasswordArgs = {
  input: AuthUserVerifyPasswordInput;
};


/** Mutations for authentication */
export type AuthMutationUserVerifyTwoFactorAuthenticationArgs = {
  input: AuthUserTwoFactorAuthenticationVerifyInput;
};


/** Mutations for authentication */
export type AuthMutationWellevatePractitionerSignUpArgs = {
  input: AuthWellevatePractitionerSignUpInput;
};

/** Attributes for activating a sub-practitioner or clerk account */
export type AuthOfficeActivateAccountAttributes = {
  invitationToken: Scalars['String']['input'];
};

/** Autogenerated error type of AuthOfficeActivateAccount */
export type AuthOfficeActivateAccountError = {
  /** Field errors */
  fields?: Maybe<AuthOfficeActivateAccountFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthOfficeActivateAccount */
export type AuthOfficeActivateAccountFieldError = {
  invitationToken?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthOfficeActivateAccount */
export type AuthOfficeActivateAccountInput = {
  attributes: AuthOfficeActivateAccountAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthOfficeActivateAccount. */
export type AuthOfficeActivateAccountPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors returned if there was a problem activating the user */
  errors: AuthOfficeActivateAccountError;
  redirectPath?: Maybe<Scalars['String']['output']>;
  /** The role of the user */
  role?: Maybe<OfficeRoleUnion>;
};

/** Attributes for signing up a sub-practitioner or clerk */
export type AuthOfficeSignUpAttributes = {
  invitationToken: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
  password: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
};

/** Autogenerated error type of AuthOfficeSignUp */
export type AuthOfficeSignUpError = {
  /** Field errors */
  fields?: Maybe<AuthOfficeSignUpFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthOfficeSignUp */
export type AuthOfficeSignUpFieldError = {
  invitationToken?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthOfficeSignUp */
export type AuthOfficeSignUpInput = {
  attributes: AuthOfficeSignUpAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthOfficeSignUp. */
export type AuthOfficeSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors returned if there was a problem signing up the user */
  errors: AuthOfficeSignUpError;
  redirectPath?: Maybe<Scalars['String']['output']>;
  /** The role of the signed up user */
  role?: Maybe<OfficeRoleUnion>;
};

/** Attributes for checking a patient invitation */
export type AuthPatientInvitationCheckAttributes = {
  email: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
};

/** Autogenerated error type of AuthPatientInvitationCheck */
export type AuthPatientInvitationCheckError = {
  /** Field errors */
  fields?: Maybe<AuthPatientInvitationCheckFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthPatientInvitationCheck */
export type AuthPatientInvitationCheckFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthPatientInvitationCheck */
export type AuthPatientInvitationCheckInput = {
  attributes: AuthPatientInvitationCheckAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthPatientInvitationCheck. */
export type AuthPatientInvitationCheckPayload = {
  accountExists?: Maybe<Scalars['Boolean']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthPatientInvitationCheckError;
};

/** Autogenerated error type of AuthPatientNextLoginStep */
export type AuthPatientNextLoginStepError = {
  /** Field errors */
  fields?: Maybe<AuthPatientNextLoginStepFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthPatientNextLoginStep */
export type AuthPatientNextLoginStepFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  redirectPath?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthPatientNextLoginStep */
export type AuthPatientNextLoginStepInput = {
  attributes: AuthUserExistingAccountCheckAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthPatientNextLoginStep. */
export type AuthPatientNextLoginStepPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthPatientNextLoginStepError;
  nextStep?: Maybe<Scalars['String']['output']>;
};

/** Attributes for signing up a patient */
export type AuthPatientSignUpAttributes = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  intake?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  optional?: InputMaybe<AuthPatientSignUpFromRxOptionalAttributes>;
  password: Scalars['String']['input'];
  requestedPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  storeId: Scalars['String']['input'];
};

/** Autogenerated error type of AuthPatientSignUp */
export type AuthPatientSignUpError = {
  /** Field errors */
  fields?: Maybe<AuthPatientSignUpFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthPatientSignUp */
export type AuthPatientSignUpFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Attributes for signing up a patient from a treatment plan */
export type AuthPatientSignUpFromRxAttributes = {
  formVersion?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId?: InputMaybe<Scalars['String']['input']>;
  optional?: InputMaybe<AuthPatientSignUpFromRxOptionalAttributes>;
  pageVersion: Scalars['String']['input'];
  password: Scalars['String']['input'];
  signUpPage: Scalars['String']['input'];
  storeSlug: Scalars['String']['input'];
  treatmentPlanSlug?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of AuthPatientSignUpFromRx */
export type AuthPatientSignUpFromRxError = {
  /** Field errors */
  fields?: Maybe<AuthPatientSignUpFromRxFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthPatientSignUpFromRx */
export type AuthPatientSignUpFromRxFieldError = {
  formVersion?: Maybe<Array<Scalars['String']['output']>>;
  labRecommendationId?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
  storeSlug?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanSlug?: Maybe<Array<Scalars['String']['output']>>;
  uuid?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthPatientSignUpFromRx */
export type AuthPatientSignUpFromRxInput = {
  attributes: AuthPatientSignUpFromRxAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Optional attributes for signing up a patient from a treatment plan */
export type AuthPatientSignUpFromRxOptionalAttributes = {
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  roleType?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  utmAdgroup?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmChannel?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmDevice?: InputMaybe<Scalars['String']['input']>;
  utmId?: InputMaybe<Scalars['String']['input']>;
  utmMatchtype?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthPatientSignUpFromRx. */
export type AuthPatientSignUpFromRxPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthPatientSignUpFromRxError;
  patient?: Maybe<Patient>;
  redirectPath?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthPatientSignUp */
export type AuthPatientSignUpInput = {
  attributes: AuthPatientSignUpAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthPatientSignUp. */
export type AuthPatientSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthPatientSignUpError;
  otpMethod?: Maybe<TwoFactorAuthenticationMethods>;
  patient?: Maybe<Patient>;
  phoneLast4?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  twoFactorAuthenticationToken?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

/** Peer referral attributes */
export type AuthPeerReferralAttributes = {
  /** Referred person's email */
  referredEmail?: Maybe<Scalars['String']['output']>;
  /** Referred person's first name */
  referredFirstName?: Maybe<Scalars['String']['output']>;
  /** Referred person's last name */
  referredLastName?: Maybe<Scalars['String']['output']>;
  /** Original referrer's avatar URL */
  referrerAvatarUrl?: Maybe<Scalars['String']['output']>;
  /** Original referrer's first name */
  referrerFirstName?: Maybe<Scalars['String']['output']>;
};

/** Additional attributes for practitioner sign up */
export type AuthPractitionerSignUpAdditionalAttributes = {
  /** Google click identifier */
  googleClickId?: Maybe<Scalars['String']['output']>;
  /** Microsite identifier */
  micrositeId?: Maybe<Scalars['String']['output']>;
  /** Peer referral code */
  peerReferralCode?: Maybe<Scalars['String']['output']>;
  /** Peer referral attributes such as firstName etc */
  peerReferralDisplayAttributes?: Maybe<AuthPeerReferralAttributes>;
  /** Peer referrer slug */
  peerReferrerSlug?: Maybe<Scalars['String']['output']>;
  /** Redirect path after the practitioner has signed up */
  redirectPath?: Maybe<Scalars['String']['output']>;
  /** Referral code to be used by referral partners */
  referralCode?: Maybe<Scalars['String']['output']>;
  /** Referral code record id */
  referralCodeId?: Maybe<Scalars['String']['output']>;
  /** Referral partner record id */
  referralPartnerId?: Maybe<Scalars['String']['output']>;
  /** Sign up source */
  source?: Maybe<Scalars['String']['output']>;
};

/** Attributes for passing into PractitionerSignUpAdditionalAttributes service */
export type AuthPractitionerSignUpAdditionalAttributesInput = {
  /** Google click identifier */
  googleClickId?: InputMaybe<Scalars['String']['input']>;
  /** Microsite identifier */
  micrositeId?: InputMaybe<Scalars['String']['input']>;
  /** Peer referral code */
  peerReferralCode?: InputMaybe<Scalars['String']['input']>;
  /** Peer referrer slug */
  peerReferrerSlug?: InputMaybe<Scalars['String']['input']>;
  /** Referral partner slug */
  referralPartnerSlug?: InputMaybe<Scalars['String']['input']>;
  /** Sign up source, see apps/authentication/models/spree/user.rb for possible values */
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for signing up a practitioner */
export type AuthPractitionerSignUpAttributes = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  optional?: InputMaybe<AuthPractitionerSignUpOptionalAttributes>;
  pageVersion: Scalars['String']['input'];
  password: Scalars['String']['input'];
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
  signUpPage: Scalars['String']['input'];
  storeName?: InputMaybe<Scalars['String']['input']>;
  storePlatform?: InputMaybe<Scalars['String']['input']>;
  tosAcceptedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Autogenerated error type of AuthPractitionerSignUp */
export type AuthPractitionerSignUpError = {
  /** Field errors */
  fields?: Maybe<AuthPractitionerSignUpFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthPractitionerSignUp */
export type AuthPractitionerSignUpFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
  storeName?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
  tosAcceptedAt?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthPractitionerSignUp */
export type AuthPractitionerSignUpInput = {
  attributes: AuthPractitionerSignUpAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Optional attributes for signing up a practitioner */
export type AuthPractitionerSignUpOptionalAttributes = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Bizible ID (_biz_uid)
   * @deprecated Bizible is no longer used.
   */
  bizibleId?: InputMaybe<Scalars['String']['input']>;
  /** Google Click ID */
  gclid?: InputMaybe<Scalars['String']['input']>;
  /** Google Analytics ID (_ga) */
  googleAnalyticsId?: InputMaybe<Scalars['String']['input']>;
  /** Google Tag Manager ID (_gid) */
  googleId?: InputMaybe<Scalars['String']['input']>;
  micrositeId?: InputMaybe<Scalars['String']['input']>;
  peerReferralCode?: InputMaybe<Scalars['String']['input']>;
  peerReferrerSlug?: InputMaybe<Scalars['String']['input']>;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  referralCode?: InputMaybe<Scalars['String']['input']>;
  referralCodeId?: InputMaybe<Scalars['String']['input']>;
  referralPartnerId?: InputMaybe<Scalars['String']['input']>;
  signupSource?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthPractitionerSignUp. */
export type AuthPractitionerSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthPractitionerSignUpError;
  practitioner?: Maybe<Practitioner>;
  redirectPath?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AuthSmartUserPostBaa */
export type AuthSmartUserPostBaaError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthSmartUserPostBaa */
export type AuthSmartUserPostBaaInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalIntegration?: InputMaybe<AuthUserSignInOptionalAttributes>;
};

/** Autogenerated return type of AuthSmartUserPostBaa. */
export type AuthSmartUserPostBaaPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthSmartUserPostBaaError;
  patient?: Maybe<PlainPatient>;
  showPractitionerPicker?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlanId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AuthSmartUserSignIn */
export type AuthSmartUserSignInError = {
  /** Field errors */
  fields?: Maybe<AuthSmartUserSignInFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthSmartUserSignIn */
export type AuthSmartUserSignInFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  rememberMe?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  staySignedIn?: Maybe<Array<Scalars['String']['output']>>;
  usingBiometrics?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthSmartUserSignIn */
export type AuthSmartUserSignInInput = {
  attributes: AuthUserSignInAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthSmartUserSignIn. */
export type AuthSmartUserSignInPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthSmartUserSignInError;
  hasBaa?: Maybe<Scalars['Boolean']['output']>;
  isFsEmbed?: Maybe<Scalars['Boolean']['output']>;
  jwt?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<PlainPatient>;
  role?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
  showPractitionerPicker?: Maybe<Scalars['Boolean']['output']>;
  treatmentPlanId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

/** Attributes for the update migration password mutation */
export type AuthUpdateMigrationPasswordAttributes = {
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated error type of AuthUserCreateUniversalLink */
export type AuthUserCreateUniversalLinkError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserCreateUniversalLink */
export type AuthUserCreateUniversalLinkInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  redirectPath: Scalars['String']['input'];
  utmAdgroup?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmChannel?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmDevice?: InputMaybe<Scalars['String']['input']>;
  utmId?: InputMaybe<Scalars['String']['input']>;
  utmMatchtype?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserCreateUniversalLink. */
export type AuthUserCreateUniversalLinkPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserCreateUniversalLinkError;
  universalLink?: Maybe<Scalars['String']['output']>;
};

/** Attributes for checking if a user account exists */
export type AuthUserExistingAccountCheckAttributes = {
  email: Scalars['String']['input'];
  intake?: InputMaybe<Scalars['Boolean']['input']>;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  requestedPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated error type of AuthUserExistingAccountCheck */
export type AuthUserExistingAccountCheckError = {
  /** Field errors */
  fields?: Maybe<AuthUserExistingAccountCheckFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthUserExistingAccountCheck */
export type AuthUserExistingAccountCheckFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  redirectPath?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthUserExistingAccountCheck */
export type AuthUserExistingAccountCheckInput = {
  attributes: AuthUserExistingAccountCheckAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserExistingAccountCheck. */
export type AuthUserExistingAccountCheckPayload = {
  accountExists?: Maybe<Scalars['Boolean']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserExistingAccountCheckError;
};

/** Attributes for triggering the forgot password email */
export type AuthUserForgotPasswordEmailAttributes = {
  email: Scalars['String']['input'];
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of AuthUserGetExternalToken */
export type AuthUserGetExternalTokenError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserGetExternalToken */
export type AuthUserGetExternalTokenInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** The client this token is for */
  client: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserGetExternalToken. */
export type AuthUserGetExternalTokenPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserGetExternalTokenError;
  jwtToken?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AuthUserLogChallengePassed */
export type AuthUserLogChallengePassedError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserLogChallengePassed */
export type AuthUserLogChallengePassedInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserLogChallengePassed. */
export type AuthUserLogChallengePassedPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<AuthUserLogChallengePassedError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated error type of AuthUserMobileSignInWithMagicLink */
export type AuthUserMobileSignInWithMagicLinkError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserMobileSignInWithMagicLink */
export type AuthUserMobileSignInWithMagicLinkInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Magic link token to be verified */
  token: Scalars['String']['input'];
};

/** Autogenerated return type of AuthUserMobileSignInWithMagicLink. */
export type AuthUserMobileSignInWithMagicLinkPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Error object */
  errors: AuthUserMobileSignInWithMagicLinkError;
  /** Authenticated user */
  user?: Maybe<User>;
};

/** Autogenerated return type of AuthUserMobileSignOut. */
export type AuthUserMobileSignOutPayload = {
  success: Scalars['Boolean']['output'];
};

/** Attributes for a user Single Sign On (SSO) through the mobile app */
export type AuthUserMobileSingleSignOnAttributes = {
  accessToken: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  idToken: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  nonce?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of AuthUserRegenerateBackupCode */
export type AuthUserRegenerateBackupCodeError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserRegenerateBackupCode */
export type AuthUserRegenerateBackupCodeInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserRegenerateBackupCode. */
export type AuthUserRegenerateBackupCodePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserRegenerateBackupCodeError;
  newBackupCode?: Maybe<Scalars['String']['output']>;
};

/** Attributes resetting a user's password */
export type AuthUserResetPasswordAttributes = {
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  passwordResetToken: Scalars['String']['input'];
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of AuthUserResetPassword */
export type AuthUserResetPasswordError = {
  /** Field errors */
  fields?: Maybe<AuthUserResetPasswordFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthUserResetPassword */
export type AuthUserResetPasswordFieldError = {
  password?: Maybe<Array<Scalars['String']['output']>>;
  passwordConfirmation?: Maybe<Array<Scalars['String']['output']>>;
  passwordResetToken?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthUserResetPassword */
export type AuthUserResetPasswordInput = {
  attributes: AuthUserResetPasswordAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserResetPassword. */
export type AuthUserResetPasswordPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  emailMasked?: Maybe<Scalars['String']['output']>;
  /** The errors that occurred. */
  errors: AuthUserResetPasswordError;
  otpMethod?: Maybe<TwoFactorAuthenticationMethods>;
  phoneLast4?: Maybe<Scalars['String']['output']>;
  /** The path to redirect to after resetting the password. */
  redirectPath?: Maybe<Scalars['String']['output']>;
  twoFactorAuthenticationToken?: Maybe<Scalars['String']['output']>;
  /** The user that was reset. */
  user?: Maybe<User>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AuthUserSendMagicLink */
export type AuthUserSendMagicLinkError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserSendMagicLink */
export type AuthUserSendMagicLinkInput = {
  captchaToken: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  intake?: InputMaybe<Scalars['Boolean']['input']>;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  utmAdgroup?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmChannel?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmDevice?: InputMaybe<Scalars['String']['input']>;
  utmId?: InputMaybe<Scalars['String']['input']>;
  utmMatchtype?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserSendMagicLink. */
export type AuthUserSendMagicLinkPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserSendMagicLinkError;
};

/** Additional attributes for user sign in */
export type AuthUserSignInAdditionalAttributes = {
  /** Redirect path after sign in */
  redirectPath?: Maybe<Scalars['String']['output']>;
};

/** Attributes for signing in a user */
export type AuthUserSignInAttributes = {
  email: Scalars['String']['input'];
  intake?: InputMaybe<Scalars['Boolean']['input']>;
  optional?: InputMaybe<AuthUserSignInOptionalAttributes>;
  password: Scalars['String']['input'];
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
  requestedPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Currently unused. */
  staySignedIn?: InputMaybe<Scalars['Boolean']['input']>;
  usingBiometrics?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of AuthUserSignIn */
export type AuthUserSignInError = {
  /** Field errors */
  fields?: Maybe<AuthUserSignInFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthUserSignIn */
export type AuthUserSignInFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  rememberMe?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  staySignedIn?: Maybe<Array<Scalars['String']['output']>>;
  usingBiometrics?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthUserSignIn */
export type AuthUserSignInInput = {
  attributes: AuthUserSignInAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Optional attributes for signing in a user */
export type AuthUserSignInOptionalAttributes = {
  externalApplicationId?: InputMaybe<Scalars['String']['input']>;
  externalPatientId?: InputMaybe<Scalars['String']['input']>;
  externalPracticeId?: InputMaybe<Scalars['String']['input']>;
  externalPractitionerId?: InputMaybe<Scalars['String']['input']>;
  formVersion?: InputMaybe<Scalars['String']['input']>;
  isOauth?: InputMaybe<Scalars['Boolean']['input']>;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
  roleType?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  storePlatform?: InputMaybe<Scalars['String']['input']>;
  utmAdgroup?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmChannel?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmDevice?: InputMaybe<Scalars['String']['input']>;
  utmId?: InputMaybe<Scalars['String']['input']>;
  utmMatchtype?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserSignIn. */
export type AuthUserSignInPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  emailMasked?: Maybe<Scalars['String']['output']>;
  errors: AuthUserSignInError;
  otpMethod?: Maybe<TwoFactorAuthenticationMethods>;
  phoneLast4?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  twoFactorAuthenticationToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AuthUserTwoFactorAuthenticationDisable */
export type AuthUserTwoFactorAuthenticationDisableError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserTwoFactorAuthenticationDisable */
export type AuthUserTwoFactorAuthenticationDisableInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserTwoFactorAuthenticationDisable. */
export type AuthUserTwoFactorAuthenticationDisablePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationDisableError;
  twoFactorAuthentication?: Maybe<TwoFactorAuthentication>;
};

/** Autogenerated error type of AuthUserTwoFactorAuthenticationEdit */
export type AuthUserTwoFactorAuthenticationEditError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserTwoFactorAuthenticationEdit */
export type AuthUserTwoFactorAuthenticationEditInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserTwoFactorAuthenticationEdit. */
export type AuthUserTwoFactorAuthenticationEditPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationEditError;
  twoFactorAuthentication?: Maybe<TwoFactorAuthentication>;
};

/** Autogenerated error type of AuthUserTwoFactorAuthenticationEnable */
export type AuthUserTwoFactorAuthenticationEnableError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserTwoFactorAuthenticationEnable */
export type AuthUserTwoFactorAuthenticationEnableInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otp: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  verificationToken?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserTwoFactorAuthenticationEnable. */
export type AuthUserTwoFactorAuthenticationEnablePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationEnableError;
  newBackupCode?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  twoFactorAuthentication?: Maybe<TwoFactorAuthentication>;
};

/** Autogenerated error type of AuthUserTwoFactorAuthenticationResendEdit */
export type AuthUserTwoFactorAuthenticationResendEditError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserTwoFactorAuthenticationResendEdit */
export type AuthUserTwoFactorAuthenticationResendEditInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserTwoFactorAuthenticationResendEdit. */
export type AuthUserTwoFactorAuthenticationResendEditPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationResendEditError;
  sent: Scalars['Boolean']['output'];
};

/** Autogenerated error type of AuthUserTwoFactorAuthenticationResendEmailSetup */
export type AuthUserTwoFactorAuthenticationResendEmailSetupError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserTwoFactorAuthenticationResendEmailSetup */
export type AuthUserTwoFactorAuthenticationResendEmailSetupInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserTwoFactorAuthenticationResendEmailSetup. */
export type AuthUserTwoFactorAuthenticationResendEmailSetupPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationResendEmailSetupError;
  sent: Scalars['Boolean']['output'];
};

/** Autogenerated error type of AuthUserTwoFactorAuthenticationResend */
export type AuthUserTwoFactorAuthenticationResendError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserTwoFactorAuthenticationResend */
export type AuthUserTwoFactorAuthenticationResendInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of AuthUserTwoFactorAuthenticationResend. */
export type AuthUserTwoFactorAuthenticationResendPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationResendError;
  sent: Scalars['Boolean']['output'];
};

/** Autogenerated error type of AuthUserTwoFactorAuthenticationSetup */
export type AuthUserTwoFactorAuthenticationSetupError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserTwoFactorAuthenticationSetup */
export type AuthUserTwoFactorAuthenticationSetupInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otpMethod: TwoFactorAuthenticationMethods;
  otpMethodField?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  verificationToken?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserTwoFactorAuthenticationSetup. */
export type AuthUserTwoFactorAuthenticationSetupPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationSetupError;
  manualSetupCode?: Maybe<Scalars['String']['output']>;
  qrCodeUrl?: Maybe<Scalars['String']['output']>;
  twoFactorAuthentication?: Maybe<TwoFactorAuthentication>;
};

/** Autogenerated error type of AuthUserTwoFactorAuthenticationVerifyEdit */
export type AuthUserTwoFactorAuthenticationVerifyEditError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserTwoFactorAuthenticationVerifyEdit */
export type AuthUserTwoFactorAuthenticationVerifyEditInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otp: Scalars['String']['input'];
};

/** Autogenerated return type of AuthUserTwoFactorAuthenticationVerifyEdit. */
export type AuthUserTwoFactorAuthenticationVerifyEditPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationVerifyEditError;
  invalid?: Maybe<Scalars['Boolean']['output']>;
  locked?: Maybe<Scalars['Boolean']['output']>;
  newBackupCode?: Maybe<Scalars['String']['output']>;
  verificationToken?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AuthUserTwoFactorAuthenticationVerify */
export type AuthUserTwoFactorAuthenticationVerifyError = {
  /** Field errors */
  fields?: Maybe<AuthUserTwoFactorAuthenticationVerifyFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthUserTwoFactorAuthenticationVerify */
export type AuthUserTwoFactorAuthenticationVerifyFieldError = {
  otp?: Maybe<Array<Scalars['String']['output']>>;
  token?: Maybe<Array<Scalars['String']['output']>>;
  trustThisDevice?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthUserTwoFactorAuthenticationVerify */
export type AuthUserTwoFactorAuthenticationVerifyInput = {
  attributes: AuthUserVerifyTwoFactorAuthenticationAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthUserTwoFactorAuthenticationVerify. */
export type AuthUserTwoFactorAuthenticationVerifyPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserTwoFactorAuthenticationVerifyError;
  locked?: Maybe<Scalars['Boolean']['output']>;
  newBackupCode?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  returnToSignin?: Maybe<Scalars['Boolean']['output']>;
  roleType?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

/** Autogenerated error type of AuthUserVerifyPassword */
export type AuthUserVerifyPasswordError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthUserVerifyPassword */
export type AuthUserVerifyPasswordInput = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/** Autogenerated return type of AuthUserVerifyPassword. */
export type AuthUserVerifyPasswordPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthUserVerifyPasswordError;
  /** The current user */
  user?: Maybe<User>;
};

/** Attributes for verifying 2FA */
export type AuthUserVerifyTwoFactorAuthenticationAttributes = {
  otp: Scalars['String']['input'];
  token: Scalars['String']['input'];
  trustThisDevice?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Attributes for signing up a practitioner for Wellevate loyalist */
export type AuthWellevatePractitionerSignUpAttributes = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  optional?: InputMaybe<AuthPractitionerSignUpOptionalAttributes>;
  pageVersion: Scalars['String']['input'];
  password: Scalars['String']['input'];
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
  signUpPage: Scalars['String']['input'];
  storeName?: InputMaybe<Scalars['String']['input']>;
  storePlatform?: InputMaybe<Scalars['String']['input']>;
  tosAcceptedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wellevatePractitionerId: Scalars['ID']['input'];
};

/** Autogenerated error type of AuthWellevatePractitionerSignUp */
export type AuthWellevatePractitionerSignUpError = {
  /** Field errors */
  fields?: Maybe<AuthWellevatePractitionerSignUpFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthWellevatePractitionerSignUp */
export type AuthWellevatePractitionerSignUpFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
  signUpPage?: Maybe<Array<Scalars['String']['output']>>;
  storeName?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
  tosAcceptedAt?: Maybe<Array<Scalars['String']['output']>>;
  wellevatePractitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthWellevatePractitionerSignUp */
export type AuthWellevatePractitionerSignUpInput = {
  attributes: AuthWellevatePractitionerSignUpAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthWellevatePractitionerSignUp. */
export type AuthWellevatePractitionerSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthWellevatePractitionerSignUpError;
  practitioner?: Maybe<Practitioner>;
  redirectPath?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AuthenticateWellevateCredentials */
export type AuthenticateWellevateCredentialsError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AuthenticateWellevateCredentials */
export type AuthenticateWellevateCredentialsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated return type of AuthenticateWellevateCredentials. */
export type AuthenticateWellevateCredentialsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: AuthenticateWellevateCredentialsError;
};

/** Attributes for checking if the Wellevate credentials are correct */
export type AuthenticateWellevatePractitionerAttributes = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated error type of AuthenticateWellevatePractitioner */
export type AuthenticateWellevatePractitionerError = {
  /** Field errors */
  fields?: Maybe<AuthenticateWellevatePractitionerFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of AuthenticateWellevatePractitioner */
export type AuthenticateWellevatePractitionerFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of AuthenticateWellevatePractitioner */
export type AuthenticateWellevatePractitionerInput = {
  attributes: AuthenticateWellevatePractitionerAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of AuthenticateWellevatePractitioner. */
export type AuthenticateWellevatePractitionerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dispensaryName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<AuthenticateWellevatePractitionerError>;
  firstname?: Maybe<Scalars['String']['output']>;
  fullscriptPractitionerType?: Maybe<PractitionerType>;
  lastname?: Maybe<Scalars['String']['output']>;
  practitionerId?: Maybe<Scalars['String']['output']>;
};

/** Authentication providers */
export const AuthenticationProviders = {
  /** Apple SSO */
  AppleSso: 'APPLE_SSO',
  /** Regular email/password */
  EmailPassword: 'EMAIL_PASSWORD',
  /** Google SSO */
  GoogleSso: 'GOOGLE_SSO'
} as const;

export type AuthenticationProviders = typeof AuthenticationProviders[keyof typeof AuthenticationProviders];
/** The autorefill orders data to be displayed on the store analytics dashboard */
export type AutorefillOrdersData = {
  /** The last 12 months number of autorefill orders for the store (excluding the current month) */
  lastTwelveMonths: Scalars['Float']['output'];
  /** The month to date number of autorefill orders for the store */
  monthToDate: Scalars['Int']['output'];
  /** The autorefill data for the store */
  storeAutorefillAnalyticsData: Array<StoreAutorefillAnalyticsData>;
  /** The year to date number of autorefill orders for the store */
  yearToDate: Scalars['Int']['output'];
};

/** The autorefill revenue data to be displayed on the store analytics dashboard */
export type AutorefillRevenueData = {
  /** The last 12 months autorefill revenue for the store (excluding the current month) */
  lastTwelveMonths: Scalars['Float']['output'];
  /** The month to date autorefill revenue for the store */
  monthToDate: Scalars['Float']['output'];
  /** The autorefill data for the store */
  storeAutorefillAnalyticsData: Array<StoreAutorefillAnalyticsData>;
  /** The year to date autorefill revenue for the store */
  yearToDate: Scalars['Float']['output'];
};

/** Products that a patient is subscribed to receive at a specified interval */
export type Autoship = Node & Timestamps & {
  /** Id of the billing address used for the subscription payment */
  billingAddressId?: Maybe<Scalars['ID']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Id of a credit card used for subscription payment */
  creditCardId?: Maybe<Scalars['ID']['output']>;
  /** The discount percentage that is applied to the subscription */
  discountValue?: Maybe<Scalars['Int']['output']>;
  /**
   * The frequency that that the variant is scheduled to be ordered. Valid options
   * include:         two weeks, three weeks, month, six weeks, two months, three
   * months, four months, six months
   */
  frequency: Scalars['String']['output'];
  /** The frequency that the variant is scheduled to be ordered as an enum value */
  frequencyEnum: OrderFrequencies;
  /** The frequency presented in integer days */
  frequencyValue?: Maybe<Scalars['Int']['output']>;
  /** Returns true if shipping address is in California */
  hasCaliforniaAddress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** Returns false when no critical field is missing */
  isMissingCriticalFields: Scalars['Boolean']['output'];
  /** Failure reason for their last autoship order */
  lastOrderFailureReason?: Maybe<Scalars['String']['output']>;
  /** When tha last order was placed */
  lastOrderedOn?: Maybe<Scalars['Date']['output']>;
  /** When the next order is scheduled */
  nextOrderDate?: Maybe<Scalars['Date']['output']>;
  /** The recipient of the subscription */
  patient: Patient;
  /** When the next order is estimated to be received */
  receiveByDate?: Maybe<Scalars['Date']['output']>;
  /** Id of the address the subscription will be shipped to */
  shippingAddressId?: Maybe<Scalars['ID']['output']>;
  /** Available shipping rates for the autoship estimation */
  shippingRates?: Maybe<Array<AutoshipShippingRate>>;
  /** Metadata about the variants subscribed */
  subscriptionsVariants: Array<SubscriptionsVariant>;
  /** The total cost that will be charged to the patient */
  subtotal: Scalars['Float']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Variants included in the subscription */
  variants: VariantConnection;
};


/** Products that a patient is subscribed to receive at a specified interval */
export type AutoshipSubscriptionsVariantsArgs = {
  filters?: InputMaybe<SubscriptionVariantFilterObject>;
};


/** Products that a patient is subscribed to receive at a specified interval */
export type AutoshipVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated error type of AutoshipCancel */
export type AutoshipCancelError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AutoshipCancel */
export type AutoshipCancelInput = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  openTextCancellation?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

/** Autogenerated return type of AutoshipCancel. */
export type AutoshipCancelPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: AutoshipCancelError;
  patient?: Maybe<Patient>;
};

/** Arguments for filtering autoships. */
export type AutoshipFilterObject = {
  autoshipId?: InputMaybe<Scalars['ID']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
};

/** Subscriptions group */
export type AutoshipGroup = Node & {
  id: Scalars['ID']['output'];
  /** The date that the subscriptions are scheduled to be ordered */
  nextOrderDate: Scalars['Date']['output'];
  /** The shipping method code for the group */
  shippingMethod?: Maybe<Scalars['String']['output']>;
  /** Available shipping rates for the autoship estimation */
  shippingRates?: Maybe<Array<AutoshipShippingRate>>;
  /** Subscriptions in the group */
  subscriptions: Array<Autoship>;
};


/** Subscriptions group */
export type AutoshipGroupSubscriptionsArgs = {
  filters?: InputMaybe<AutoshipFilterObject>;
};

/** Arguments for filtering autoship groups. */
export type AutoshipGroupFilterObject = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated error type of AutoshipGroupOrderNow */
export type AutoshipGroupOrderNowError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AutoshipGroupOrderNow */
export type AutoshipGroupOrderNowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriptionsGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of AutoshipGroupOrderNow. */
export type AutoshipGroupOrderNowPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: AutoshipGroupOrderNowError;
  patient?: Maybe<Patient>;
};

/** Autogenerated error type of AutoshipOrderNow */
export type AutoshipOrderNowError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AutoshipOrderNow */
export type AutoshipOrderNowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

/** Autogenerated return type of AutoshipOrderNow. */
export type AutoshipOrderNowPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: AutoshipOrderNowError;
  patient?: Maybe<Patient>;
  /**
   * The subscription
   * @deprecated Go through patient type
   */
  subscription?: Maybe<Autoship>;
};

/** Autogenerated error type of AutoshipReschedule */
export type AutoshipRescheduleError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of AutoshipRescheduleGroup */
export type AutoshipRescheduleGroupError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AutoshipRescheduleGroup */
export type AutoshipRescheduleGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nextOrderDate: Scalars['Date']['input'];
  subscriptionsGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of AutoshipRescheduleGroup. */
export type AutoshipRescheduleGroupPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: AutoshipRescheduleGroupError;
  patient?: Maybe<Patient>;
};

/** Autogenerated input type of AutoshipReschedule */
export type AutoshipRescheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nextOrderDate: Scalars['Date']['input'];
  subscriptionId: Scalars['ID']['input'];
};

/** Autogenerated return type of AutoshipReschedule. */
export type AutoshipReschedulePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: AutoshipRescheduleError;
  patient?: Maybe<Patient>;
  /**
   * The updated subscription
   * @deprecated Go through patient type
   */
  subscription?: Maybe<Autoship>;
};

/** Attributes for scheduling autoship subscriptions */
export type AutoshipScheduleAttributes = {
  /** The frequency of the autoship */
  frequency: Scalars['String']['input'];
  /** The quantity of the variant to associate with the autoship */
  quantity: Scalars['Int']['input'];
  /** The variant to associate with the autoship */
  variantId: Scalars['ID']['input'];
};

/** Shipping rate for autoship estimation */
export type AutoshipShippingRate = Node & Timestamps & {
  /** The calculated cost of this shipping method */
  cost: Scalars['Float']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The shipping method description, which includes the shipping time */
  description: Scalars['String']['output'];
  /** A date or from+to date range when the shipment is estimated to arrive */
  estimatedShippingDates?: Maybe<Array<Scalars['Date']['output']>>;
  id: Scalars['ID']['output'];
  /** Whether a promotion has granted free shipping */
  isShippingFree: Scalars['Boolean']['output'];
  /** Whether this is the selected shipping rate for the shipment */
  selected: Scalars['Boolean']['output'];
  /** The name of the shipping method */
  shippingMethod: Scalars['String']['output'];
  /** The id of the shipping method */
  shippingMethodId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Set to `true` when shipping method has attached free shipping reward */
  usingReward: Scalars['Boolean']['output'];
};

/** Autogenerated error type of AutoshipSkipNextGroupOrder */
export type AutoshipSkipNextGroupOrderError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AutoshipSkipNextGroupOrder */
export type AutoshipSkipNextGroupOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriptionsGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of AutoshipSkipNextGroupOrder. */
export type AutoshipSkipNextGroupOrderPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: AutoshipSkipNextGroupOrderError;
  patient?: Maybe<Patient>;
};

/** Autogenerated error type of AutoshipSkipNextOrder */
export type AutoshipSkipNextOrderError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AutoshipSkipNextOrder */
export type AutoshipSkipNextOrderInput = {
  causedByVariantId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};

/** Autogenerated return type of AutoshipSkipNextOrder. */
export type AutoshipSkipNextOrderPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: AutoshipSkipNextOrderError;
  patient?: Maybe<Patient>;
  /**
   * The updated subscription
   * @deprecated Go through patient type
   */
  subscription?: Maybe<Autoship>;
};

/** User's uploaded image avatar */
export type Avatar = {
  id: Scalars['ID']['output'];
  /** Url for the user's avatar photo */
  photoUrl: Scalars['String']['output'];
};


/** User's uploaded image avatar */
export type AvatarPhotoUrlArgs = {
  filter: PhotoSizeFilters;
};

/** Avatax certificate type */
export type AvataxCertificate = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Customer associated with the certificate */
  customer: AvataxCustomer;
  /** Date the certificate expires at */
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** State associated with the certificate */
  state: State;
  /** Status of the certificate */
  status: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Avatax certificate filter list field */
export const AvataxCertificateFilterListField = {
  /** Filters for latest about to be expired certificates from each exposure zone */
  AboutToExpire: 'ABOUT_TO_EXPIRE',
  /** Filters for latest accepted certificates from each exposure zone */
  Accepted: 'ACCEPTED',
  /** Filters for latest expired certificates from each exposure zone */
  Expired: 'EXPIRED'
} as const;

export type AvataxCertificateFilterListField = typeof AvataxCertificateFilterListField[keyof typeof AvataxCertificateFilterListField];
/** Avatax certificate filter object */
export type AvataxCertificateFilterObject = {
  by?: InputMaybe<AvataxCertificateFilterListField>;
};

/** Avatax Customer */
export type AvataxCustomer = Node & Timestamps & {
  /** Link for CertExpress */
  certexpressLink?: Maybe<Scalars['String']['output']>;
  /** Tax exemption certificate */
  certificates: Array<AvataxCertificate>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** External Id */
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Store the Avatax customer belongs to */
  store: Store;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Avatax Customer */
export type AvataxCustomerCertificatesArgs = {
  filters?: InputMaybe<AvataxCertificateFilterObject>;
};

/** Address Validation Record associated to a Spree Address used as the shipping address of an order */
export type Avr = Timestamps & {
  /** ID of the object the address is linked with */
  addressableId?: Maybe<Scalars['String']['output']>;
  /** Object type the address is linked with */
  addressableType?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Date at which the user proceeded with their original address */
  enteredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Date at which the user selected to not be asked about this address anymore */
  ignoredAt?: Maybe<Scalars['DateTime']['output']>;
  /** Address line 1 */
  suggestedAddress1?: Maybe<Scalars['String']['output']>;
  /** Address line 2 */
  suggestedAddress2?: Maybe<Scalars['String']['output']>;
  /** Name of the city */
  suggestedCity?: Maybe<Scalars['String']['output']>;
  /** Id of the country */
  suggestedCountryId?: Maybe<Scalars['ID']['output']>;
  /** Name of the state or province */
  suggestedState?: Maybe<Scalars['String']['output']>;
  /** Zip or postal code */
  suggestedZipcode?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Date at which the user proceeded with the validated address */
  validatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** AWS Token type */
export type AwsToken = {
  /** Access key ID */
  accessKeyId: Scalars['String']['output'];
  /** AWS key region */
  region: Scalars['String']['output'];
  /** Secret access key */
  secretAccessKey: Scalars['String']['output'];
  /** Session token */
  sessionToken: Scalars['String']['output'];
};

/** Autogenerated error type of BaaFullscriptAcceptAgreement */
export type BaaFullscriptAcceptAgreementError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BaaFullscriptAcceptAgreement */
export type BaaFullscriptAcceptAgreementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  docuSignEnvelopeId?: InputMaybe<Scalars['String']['input']>;
  externalApplicationUid?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BaaFullscriptAcceptAgreement. */
export type BaaFullscriptAcceptAgreementPayload = {
  agreement?: Maybe<PractitionerAgreement>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: BaaFullscriptAcceptAgreementError;
  practitioner?: Maybe<Practitioner>;
};

/** Autogenerated error type of BaaFullscriptCheckAgreementStatus */
export type BaaFullscriptCheckAgreementStatusError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BaaFullscriptCheckAgreementStatus */
export type BaaFullscriptCheckAgreementStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  docuSignEnvelopeId: Scalars['String']['input'];
};

/** Autogenerated return type of BaaFullscriptCheckAgreementStatus. */
export type BaaFullscriptCheckAgreementStatusPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: BaaFullscriptCheckAgreementStatusError;
  isAgreementSigned?: Maybe<Scalars['Boolean']['output']>;
  practitioner?: Maybe<Practitioner>;
};

/** Autogenerated error type of BaaGenerateAgreement */
export type BaaGenerateAgreementError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BaaGenerateAgreement */
export type BaaGenerateAgreementInput = {
  agreementType: PractitionerAgreementTypes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  returnPath?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BaaGenerateAgreement. */
export type BaaGenerateAgreementPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  docuSignEnvelopeId?: Maybe<Scalars['String']['output']>;
  errors: BaaGenerateAgreementError;
  url?: Maybe<Scalars['String']['output']>;
};

/** Details of a balance sheet */
export type BalanceSheet = {
  /** The amount of balance */
  balanceCurrency: Scalars['Currency']['output'];
  /** The amount of cost of sales */
  cogsTotalCurrency: Scalars['Currency']['output'];
  /** The amount of net income */
  netIncomeCurrency: Scalars['Currency']['output'];
  /** The amount of sales total */
  salesTotalCurrency: Scalars['Currency']['output'];
  /** The year of the balance sheet. */
  year: Scalars['Int']['output'];
};

/** Details of a bank account belonging to the store owner */
export type BankAccount = Node & {
  /** Unique string of characters that identify the bank account */
  accountNumber: Scalars['String']['output'];
  /** Account type (eg. checking or savings) */
  accountType: Scalars['String']['output'];
  /** Name of the bank this account exists in */
  bankName: Scalars['String']['output'];
  /** Describes whether the bank account is active or not (eg. active, inactive) */
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** Autogenerated error type of BankAccountActivate */
export type BankAccountActivateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankAccountActivate */
export type BankAccountActivateInput = {
  bankAccountId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BankAccountActivate. */
export type BankAccountActivatePayload = {
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<BankAccountActivateError>;
};

/** Attributes for updating a store's bank account. */
export type BankAccountAttributes = {
  accountHeldBy: Scalars['String']['input'];
  accountHolder: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  accountType: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
  setAsDefault?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of BankAccountCreate */
export type BankAccountCreateError = {
  /** Field errors */
  fields?: Maybe<BankAccountCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of BankAccountCreate */
export type BankAccountCreateFieldError = {
  accountHeldBy?: Maybe<Array<Scalars['String']['output']>>;
  accountHolder?: Maybe<Array<Scalars['String']['output']>>;
  accountNumber?: Maybe<Array<Scalars['String']['output']>>;
  accountType?: Maybe<Array<Scalars['String']['output']>>;
  routingNumber?: Maybe<Array<Scalars['String']['output']>>;
  setAsDefault?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of BankAccountCreate */
export type BankAccountCreateInput = {
  attributes: BankAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BankAccountCreate. */
export type BankAccountCreatePayload = {
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<BankAccountCreateError>;
  store?: Maybe<Store>;
};

/** Common details of an address. Can be from 'addresses' or 'spree_addresses' */
export type BaseAddress = Timestamps & {
  /** Address line 1 */
  address1: Scalars['String']['output'];
  /** Address line 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** Name of city */
  city: Scalars['String']['output'];
  /** Full name of country */
  country: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** A value indicating whether the address is set as default for the store */
  default?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** A value indicating whether the address is a dropship address */
  isDropship?: Maybe<Scalars['Boolean']['output']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Full name of state or province */
  state: Scalars['String']['output'];
  /** Id of the state or province of the address. */
  stateId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Zipcode or postal code */
  zipcode: Scalars['String']['output'];
};

/** Autogenerated error type of BaseAddressMutation */
export type BaseAddressMutationError = {
  /** Field errors */
  fields?: Maybe<BaseAddressMutationFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of BaseAddressMutation */
export type BaseAddressMutationFieldError = {
  billingAddress1?: Maybe<Array<Scalars['String']['output']>>;
  billingAddress2?: Maybe<Array<Scalars['String']['output']>>;
  billingAddressId?: Maybe<Array<Scalars['String']['output']>>;
  billingCity?: Maybe<Array<Scalars['String']['output']>>;
  billingStateId?: Maybe<Array<Scalars['String']['output']>>;
  billingZipcode?: Maybe<Array<Scalars['String']['output']>>;
  shippingAddress1?: Maybe<Array<Scalars['String']['output']>>;
  shippingAddress2?: Maybe<Array<Scalars['String']['output']>>;
  shippingAddressId?: Maybe<Array<Scalars['String']['output']>>;
  shippingAndBilling?: Maybe<Array<Scalars['String']['output']>>;
  shippingCity?: Maybe<Array<Scalars['String']['output']>>;
  shippingStateId?: Maybe<Array<Scalars['String']['output']>>;
  shippingZipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated error type of Base */
export type BaseError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** An edge in a connection. */
export type BiomarkerCategoryForJourneyVariantEdge = {
  /** Biomarkers belonging to the biomarker category */
  biomarkers?: Maybe<Array<LabsBiomarker>>;
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LabsBiomarkerCategory>;
};

/** Categories of a biomarker result range */
export const BiomarkerResultRangeCategories = {
  /** Indicates that a biomarker result is within optimal range */
  Optimal: 'OPTIMAL',
  /** Indicates that a biomarker result is out of range */
  OutOfRange: 'OUT_OF_RANGE',
  /** Indicates that a biomarker result is within suboptimal range */
  Suboptimal: 'SUBOPTIMAL',
  /** Indicates that a biomarker result's range is unavailable */
  Unavailable: 'UNAVAILABLE'
} as const;

export type BiomarkerResultRangeCategories = typeof BiomarkerResultRangeCategories[keyof typeof BiomarkerResultRangeCategories];
/** The Brand name of a product */
export type Brand = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Number of search results that include products from this brand */
  esResultCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** Whether the brand is a lab brand */
  isLabs: Scalars['Boolean']['output'];
  /** Whether the brand has any types of restrictions */
  isRestricted: Scalars['Boolean']['output'];
  /** The brand's logo */
  logo?: Maybe<Scalars['String']['output']>;
  /** The brand's name */
  name: Scalars['String']['output'];
  /** NP's unique string identifier */
  npSlug?: Maybe<Scalars['String']['output']>;
  /** Whether the brand requires a prescription from a valid practitioner to be purchased */
  prescriptionOnly: Scalars['Boolean']['output'];
  /** The brand's products */
  products: ProductConnection;
  /** The brand's restrictions config */
  restrictionsConfig?: Maybe<BrandRestrictionsConfig>;
  /** The brand's unique string indentifier */
  slug: Scalars['String']['output'];
  /** The brand's rank on Fullscript */
  topBrand?: Maybe<TopBrand>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** The Brand name of a product */
export type BrandProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Brand. */
export type BrandConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BrandEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Brand>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BrandEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Brand>;
};

/** Brand filter list field */
export const BrandFilterListField = {
  /** Filters for emerson top brands */
  EeTopBrands: 'EE_TOP_BRANDS',
  /** Filters the featured brands for the megamenu */
  FeaturedBrands: 'FEATURED_BRANDS',
  /** Filters for top brands */
  TopBrands: 'TOP_BRANDS',
  /** Filters for brands with one or more new products */
  WithNewProducts: 'WITH_NEW_PRODUCTS'
} as const;

export type BrandFilterListField = typeof BrandFilterListField[keyof typeof BrandFilterListField];
/** Arguments for filtering brands. */
export type BrandFilterObject = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter brands by the starting letter. */
  letter?: InputMaybe<Scalars['String']['input']>;
  list?: InputMaybe<BrandFilterListField>;
  /** The search session ID to use for filtering. */
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
};

/** Returns a list of all available brands */
export type BrandList = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The brand's name */
  name: Scalars['String']['output'];
  /** The brand's unique string identifier */
  slug: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Brand level restrictions for a product */
export type BrandRestrictionsConfig = Node & Timestamps & {
  /** Whether the brand allows product to be purchased if a practitioner has favorited it */
  allowFavorites: Scalars['Boolean']['output'];
  /** Whether the brand allows previously purchased products to be purchased again */
  allowPreviouslyPurchased: Scalars['Boolean']['output'];
  /** Whether the brand allows product to be purchased on a public multi-patient plan */
  allowPublicMultiPatientPlan: Scalars['Boolean']['output'];
  /** Whether the brand allows product to be purchased on a selective multi-patient plan */
  allowSelectiveMultiPatientPlan: Scalars['Boolean']['output'];
  /** Whether the brand allows product to be showcased on a practitioner's storefront */
  allowShowcase: Scalars['Boolean']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Whether the brand requires a recommendation from a valid practitioner to be purchased */
  requireRecommendation: Scalars['Boolean']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Minimum advertised price (MAP) agreement for a brand */
export type BrandsMapAgreement = Node & Timestamps & {
  /** URL where the brand MAP agreement is stored */
  agreementUrl: Scalars['String']['output'];
  /** Brand associated with the brand MAP agreement */
  brand: Brand;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Datetime when the brand MAP agreement expired */
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Status for how the store has interacted with the brand MAP agreement */
  statusForStore?: Maybe<UsersMapAgreementStatusTypes>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Bulk Edit Process of a store */
export type BulkEditProcess = Node & Timestamps & {
  /** Bulk Edit Process completion date */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Status of the process (started,completed, failed) */
  currentState: Scalars['String']['output'];
  /** Discount field to bulk update entries with */
  discount?: Maybe<Scalars['Int']['output']>;
  /** Represents each patient to be updated by the process */
  entries: BulkEditProcessEntryConnection;
  id: Scalars['ID']['output'];
  /** Store doing the bulk edit process */
  store: Store;
  /** Initial count of records that is expected to be processesed */
  totalRecords?: Maybe<Scalars['Int']['output']>;
  /** The type of the bulk edit process. */
  type: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Prac/clerk that initiated the process */
  userRole: Roleable;
};


/** Bulk Edit Process of a store */
export type BulkEditProcessEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Bulk edit process entry fields */
export type BulkEditProcessEntry = Node & Timestamps & {
  /** The bulk edit process associated with this entry */
  bulkEditProcess: BulkEditProcess;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Status of the entry */
  status: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for BulkEditProcessEntry. */
export type BulkEditProcessEntryConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BulkEditProcessEntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BulkEditProcessEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BulkEditProcessEntryEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<BulkEditProcessEntry>;
};

/** Bulk Edit Summary of a store */
export type BulkEditSummary = {
  /** If patient discount is consistent this shows the discount */
  consistentPatientDiscount?: Maybe<Scalars['Int']['output']>;
  /** Number of patients whose discount will exceed the max when bulk editing */
  excludedFromBulkEditCount?: Maybe<Scalars['Int']['output']>;
  /** Number of patients whose discount will not exceed the max when bulk editing */
  includedInBulkEditCount?: Maybe<Scalars['Int']['output']>;
};

/** Attributes for assigning a practitioner for the given patients. */
export type BulkPatientAssignPractitionerAttributes = {
  allPatients?: InputMaybe<Scalars['Boolean']['input']>;
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  practitionerIdToAssign: Scalars['ID']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  withNoAssignedPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Attributes for removing a practitioners assignment for the given patients. */
export type BulkPatientReassignPractitionerAttributes = {
  allPatients?: InputMaybe<Scalars['Boolean']['input']>;
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  practitionerIdToRemove: Scalars['ID']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  withNoAssignedPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Attributes for creating a bulk edit process for given filter and excluded patient ids */
export type BulkSavingsUpdateAllAttributes = {
  discount: Scalars['Int']['input'];
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  withNoAssignedPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Attributes for creating a bulk edit process for given patients */
export type BulkSavingsUpdateAttributes = {
  discount: Scalars['Int']['input'];
  patientIds: Array<Scalars['ID']['input']>;
};

/** Attributes for adding a business address to a canadian store with business account. */
export type BusinessAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

/** Field error type for BusinessAddressFieldErrors. */
export type BusinessAddressFieldErrors = {
  /** Business address street address. */
  address1?: Maybe<Array<Scalars['String']['output']>>;
  /** Business address street address 2. */
  address2?: Maybe<Array<Scalars['String']['output']>>;
  /** Business Address city. */
  city?: Maybe<Array<Scalars['String']['output']>>;
  /** Business address phone. */
  phone?: Maybe<Array<Scalars['String']['output']>>;
  /** Business address state. */
  state?: Maybe<Array<Scalars['String']['output']>>;
  /** Business address postal code. */
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated error type of CancelLabAppointment */
export type CancelLabAppointmentError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CancelLabAppointment */
export type CancelLabAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labAppointmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelLabAppointment. */
export type CancelLabAppointmentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: CancelLabAppointmentError;
  labAppointment?: Maybe<LabsAppointment>;
  redirectToClient?: Maybe<Scalars['Boolean']['output']>;
};

/** Attributes for adding an Item to a express checkout cart */
export type CartAttributes = {
  /** The amount of an item you want to add to cart */
  quantity: Scalars['Int']['input'];
  /** Variant ID for adding an item to cart */
  variantId: Scalars['ID']['input'];
};

/** Attributes for adding an Item to a cart */
export type CartItemAttributes = {
  /** When autoship_selected is true creates a draft autoship variant for the line item with this frequency */
  autoshipFrequency?: InputMaybe<Scalars['String']['input']>;
  /** When true creates a draft autoship variant for the created line item */
  autoshipSelected?: InputMaybe<Scalars['Boolean']['input']>;
  /** The lab recommendation ID associated with the lab variant */
  labRecommendationId?: InputMaybe<Scalars['ID']['input']>;
  /** The amount of an item you want to add to cart */
  quantity: Scalars['Int']['input'];
  /** The Recommendation ID associated with the variant when it comes from an RX */
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
  /** Variant ID for adding an item to cart */
  variantId: Scalars['ID']['input'];
};

/** Set restrictions on how much of a store's catalog patients can see */
export const CatalogPermissionTypes = {
  /** Patients can only access products that are recommended for them */
  Closed: 'CLOSED',
  /** Patients have access to self-serve & purchase across the entire product catalog */
  Open: 'OPEN',
  /** Patients have access to entire catalog, but do not see which products have been favorited or added to  categories */
  PrivateCategories: 'PRIVATE_CATEGORIES',
  /** Patients can only access the practitioner's custom catalog and any products that are recommended for them */
  Restricted: 'RESTRICTED'
} as const;

export type CatalogPermissionTypes = typeof CatalogPermissionTypes[keyof typeof CatalogPermissionTypes];
/** Autogenerated error type of CatalogPermissionsUpdate */
export type CatalogPermissionsUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CatalogPermissionsUpdate */
export type CatalogPermissionsUpdateInput = {
  catalogPermission: CatalogPermissionTypes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CatalogPermissionsUpdate. */
export type CatalogPermissionsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: CatalogPermissionsUpdateError;
  store?: Maybe<Store>;
};

/** A category displayed in catalog, curated by a practitioner. Also known as favorites. */
export type Category = Node & Timestamps & {
  /** Describes the theme of the category (eg. general_wellness, best_sellers). */
  categoryType?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /**
   * ID of the grandparent category.
   * @deprecated Not going to be used
   */
  grandParentId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** Whether the category is the default one for the store */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the category is a labs category */
  isLabs: Scalars['Boolean']['output'];
  /** Indicates whether a category is visible to patients, or just to the staff of a store. */
  isVisibleToPatients?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the category. */
  name: Scalars['String']['output'];
  /**
   * ID of the parent category.
   * @deprecated Not going to be used
   */
  parentId?: Maybe<Scalars['ID']['output']>;
  /** Products in the category. */
  products: ProductConnection;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** A category displayed in catalog, curated by a practitioner. Also known as favorites. */
export type CategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Category. */
export type CategoryConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CategoryEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Category>;
};

/** Suggested category grouping with default products. */
export type CategoryGroup = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the category grouping. */
  name: Scalars['String']['output'];
  /** Products in the category grouping. */
  products: ProductConnection;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Suggested category grouping with default products. */
export type CategoryGroupProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for CategoryGroup. */
export type CategoryGroupConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryGroupEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CategoryGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CategoryGroupEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CategoryGroup>;
};

/** Category group filter list field */
export const CategoryGroupFilterListField = {
  /** Filters for category groups that are not already added by the store */
  NotAdded: 'NOT_ADDED'
} as const;

export type CategoryGroupFilterListField = typeof CategoryGroupFilterListField[keyof typeof CategoryGroupFilterListField];
/** Arguments for filtering category groups */
export type CategoryGroupFilterObject = {
  list?: InputMaybe<Array<CategoryGroupFilterListField>>;
};

/** Arguments for sorting favorite categories. */
export type CategorySort = {
  direction: SortDirection;
  field?: InputMaybe<CategorySortField>;
};

/** Fields for sorting favorite categories */
export const CategorySortField = {
  /** Sort by date a product was last added to the category */
  LastUpdatedAt: 'LAST_UPDATED_AT',
  /** Sort by category name */
  Name: 'NAME'
} as const;

export type CategorySortField = typeof CategorySortField[keyof typeof CategorySortField];
/** Certification used for authorizing dispensaries */
export type Certification = Node & Timestamps & {
  /** URL of the certificate */
  certificationUrl?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** State of the certificate (e.g. approved) */
  currentState: Scalars['String']['output'];
  /** Certification expiration date */
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Name of the certificate */
  name?: Maybe<Scalars['String']['output']>;
  /** Type of practitioner tied to this certificate */
  practitionerType?: Maybe<PractitionerType>;
  /** State/Province that licensed this certificate */
  state?: Maybe<State>;
  /** Store tied to this certificate */
  store: Store;
  /** Store platform tied to this certificate */
  storePlatform: StorePlatform;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Certification attributes overwrites */
export type CertificationOverwrites = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  modality?: InputMaybe<Scalars['String']['input']>;
};

/** Details of a Clerk */
export type Clerk = LegacyId & Node & Roleable & Timestamps & {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
  /**
   * Returns true if the owner practitioner's health care credentials or provider
   * id (NPI number) is valid and has been approved
   */
  anyCredentialApproved: Scalars['Boolean']['output'];
  /** Avatar for the clerk */
  avatar?: Maybe<Avatar>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Clerk's email address. */
  email: Scalars['String']['output'];
  /** Clerk's external EHR integrations */
  externalIntegrations?: Maybe<Array<ApiExternalMetadatum>>;
  /** Clerk's first name */
  firstName: Scalars['String']['output'];
  /** The full name used for the role */
  fullName: Scalars['String']['output'];
  /** Whether the clerk has access to financial reports */
  hasReportAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Whether the clerk is archived */
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the role's user account is locked */
  isLocked: Scalars['Boolean']['output'];
  /** Whether the clerk does not have an associated user */
  isSignupPending?: Maybe<Scalars['Boolean']['output']>;
  /** Clerk's last name */
  lastName: Scalars['String']['output'];
  /** Clerk's full name */
  name: Scalars['String']['output'];
  /** Clerk's shipping state */
  shipState?: Maybe<State>;
  /** Store that the clerk belongs to */
  store: Store;
  /** The id of the store the role belongs to */
  storeId?: Maybe<Scalars['ID']['output']>;
  /** The name of the store the role belongs to */
  storeName?: Maybe<Scalars['String']['output']>;
  /** The role type: Admin, Patient, Practitioner, Clerk, or OrganizationManager */
  type: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of ClerkArchive */
export type ClerkArchiveError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ClerkArchive */
export type ClerkArchiveInput = {
  clerkId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ClerkArchive. */
export type ClerkArchivePayload = {
  clerk?: Maybe<Clerk>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: ClerkArchiveError;
};

/** Arguments for filtering clerks. */
export type ClerkFilterObject = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of ClerkRestore */
export type ClerkRestoreError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ClerkRestore */
export type ClerkRestoreInput = {
  clerkId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ClerkRestore. */
export type ClerkRestorePayload = {
  clerk?: Maybe<Clerk>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: ClerkRestoreError;
};

/** Autogenerated error type of ClerkUpdate */
export type ClerkUpdateError = {
  /** Field errors */
  fields?: Maybe<ClerkUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of ClerkUpdate */
export type ClerkUpdateFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of ClerkUpdate */
export type ClerkUpdateInput = {
  attributes: OfficeClerkUpdateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ClerkUpdate. */
export type ClerkUpdatePayload = {
  clerk?: Maybe<Clerk>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ClerkUpdateError;
};

/** Autogenerated error type of CollectionProvidersForZipcode */
export type CollectionProvidersForZipcodeError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CollectionProvidersForZipcode */
export type CollectionProvidersForZipcodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  zipcode: Scalars['String']['input'];
};

/** Autogenerated return type of CollectionProvidersForZipcode. */
export type CollectionProvidersForZipcodePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: CollectionProvidersForZipcodeError;
  recommendationSampleCollectionProviders?: Maybe<Array<LabsRecommendationSampleCollectionProvider>>;
};

/** Arguments for filtering combined wholesale and emerson classic orders. */
export type CombinedOrdersFilterObject = {
  completedAt?: InputMaybe<DateRangeFilter>;
  /** @deprecated Use `query` instead. */
  number?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

/** Arguments for filtering combined wholesale and emerson classic line items. */
export type CombinedReorderLineItemsFilterObject = {
  orderedAt?: InputMaybe<DateRangeFilter>;
  queryString?: InputMaybe<Scalars['String']['input']>;
};

/** Arguments for sorting combined reorder line items. */
export type CombinedReorderLineItemsSort = {
  direction: SortDirection;
  field: CombinedReorderLineItemsSortField;
};

/** Emerson classic and wholesale reorder line items sort field */
export const CombinedReorderLineItemsSortField = {
  /** Date on which the line item was completed */
  CompletedAt: 'COMPLETED_AT',
  /** Name of the line item */
  Name: 'NAME',
  /** Total price of the line item */
  Price: 'PRICE'
} as const;

export type CombinedReorderLineItemsSortField = typeof CombinedReorderLineItemsSortField[keyof typeof CombinedReorderLineItemsSortField];
/** Commentary */
export type Commentary = Timestamps & {
  /** ID of the object the commentary is about */
  aboutId?: Maybe<Scalars['String']['output']>;
  /** Object type the commentary is about */
  aboutType?: Maybe<Scalars['String']['output']>;
  /** Contents of the commentary */
  contents?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes for creating a commentary */
export type CommentaryAttributes = {
  aboutId: Scalars['ID']['input'];
  contents: Scalars['String']['input'];
};

/** Autogenerated error type of CommentaryCreate */
export type CommentaryCreateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CommentaryCreate */
export type CommentaryCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  commentaryAttributes: CommentaryAttributes;
};

/** Autogenerated return type of CommentaryCreate. */
export type CommentaryCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  commentary?: Maybe<Commentary>;
  errors: CommentaryCreateError;
};

/** Autogenerated error type of CommentaryUpdate */
export type CommentaryUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CommentaryUpdate */
export type CommentaryUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  commentaryId: Scalars['ID']['input'];
  contents: Scalars['String']['input'];
};

/** Autogenerated return type of CommentaryUpdate. */
export type CommentaryUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  commentary?: Maybe<Commentary>;
  errors: CommentaryUpdateError;
};

/**
 * A commonly used medication in the FS Assist system. This type represents
 * medications that are frequently prescribed or referenced in the system.
 */
export type CommonMedication = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The associated medication product */
  medicationProduct: MedicationProduct;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Arguments for sorting the commonly prescribed products. */
export type CommonlyPrescribedProductSort = {
  direction: SortDirection;
  field: CommonlyPrescribedProductSortField;
};

/** Commonly prescribed products sort fields. */
export const CommonlyPrescribedProductSortField = {
  /** Sorts alphabetically. */
  Alphabetical: 'ALPHABETICAL',
  /** Sorts by the variant prescription frequency. */
  PrescriptionFrequency: 'PRESCRIPTION_FREQUENCY',
  /** Sorts by price. */
  Price: 'PRICE'
} as const;

export type CommonlyPrescribedProductSortField = typeof CommonlyPrescribedProductSortField[keyof typeof CommonlyPrescribedProductSortField];
/** Arguments for filtering commonly prescribed variants. */
export type CommonlyPrescribedVariantFilterObject = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Attributes for creating an audio chunk */
export type CopilotAudioChunkCreateAttributes = {
  copilotAudioSessionId: Scalars['ID']['input'];
  /** The results from the audio session as returned by AWS Transcribe */
  results: Scalars['String']['input'];
};

/** Autogenerated error type of CopilotAudioChunkCreate */
export type CopilotAudioChunkCreateError = {
  /** Field errors */
  fields?: Maybe<CopilotAudioChunkCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of CopilotAudioChunkCreate */
export type CopilotAudioChunkCreateFieldError = {
  copilotAudioSessionId?: Maybe<Array<Scalars['String']['output']>>;
  results?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of CopilotAudioChunkCreate */
export type CopilotAudioChunkCreateInput = {
  attributes: CopilotAudioChunkCreateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CopilotAudioChunkCreate. */
export type CopilotAudioChunkCreatePayload = {
  audioChunk?: Maybe<AudioChunk>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotAudioChunkCreateError>;
};

/** Attributes for ending an audio session */
export type CopilotAudioSessionEndAttributes = {
  copilotAudioSessionId: Scalars['ID']['input'];
  /** Kind of summary to generate (standard | integrative) */
  kind?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of CopilotAudioSessionEnd */
export type CopilotAudioSessionEndError = {
  /** Field errors */
  fields?: Maybe<CopilotAudioSessionEndFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of CopilotAudioSessionEnd */
export type CopilotAudioSessionEndFieldError = {
  copilotAudioSessionId?: Maybe<Array<Scalars['String']['output']>>;
  kind?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of CopilotAudioSessionEnd */
export type CopilotAudioSessionEndInput = {
  attributes: CopilotAudioSessionEndAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CopilotAudioSessionEnd. */
export type CopilotAudioSessionEndPayload = {
  audioSession?: Maybe<AudioSession>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotAudioSessionEndError>;
};

/** Autogenerated error type of CopilotAudioSessionStart */
export type CopilotAudioSessionStartError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CopilotAudioSessionStart */
export type CopilotAudioSessionStartInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  /** One of in-person | call | upload */
  recordingType: Scalars['String']['input'];
};

/** Autogenerated return type of CopilotAudioSessionStart. */
export type CopilotAudioSessionStartPayload = {
  audioSession?: Maybe<AudioSession>;
  /** AWS token information to use with Transcribe service */
  awsToken?: Maybe<AwsToken>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotAudioSessionStartError>;
};

/** Autogenerated error type of CopilotCreateResources */
export type CopilotCreateResourcesError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CopilotCreateResources */
export type CopilotCreateResourcesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotAudioSessionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CopilotCreateResources. */
export type CopilotCreateResourcesPayload = {
  audioSession?: Maybe<AudioSession>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotCreateResourcesError>;
};

/** Attributes for creating an audio summary */
export type CopilotCreateSummaryAttributes = {
  copilotAudioSessionId: Scalars['ID']['input'];
  /** Kind of summary to generate (standard | integrative) */
  kind?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of CopilotCreateSummary */
export type CopilotCreateSummaryError = {
  /** Field errors */
  fields?: Maybe<CopilotCreateSummaryFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of CopilotCreateSummary */
export type CopilotCreateSummaryFieldError = {
  copilotAudioSessionId?: Maybe<Array<Scalars['String']['output']>>;
  kind?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of CopilotCreateSummary */
export type CopilotCreateSummaryInput = {
  attributes: CopilotCreateSummaryAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CopilotCreateSummary. */
export type CopilotCreateSummaryPayload = {
  audioSession?: Maybe<AudioSession>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotCreateSummaryError>;
};

/** Autogenerated error type of CopilotCreateSupplements */
export type CopilotCreateSupplementsError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CopilotCreateSupplements */
export type CopilotCreateSupplementsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotAudioSessionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CopilotCreateSupplements. */
export type CopilotCreateSupplementsPayload = {
  audioSession?: Maybe<AudioSession>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotCreateSupplementsError>;
};

/** Copilot mutations */
export type CopilotMutation = {
  /** Creates resources list for a session note */
  sessionNotesCreateResources?: Maybe<SessionNotesCreateResourcesPayload>;
  /** Creates summary resources for a session note */
  sessionNotesCreateSummaryMessage?: Maybe<SessionNotesCreateSummaryMessagePayload>;
  /** Creates supplements for the session note */
  sessionNotesCreateSupplements?: Maybe<SessionNotesCreateSupplementsPayload>;
  /** Creates a session note or updates existing one */
  sessionNotesSave?: Maybe<SessionNotesSavePayload>;
};


/** Copilot mutations */
export type CopilotMutationSessionNotesCreateResourcesArgs = {
  input: SessionNotesCreateResourcesInput;
};


/** Copilot mutations */
export type CopilotMutationSessionNotesCreateSummaryMessageArgs = {
  input: SessionNotesCreateSummaryMessageInput;
};


/** Copilot mutations */
export type CopilotMutationSessionNotesCreateSupplementsArgs = {
  input: SessionNotesCreateSupplementsInput;
};


/** Copilot mutations */
export type CopilotMutationSessionNotesSaveArgs = {
  input: SessionNotesSaveInput;
};

/** Autogenerated error type of CopilotUploadTranscript */
export type CopilotUploadTranscriptError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CopilotUploadTranscript */
export type CopilotUploadTranscriptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  transcript: Scalars['String']['input'];
};

/** Autogenerated return type of CopilotUploadTranscript. */
export type CopilotUploadTranscriptPayload = {
  audioSession?: Maybe<AudioSession>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CopilotUploadTranscriptError>;
};

/** Information related to a country */
export type Country = {
  id: Scalars['ID']['output'];
  /** ISO code for the country. */
  iso?: Maybe<Scalars['String']['output']>;
  /** ISO3 code for the country. */
  iso3?: Maybe<Scalars['String']['output']>;
  /** ISO name for the country. */
  isoName?: Maybe<Scalars['String']['output']>;
  /** Full name of the country. */
  name?: Maybe<Scalars['String']['output']>;
  /** Numeric code for the country. */
  numcode?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated error type of CreateProductAccessRequest */
export type CreateProductAccessRequestError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateProductAccessRequest */
export type CreateProductAccessRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateProductAccessRequest. */
export type CreateProductAccessRequestPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CreateProductAccessRequestError>;
  productAccessRequest?: Maybe<ProductAccessRequest>;
};

/** Autogenerated error type of CreateSummaryMessage */
export type CreateSummaryMessageError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateSummaryMessage */
export type CreateSummaryMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotAudioSessionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateSummaryMessage. */
export type CreateSummaryMessagePayload = {
  audioSession?: Maybe<AudioSession>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<CreateSummaryMessageError>;
};

/** Details of a Credit Card */
export type CreditCard = Node & Timestamps & {
  /** The billing address for the Credit Card */
  billingAddress?: Maybe<PatientAddress>;
  /** The kind of Credit Card (ie. Visa, MasterCard, etc.) */
  ccType: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Boolean value indicating whether this card is set as default */
  defaultCard?: Maybe<Scalars['Boolean']['output']>;
  /** True if the Credit Card is expired. */
  expired: Scalars['Boolean']['output'];
  /** The first name of the Credit Card holder. */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The last 4 digits of the Credit Card. */
  lastDigits: Scalars['String']['output'];
  /** The last name of the Credit Card holder. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The Credit Card expiry month. */
  month: Scalars['String']['output'];
  /** The full name of the Credit Card holder. */
  name: Scalars['String']['output'];
  /** Boolean value indicating whether this card is set as default for the patient */
  patientDefaultCard?: Maybe<Scalars['Boolean']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The Credit Card expiry year. */
  year: Scalars['String']['output'];
};

/** Attributes for adding a credit card to an order */
export type CreditCardAttributes = {
  defaultCard?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  oneTimeUse?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated input type of CreditCardDelete */
export type CreditCardDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCardId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreditCardDelete. */
export type CreditCardDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
};

/** Autogenerated error type of CreditCardSetDefault */
export type CreditCardSetDefaultError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreditCardSetDefault */
export type CreditCardSetDefaultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCardId: Scalars['ID']['input'];
  default: Scalars['Boolean']['input'];
};

/** Autogenerated return type of CreditCardSetDefault. */
export type CreditCardSetDefaultPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  creditCard?: Maybe<CreditCard>;
  errors: CreditCardSetDefaultError;
};

/** Autogenerated error type of CreditCardUpdate */
export type CreditCardUpdateError = {
  /** Field errors */
  fields?: Maybe<CreditCardUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of CreditCardUpdate */
export type CreditCardUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  countryId?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  shippingAndBilling?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of CreditCardUpdate */
export type CreditCardUpdateInput = {
  attributes?: InputMaybe<PatientAccountAddressAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCardId: Scalars['ID']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  setAutoship?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of CreditCardUpdate. */
export type CreditCardUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  creditCard?: Maybe<CreditCard>;
  errors: CreditCardUpdateError;
  patient?: Maybe<Patient>;
};

/** Daily Pack Type */
export type DailyPack = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The name of the Daily Pack */
  name?: Maybe<Scalars['String']['output']>;
  /** The name of the Daily Pack with the supply appended */
  nameWithSupply?: Maybe<Scalars['String']['output']>;
  /** The pills in this Dynamic Daily Pack */
  packPills?: Maybe<PackPillConnection>;
  /** The practitioner that created this Dynamic Daily Pack */
  practitioner?: Maybe<Practitioner>;
  /** The Spree::Product representing the Daily Pack */
  product: Product;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Validation errors to say whether pack was built properly */
  validation?: Maybe<DailyPackValidationType>;
  /** The Spree::Variant representing the Daily Pack. */
  variant: Variant;
};


/** Daily Pack Type */
export type DailyPackPackPillsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for DailyPack. */
export type DailyPackConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DailyPackEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DailyPack>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DailyPackEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DailyPack>;
};

/** Daily Pack Pill Type (based on VariantType) */
export type DailyPackPill = Timestamps & {
  /** Availability of the variant. (ex: available, unavailable, discontinued, backordered) */
  availabilityStatus: Scalars['String']['output'];
  /** Brand of the variant */
  brand: Brand;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The default am dosage of this pill. */
  defaultAmDosage?: Maybe<Scalars['Int']['output']>;
  /** Default dosage instruction */
  defaultDosageInstruction?: Maybe<DefaultDosageInstruction>;
  /** The default pm dosage of this pill. */
  defaultPmDosage?: Maybe<Scalars['Int']['output']>;
  /** The first DosageFormat of the pill. */
  dosageFormat?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** URL for the variant's image */
  image: Scalars['String']['output'];
  /** Whether the variant is the master variant */
  isMaster: Scalars['Boolean']['output'];
  /** Cost of pill considering one pill per day for a month */
  monthlyMsrp: Scalars['Float']['output'];
  /**
   * Cost of pill considering one pill per day for a month
   * @deprecated Use 'monthlyMsrp' field instead
   */
  monthlyPrice: Scalars['Float']['output'];
  /** Cost of pill considering one pill per day for a month (factoring in discounts) */
  monthlySellPrice: Scalars['Float']['output'];
  /** Manufacturer's suggested retail price */
  msrp?: Maybe<Scalars['Float']['output']>;
  /** Name of the variant's product */
  name: Scalars['String']['output'];
  /** The category that this pill belongs to. */
  pillCategories?: Maybe<Array<PillCategory>>;
  /** The description of this pill. */
  pillDescription?: Maybe<Scalars['String']['output']>;
  /** The name of this pill. */
  pillName?: Maybe<Scalars['String']['output']>;
  /** Product for the variant */
  product: Product;
  /** Product ID for the variant */
  productId: Scalars['ID']['output'];
  /** Price of the variant for the current role, including any discounts */
  sellPrice: Scalars['Float']['output'];
  /** Formatted string for the sell price including the currency (ex: '$25.90') */
  sellPriceCurrency: Scalars['Currency']['output'];
  /** Code used to identify the variant (Stock keeping unit) */
  sku: Scalars['String']['output'];
  /** Universal product code */
  upc?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Whether the variant is available for wholesale (practitioners) */
  wholesale: Scalars['Boolean']['output'];
  /** Price of the variant when bought through wholesale */
  wholesalePrice?: Maybe<Scalars['Float']['output']>;
  /** Formatted string for the wholesale price including the currency (ex: '$25.90') */
  wholesalePriceCurrency?: Maybe<Scalars['Currency']['output']>;
};

/** The connection type for DailyPackPill. */
export type DailyPackPillConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DailyPackPillEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DailyPackPill>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DailyPackPillEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DailyPackPill>;
};

/** Arguments for filtering Daily Pack pills. */
export type DailyPackPillFilterObject = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Arguments for filtering daily pack templates. */
export type DailyPackTemplateFilterObject = {
  /** Filter daily pack templates by practitioner ID */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter daily pack templates by matching name queries. */
  query?: InputMaybe<Scalars['String']['input']>;
};

/** Arguments for sorting daily pack templates. */
export type DailyPackTemplateSort = {
  direction: SortDirection;
  field: DailyPackTemplateSortField;
};

/** Arguments for sorting daily pack templates */
export const DailyPackTemplateSortField = {
  /** Date on which the template was created */
  CreatedAt: 'CREATED_AT',
  /** Name of the template */
  Name: 'NAME'
} as const;

export type DailyPackTemplateSortField = typeof DailyPackTemplateSortField[keyof typeof DailyPackTemplateSortField];
/** Daily Pack Validation Type */
export type DailyPackValidationType = {
  /** Returns true if too many pills in am pack */
  isAmOverfilled?: Maybe<Scalars['Boolean']['output']>;
  /** Returns true if too many pills in pm pack */
  isPmOverfilled?: Maybe<Scalars['Boolean']['output']>;
  /** Returns true if not enough different pills selected */
  isUnderfilled?: Maybe<Scalars['Boolean']['output']>;
};

/** The data to be displayed on the store analytics dashboard */
export type Dashboard = {
  /** The autorefill orders data for the store */
  autorefillOrdersData: AutorefillOrdersData;
  /** The autorefill revenue data for the store */
  autorefillRevenueData: AutorefillRevenueData;
  /** Data is included for the store up until this date, this is the day before the last snowflake sync */
  dataRepresentedTo: Scalars['DateTime']['output'];
  /** The first time autorefill patients data for the store */
  firstTimeAutorefillPatientsData: FirstTimeAutorefillPatientsData;
  id: Scalars['ID']['output'];
  /** Orders placed  data for the store */
  orderingPatientsData: OrderingPatientsData;
  /** Orders placed  data for the store */
  ordersPlacedData: OrdersPlacedData;
  /** The plans activated data for the store */
  plansActivatedData: PlansActivatedData;
  /** Profit margin data for the store */
  profitMarginData: ProfitMarginData;
  /** The sales data for the store */
  salesData: SalesData;
  /** The treatment plan conversation rate data for the store */
  treatmentPlanConversionRateData: TreatmentPlanConversionRateData;
};

/** Date range used for filtering. */
export type DateRangeFilter = {
  /** Greater than */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Less than */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Default dosage instruction for a variant */
export type DefaultDosageInstruction = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Variant associated with the dosage instruction */
  defaultVariant: Variant;
  /** The number of units that need to be taken with each dose */
  dosageAmount?: Maybe<Scalars['String']['output']>;
  /** How long the doses must be taken */
  dosageDuration?: Maybe<Scalars['String']['output']>;
  /** The form in which the the product is marketed for use */
  dosageFormat?: Maybe<Scalars['String']['output']>;
  /** The number of times that the dose must be taken each day */
  dosageFrequency?: Maybe<Scalars['String']['output']>;
  /** Personalized dosage instructions */
  dosagePermutations?: Maybe<Scalars['String']['output']>;
  /**
   * Recommended quantity of units to purchase
   * @deprecated Removed practitioner ability to select dosage quantity.
   */
  dosageQuantity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** When advanced dosage information is missing (dosage_amount, dosage_frequency, dosage_duration, dosage_format) */
  isTextOnly: Scalars['Boolean']['output'];
  /** Practitioner associated with the dosage instruction */
  practitioner: Practitioner;
  /** Product associated with the dosage instruction */
  product: Product;
  /** The take with instructions for the dosage instruction */
  takeWith?: Maybe<Scalars['String']['output']>;
  /** The dosage time of day list for the dosage instruction */
  timeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Whether product should be taken with food or not */
  withFood: Scalars['Boolean']['output'];
};

/** Autogenerated error type of DeleteDailyPackTemplate */
export type DeleteDailyPackTemplateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DeleteDailyPackTemplate */
export type DeleteDailyPackTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dailyPackId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteDailyPackTemplate. */
export type DeleteDailyPackTemplatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: DeleteDailyPackTemplateError;
};

/** Ingredient recommendation for a specific demographic */
export type DemographicVariation = {
  /** The demographic, ie- female */
  category: Scalars['String']['output'];
  /** Articles supporting the recommendation */
  evidence?: Maybe<Array<Evidence>>;
};

export type Dependent = Node & {
  /** Type of dependent (e.g. minor, adult) */
  dependentType: Scalars['String']['output'];
  /** Email of the newly emancipated dependent */
  emancipationInviteEmail?: Maybe<Scalars['String']['output']>;
  /** First name of the dependent */
  firstName: Scalars['String']['output'];
  /** Full name of the dependent */
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Whether the guardian has authorized the parent-child relationship */
  isGuardianAuthorized: Scalars['Boolean']['output'];
  /** The patient in the current store that is associated with the dependent */
  patientInCurrentStore?: Maybe<Patient>;
  /** The state that the dependent is in (e. active, started_emancipation, emancipation_invitation_sent, completed_emancipation */
  state: DependentState;
};

/** Autogenerated error type of DependentEmancipationInviteResend */
export type DependentEmancipationInviteResendError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DependentEmancipationInviteResend */
export type DependentEmancipationInviteResendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dependentId: Scalars['ID']['input'];
};

/** Autogenerated return type of DependentEmancipationInviteResend. */
export type DependentEmancipationInviteResendPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors if the action was unsuccessful */
  errors: DependentEmancipationInviteResendError;
};

/** Filters for DependentType */
export type DependentFilterObject = {
  /** Filters by dependents that have patients in the current store */
  inCurrentStore?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Patient intake status */
export const DependentState = {
  /** The dependent is under 18 years old */
  Active: 'ACTIVE',
  /** The dependent has completed emancipation */
  CompletedEmancipation: 'COMPLETED_EMANCIPATION',
  /** The dependent has been sent the emancipation invitation but has not yet completed emancipation */
  EmancipationInvitationSent: 'EMANCIPATION_INVITATION_SENT',
  /** The dependent has turned 18 years old and has not been sent the emancipation invitation */
  StartedEmancipation: 'STARTED_EMANCIPATION'
} as const;

export type DependentState = typeof DependentState[keyof typeof DependentState];
export const DesiredTones = {
  /** A concise tone */
  Concise: 'CONCISE',
  /** A friendly tone */
  Friendly: 'FRIENDLY',
  /** A professional tone */
  Professional: 'PROFESSIONAL'
} as const;

export type DesiredTones = typeof DesiredTones[keyof typeof DesiredTones];
/** Autogenerated error type of Discontinue */
export type DiscontinueError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of Discontinue */
export type DiscontinueInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dailyPackId: Scalars['ID']['input'];
};

/** Autogenerated return type of Discontinue. */
export type DiscontinuePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: DiscontinueError;
};

/** Document Attachments */
export type Document = Node & Timestamps & Uploadable & {
  /** Attachment URL link */
  attachment: Scalars['String']['output'];
  /** Attachment File name */
  attachmentFileName: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Document Category */
  documentCategory?: Maybe<ImatContentCategory>;
  /** Document Library */
  documentLibrary: Scalars['Boolean']['output'];
  /** Document Type */
  documentType?: Maybe<ImatContentType>;
  /** Name of the file */
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Returns whether it's attached to a plan or not */
  isAttached: Scalars['Boolean']['output'];
  /** Document Name */
  name?: Maybe<Scalars['String']['output']>;
  /** URL for viewing content that allows for tracking actions */
  trackingUrl: Scalars['String']['output'];
  /** Type of the uploadable, Imat::Content or Document */
  type: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Document Attachments */
export type DocumentIsAttachedArgs = {
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

/** A link between an attachable and an uploadable. */
export type DocumentAttachment = Node & Timestamps & {
  /** The item to which the Document or Imat::Content is attached (eg. treatment plan). */
  attachable: Attachable;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Date the document attachment was deleted. */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The Document or Imat::Content that is attached. */
  uploadable: Uploadable;
};

/** The connection type for DocumentAttachment. */
export type DocumentAttachmentConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentAttachmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DocumentAttachment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DocumentAttachmentEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DocumentAttachment>;
};

/** The connection type for Document. */
export type DocumentConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Document>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DocumentEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Document>;
};

/** Arguments for filtering documents. */
export type DocumentFilterObject = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  otherCategory?: InputMaybe<Scalars['Boolean']['input']>;
  otherType?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Arguments for sorting documents. */
export type DocumentSort = {
  direction: SortDirection;
  field: DocumentSortField;
};

/** Arguments for sorting documents */
export const DocumentSortField = {
  /** Date on which the document was created */
  CreatedAt: 'CREATED_AT',
  /** Name of the document */
  Name: 'NAME'
} as const;

export type DocumentSortField = typeof DocumentSortField[keyof typeof DocumentSortField];
/** Dosage formats for products */
export type DosageFormat = Node & Timestamps & {
  /** Base format (ex. capsule, tablet, etc.) */
  baseFormat: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The number of items in a search result that are of this object's format */
  esResultCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Variation of the base format (ex. gummy is a variation of chewable) */
  variation: Scalars['String']['output'];
};

/** Arguments for sorting dosage formats. */
export type DosageFormatSort = {
  direction: SortDirection;
  field: DosageFormatSortField;
};

/** Dosage format sort field */
export const DosageFormatSortField = {
  /** How often a dosage format is used to filter search results */
  SearchPopularity: 'SEARCH_POPULARITY',
  /** Sorts by sum of products count for base format */
  SumProductsCount: 'SUM_PRODUCTS_COUNT'
} as const;

export type DosageFormatSortField = typeof DosageFormatSortField[keyof typeof DosageFormatSortField];
/** Autogenerated error type of DraftAutoshipVariant */
export type DraftAutoshipVariantError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DraftAutoshipVariant */
export type DraftAutoshipVariantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  /** The line item to create the draft autoship variant for */
  lineItemId: Scalars['ID']['input'];
};

/** Autogenerated return type of DraftAutoshipVariant. */
export type DraftAutoshipVariantPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** error message when things go wrong */
  errors: DraftAutoshipVariantError;
  lineItem?: Maybe<LineItem>;
};

/** Details of a draft dynamic daily pack */
export type DraftDynamicDailyPack = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Multi patient plan the draft dynamic daily pack belongs to */
  multiPatientPlan?: Maybe<MultiPatientPlan>;
  /** Treatment plan the draft dynamic daily pack belongs to */
  treatmentPlan?: Maybe<TreatmentPlan>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Variant for the draft dynamic daily pack */
  variant: Variant;
};

/** Autogenerated error type of EDeliveryConsent1099kUpdate */
export type EDeliveryConsent1099kUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of EDeliveryConsent1099kUpdate */
export type EDeliveryConsent1099kUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eDeliveryConsent: Scalars['Boolean']['input'];
};

/** Autogenerated return type of EDeliveryConsent1099kUpdate. */
export type EDeliveryConsent1099kUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<EDeliveryConsent1099kUpdateError>;
  store?: Maybe<Store>;
};

/** Details for an earnings report */
export type EarningsReport = {
  /** Earnings report entry has patient, payee and order information */
  entries?: Maybe<Array<EarningsReportEntry>>;
};


/** Details for an earnings report */
export type EarningsReportEntriesArgs = {
  patientId?: InputMaybe<Scalars['Int']['input']>;
  payees?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Patient that has an order in an earnings report */
export type EarningsReportEntry = {
  /** First name of the patient */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Last name of the patient */
  lastName?: Maybe<Scalars['String']['output']>;
  /** orders of a patient */
  orders?: Maybe<Array<EarningsReportOrder>>;
  /** ID of the patient */
  patientId?: Maybe<Scalars['ID']['output']>;
  /** Practitioner attributed to the patient */
  payee?: Maybe<Practitioner>;
  /** Clinic payees */
  payeesClinic?: Maybe<Array<Store>>;
  /** Other payees (non-prescribing pracs) */
  payeesOther?: Maybe<Array<Practitioner>>;
  /** Prescribing prac payees */
  payeesPracs?: Maybe<Array<Practitioner>>;
};

/** A patient's order, found in the earnings report */
export type EarningsReportOrder = {
  /** ID of the practitioner that is attributable to the order */
  attributablePractitionerId?: Maybe<Scalars['Int']['output']>;
  /** Bonus earnings of the order */
  bonusEarningsAmount?: Maybe<Scalars['Currency']['output']>;
  /** When the order was completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Earnings of the order */
  earningsAmount?: Maybe<Scalars['Currency']['output']>;
  /** ID of the order */
  id?: Maybe<Scalars['ID']['output']>;
  /** Items of the order */
  items?: Maybe<Array<EarningsReportOrderItem>>;
  /** Number of the order */
  number?: Maybe<Scalars['String']['output']>;
  /** Practitioner associated with the order */
  payee?: Maybe<Practitioner>;
};

/** Item of an order in an earnings report */
export type EarningsReportOrderItem = {
  /** Price of the cancelled item */
  deletedInventoryUnitAmount?: Maybe<Scalars['Int']['output']>;
  /** Price of cancelled item in currency */
  deletedInventoryUnitAmountCurrency?: Maybe<Scalars['Currency']['output']>;
  /** When the item was cancelled */
  deletedInventoryUnitCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the item if it's a cancellation */
  deletedInventoryUnitId?: Maybe<Scalars['Int']['output']>;
  /** Type of the disbursal (net_proceeds, bonus_proceeds ) */
  disbType?: Maybe<Scalars['String']['output']>;
  /** Whether the order item is an earning, return or cancellation */
  orderItemType?: Maybe<Scalars['String']['output']>;
  /** Prescriber of the item */
  prescriber?: Maybe<Scalars['String']['output']>;
  /** Quantity of the item */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** Amount of the return/cancellation */
  rmaAmount?: Maybe<Scalars['Int']['output']>;
  /** Amount in currency */
  rmaAmountCurrency?: Maybe<Scalars['Currency']['output']>;
  /** When the item was returned */
  rmaCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** RMA id of the item if it's a return */
  rmaId?: Maybe<Scalars['Int']['output']>;
  /** SKU of the item */
  variantSku?: Maybe<Scalars['String']['output']>;
};

/** Attributes for collecting events for edit treatment plan */
export type EditClickAttributes = {
  pageVersion: Scalars['String']['input'];
  treatmentPlanId: Scalars['ID']['input'];
  treatmentPlanStatus: Scalars['String']['input'];
};

/** Autogenerated error type of ElationLinkPatient */
export type ElationLinkPatientError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ElationLinkPatient */
export type ElationLinkPatientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalPatient: ExternalPatient;
  integrationUid: Scalars['String']['input'];
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of ElationLinkPatient. */
export type ElationLinkPatientPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ElationLinkPatientError;
  patientMapping?: Maybe<ApiExternalMetadatum>;
};

/** Autogenerated error type of ElationLinkPhysician */
export type ElationLinkPhysicianError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ElationLinkPhysician */
export type ElationLinkPhysicianInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalPractitionerId: Scalars['String']['input'];
  integrationUid: Scalars['String']['input'];
};

/** Autogenerated return type of ElationLinkPhysician. */
export type ElationLinkPhysicianPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ElationLinkPhysicianError;
  practitionerMapping?: Maybe<ApiExternalMetadatum>;
};

/** Autogenerated error type of ElationLinkPractitioner */
export type ElationLinkPractitionerError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ElationLinkPractitioner */
export type ElationLinkPractitionerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Mutation renamed ElationLinkPhysician as it now links Clerks as well as Practitioners */
  externalPractitionerId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Mutation renamed ElationLinkPhysician as it now links Clerks as well as Practitioners */
  integrationUid?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ElationLinkPractitioner. */
export type ElationLinkPractitionerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ElationLinkPractitionerError;
  practitionerMapping?: Maybe<ApiExternalMetadatum>;
};

/** Autogenerated error type of Emancipation */
export type EmancipationError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of Emancipation */
export type EmancipationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dependentId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
};

/** Autogenerated return type of Emancipation. */
export type EmancipationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors if the action was unsuccessful */
  errors: EmancipationError;
};

/** Autogenerated error type of EmersonAuthUserSignIn */
export type EmersonAuthUserSignInError = {
  /** Field errors */
  fields?: Maybe<EmersonAuthUserSignInFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of EmersonAuthUserSignIn */
export type EmersonAuthUserSignInFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  optional?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
  rememberMe?: Maybe<Array<Scalars['String']['output']>>;
  requestedPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  staySignedIn?: Maybe<Array<Scalars['String']['output']>>;
  usingBiometrics?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of EmersonAuthUserSignIn */
export type EmersonAuthUserSignInInput = {
  attributes: AuthUserSignInAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EmersonAuthUserSignIn. */
export type EmersonAuthUserSignInPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  emailMasked?: Maybe<Scalars['String']['output']>;
  errors: EmersonAuthUserSignInError;
  otpMethod?: Maybe<TwoFactorAuthenticationMethods>;
  phoneLast4?: Maybe<Scalars['String']['output']>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  twoFactorAuthenticationToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

/** Emerson Classic Address */
export type EmersonClassicAddress = {
  /** Address line 1 */
  address1?: Maybe<Scalars['String']['output']>;
  /** Address line 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** Name of the address */
  addressName?: Maybe<Scalars['String']['output']>;
  /** Name of city */
  city?: Maybe<Scalars['String']['output']>;
  /** Full name of country */
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Full name of state or province */
  state?: Maybe<Scalars['String']['output']>;
  /** Zipcode or postal code */
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** Input object for classic backordered item */
export type EmersonClassicBackorderedItemInput = {
  itemCode: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

/** Arguments for sorting Combined orders. */
export type EmersonClassicCombinedOrdersSort = {
  direction: SortDirection;
  field: EmersonClassicCombinedOrdersSortField;
};

/** Emerson Classic order sort field */
export const EmersonClassicCombinedOrdersSortField = {
  /** Date on which the order was placed */
  CompletedAt: 'COMPLETED_AT',
  /** Total price of the order */
  Total: 'TOTAL'
} as const;

export type EmersonClassicCombinedOrdersSortField = typeof EmersonClassicCombinedOrdersSortField[keyof typeof EmersonClassicCombinedOrdersSortField];
/** Emerson Classic Credit Card */
export type EmersonClassicCreditCard = {
  /** The kind of Credit Card (ie. Visa, MasterCard, etc.) */
  ccType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The last 4 digits of the Credit Card. */
  lastDigits?: Maybe<Scalars['String']['output']>;
};

/** Emerson Classic Inventory Unit */
export type EmersonClassicInventoryUnit = {
  /** The ID of the inventory unit */
  id: Scalars['ID']['output'];
  /** Line item for the shipment line */
  lineItem: EmersonClassicOrderLineItem;
  /** The quantity of the inventory unit */
  quantity: Scalars['Int']['output'];
  /** Whether the inventory unit is requested for cancellation */
  requestedCancellation: Scalars['Boolean']['output'];
  /** The shipped quantity of the inventory unit */
  shippedQuantity: Scalars['Int']['output'];
  /** The variant of the inventory unit */
  variant?: Maybe<Variant>;
};

/** Filters for Emerson Classic Inventory Units */
export type EmersonClassicInventoryUnitFilterObject = {
  notShipped?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Emerson Classic Invoice */
export type EmersonClassicInvoice = {
  id: Scalars['ID']['output'];
  /** Download URL for the invoice in PDF form. */
  invoiceUrl: Scalars['String']['output'];
  /** A unique identifier for the invoice. This is a customer-facing value. */
  number: Scalars['String']['output'];
};

/** Emerson Classic Order */
export type EmersonClassicOrder = Node & {
  /** The billing address information. */
  billingAddress?: Maybe<EmersonClassicAddress>;
  /** The timestamp of when the order was completed. */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Invoices associated with this order. */
  invoices: Array<EmersonClassicInvoice>;
  /** Whether the order was placed on Cyber Monday */
  isFromCyberMonday: Scalars['Boolean']['output'];
  /** Is the order net terms */
  isNetTerms: Scalars['Boolean']['output'];
  /** Number of items within the order */
  itemCount: Scalars['Int']['output'];
  /** The sum of all the line items for this order. (subtotal) */
  itemTotal: Scalars['Float']['output'];
  /** Items in this order. Includes quantity */
  lineItems: Array<EmersonClassicOrderLineItem>;
  /** The unique identifier for this order. This number is shown to the users */
  number: Scalars['String']['output'];
  /** Payment wrapper for the order */
  payment?: Maybe<EmersonClassicPayment>;
  /** The corresponding optional customer identifier for Emerson classic order */
  poNumber?: Maybe<Scalars['String']['output']>;
  /** The total value of all savings costs. */
  savingsTotal: Scalars['Float']['output'];
  /** Shipment state from the order */
  shipmentState: Scalars['String']['output'];
  /** Shipments for this order. */
  shipments: Array<EmersonClassicShipment>;
  /** The shipping address information. */
  shippingAddress: EmersonClassicAddress;
  /** The total value of all shipments’ costs */
  shippingTotal?: Maybe<Scalars['Float']['output']>;
  /** State from the order */
  state: Scalars['String']['output'];
  /** The taxes for this order. */
  taxTotal?: Maybe<Scalars['Float']['output']>;
  /** The sum of item total and adjustment total. */
  total: Scalars['Float']['output'];
};


/** Emerson Classic Order */
export type EmersonClassicOrderShipmentsArgs = {
  filters?: InputMaybe<EmersonClassicShipmentFilterObject>;
};

/** The connection type for EmersonOrdersUnion. */
export type EmersonClassicOrderConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmersonOrdersUnionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EmersonOrdersUnion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** Emerson Classic Order Line Item */
export type EmersonClassicOrderLineItem = {
  /** Total price of items. */
  amount: Scalars['Float']['output'];
  /** The Emerson Classic brand name for the item */
  brandName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Whether the item was cancelled */
  itemCancelled: Scalars['Boolean']['output'];
  /** The Emerson Classic item code for the item */
  itemCode: Scalars['String']['output'];
  /** The Emerson Classic description for the item */
  itemDescription: Scalars['String']['output'];
  /** Line item for the shipment line */
  lineItem: EmersonClassicOrderLineItem;
  /** Order's completed_at date. */
  orderedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Unit price of the item. */
  price: Scalars['Float']['output'];
  /** Number of items of this kind. */
  quantity: Scalars['Int']['output'];
  /** Whether the item was requested to be cancelled */
  requestedCancellation: Scalars['Boolean']['output'];
  /** Product variant. */
  variant?: Maybe<Variant>;
};

/** EmersonClassic payment */
export type EmersonClassicPayment = {
  /** Fake ID using for schema validation */
  id: Scalars['ID']['output'];
  /** Description of the payment method used */
  paymentDescription: Scalars['String']['output'];
  /**
   * Credit card used for the payment
   * @deprecated Use sourceUnion instead
   */
  source?: Maybe<EmersonClassicCreditCard>;
  /** Credit card used for the payment */
  sourceUnion?: Maybe<EmersonClassicCreditCard>;
};

/** The connection type for EmersonReorderLineItemsUnion. */
export type EmersonClassicReorderLineItemConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmersonReorderLineItemsUnionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EmersonReorderLineItemsUnion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** Emerson Classic Shipment */
export type EmersonClassicShipment = {
  /** Total price of items included in the shipment. */
  grandTotal: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** Inventory units for this shipment. */
  inventoryUnits?: Maybe<Array<EmersonClassicInventoryUnit>>;
  /** Shipment reference number. */
  referenceNumber?: Maybe<Scalars['String']['output']>;
  /** Shipment lines for this shipment. */
  shipmentLines: Array<EmersonClassicShipmentLine>;
  /** Shipment receipt url */
  shipmentReceiptUrl?: Maybe<Scalars['String']['output']>;
  /** DateTime the shipment was shipped at */
  shippedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Current state of the shipment. (pending or shipped) */
  state: Scalars['String']['output'];
  /** Shipment tracking number. */
  trackingNumber?: Maybe<Scalars['String']['output']>;
  /** Shipment tracking URL */
  trackingUrl?: Maybe<Scalars['String']['output']>;
  /** Tracking information for this shipment */
  trackings?: Maybe<Array<EmersonClassicTracking>>;
};


/** Emerson Classic Shipment */
export type EmersonClassicShipmentInventoryUnitsArgs = {
  filters?: InputMaybe<EmersonClassicInventoryUnitFilterObject>;
};

/** Arguments for filtering combined wholesale and emerson classic orders. */
export type EmersonClassicShipmentFilterObject = {
  shipped?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Emerson Classic Shipment Line */
export type EmersonClassicShipmentLine = {
  id: Scalars['ID']['output'];
  /** Item code related to the shipment line. */
  itemCode: Scalars['String']['output'];
  /** Quantity of items shipped. */
  quantity: Scalars['Int']['output'];
  /** Quantity of items shipped. */
  quantityShipped: Scalars['Int']['output'];
};

/** Emerson Classic Tracking information */
export type EmersonClassicTracking = {
  /** The ID of the shipment associated with this tracking */
  id: Scalars['ID']['output'];
  /** The URL for tracking the shipment */
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of EmersonHarmonizedMigrationConsolidatePassword */
export type EmersonHarmonizedMigrationConsolidatePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAttributes: UserAttributes;
};

/** Autogenerated return type of EmersonHarmonizedMigrationConsolidatePassword. */
export type EmersonHarmonizedMigrationConsolidatePasswordPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The emerson migration status */
  emersonMigrationStatus?: Maybe<EmersonMigrationStatus>;
  /** Errors if the action was unsuccessful */
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of EmersonHarmonizedMigrationConsolidatePractitionerData */
export type EmersonHarmonizedMigrationConsolidatePractitionerDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerAndStoreMerchantDataAttributes: PractitionerAndStoreMerchantDataAttributes;
};

/** Autogenerated return type of EmersonHarmonizedMigrationConsolidatePractitionerData. */
export type EmersonHarmonizedMigrationConsolidatePractitionerDataPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The emerson migration status */
  emersonMigrationStatus?: Maybe<EmersonMigrationStatus>;
  /** Errors if the action was unsuccessful */
  errors: Array<Scalars['String']['output']>;
};

/** Attributes for updating a store's merchant data's address. */
export type EmersonMerchantDataAddressAttributes = {
  city: Scalars['String']['input'];
  graduationDate?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
  region: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
};

/** Emerson migration status */
export type EmersonMigrationStatus = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Whether the user is a store owner */
  fullscriptStoreOwner: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Current migration status step */
  step: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Union type for combined Emerson's Wholesale Orders and EmersonClassic Orders */
export type EmersonOrdersUnion = EmersonClassicOrder | WholesaleOrder;

/** An edge in a connection. */
export type EmersonOrdersUnionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EmersonOrdersUnion>;
};

/** Union type for combined Emerson's Wholesale Line Items and EmersonClassic Line Items for reorder */
export type EmersonReorderLineItemsUnion = EmersonClassicOrderLineItem | WholesaleLineItem;

/** An edge in a connection. */
export type EmersonReorderLineItemsUnionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EmersonReorderLineItemsUnion>;
};

/** Autogenerated error type of EmersonUserAdditionalInfoMigration */
export type EmersonUserAdditionalInfoMigrationError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of EmersonUserAdditionalInfoMigration */
export type EmersonUserAdditionalInfoMigrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EmersonUserAdditionalInfoMigration. */
export type EmersonUserAdditionalInfoMigrationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: EmersonUserAdditionalInfoMigrationError;
  store?: Maybe<Store>;
};

/** Autogenerated error type of EnableSharedAccount */
export type EnableSharedAccountError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of EnableSharedAccount */
export type EnableSharedAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  requestedPlatform: StorePlatformType;
};

/** Autogenerated return type of EnableSharedAccount. */
export type EnableSharedAccountPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: EnableSharedAccountError;
};

/** Types of EQP levels */
export const EqpLevelType = {
  /** GOLD */
  Gold: 'Gold',
  /** SILVER */
  Silver: 'Silver'
} as const;

export type EqpLevelType = typeof EqpLevelType[keyof typeof EqpLevelType];
/** Logged event types for patient orders */
export const EventTypes = {
  /** Patient tracks their order */
  TracksOrder: 'TRACKS_ORDER',
  /** Patient views their order receipt */
  ViewReceipt: 'VIEW_RECEIPT'
} as const;

export type EventTypes = typeof EventTypes[keyof typeof EventTypes];
/** Ingredient recommendation for a specific demographic */
export type Evidence = {
  /** The authors of the article */
  authors?: Maybe<Scalars['String']['output']>;
  /** The name of the journal */
  journalName?: Maybe<Scalars['String']['output']>;
  /** Link to article */
  url: Scalars['String']['output'];
  /** The year the article was published */
  year?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of ExchangeMigrationToken */
export type ExchangeMigrationTokenError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ExchangeMigrationToken */
export type ExchangeMigrationTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The migration token to exchange */
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ExchangeMigrationToken. */
export type ExchangeMigrationTokenPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<ExchangeMigrationTokenError>;
  migrationConfiguration?: Maybe<RupaMigrationConfiguration>;
};

/** Autogenerated input type of ExperimentCreate */
export type ExperimentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  experiment: Scalars['String']['input'];
  group: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  subjectId: Scalars['ID']['input'];
};

/** Autogenerated return type of ExperimentCreate. */
export type ExperimentCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  experimentCreated?: Maybe<Scalars['Boolean']['output']>;
};

/** The base health areas for the Explore page */
export type Explore = {
  /** An array of health areas */
  healthAreas?: Maybe<Array<ExploreHealthArea>>;
};

/** The health areas on the Explore page. */
export type ExploreHealthArea = {
  /** An array of health conditions associated with the health area */
  healthConditions?: Maybe<Array<ExploreHealthCondition>>;
  /** An icon name for the health area */
  iconName?: Maybe<Scalars['String']['output']>;
  /** Name of the health area to be displayed */
  name?: Maybe<Scalars['String']['output']>;
};

/** The health conditions associated with each health area on the Explore page. */
export type ExploreHealthCondition = {
  /** An array of associated ingredients */
  ingredients?: Maybe<Array<Ingredient>>;
  /** Name of the health condition to be displayed */
  name?: Maybe<Scalars['String']['output']>;
  /** The associated treatment plan template */
  template?: Maybe<TreatmentPlanTemplate>;
  /** An array of associated variants */
  variants?: Maybe<Array<Variant>>;
};

/** The categories for the Explore Menu */
export type ExploreMenuCategory = {
  /** The ID to be used in the filter for the Menu Category */
  id: Scalars['ID']['output'];
  /** The image to be displayed for the Menu Category */
  image?: Maybe<Scalars['String']['output']>;
  /** The name of the Menu Category to be displayed */
  name: Scalars['String']['output'];
};

/** The popular searches by modality for Explore Page */
export type ExplorePopularSearch = {
  /** Name of the modality used for filtering purposes */
  description?: Maybe<Scalars['String']['output']>;
  /** Name of the modality */
  name?: Maybe<Scalars['String']['output']>;
  /** The popular searches by the modality */
  searches?: Maybe<Array<ExplorePopularSearchItem>>;
};

/** The popular search in the list */
export type ExplorePopularSearchItem = {
  /** The filter type to be applied for the popular search item */
  filterType?: Maybe<Scalars['String']['output']>;
  /** The ID to be used in the filter for the popular search item */
  id?: Maybe<Scalars['ID']['output']>;
  /** Name of the ingredient or supplement type */
  name?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of ExpressCheckoutConfirm */
export type ExpressCheckoutConfirmError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ExpressCheckoutConfirm */
export type ExpressCheckoutConfirmInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  selectedCreditCardId: Scalars['ID']['input'];
};

/** Autogenerated return type of ExpressCheckoutConfirm. */
export type ExpressCheckoutConfirmPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ExpressCheckoutConfirmError;
};

/** Attributes for updating an externally linked patient */
export type ExternalPatient = {
  biologicalSex?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
  smsNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Details about a external product */
export type ExternalProduct = Node & {
  /** Brand name of the external product */
  brandName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Name of the external product */
  name: Scalars['String']['output'];
};

/** Autogenerated error type of FavoriteProductAdd */
export type FavoriteProductAddError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of FavoriteProductAdd */
export type FavoriteProductAddInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of FavoriteProductAdd. */
export type FavoriteProductAddPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FavoriteProductAddError;
  isDefaultCategory?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<Product>;
};

/** Autogenerated error type of FavoriteProductRemove */
export type FavoriteProductRemoveError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of FavoriteProductRemove */
export type FavoriteProductRemoveInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of FavoriteProductRemove. */
export type FavoriteProductRemovePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FavoriteProductRemoveError;
  isDefaultCategory?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<Product>;
};

/** Autogenerated error type of FavoriteProductsAdd */
export type FavoriteProductsAddError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of FavoriteProductsAdd */
export type FavoriteProductsAddInput = {
  categoryId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of FavoriteProductsAdd. */
export type FavoriteProductsAddPayload = {
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FavoriteProductsAddError;
};

/** Autogenerated error type of FavoriteProductsRemove */
export type FavoriteProductsRemoveError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of FavoriteProductsRemove */
export type FavoriteProductsRemoveInput = {
  categoryId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of FavoriteProductsRemove. */
export type FavoriteProductsRemovePayload = {
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FavoriteProductsRemoveError;
};

/** Arguments for sorting favorite search. */
export type FavoriteSearchSort = {
  direction: SortDirection;
  field?: InputMaybe<FavoriteSearchSortField>;
};

/** Arguments for sorting favorite search */
export const FavoriteSearchSortField = {
  /** Sort by product name */
  Alphabetical: 'ALPHABETICAL',
  /** Sort by number of recommendations */
  MostPrescribed: 'MOST_PRESCRIBED'
} as const;

export type FavoriteSearchSortField = typeof FavoriteSearchSortField[keyof typeof FavoriteSearchSortField];
/** Arguments for favorite variants of a store. */
export type FavoriteVariantFilterObject = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
};

/** Arguments for sorting favorite variants of a store. */
export type FavoriteVariantSort = {
  direction: SortDirection;
  field?: InputMaybe<FavoriteVariantSortField>;
};

/** Arguments for sorting favorite variants */
export const FavoriteVariantSortField = {
  /** Sort by product name */
  Name: 'NAME',
  /** Sort by number of recommendations. Only descending order is supported. */
  Recommendation: 'RECOMMENDATION'
} as const;

export type FavoriteVariantSortField = typeof FavoriteVariantSortField[keyof typeof FavoriteVariantSortField];
/** A feature request in the FS Assist system */
export type FeatureRequest = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Type of feature requested (NUTRIENT_DEPLETION, LAB_RESULTS, CONDITION_SUGGESTIONS) */
  featureName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of FindLabAppointmentTimeSlots */
export type FindLabAppointmentTimeSlotsError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of FindLabAppointmentTimeSlots */
export type FindLabAppointmentTimeSlotsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  labAppointmentLocationId: Scalars['ID']['input'];
};

/** Autogenerated return type of FindLabAppointmentTimeSlots. */
export type FindLabAppointmentTimeSlotsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FindLabAppointmentTimeSlotsError;
  labAppointmentTimeSlots?: Maybe<Array<LabsAppointmentTimeSlot>>;
  redirectToClient?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated error type of FindMobileLabAppointmentTimeSlots */
export type FindMobileLabAppointmentTimeSlotsError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of FindMobileLabAppointmentTimeSlots */
export type FindMobileLabAppointmentTimeSlotsInput = {
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  labSampleCollectionProviderId: Scalars['ID']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

/** Autogenerated return type of FindMobileLabAppointmentTimeSlots. */
export type FindMobileLabAppointmentTimeSlotsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FindMobileLabAppointmentTimeSlotsError;
  mobileLabAppointmentTimeSlots?: Maybe<Array<LabsAppointmentTimeSlot>>;
};

/** The patients with autorefill data to be displayed on the store analytics dashboard */
export type FirstTimeAutorefillPatientsData = {
  /** The last 12 months number of first time autorefill patients for the store (excluding the current month) */
  lastTwelveMonths: Scalars['Float']['output'];
  /** The month to date count of first time autorefill patients for this store */
  monthToDate: Scalars['Int']['output'];
  /** The autorefill data for the store */
  storeAutorefillAnalyticsData: Array<StoreAutorefillAnalyticsData>;
  /** The year to date count of first time autorefill patients for this store */
  yearToDate: Scalars['Int']['output'];
};

/** Details of a specific flipper */
export type Flipper = {
  /** ID */
  id: Scalars['ID']['output'];
  /** Name of the flipper */
  name: Scalars['String']['output'];
  /** Whether the flipper is enabled for the current actor */
  result: Scalars['Boolean']['output'];
};

/** Autogenerated input type of FlipperUpdate */
export type FlipperUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enable: Scalars['Boolean']['input'];
  flipper: Scalars['String']['input'];
};

/** Autogenerated return type of FlipperUpdate. */
export type FlipperUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  flipper?: Maybe<Flipper>;
};

/** Attributes for collecting events for follow up clicked */
export type FollowUpClickedAttributes = {
  pageVersion: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
};

/** Attributes for collecting events for follow up sent action */
export type FollowUpSentAttributes = {
  followUpType: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
};

/** Friend invited by a patient */
export type Friend = {
  /** Friend's email */
  email: Scalars['String']['output'];
  /** Friend's first name */
  firstName: Scalars['String']['output'];
  /** Whether the invited friend has created an account */
  hasAccessedInvite: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Friend's last name */
  lastName: Scalars['String']['output'];
};

/** Autogenerated error type of FsAssistGeneratePlanMessage */
export type FsAssistGeneratePlanMessageError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of FsAssistGeneratePlanMessage */
export type FsAssistGeneratePlanMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  maxWordLength?: InputMaybe<Scalars['Int']['input']>;
  minWordLength?: InputMaybe<Scalars['Int']['input']>;
  patientHealthInfo: Scalars['String']['input'];
  prescription: Scalars['String']['input'];
  specialInstructions?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of FsAssistGeneratePlanMessage. */
export type FsAssistGeneratePlanMessagePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<FsAssistGeneratePlanMessageError>;
  message?: Maybe<Scalars['String']['output']>;
};

/** FsAssist mutations */
export type FsAssistMutation = {
  /** Generate a message for a plan with the given notes */
  generatePlanMessage?: Maybe<FsAssistGeneratePlanMessagePayload>;
};


/** FsAssist mutations */
export type FsAssistMutationGeneratePlanMessageArgs = {
  input: FsAssistGeneratePlanMessageInput;
};

/** Fullscript new experiance migration status for a store */
export type FsExperienceUpgradeStatus = {
  /** The migration cohort this store belongs to */
  cohort?: Maybe<Scalars['String']['output']>;
  /** When the store became eligible for the upgrade */
  eligibleAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the store will be forced to upgrade */
  forcedCutoverAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the store completed the upgrade */
  movedOverAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Autogenerated error type of FsExperienceUpgradeStatusUpdate */
export type FsExperienceUpgradeStatusUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of FsExperienceUpgradeStatusUpdate */
export type FsExperienceUpgradeStatusUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of FsExperienceUpgradeStatusUpdate. */
export type FsExperienceUpgradeStatusUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: FsExperienceUpgradeStatusUpdateError;
};

/** Arguments for sorting fullscript contents. */
export type FullscriptContentSort = {
  direction: SortDirection;
  field: FullscriptContentSortField;
};

/** Arguments for sorting fullscript contents */
export const FullscriptContentSortField = {
  /** Date on which fullscript content was created */
  CreatedAt: 'CREATED_AT',
  /** Fullscript content's name */
  Name: 'NAME'
} as const;

export type FullscriptContentSortField = typeof FullscriptContentSortField[keyof typeof FullscriptContentSortField];
/** Protocols curated by IMAT */
export type FullscriptProtocol = Timestamps & {
  /** Category the protocol belongs to */
  category: FullscriptProtocolCategory;
  /** A collection of information pertaining to recommendations in the protocol */
  contents: Array<FullscriptProtocolContent>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The official rating for the protocol */
  evidenceRating: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** A collection of information pertaining to lab tests in the protocol */
  labContents: Array<FullscriptProtocolLabContent>;
  /** Name of the protocol */
  name: Scalars['String']['output'];
  /** Description of the protocol */
  overviewHtml: Scalars['String']['output'];
  /** Marks whether the protocol has been recently added */
  recentlyAdded: Scalars['Boolean']['output'];
  /** Field that holds all the academic references used in creating the protocol */
  referencesHtml: Scalars['String']['output'];
  /** Unique identifier */
  slug: Scalars['String']['output'];
  /** The treatment plan template for this protocol */
  treatmentPlanTemplate: TreatmentPlanTemplate;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Fullscript protocol category */
export type FullscriptProtocolCategory = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the category */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for FullscriptProtocol. */
export type FullscriptProtocolConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FullscriptProtocolEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FullscriptProtocol>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** A recommendation belonging to an IMAT protocol */
export type FullscriptProtocolContent = Timestamps & {
  /** Link to the product in catalog */
  catalogLink: Scalars['String']['output'];
  /** Description of the recommendation */
  contentHtml?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The actual recommendation with dosage, product, etc */
  recommendation?: Maybe<Recommendation>;
  /** Title of the recommendation */
  title: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** An edge in a connection. */
export type FullscriptProtocolEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FullscriptProtocol>;
};

/** Arguments for filtering fullscript protocols. */
export type FullscriptProtocolFilterObject = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  containsLabs?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

/** A lab_test belonging to an IMAT protocol */
export type FullscriptProtocolLabContent = Timestamps & {
  /** Link to the test in catalog */
  catalogLink: Scalars['String']['output'];
  /** Description of the test */
  contentHtml?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The recomendation_test of the lab */
  recommendationTest: LabsRecommendationTest;
  /** Title of the test */
  title: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Arguments for sorting fullscript protocols. */
export type FullscriptProtocolSort = {
  direction: SortDirection;
  field: FullscriptProtocolSortField;
};

/** Arguments for sorting fullscript protocols */
export const FullscriptProtocolSortField = {
  /** Date on which fullscript protocol was created */
  CreatedAt: 'CREATED_AT',
  /** Fullscript protocol's name */
  Name: 'NAME',
  /** Date on which fullscript protocol was last updated */
  UpdatedAt: 'UPDATED_AT'
} as const;

export type FullscriptProtocolSortField = typeof FullscriptProtocolSortField[keyof typeof FullscriptProtocolSortField];
/** Autogenerated error type of GenAiGetProductRecommendation */
export type GenAiGetProductRecommendationError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GenAiGetProductRecommendation */
export type GenAiGetProductRecommendationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  soapNotesOrQuestion: Scalars['String']['input'];
};

/** Autogenerated return type of GenAiGetProductRecommendation. */
export type GenAiGetProductRecommendationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** List of parsed conditions */
  conditions?: Maybe<Array<Scalars['String']['output']>>;
  /** Condition and its recommended ingredients */
  conditionsIngredients?: Maybe<Array<ResearchAssistantConditionIngredient>>;
  /** Parsed demographic */
  demographic?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<GenAiGetProductRecommendationError>;
};

/** GenAi mutations */
export type GenAiMutation = {
  /** Changes tone of the treatment plan text by the desired tone. */
  planMessageHelperChangeTone?: Maybe<GenAiPlanMessageHelperChangeTonePayload>;
  /** Generates treatment plan text from the treatment plan. */
  planMessageHelperGenerate?: Maybe<GenAiPlanMessageHelperGeneratePayload>;
  /** Reformats the treatment plan text. */
  planMessageHelperReformat?: Maybe<GenAiPlanMessageHelperReformatPayload>;
  /** Spell checks the treatment plan text. */
  planMessageHelperSpellCheck?: Maybe<GenAiPlanMessageHelperSpellCheckPayload>;
  /** Get ingredient recommendations based on question. */
  researchAssistantGetIngredientRecommendation?: Maybe<GenAiGetProductRecommendationPayload>;
};


/** GenAi mutations */
export type GenAiMutationPlanMessageHelperChangeToneArgs = {
  input: GenAiPlanMessageHelperChangeToneInput;
};


/** GenAi mutations */
export type GenAiMutationPlanMessageHelperGenerateArgs = {
  input: GenAiPlanMessageHelperGenerateInput;
};


/** GenAi mutations */
export type GenAiMutationPlanMessageHelperReformatArgs = {
  input: GenAiPlanMessageHelperReformatInput;
};


/** GenAi mutations */
export type GenAiMutationPlanMessageHelperSpellCheckArgs = {
  input: GenAiPlanMessageHelperSpellCheckInput;
};


/** GenAi mutations */
export type GenAiMutationResearchAssistantGetIngredientRecommendationArgs = {
  input: GenAiGetProductRecommendationInput;
};

/** Autogenerated error type of GenAiPlanMessageHelperChangeTone */
export type GenAiPlanMessageHelperChangeToneError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GenAiPlanMessageHelperChangeTone */
export type GenAiPlanMessageHelperChangeToneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The tone of the response */
  desiredTone: DesiredTones;
  treatmentPlanText: Scalars['String']['input'];
};

/** Autogenerated return type of GenAiPlanMessageHelperChangeTone. */
export type GenAiPlanMessageHelperChangeTonePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<GenAiPlanMessageHelperChangeToneError>;
  /** Improved treatment plan text. */
  treatmentPlanText?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of GenAiPlanMessageHelperGenerate */
export type GenAiPlanMessageHelperGenerateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GenAiPlanMessageHelperGenerate */
export type GenAiPlanMessageHelperGenerateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of GenAiPlanMessageHelperGenerate. */
export type GenAiPlanMessageHelperGeneratePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<GenAiPlanMessageHelperGenerateError>;
  /** Generated treatment plan text. */
  treatmentPlanText?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of GenAiPlanMessageHelperReformat */
export type GenAiPlanMessageHelperReformatError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GenAiPlanMessageHelperReformat */
export type GenAiPlanMessageHelperReformatInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanText: Scalars['String']['input'];
};

/** Autogenerated return type of GenAiPlanMessageHelperReformat. */
export type GenAiPlanMessageHelperReformatPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<GenAiPlanMessageHelperReformatError>;
  /** Reformatted treatment plan text. */
  treatmentPlanText?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of GenAiPlanMessageHelperSpellCheck */
export type GenAiPlanMessageHelperSpellCheckError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GenAiPlanMessageHelperSpellCheck */
export type GenAiPlanMessageHelperSpellCheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanText: Scalars['String']['input'];
};

/** Autogenerated return type of GenAiPlanMessageHelperSpellCheck. */
export type GenAiPlanMessageHelperSpellCheckPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<GenAiPlanMessageHelperSpellCheckError>;
  /** Spell checked treatment plan text. */
  treatmentPlanText?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of GenerateStripeAccountLink */
export type GenerateStripeAccountLinkError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GenerateStripeAccountLink */
export type GenerateStripeAccountLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of GenerateStripeAccountLink. */
export type GenerateStripeAccountLinkPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: GenerateStripeAccountLinkError;
  /** The Stripe URL to give the store owner to update their Stripe account information. */
  stripeUrl?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of GlobalPatientAccessUpdate */
export type GlobalPatientAccessUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GlobalPatientAccessUpdate */
export type GlobalPatientAccessUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  globalPatients: Scalars['Boolean']['input'];
};

/** Autogenerated return type of GlobalPatientAccessUpdate. */
export type GlobalPatientAccessUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: GlobalPatientAccessUpdateError;
  store?: Maybe<Store>;
};

/** Opting into any future patient promotions */
export type GlobalPatientPromotion = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** If the store has opted into future promotions */
  optIn: Scalars['Boolean']['output'];
  /** Targeted additional discount. Applied in addition to existing store discount. */
  targetedDiscount: Scalars['Int']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes for updating a store's global patient promotion. */
export type GlobalPatientPromotionAttributes = {
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  targetedDiscount?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated error type of GlobalPatientPromotionUpdate */
export type GlobalPatientPromotionUpdateError = {
  /** Field errors */
  fields?: Maybe<GlobalPatientPromotionUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of GlobalPatientPromotionUpdate */
export type GlobalPatientPromotionUpdateFieldError = {
  optIn?: Maybe<Array<Scalars['String']['output']>>;
  targetedDiscount?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of GlobalPatientPromotionUpdate */
export type GlobalPatientPromotionUpdateInput = {
  attributes: GlobalPatientPromotionAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of GlobalPatientPromotionUpdate. */
export type GlobalPatientPromotionUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<GlobalPatientPromotionUpdateError>;
  globalPatientPromotion?: Maybe<GlobalPatientPromotion>;
};

/** Type for health condition product filters. */
export type HealthCondition = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the health condition */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of HideMigrationBanner */
export type HideMigrationBannerError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of HideMigrationBanner */
export type HideMigrationBannerInput = {
  bannerKey: MigrationBanners;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of HideMigrationBanner. */
export type HideMigrationBannerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: HideMigrationBannerError;
  migrationStatus?: Maybe<MigrationStatus>;
};

/** Autogenerated error type of HideNotInCatalogBanner */
export type HideNotInCatalogBannerError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of HideNotInCatalogBanner */
export type HideNotInCatalogBannerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

/** Autogenerated return type of HideNotInCatalogBanner. */
export type HideNotInCatalogBannerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: HideNotInCatalogBannerError;
};

/** Autogenerated error type of HidePatientMigrationBanner */
export type HidePatientMigrationBannerError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of HidePatientMigrationBanner */
export type HidePatientMigrationBannerInput = {
  bannerKey: PatientMigrationBanners;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of HidePatientMigrationBanner. */
export type HidePatientMigrationBannerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: HidePatientMigrationBannerError;
  patientMigrationStatus?: Maybe<WellevatePatientMigrationStatus>;
};

/** Catalog image */
export type Image = Node & Viewable & {
  /** Alternative text */
  alt?: Maybe<Scalars['String']['output']>;
  /** Image's content type */
  contentType: Scalars['String']['output'];
  /** File's name */
  fileName: Scalars['String']['output'];
  /** File size in bytes */
  fileSize: Scalars['Int']['output'];
  /** The original image's height */
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** The image's position */
  position: Scalars['Int']['output'];
  /** The type of object being returned. */
  type: Scalars['String']['output'];
  /** URL of a large-sized image */
  urlLarge: Scalars['String']['output'];
  /** URL of a mini-sized image */
  urlMini: Scalars['String']['output'];
  /** URL of a orignal-sized image */
  urlOriginal: Scalars['String']['output'];
  /** URL of a product-sized (aka default) image */
  urlProduct: Scalars['String']['output'];
  /** URL of a small-sized image */
  urlSmall: Scalars['String']['output'];
  /** URL of an extra-large-sized image */
  urlXlarge: Scalars['String']['output'];
  /** The orignal image's width */
  width: Scalars['Int']['output'];
};

/** Variant image filter format field */
export const ImageFilterFormatField = {
  /** Filters for png images */
  Png: 'PNG',
  /** Filters for web_p images */
  Webp: 'WEBP'
} as const;

export type ImageFilterFormatField = typeof ImageFilterFormatField[keyof typeof ImageFilterFormatField];
/** Arguments for filtering images for variants. */
export type ImageFilterObject = {
  format?: InputMaybe<ImageFilterFormatField>;
  size?: InputMaybe<ImageFilterSizeField>;
};

/** Variant image filter size field */
export const ImageFilterSizeField = {
  /** Filters for images with size 1000 */
  Large: 'LARGE',
  /** Filters for images with size 400 */
  Medium: 'MEDIUM',
  /** Filters for images with size 225 */
  Small: 'SMALL'
} as const;

export type ImageFilterSizeField = typeof ImageFilterSizeField[keyof typeof ImageFilterSizeField];
/** Fullscript documents maintained by the IMAT team */
export type ImatContent = Node & Timestamps & Uploadable & {
  /** Attachment url */
  attachment: Scalars['String']['output'];
  /** Category of this content */
  contentCategory?: Maybe<ImatContentCategory>;
  /** Type of content */
  contentType?: Maybe<ImatContentType>;
  /** URL for the content in AWS */
  contentUrl: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** URL for fullscript document image */
  documentImageUrl?: Maybe<Scalars['String']['output']>;
  /** Name of the file */
  fileName: Scalars['String']['output'];
  /** File type of the content */
  fileType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Returns whether it's attached to a plan or not */
  isAttached: Scalars['Boolean']['output'];
  /** Is this content favourited by the current or store owner practitioner? */
  isFavourited?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the content */
  name: Scalars['String']['output'];
  /** Was the content recently created? */
  recentlyAdded: Scalars['Boolean']['output'];
  /** URL for viewing content that allows for tracking actions */
  trackingUrl: Scalars['String']['output'];
  /** Type of the uploadable, Imat::Content or Document */
  type: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Fullscript documents maintained by the IMAT team */
export type ImatContentIsAttachedArgs = {
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

/** Category of a Fullscript/IMAT document */
export type ImatContentCategory = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name for the category */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for ImatContent. */
export type ImatContentConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ImatContentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ImatContent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ImatContentEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ImatContent>;
};

/** Arguments for filtering imat contents. */
export type ImatContentFilterObject = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  otherCategory?: InputMaybe<Scalars['Boolean']['input']>;
  otherType?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Category of a Fullscript/IMAT document */
export type ImatContentType = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name for the type of content */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of ImportLoyalistData */
export type ImportLoyalistDataError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ImportLoyalistData */
export type ImportLoyalistDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ImportLoyalistData. */
export type ImportLoyalistDataPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ImportLoyalistDataError;
  migrationStatus?: Maybe<MigrationStatus>;
};

/** The patient's in-app notification */
export type InAppPatientNotification = Node & {
  /** When was the notification created */
  createdAt: Scalars['DateTime']['output'];
  /** When was the notification dismissed */
  dismissedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** The type of the notification */
  inAppPatientNotificationType: InAppPatientNotificationType;
  /** The metadata of the notification */
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** The notificationable object */
  notificationable?: Maybe<InAppPatientNotificationableTypeUnion>;
  /** The patient to whom the notification belongs */
  patient?: Maybe<Patient>;
  /** When was the notification has been read */
  readAt?: Maybe<Scalars['DateTime']['output']>;
};

/** The connection type for InAppPatientNotification. */
export type InAppPatientNotificationConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InAppPatientNotificationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InAppPatientNotification>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InAppPatientNotificationEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InAppPatientNotification>;
};

/** Filters for InAppPatientNotification */
export type InAppPatientNotificationFilterObject = {
  /** The type of notification */
  code?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Include dismissed notification. Default is TRUE */
  includeDismissed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter for unread notifications */
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of InAppPatientNotificationMarkAsDismissed */
export type InAppPatientNotificationMarkAsDismissedError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of InAppPatientNotificationMarkAsDismissed */
export type InAppPatientNotificationMarkAsDismissedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of InAppPatientNotificationMarkAsDismissed. */
export type InAppPatientNotificationMarkAsDismissedPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: InAppPatientNotificationMarkAsDismissedError;
};

/** Autogenerated error type of InAppPatientNotificationMarkAsRead */
export type InAppPatientNotificationMarkAsReadError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of InAppPatientNotificationMarkAsRead */
export type InAppPatientNotificationMarkAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of InAppPatientNotificationMarkAsRead. */
export type InAppPatientNotificationMarkAsReadPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: InAppPatientNotificationMarkAsReadError;
};

/** The InAppPatientNotificationType object */
export type InAppPatientNotificationType = {
  /** The code of the notification */
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** Objects that can be in-app patient notificationable */
export type InAppPatientNotificationableTypeUnion = TreatmentPlan;

/** Ingredient */
export type Ingredient = LegacyId & Node & Timestamps & {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
  /** Sub-ingredients in the ingredient hierarchy */
  children: IngredientConnection;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The number of items in a search result that include this ingredient */
  esResultCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** Name of the ingredient */
  name: Scalars['String']['output'];
  /** Parent ingredients in the ingredient hierarchy */
  parents: Array<Ingredient>;
  /** Ingredients in the same synonym group */
  synonyms: Array<Ingredient>;
  /** Primary unit for measuring the ingredient */
  unitOne?: Maybe<Scalars['String']['output']>;
  /** Secondary unit for measuring the ingredient */
  unitTwo?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Ingredient */
export type IngredientChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Attributes for ingredients */
export type IngredientAttributes = {
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageUnit?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** The connection type for Ingredient. */
export type IngredientConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IngredientEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Ingredient>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IngredientEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Ingredient>;
};

/** Arguments for filtering ingredients. */
export type IngredientFilterObject = {
  ingredientId?: InputMaybe<Scalars['ID']['input']>;
  topLevel?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Ingredient search addon */
export type IngredientSearchAddons = {
  dose?: InputMaybe<Scalars['Float']['input']>;
  maxDose?: InputMaybe<Scalars['Float']['input']>;
  minDose?: InputMaybe<Scalars['Float']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<IngredientUnitAddon>;
};

/** Refine your ingredient search with some added arguments. */
export type IngredientSearchAttributes = {
  addon?: InputMaybe<IngredientSearchAddons>;
  id: Scalars['ID']['input'];
};

/** Arguments for sorting ingredients. */
export type IngredientSort = {
  direction: SortDirection;
  field: IngredientSortField;
};

/** Ingredient sort field */
export const IngredientSortField = {
  /** Name of the ingredient */
  Name: 'NAME',
  /** How often an ingredient is searched */
  SearchPopularity: 'SEARCH_POPULARITY'
} as const;

export type IngredientSortField = typeof IngredientSortField[keyof typeof IngredientSortField];
/** Attributes for ingredients */
export type IngredientSuggestionAttributes = {
  includeDemographics?: InputMaybe<Scalars['Boolean']['input']>;
  labBiomarkerResultIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  medicationProductIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientId: Scalars['ID']['input'];
  pubMedConditionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplementPlanProductIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Ingredient suggestions by category */
export type IngredientSuggestions = {
  /** Whether the patient has lab results from the past 3 months */
  hasRecentLabResults: Scalars['Boolean']['output'];
  /** Not optimal lab biomarker results with their ingredient recommendations */
  labBiomarkerResults: Array<LabBiomarkerResult>;
  /** Medications with their ingredient recommendations */
  medications: Array<Medication>;
  /** Patient details */
  patient: Patient;
  /** Pubmed conditions with their ingredient recommendations */
  pubMedConditions: Array<WholePersonPlanPubMedCondition>;
  /** The patient's current supplements */
  supplementPlanProducts: Array<SupplementPlanProductType>;
};

/** Ingredient unit addon input object */
export type IngredientUnitAddon = {
  unitOne?: InputMaybe<Scalars['String']['input']>;
  unitTwo?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of InitiateMigration */
export type InitiateMigrationError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of InitiateMigration */
export type InitiateMigrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of InitiateMigration. */
export type InitiateMigrationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: InitiateMigrationError;
  migrationStatus?: Maybe<MigrationStatus>;
};

/** Integration Authorization Consent form for the external integration */
export type IntegrationAuthorizationConsent = Timestamps & {
  /** Date and time when the consent form was created */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Additional fields for A&C. */
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** Number of patients in the practice */
  patientsCount?: Maybe<Scalars['Int']['output']>;
  /** Phone number of the medical practice */
  phone: Scalars['String']['output'];
  /** The name of the medical practice */
  practiceName: Scalars['String']['output'];
  /** Number of providers in the practice */
  providersCount?: Maybe<Scalars['Int']['output']>;
  /** Date and time when the consent form was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes for submitting an integration authorization consent form. */
export type IntegrationAuthorizationConsentAttributes = {
  /** Additional fields for A&C. */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  /** UID of the OAuth application. */
  oauthApplicationId: Scalars['ID']['input'];
  /** Number of patients in the practice. */
  patientsCount?: InputMaybe<Scalars['Int']['input']>;
  /** Phone number of the medical practice. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Name of the medical practice. */
  practiceName?: InputMaybe<Scalars['String']['input']>;
  /** Number of providers in the practice. */
  providersCount?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated error type of IntegrationAuthorizationConsentsCreate */
export type IntegrationAuthorizationConsentsCreateError = {
  /** Field errors */
  fields?: Maybe<IntegrationAuthorizationConsentsCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of IntegrationAuthorizationConsentsCreate */
export type IntegrationAuthorizationConsentsCreateFieldError = {
  metadata?: Maybe<Array<Scalars['String']['output']>>;
  oauthApplicationId?: Maybe<Array<Scalars['String']['output']>>;
  patientsCount?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  practiceName?: Maybe<Array<Scalars['String']['output']>>;
  providersCount?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of IntegrationAuthorizationConsentsCreate */
export type IntegrationAuthorizationConsentsCreateInput = {
  attributes: IntegrationAuthorizationConsentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of IntegrationAuthorizationConsentsCreate. */
export type IntegrationAuthorizationConsentsCreatePayload = {
  authorizationConsent?: Maybe<IntegrationAuthorizationConsent>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: IntegrationAuthorizationConsentsCreateError;
};

/** Integration category */
export type IntegrationCategory = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the integration category */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Integration listing */
export type IntegrationListing = Timestamps & {
  /** Additional instructions provided by the integration organization */
  additionalInstructions?: Maybe<Scalars['String']['output']>;
  /** Categories associated with the integration (ex. EHR, Diet planning solution, etc. */
  categories: Array<IntegrationCategory>;
  /** URL for the integration */
  connectionUrl: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** ID of the admin who created the listing */
  createdBy: Scalars['Int']['output'];
  /** Description provided by the integration */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Whether the current store is connected to the integration */
  isConnected: Scalars['Boolean']['output'];
  /** Whether the integration request is processing */
  isProcessingRequest: Scalars['Boolean']['output'];
  /** Whether the integration is revoked. */
  isRevoked: Scalars['Boolean']['output'];
  /** List of features about the integration */
  keyFeatures?: Maybe<Scalars['String']['output']>;
  /** URL where the logo for the integration is stored */
  logoUrl?: Maybe<Scalars['String']['output']>;
  /** Modalities associated with the integration (ex. Naturopathic Doctor, Chiropractor, etc. */
  modalities: Array<IntegrationModality>;
  /** Name of the Integration */
  name: Scalars['String']['output'];
  /** the associated OAuth application */
  oauthApplication: OauthApplication;
  /** ID for the associated OAuth application */
  oauthApplicationId: Scalars['Int']['output'];
  /** Name of the organization the runs the integration */
  organization: Scalars['String']['output'];
  /** URLs for screenshots of the integration */
  screenshotUrls: Array<Scalars['String']['output']>;
  /** Slug for the integration */
  slug: Scalars['String']['output'];
  /** Status of the integration (active or inactive) */
  status: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** ID of the admin who last updated the listing */
  updatedBy?: Maybe<Scalars['Int']['output']>;
  /** URL where the intro video for the integration is hosted */
  videoUrl?: Maybe<Scalars['String']['output']>;
};

/** The connection type for IntegrationListing. */
export type IntegrationListingConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IntegrationListingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IntegrationListing>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IntegrationListingEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IntegrationListing>;
};

/** Integration modality */
export type IntegrationModality = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the modality */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Details about an interaction */
export type InteractionDetails = {
  /** Description of the interaction */
  description: Scalars['String']['output'];
  /** Evidence level of the interaction */
  evidenceLevel: Scalars['String']['output'];
  /** Unique identifier for the interaction */
  id: Scalars['ID']['output'];
  /** Related ingredient if applicable */
  ingredientName?: Maybe<Scalars['String']['output']>;
  /** Related medication if applicable */
  medicationName?: Maybe<Scalars['String']['output']>;
  /** Monograph ID of the ingredient in the interaction */
  monographId?: Maybe<Scalars['ID']['output']>;
  /** References to the interaction */
  references: Array<InteractionReference>;
  /** Severity level of the interaction */
  severity: InteractionSeverityLevel;
};

/** The connection type for InteractionDetails. */
export type InteractionDetailsConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InteractionDetailsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InteractionDetails>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InteractionDetailsEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InteractionDetails>;
};

/** Reference information for an interaction */
export type InteractionReference = {
  /** Description of the reference */
  description: Scalars['String']['output'];
  /** Reference number or identifier */
  referenceNumber: Scalars['String']['output'];
  /** URL to the reference if available */
  url?: Maybe<Scalars['String']['output']>;
};

/** Severity levels for interactions */
export const InteractionSeverityLevel = {
  Major: 'MAJOR',
  Minor: 'MINOR',
  Moderate: 'MODERATE'
} as const;

export type InteractionSeverityLevel = typeof InteractionSeverityLevel[keyof typeof InteractionSeverityLevel];
/** A inventory unit for retail orders. */
export type InventoryUnit = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The line item associated with the inventory unit */
  lineItem: LineItem;
  /** The state of the inventory unit (shipped, on_hand, returned, backordered) */
  state: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The variant associated with the inventory unit. */
  variant: Variant;
};

/** Invoice address of the store */
export type InvoiceAddress = Timestamps & {
  /** Address line 1 */
  address1: Scalars['String']['output'];
  /** Address line 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** Name of city */
  city: Scalars['String']['output'];
  /** Full name of country */
  country: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** A value indicating whether the address is set as default for the store */
  default?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** A value indicating whether the address is a dropship address */
  isDropship?: Maybe<Scalars['Boolean']['output']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Full name of state or province */
  state: Scalars['String']['output'];
  /** Id of the state or province of the address. */
  stateId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Zipcode or postal code */
  zipcode: Scalars['String']['output'];
};

/** Attributes for adding an invoice address to a store. */
export type InvoiceAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

/** Attributes for adding a test to a plan */
export type LabAddTestToPlanAttributes = {
  labTestId: Scalars['ID']['input'];
  parentTestId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

/** Lab appointment location address details */
export type LabAppointmentLocationAddress = Timestamps & {
  /** Address line 1 */
  address1: Scalars['String']['output'];
  /** Address line 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** Name of city */
  city: Scalars['String']['output'];
  /** Full name of country */
  country: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** A value indicating whether the address is set as default for the store */
  default?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** A value indicating whether the address is a dropship address */
  isDropship?: Maybe<Scalars['Boolean']['output']>;
  /** Latitude coordinate of the address */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** Longitude coordinate of the address */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Full name of state or province */
  state: Scalars['String']['output'];
  /** Id of the state or province of the address. */
  stateId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Zipcode or postal code */
  zipcode: Scalars['String']['output'];
};

/** Lab biomarker result with their ingredient recommendations */
export type LabBiomarkerResult = {
  /** The date the biomarker results were received */
  createdAt: Scalars['DateTime']['output'];
  /** The unique identifier for the lab_biomarker_result */
  id: Scalars['ID']['output'];
  /** The label for this range (e.g. high, low, suboptimal high, suboptimal low or optimal). */
  label: Scalars['String']['output'];
  /** Name of the biomarker */
  name: Scalars['String']['output'];
  /** Array of ingredients recommended for the condition */
  recommendedIngredients: Array<RecommendedIngredient>;
  /** The unit corresponding to the value */
  unit?: Maybe<Scalars['String']['output']>;
  /** The value of the biomarker result */
  value: Scalars['String']['output'];
};

/** Arguments for filtering lab_companies query */
export type LabCompanyFilterObject = {
  wholesale?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Attributes for adding/removing to favorites */
export type LabFavoritesAttributes = {
  labTestId: Scalars['ID']['input'];
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Arguments for filtering lab_fulfillment_biomarker_results */
export type LabFulfillmentLabBiomarkerResultFilterObject = {
  biomarkerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  biomarkerName?: InputMaybe<Scalars['String']['input']>;
  healthCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  markerStatuses?: InputMaybe<Array<BiomarkerResultRangeCategories>>;
};

/** Lab fulfillment information for the authorization of a recommendation if required */
export type LabFulfillmentsAuthorization = Node & Timestamps & {
  /** When the lab recommendation was approved */
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Error text for the authorization */
  errorText?: Maybe<Scalars['String']['output']>;
  /** When the lab recommendation failed */
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Lab fulfillment recommendation of the authorization */
  fulfillmentRecommendation: LabFulfillmentsRecommendation;
  id: Scalars['ID']['output'];
  /** Metadata for the authorization */
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** The name of the provider that performed the authorization */
  provider?: Maybe<Scalars['String']['output']>;
  /** When the lab recommendation was rejected if it was */
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The reason the lab recommendation was rejected */
  rejectedReason?: Maybe<Scalars['String']['output']>;
  /** When the authorization was sent to the provider */
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** An aggregation of Biomarker Result Group Optimality per Biomarker Category */
export type LabFulfillmentsBiomarkerCategoryOptimality = {
  /** The category for the biomarkers */
  labBiomarkerCategory: LabsBiomarkerCategory;
  /** An aggregation of optimalities for the category of biomarkers */
  optimality: LabFulfillmentsBiomarkerResultGroupOptimality;
};

/** Individual lab biomarker results */
export type LabFulfillmentsBiomarkerResult = Node & Timestamps & {
  /** All of the results for the associated biomarker */
  allResults: Array<LabFulfillmentsBiomarkerResult>;
  /** Whether the value was amended */
  amended: Scalars['Boolean']['output'];
  /** The biomarker associated with the result (if matched successfully) */
  biomarker?: Maybe<LabsBiomarker>;
  /**
   * Deprecated
   * @deprecated Use interpreted_biomarker_result instead.
   */
  biomarkerResultInterpretation: LabFulfillmentsBiomarkerResultInterpretation;
  /**
   * Deprecated
   * @deprecated Use interpreted_biomarker_result instead.
   */
  biomarkerResultInterpretations: Array<LabFulfillmentsBiomarkerResultInterpretation>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The latest interpretation associated to this biomarker result */
  interpretedBiomarkerResult?: Maybe<LabFulfillmentsBiomarkerResultInterpretation>;
  /** True if the result value is Do Not Report */
  isDoNotReport: Scalars['Boolean']['output'];
  /** The lab result associated with the biomarker result */
  labResult: LabFulfillmentsLabResult;
  /** The previous result for the biomarker */
  previousResult?: Maybe<LabFulfillmentsBiomarkerResult>;
  /** The raw discrete data */
  rawData: Scalars['String']['output'];
  /** Aggregation of data for the rendering of the Trend Graph. */
  trendGraph: LabFulfillmentsBiomarkerResultTrendGraph;
  /** Aggregation of data to render the trend line. */
  trendLine: LabFulfillmentsBiomarkerResultTrendLine;
  /** The unit corresponding to the value */
  unit?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The value of the biomarker result */
  value: Scalars['String']['output'];
  /** The type of value */
  valueType: Scalars['String']['output'];
};

/** The connection type for LabFulfillmentsBiomarkerResult. */
export type LabFulfillmentsBiomarkerResultConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LabFulfillmentsBiomarkerResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LabFulfillmentsBiomarkerResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LabFulfillmentsBiomarkerResultEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LabFulfillmentsBiomarkerResult>;
};

/** An aggregation of optimalities for a given set of Biomarkers */
export type LabFulfillmentsBiomarkerResultGroupOptimality = {
  /** Number of biomarkers in the optimal range */
  numberOptimal: Scalars['Int']['output'];
  /** Number of biomarkers out of the range */
  numberOutOfRange: Scalars['Int']['output'];
  /** Number of biomarkers in the suboptimal range */
  numberSuboptimal: Scalars['Int']['output'];
};

/** Practitioner interpretations of individual biomarker results */
export type LabFulfillmentsBiomarkerResultInterpretation = Node & Timestamps & {
  /** Whether this result is an area of concern */
  areaOfConcern: Scalars['Boolean']['output'];
  /** The lab biomarker result this interpretation is for */
  biomarkerResult: LabFulfillmentsBiomarkerResult;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The top level lab result interpretation this interpretation is associated with */
  labResultInterpretation: LabFulfillmentsLabResultInterpretation;
  /** The lab biomarker result range the result falls into */
  matchedRange?: Maybe<LabsBiomarkerResultRange>;
  /** The practitioner's note interpreting the biomarker result */
  note?: Maybe<Scalars['String']['output']>;
  /** True if the note for the lab biomarker result has been updated from the default */
  noteIsEdited: Scalars['Boolean']['output'];
  /**
   * The lab biomarker result range group this interpretation is associated with
   * (depending on the appropriate patient demographic data)
   */
  rangeGroup?: Maybe<LabsBiomarkerResultRangeGroup>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Aggregation of data for simplifying the rendering of the Trend Graph. */
export type LabFulfillmentsBiomarkerResultTrendGraph = {
  /** A list of the entries in the Trend Graph. */
  entries: Array<LabFulfillmentsBiomarkerResultTrendGraphEntry>;
  /** The id of the biomarker result record. */
  id: Scalars['ID']['output'];
  /** Whether or not the trend graph should be displayed. */
  isDisplayGraph: Scalars['Boolean']['output'];
  /** The range group's type of value (Numerical, Binary, Categorical). */
  rangeType?: Maybe<Scalars['String']['output']>;
  /** The ranges associated with the biomarker. */
  ranges: Array<LabsBiomarkerResultRange>;
};

/** An entry in the Trend Graph. */
export type LabFulfillmentsBiomarkerResultTrendGraphEntry = {
  /** The date of creation of the biomarker result record. */
  date: Scalars['ISO8601DateTime']['output'];
  /** The id of the biomarker result record. */
  id: Scalars['ID']['output'];
  /** The matched range associated to the interpretation. */
  range?: Maybe<LabsBiomarkerResultRange>;
  /**
   * Deprecated
   * @deprecated Use valueString instead.
   */
  value: Scalars['Float']['output'];
  /** The value of the biomarker result record (as a string). */
  valueString: Scalars['String']['output'];
};

/** Aggregation for the rendering of the trend line. */
export type LabFulfillmentsBiomarkerResultTrendLine = {
  /** The matched range category of the current result. */
  currentRangeCategory: BiomarkerResultRangeCategories;
  /**
   * Deprecated
   * @deprecated Use currentResultString instead.
   */
  currentResult: Scalars['Float']['output'];
  /** The value of the current result (as a string). */
  currentResultString: Scalars['String']['output'];
  /** The encoded id representing the current result within the trend line type. */
  id: Scalars['ID']['output'];
  /** The is_do_not_report value of the current result. */
  isDoNotReport: Scalars['Boolean']['output'];
  /** The matched range category of the previous result. */
  previousRangeCategory?: Maybe<BiomarkerResultRangeCategories>;
  /**
   * Deprecated
   * @deprecated Use previousResultString instead.
   */
  previousResult?: Maybe<Scalars['Float']['output']>;
  /** The value of the previous result (as a string). */
  previousResultString?: Maybe<Scalars['String']['output']>;
  /** The unit of the current result. */
  unit?: Maybe<Scalars['String']['output']>;
};

/** Lab fulfillment invocations for the recommendation */
export type LabFulfillmentsInvocation = Node & Timestamps & {
  /** The action of the invocation */
  action: Scalars['String']['output'];
  /** The number of attempts for the invocation */
  attempts: Scalars['Int']['output'];
  /** When the invocation is awaiting a response */
  awaitingResponseAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the invocation is completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Error text for the invocation */
  errorText?: Maybe<Scalars['String']['output']>;
  /** When the invocation failed */
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Metadata for the invocation */
  metadata?: Maybe<Scalars['JSON']['output']>;
  /** When the invocation was queued */
  queuedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The recording URL for the invocation */
  recordingUrl?: Maybe<Scalars['String']['output']>;
  /** When the invocation started */
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The state of the invocation */
  state: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Lab results corresponding to a lab line item */
export type LabFulfillmentsLabResult = Node & Timestamps & {
  /** Whether the lab result has been amended or not */
  amended: Scalars['Boolean']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Whether the lab result is critical or not */
  critical: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The latest lab result interpretation for the lab result */
  latestLabResultInterpretation?: Maybe<LabFulfillmentsLabResultInterpretation>;
  /** Notes about the lab result */
  notes?: Maybe<Scalars['String']['output']>;
  /** URL to the PDF of the lab result */
  resultPdfUrl?: Maybe<Scalars['String']['output']>;
  /** The date and time the lab result was reviewed */
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Lab result interpretations corresponding to a lab result */
export type LabFulfillmentsLabResultInterpretation = Node & Timestamps & {
  /** Date the interpretation was autosent to the patient. */
  autosendOn?: Maybe<Scalars['Date']['output']>;
  /** Aggregation of Biomarker Optimality per Biomarker Category */
  categoryOptimalities: Array<LabFulfillmentsBiomarkerCategoryOptimality>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** When the pdf was last generated for the interpretation */
  exportedPdfGeneratedAt?: Maybe<Scalars['DateTime']['output']>;
  /** URL to the exported PDF of the interpretation */
  exportedPdfUrl?: Maybe<Scalars['String']['output']>;
  /** Biomarker results associated to this result that have been flagged for review. */
  flaggedBiomarkerResults?: Maybe<Array<LabFulfillmentsBiomarkerResult>>;
  id: Scalars['ID']['output'];
  /** Whether the interpretation has been autosent to the patient */
  isAutosent: Scalars['Boolean']['output'];
  /** Whether the interpretation has been released to the patient */
  isReleased: Scalars['Boolean']['output'];
  /** True if the interpretation summary was generated by Fullscript */
  isSummaryFullscriptGenerated: Scalars['Boolean']['output'];
  /**
   * Deprecated
   * @deprecated Use `paginatedLabBiomarkerResults` instead
   */
  labBiomarkerResults: Array<LabFulfillmentsBiomarkerResult>;
  /** When this Interpretation received practitioner consent to send to patient, if consent was required */
  outOfRangeConsentAt?: Maybe<Scalars['DateTime']['output']>;
  /** True if the Interpretation requires practitioner consent before sending to patient */
  outOfRangeConsentRequired: Scalars['Boolean']['output'];
  /** Interpretable biomarker results associated to this result, with available biomarker interpretations. */
  paginatedLabBiomarkerResults: LabFulfillmentsBiomarkerResultConnection;
  /** Age of the patient at the time of sample collection */
  patientAge: Scalars['Int']['output'];
  /** Biological sex of the patient at the time of sample collection */
  patientBiologicalSex: Scalars['String']['output'];
  /** Lactating status of the patient at the time of sample collection */
  patientLactatingStatus?: Maybe<Scalars['Boolean']['output']>;
  /** Lactating status of the patient at the time of sample collection */
  patientPregnancyStatus?: Maybe<Scalars['Boolean']['output']>;
  /** Smoking status of the patient at the time of the sample collection */
  patientSmokingStatus?: Maybe<Scalars['Boolean']['output']>;
  /** When this interpretation was released to the patient */
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Summary of the interpretation */
  summary?: Maybe<Scalars['String']['output']>;
  /** Aggregation of Biomarker Optimality for all Biomarkers in Interpretation. */
  totalOptimality: LabFulfillmentsBiomarkerResultGroupOptimality;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Lab result interpretations corresponding to a lab result */
export type LabFulfillmentsLabResultInterpretationCategoryOptimalitiesArgs = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** Lab result interpretations corresponding to a lab result */
export type LabFulfillmentsLabResultInterpretationLabBiomarkerResultsArgs = {
  filters?: InputMaybe<LabFulfillmentLabBiomarkerResultFilterObject>;
};


/** Lab result interpretations corresponding to a lab result */
export type LabFulfillmentsLabResultInterpretationPaginatedLabBiomarkerResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LabFulfillmentLabBiomarkerResultFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Lab fulfillment information of lab line items */
export type LabFulfillmentsLineItem = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Lab fulfillment recommendation of the lab line item */
  labFulfillmentRecommendation: LabFulfillmentsRecommendation;
  /** Lab results of the lab line item */
  labResults?: Maybe<Array<LabFulfillmentsLabResult>>;
  /** Spree line item of the lab line item */
  spreeLineItem: LineItem;
  /** What the state of the lab line item is (requisition ready, processing, results ready, etc.) */
  state: LineItemStates;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Lab fulfillment information of a lab recommendation */
export type LabFulfillmentsRecommendation = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Lab fulfillment authorization of the lab recommendation */
  labFulfillmentAuthorization?: Maybe<LabFulfillmentsAuthorization>;
  /** Lab fulfillment line items of the lab recommendation */
  labFulfillmentLineItems?: Maybe<Array<LabFulfillmentsLineItem>>;
  /** The lab recommendation this fulfillment recommendation belongs to */
  labRecommendation: LabsRecommendation;
  /** The latest lab result on the fulfillment recommendation */
  latestLabResult?: Maybe<LabFulfillmentsLabResult>;
  /** The order this lab recommendation belongs to */
  order?: Maybe<Order>;
  /** Whether the lab recommendation requires authorization before it can be fulfilled */
  requiresAuthorization: Scalars['Boolean']['output'];
  /** URL to the requisition form of the lab recommendation */
  requisitionFormUrl?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes for activating a lab_kit */
export type LabKitActivationAttributes = {
  /** The activation code for the kit */
  activationCode: Scalars['String']['input'];
  /** Optional array of addon_ids */
  addonIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The ID of the lab test to add to the treatment_plan */
  labTestId: Scalars['ID']['input'];
  /** Optional patient_id for the treatment_plan */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Optional practitioner_id for the clerks */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Attributes for adding/removing to kit favorites */
export type LabKitFavoritesAttributes = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  spreeProductId: Scalars['ID']['input'];
};

/** Attributes for updating a practitioner's lab_panel */
export type LabPanelAttributes = {
  instructions?: InputMaybe<Scalars['String']['input']>;
  labPanelId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  requiresFasting?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Attributes for adding/removing tests to a lab panel */
export type LabPanelTestAttributes = {
  labPanelId: Scalars['ID']['input'];
  labTestId: Scalars['ID']['input'];
};

/** Attributes for updating a practitioner for labs */
export type LabPractitionerSettingsUpdateAttributes = {
  /** The first name of the practitioner */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the practitioner */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Expiry date for the license. */
  licenseExpiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** License number */
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  /** State abbreviation of the license. */
  licenseState?: InputMaybe<Scalars['String']['input']>;
  /** The NPI of the practitioner */
  npi?: InputMaybe<Scalars['String']['input']>;
  /** Degree id (i.e. practitioner type id)for this license. */
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Attributes for updating a lab recommendation */
export type LabRecommendationAttributes = {
  instructions?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  requiresFasting?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Attributes for updating a lab_recommendation custom fee */
export type LabRecommendationCustomFeeAttributes = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for deleting a lab recommendation from a plan */
export type LabRecommendationDeleteAttributes = {
  labRecommendationId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

/** Attributes for deleting a lab test from a lab recommendation */
export type LabRecommendationDeleteTestAttributes = {
  labTestId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

/** Arguments for filtering lab_recommendations */
export type LabRecommendationFilterObject = {
  actionRequired?: InputMaybe<Scalars['Boolean']['input']>;
  ordered?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<RecommendationFilterTypeObject>;
};

/** Arguments for lab recommendations sorting. */
export type LabRecommendationSort = {
  direction: SortDirection;
  field: LabRecommendationSortField;
};

/** Field arguments for lab recommendation sorting. */
export const LabRecommendationSortField = {
  /** Sort by available_at on treatment plan */
  AvailableAt: 'AVAILABLE_AT',
  /** Sort by created at */
  CreatedAt: 'CREATED_AT',
  /** Sort by most recently updated recommendation */
  RecentlyUpdated: 'RECENTLY_UPDATED',
  /** Sort by updated at */
  UpdatedAt: 'UPDATED_AT'
} as const;

export type LabRecommendationSortField = typeof LabRecommendationSortField[keyof typeof LabRecommendationSortField];
/** Arguments for filtering lab_tests search */
export type LabTestSearchFilterObject = {
  biomarkerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  favoritesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  healthCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  phlebotomyNotRequired?: InputMaybe<Scalars['Boolean']['input']>;
  phlebotomyRequired?: InputMaybe<Scalars['Boolean']['input']>;
  sampleTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  testTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  wholesale?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Arguments for lab tests search sorting. */
export type LabTestSearchSort = {
  direction: SortDirection;
  field: LabTestSearchSortField;
};

/** Field arguments for lab tests search sorting. */
export const LabTestSearchSortField = {
  /** Sort alphabetically */
  Alphabetical: 'ALPHABETICAL',
  /** Sort by best match */
  BestMatch: 'BEST_MATCH',
  /** Sort by popularity */
  Popularity: 'POPULARITY'
} as const;

export type LabTestSearchSortField = typeof LabTestSearchSortField[keyof typeof LabTestSearchSortField];
/** LabTypeaheadType */
export type LabTypeahead = {
  /** The type of the typeahead */
  entityType: Scalars['String']['output'];
  /** The ID of the typeahead */
  entityTypeId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Name of the typehead */
  name: Scalars['String']['output'];
};

/** LabTypeaheadGroupType */
export type LabTypeaheadGroupType = {
  /** Grouped typeahead data for the entity type */
  data: Array<LabTypeahead>;
  /** Entity type */
  entityType: Scalars['String']['output'];
};

/** Lab appointments for phlebotomy */
export type LabsAppointment = Node & Timestamps & {
  /** Timestamp when the appointment was cancelled */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The location of the appointment */
  labAppointmentLocation: LabsAppointmentLocation;
  /** The Lab Recommendation associated with the appointment */
  labRecommendation: LabsRecommendation;
  /** The patient associated with the appointment */
  patient: Patient;
  /** If there is an appointment window, this is the end time of the window */
  scheduledEnd?: Maybe<Scalars['DateTime']['output']>;
  /** When the appointment is scheduled to take place */
  scheduledTime?: Maybe<Scalars['DateTime']['output']>;
  /** The service address of the appointment */
  serviceAddress?: Maybe<LabAppointmentLocationAddress>;
  /** The current state of the appointment (ie - pending, cancelled, etc) */
  state: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Location for lab appointments */
export type LabsAppointmentLocation = Node & Timestamps & {
  /** Address of the location */
  address?: Maybe<LabAppointmentLocationAddress>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Distance from the location in miles */
  distance?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  /** Company of the location */
  labCompany: LabsCompany;
  /** Landmark information for the location */
  landmark?: Maybe<Scalars['String']['output']>;
  /** Latitude of the location */
  latitude: Scalars['Float']['output'];
  /** Longitude of the location */
  longitude: Scalars['Float']['output'];
  /** Name of the location */
  name: Scalars['String']['output'];
  /** Operating hours of the location */
  operatingHours: LabsAppointmentLocationOperatingHours;
  /** Timezone of the location */
  timezone: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for LabsAppointmentLocation. */
export type LabsAppointmentLocationConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LabsAppointmentLocationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LabsAppointmentLocation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LabsAppointmentLocationEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LabsAppointmentLocation>;
};

/** Operating hours for lab appointment location */
export type LabsAppointmentLocationOperatingHours = {
  /** Operating hours for Friday */
  friday?: Maybe<Scalars['String']['output']>;
  /** Operating hours for Monday */
  monday?: Maybe<Scalars['String']['output']>;
  /** Operating hours for Saturday */
  saturday?: Maybe<Scalars['String']['output']>;
  /** Operating hours for Sunday */
  sunday?: Maybe<Scalars['String']['output']>;
  /** Operating hours for Thursday */
  thursday?: Maybe<Scalars['String']['output']>;
  /** Operating hours for Tuesday */
  tuesday?: Maybe<Scalars['String']['output']>;
  /** Operating hours for Wednesday */
  wednesday?: Maybe<Scalars['String']['output']>;
};

/** Time slot for a lab appointment */
export type LabsAppointmentTimeSlot = {
  /** The duration of the appointment in minutes */
  duration?: Maybe<Scalars['Int']['output']>;
  /** The key associated with the lab appointment */
  key?: Maybe<Scalars['String']['output']>;
  /** The time slots available for the appointment */
  timeSlot: Scalars['String']['output'];
};

/** Biomarker of a lab test (what the tests covers e.g. Zinc) */
export type LabsBiomarker = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the Biomarker */
  description?: Maybe<Scalars['String']['output']>;
  /** A description of what it means to have a high value for this biomarker */
  highDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Whether the biomarker is included in the given tests */
  includedInTests: Array<Scalars['Boolean']['output']>;
  /** Categories that the Biomarker belongs to */
  labBiomarkerCategory?: Maybe<LabsBiomarkerCategory>;
  /** A description of what it means to have a low value for this biomarker */
  lowDescription?: Maybe<Scalars['String']['output']>;
  /** Name of the biomarker */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Biomarker of a lab test (what the tests covers e.g. Zinc) */
export type LabsBiomarkerIncludedInTestsArgs = {
  testIds: Array<Scalars['ID']['input']>;
};

/** A category for biomarkers of a lab test (what the tests covers e.g. Zinc) */
export type LabsBiomarkerCategory = {
  /** Biomarkers belonging to the biomarker category */
  biomarkers?: Maybe<Array<LabsBiomarker>>;
  /** A description of the biomarker category */
  description: Scalars['String']['output'];
  /** The name of the icon for the biomarker category */
  iconName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Name of the biomarker category */
  name: Scalars['String']['output'];
};

/** The connection type for LabsBiomarkerCategory. */
export type LabsBiomarkerCategoryConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BiomarkerCategoryForJourneyVariantEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LabsBiomarkerCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** The connection type for LabsBiomarker. */
export type LabsBiomarkerConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LabsBiomarkerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LabsBiomarker>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LabsBiomarkerEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LabsBiomarker>;
};

/**
 * A single lab result range (part of a group of ranges). Each range has a min and
 * max value and a label (e.g. 'below optimal', 'optimal', etc).
 */
export type LabsBiomarkerResultRange = Node & Timestamps & {
  /** The category for this range (e.g. out of range, sub optimal, and optimal). */
  category: BiomarkerResultRangeCategories;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The default interpretation for this range. */
  defaultInterpretation?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** The label for this range (e.g. 'below optimal', 'optimal', etc) */
  label: Scalars['String']['output'];
  /** The lab biomarker range group this range is associated with */
  rangeGroup: LabsBiomarkerResultRangeGroup;
  /** The maximum value for this range. Null means it is open-ended. */
  rangeMax?: Maybe<Scalars['String']['output']>;
  /** The minimum value for this range. Null means it is open-ended. */
  rangeMin?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** A group of lab result ranges tied to a single biomarker and a set of patient demographic data */
export type LabsBiomarkerResultRangeGroup = Node & Timestamps & {
  /** The maximum age (of the patient) this range group applies to. Null means it is an open ended range. */
  ageMax?: Maybe<Scalars['Int']['output']>;
  /** The minimum age (of the patient) this range group applies to. Null means it is an open ended range. */
  ageMin?: Maybe<Scalars['Int']['output']>;
  /** The biological sex (of the patient) this range group applies to. Null means it applies to all. */
  biologicalSex?: Maybe<Scalars['String']['output']>;
  /** The lab biomarker this range group is associated with */
  biomarker: LabsBiomarker;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The interpretations associated to this range group */
  interpretations: Array<LabFulfillmentsBiomarkerResultInterpretation>;
  /** The lactating status (of the patient) this range group applies to. Null means it applies to all. */
  lactatingStatus: Scalars['Boolean']['output'];
  /** The pregnancy status (of the patient) this range group applies to. Null means it applies to all. */
  pregnancyStatus: Scalars['Boolean']['output'];
  /** The ranges associated to this range group */
  ranges: Array<LabsBiomarkerResultRange>;
  /** The references associated to this range group */
  references: Array<LabsBiomarkerResultRangeGroupReference>;
  /** The smoking status (of the patient) this range group applies to. Null means it applies to all. */
  smokingStatus: Scalars['Boolean']['output'];
  /** The unit for this range group (e.g. 'mg/dL', 'mmol/L', etc) */
  unit: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The type of value for this range group (Numerical, Binary, Categorical) */
  valueType: Scalars['String']['output'];
};

/** References tied to the lab result range groups */
export type LabsBiomarkerResultRangeGroupReference = Node & Timestamps & {
  /** Authors of the reference, comma separated */
  authors: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** The lab result range group this reference is tied to */
  rangeGroup: LabsBiomarkerResultRangeGroup;
  /** Title of the reference */
  title: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** URL of the reference */
  url: Scalars['String']['output'];
};

/** Company of a lab test */
export type LabsCompany = Node & Timestamps & {
  /** Alerts for the test */
  alerts?: Maybe<Array<Alert>>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Whether this is Quest Diagnostics */
  isQuest: Scalars['Boolean']['output'];
  /** The company's logo */
  logo?: Maybe<Scalars['String']['output']>;
  /** Name of the company */
  name: Scalars['String']['output'];
  /** Whether the lab company requires registration before orders can be fulfilled */
  requiresRegistration: Scalars['Boolean']['output'];
  /** Requisition fee from the company */
  requisitionFee?: Maybe<Scalars['Float']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Company of a lab test */
export type LabsCompanyAlertsArgs = {
  alertType: AlertTypeEnum;
};

/** Sample collection provider for a lab company */
export type LabsCompanySampleCollectionProvider = {
  /** Flag if this is the default provider */
  default: Scalars['Boolean']['output'];
  /** Sample collection providers fee */
  fee: Scalars['Float']['output'];
  /** Sample collection provider details */
  sampleCollectionProvider: LabsSampleCollectionProvider;
};

/** Health category of a lab test (Adrenal/Cardiovascular/etc) */
export type LabsHealthCategory = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the health category */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Labs Journeys Option Values */
export type LabsJourneysOptionValue = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the Journey option value */
  description: Scalars['String']['output'];
  /** Display name of the Journey option value */
  displayName: Scalars['String']['output'];
  /** Name of the Journey option value */
  name: Scalars['String']['output'];
  /** Display position of the Journey option value */
  position: Scalars['Int']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Labs Journeys Order */
export type LabsJourneysOrder = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Returns true if the Journey Order has been placed */
  isOrdered: Scalars['Boolean']['output'];
  /** The Journey Subscription the Journey Order belongs to */
  journeySubscription: LabsJourneysSubscription;
  /** Spree Order that is associated with the Journey Order */
  order?: Maybe<Order>;
  /** Date that this Journey Order will be placed */
  orderDate: Scalars['Date']['output'];
  /** Lab recommendations associated with the Journey Order */
  recommendations: Array<LabsRecommendation>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Labs Journeys Subscription */
export type LabsJourneysSubscription = Node & Timestamps & {
  /** Brand of the journey */
  brand: Brand;
  /** When the subscription was cancelled */
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The name of who cancelled the subscription */
  canceledBy?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The credit card associated to this subscription */
  creditCard?: Maybe<CreditCard>;
  /** The frequency of the subscription */
  frequency: Scalars['String']['output'];
  /** Full name of the subscription */
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The initial recommendation of the journey */
  initialRecommendation?: Maybe<LabsRecommendation>;
  /** Prescribed journey reference */
  labJourneyVariant: LabsJourneysVariant;
  /** Date that the last order occured on */
  lastOrderDate?: Maybe<Scalars['Date']['output']>;
  /** Date that the next order will occur on */
  nextOrderDate?: Maybe<Scalars['Date']['output']>;
  /** Notes from the practitioner */
  notes?: Maybe<Scalars['String']['output']>;
  /** Journey Orders for the subscription */
  orders: Array<LabsJourneysOrder>;
  /** Patient that subscription is created for */
  patient: Patient;
  /** Practitioner that created this subscription */
  practitioner: Practitioner;
  /** State of the subscription */
  state: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Labs Journeys Variants */
export type LabsJourneysVariant = Node & Timestamps & {
  /**
   * Deprecated
   * @deprecated Use categories instead
   */
  biomarkerCategories: Array<LabsBiomarkerCategory>;
  /** The number of Biomarkers tested for a Journey Variant */
  biomarkerCount: Scalars['Int']['output'];
  /** Ordering permission for the test */
  canOrder: OrderingPermissions;
  /** Biomarker categories belonging to this Journey */
  categories: LabsBiomarkerCategoryConnection;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the Journey Variant */
  name: Scalars['String']['output'];
  /** Option values for this Journey option type */
  optionValues: Array<LabsJourneysOptionValue>;
  /** Patient instructions of the Journey Variant */
  patientInstructions?: Maybe<Scalars['String']['output']>;
  /** Retail cost of the Journey Variant */
  price: Scalars['Float']['output'];
  /** Tests that make up this Journey Variant */
  tests: Array<LabsTest>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Labs Journeys Variants */
export type LabsJourneysVariantCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Consolidated information of the tests of a physical kit */
export type LabsKitTestInformationType = {
  /** Consolidated addons of the kit */
  addons?: Maybe<Array<LabsTest>>;
  /** Ordering permission for the kit */
  canOrder: OrderingPermissions;
  /** Company of the kit */
  company: LabsCompany;
  /** Description of the kit */
  description?: Maybe<Scalars['String']['output']>;
  /** Whether kit is favorited or not by the practitioner */
  isFavorited: Scalars['Boolean']['output'];
  /** If any of the tests in the kit require fasting */
  requiresFasting: Scalars['Boolean']['output'];
  /** If any of the tests in the kit require phlebotomy */
  requiresPhlebotomy: Scalars['Boolean']['output'];
  /** Consolidated sample types of the kit */
  sampleTypes: Array<LabsSampleType>;
  /** Tests of the kit */
  tests: Array<LabsTest>;
};


/** Consolidated information of the tests of a physical kit */
export type LabsKitTestInformationTypeCanOrderArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};


/** Consolidated information of the tests of a physical kit */
export type LabsKitTestInformationTypeIsFavoritedArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** License statuses for ordering labs. */
export const LabsLicenseStatuses = {
  FullyLicensed: 'fully_licensed',
  PartiallyLicensed: 'partially_licensed',
  Unlicensed: 'unlicensed'
} as const;

export type LabsLicenseStatuses = typeof LabsLicenseStatuses[keyof typeof LabsLicenseStatuses];
/** Autogenerated error type of LabsOnboardingAcceptAgreement */
export type LabsOnboardingAcceptAgreementError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of LabsOnboardingAcceptAgreement */
export type LabsOnboardingAcceptAgreementInput = {
  agreementType: PractitionerAgreementTypes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  docuSignEnvelopeId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of LabsOnboardingAcceptAgreement. */
export type LabsOnboardingAcceptAgreementPayload = {
  agreement?: Maybe<PractitionerAgreement>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: LabsOnboardingAcceptAgreementError;
  practitioner?: Maybe<Practitioner>;
};

/** Autogenerated error type of LabsOnboardingGenerateBaaAgreement */
export type LabsOnboardingGenerateBaaAgreementError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of LabsOnboardingGenerateBaaAgreement */
export type LabsOnboardingGenerateBaaAgreementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of LabsOnboardingGenerateBaaAgreement. */
export type LabsOnboardingGenerateBaaAgreementPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  docuSignEnvelopeId?: Maybe<Scalars['String']['output']>;
  errors: LabsOnboardingGenerateBaaAgreementError;
  practitioner?: Maybe<Practitioner>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Permission levels for ordering labs. */
export const LabsOrderingPermissionLevels = {
  AllExceptRegistrationRequiredTests: 'all_except_registration_required_tests',
  AllTests: 'all_tests',
  NetworkRequiredForAllTests: 'network_required_for_all_tests',
  NetworkRequiredForSomeTests: 'network_required_for_some_tests',
  NoTests: 'no_tests',
  UnrestrictedTestsOnly: 'unrestricted_tests_only'
} as const;

export type LabsOrderingPermissionLevels = typeof LabsOrderingPermissionLevels[keyof typeof LabsOrderingPermissionLevels];
/** Practitioner's custom panel that holds a collection of tests that can be applied to a treatment_plan */
export type LabsPanel = Node & Timestamps & {
  /** Authorization network fee for the lab panel (if applicable) */
  authNetworkFee?: Maybe<Scalars['Float']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Current state of the lab panel */
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Instructions for the tests of the lab panel */
  instructions?: Maybe<Scalars['String']['output']>;
  /** Name of the lab panel */
  name: Scalars['String']['output'];
  /** Practitioner who created the lab panel */
  practitioner: Practitioner;
  /** Total price of the tests of a lab recommendation */
  price?: Maybe<Scalars['Float']['output']>;
  /** Total price of the tests in currency of a lab recommendation */
  priceCurrency?: Maybe<Scalars['Currency']['output']>;
  /** Whether the tests in the panel require fasting, with exceptions handled by the instructions */
  requiresFasting: Scalars['Boolean']['output'];
  /** Requisition fee for the panel (if applicable) */
  requisitionFee?: Maybe<Scalars['Float']['output']>;
  /** Lab tests included in the this lab panel */
  tests: Array<LabsTest>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for LabsPanel. */
export type LabsPanelConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LabsPanelEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LabsPanel>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LabsPanelEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LabsPanel>;
};

/** Autogenerated error type of LabsPractitionersRegistrationViewTrigger */
export type LabsPractitionersRegistrationViewTriggerError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of LabsPractitionersRegistrationViewTrigger */
export type LabsPractitionersRegistrationViewTriggerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of LabsPractitionersRegistrationViewTrigger. */
export type LabsPractitionersRegistrationViewTriggerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: LabsPractitionersRegistrationViewTriggerError;
  practitioner?: Maybe<Practitioner>;
};

/** Lab recommendation that is part of a treatment plan */
export type LabsRecommendation = Node & Timestamps & {
  /** Activation code for the lab recommendation */
  activationCode?: Maybe<Scalars['String']['output']>;
  /** Authorization network fees for the lab recommendation (if applicable) */
  authNetworkFee?: Maybe<Scalars['Float']['output']>;
  /** When lab recommendation became available to a patient */
  availableAt?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the tests of the lab recommendation can be activated */
  canActivate: Scalars['Boolean']['output'];
  /** Whether the lab recommendation can be ordered */
  canPatientOrder: Scalars['Boolean']['output'];
  /** Whether the lab recommendation can be saved as a panel */
  canSaveAsPanel: Scalars['Boolean']['output'];
  /** Company that the tests of the lab recommendation belong to */
  company: LabsCompany;
  /** Requisition fee from the lab company */
  companyRequisitionFee: Scalars['Float']['output'];
  /** List of sample collection providers for the lab recommendation company */
  companySampleCollectionProviders: Array<LabsCompanySampleCollectionProvider>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Status of recommendation */
  currentState: Scalars['String']['output'];
  /** Custom fee that belongs to this lab recommendation */
  customFee?: Maybe<LabsRecommendationCustomFee>;
  /** Delivery method for the lab recommendation */
  deliveryMethod: RecommendationDeliveryMethods;
  /** Events for the lab recommendation in descending order */
  events: Array<LabsRecommendationEvent>;
  id: Scalars['ID']['output'];
  /** Instructions for the lab recommendation */
  instructions?: Maybe<Scalars['String']['output']>;
  /** Used to identify if an interpretation was autosent for this lab recommendation */
  isAutosent: Scalars['Boolean']['output'];
  /** Used to identify if lab recommendation is for a Journey that has not been ordered yet */
  isFutureJourney: Scalars['Boolean']['output'];
  /** Used to identify if an interpretation will be generated for this lab recommendation */
  isInterpretable: Scalars['Boolean']['output'];
  /** Used to identify if lab recommendation belongs to a Journey */
  isJourney: Scalars['Boolean']['output'];
  /** Whether the lab recommendation has been purchased */
  isPurchased: Scalars['Boolean']['output'];
  /** Brand name of the  journey subscription */
  journeyBrandName?: Maybe<Scalars['String']['output']>;
  /** Journey Order associated with the Recommendation */
  journeyOrder?: Maybe<LabsJourneysOrder>;
  /** Journey Subscription associated with the Recommendation */
  journeySubscription?: Maybe<LabsJourneysSubscription>;
  /** The lab appointment associated with the lab recommendation, does not return cancelled appointments */
  labAppointment?: Maybe<LabsAppointment>;
  /** Lab fulfillment recommendation of the lab recommendation */
  labFulfillmentRecommendation?: Maybe<LabFulfillmentsRecommendation>;
  /** Most recent event timestamp for the lab recommendation */
  latestStateTransitionedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Name of the lab recommendation */
  name?: Maybe<Scalars['String']['output']>;
  /** What the state of the lab recommendation is, from the patient's perspective */
  patientFriendlyState: PatientRecommendationStates;
  /** What the state of the lab recommendation is, from the practitioner's perspective */
  practitionerFriendlyState: PractitionerRecommendationStates;
  /** Total price of the lab recommendation, including all applicable fees */
  price?: Maybe<Scalars['Float']['output']>;
  /** Total price of the lab recommendation, including the authorization network fees (if applicable), with currency */
  priceCurrency?: Maybe<Scalars['Currency']['output']>;
  /** Top-level tests included in the lab recommendation */
  recommendationTests?: Maybe<Array<LabsRecommendationTest>>;
  /** Whether the tests in the recommendation require fasting, with exceptions handled by the instructions */
  requiresFasting?: Maybe<Scalars['Boolean']['output']>;
  /** Sample collection provider for the lab recommendation */
  sampleCollectionProvider?: Maybe<LabsSampleCollectionProvider>;
  /** Total price of the tests of the lab recommendation */
  subTotal?: Maybe<Scalars['Float']['output']>;
  /**
   * Lab tests included in the this lab recommendation
   * @deprecated Use recommendationTests instead
   */
  tests: Array<LabsTest>;
  /** Treatment plan this lab recommendation belongs to */
  treatmentPlan: TreatmentPlan;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for LabsRecommendation. */
export type LabsRecommendationConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LabsRecommendationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LabsRecommendation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** Custom fee that is associated with a Lab Recommendation */
export type LabsRecommendationCustomFee = {
  /** The amount of the custom fee */
  amount: Scalars['Float']['output'];
  /** The name of the custom fee */
  name: Scalars['String']['output'];
};

/** An edge in a connection. */
export type LabsRecommendationEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LabsRecommendation>;
};

/** An event for a lab recommendation */
export type LabsRecommendationEvent = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The type of event */
  eventType: RecommendationEventTypes;
  /** The fulfillment authorization associated with the event */
  fulfillmentAuthorization?: Maybe<LabFulfillmentsAuthorization>;
  /** The fulfillment lab result associated with the event */
  fulfillmentLabResult?: Maybe<LabFulfillmentsLabResult>;
  /** The fulfillment recommendation associated with the event */
  fulfillmentRecommendation?: Maybe<LabFulfillmentsRecommendation>;
  id: Scalars['ID']['output'];
  /** The order associated with the event */
  order?: Maybe<Order>;
  /** The shipment associated with the event */
  shipment?: Maybe<Shipment>;
  /** The treatment plan associated with the event */
  treatmentPlan?: Maybe<TreatmentPlan>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Sample collection provider details for a lab recommendation */
export type LabsRecommendationSampleCollectionProvider = {
  /** Whether the sample collection provider is available */
  available: Scalars['Boolean']['output'];
  /** Sample collection provider details for lab company */
  companySampleCollectionProvider: LabsCompanySampleCollectionProvider;
  /**
   * Distance to the nearest sample collection providers location capped at 500
   * miles (if no location is within 500 miles, this field will return 500)
   */
  distanceToNearestLocation?: Maybe<Scalars['Int']['output']>;
};

/** The connection between a lab recommendation and a lab test */
export type LabsRecommendationTest = Timestamps & {
  /** Child tests of the top-level test included in the lab recommendation */
  childTests?: Maybe<Array<LabsTest>>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The lab recommendation in a treatment plan */
  recommendation: LabsRecommendation;
  /** Top-level test of the lab recommendation */
  test: LabsTest;
  /** Whether the test is a top-level test of the lab recommendation */
  topLevelTest: Scalars['Boolean']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Sample collection provider details */
export type LabsSampleCollectionProvider = {
  id: Scalars['ID']['output'];
  /** Maximum acceptable distance (mi) from sample collection provider */
  maximumAcceptableDistance?: Maybe<Scalars['Int']['output']>;
  /** Name of sample collection provider */
  name: Scalars['String']['output'];
  /** Method of sample collection (lab/mobile) */
  providerType: Scalars['String']['output'];
};

/** Sample Type of a test (Blood/Serum/Urine/etc) */
export type LabsSampleType = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the sample type */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Lab tests synced from API partners */
export type LabsTest = LegacyId & Node & Timestamps & {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
  /** Alerts for the test */
  alerts?: Maybe<Array<Alert>>;
  /** Biomarkers for the test */
  biomarkers?: Maybe<Array<LabsBiomarker>>;
  /** Ordering permission for the test */
  canOrder: OrderingPermissions;
  /** Company of the test */
  company: LabsCompany;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the test */
  description?: Maybe<Scalars['String']['output']>;
  /** Estimated total days to get results */
  estimatedDaysForResults?: Maybe<Scalars['String']['output']>;
  /** ID of test from API */
  externalTestId: Scalars['String']['output'];
  /** Health categories of the test */
  healthCategories: Array<LabsHealthCategory>;
  id: Scalars['ID']['output'];
  /** Whether the test is in office stock */
  inOfficeStock: Scalars['Boolean']['output'];
  /** Count of the kits in stock for the test */
  inOfficeStockCount: Scalars['Int']['output'];
  /** Instructions for the test */
  instructions?: Maybe<Scalars['String']['output']>;
  /** Whether a test is active or not */
  isActive: Scalars['Boolean']['output'];
  /** Whether a test is favorited or not by the practitioner */
  isFavorited: Scalars['Boolean']['output'];
  /** Whether a test is shippable or not */
  isShippable: Scalars['Boolean']['output'];
  /** Minimum age requirement to order this test */
  minAge?: Maybe<Scalars['Int']['output']>;
  /** Name of the test */
  name: Scalars['String']['output'];
  /** Retail cost of one test */
  price: Scalars['Float']['output'];
  /** Reference range */
  referenceRanges?: Maybe<Scalars['String']['output']>;
  /** If test requires an at-home sample collection to be performed */
  requiresAtHomeSampleCollection: Scalars['Boolean']['output'];
  /** If test requires fasting to be performed */
  requiresFasting?: Maybe<Scalars['Boolean']['output']>;
  /** If test requires phlebotomy to be performed */
  requiresPhlebotomy: Scalars['Boolean']['output'];
  /** URL of a sample report */
  sampleReportUrl?: Maybe<Scalars['String']['output']>;
  /** Sample types of the test */
  sampleTypes: Array<LabsSampleType>;
  /** Short description of the test */
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** Patient preparation instructions */
  testPreparation: Scalars['String']['output'];
  /** Type of the test (Kit/Panel/Marker) */
  testType?: Maybe<LabsTestType>;
  /** Tests that make up this panel or bundle of tests */
  tests?: Maybe<Array<LabsTest>>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Link to more information about the test */
  url?: Maybe<Scalars['String']['output']>;
  /** Variant associated with the test */
  variant: Variant;
  /** URL to video instructions for the patient on how to collect the sample */
  videoInstructionsUrl?: Maybe<Scalars['String']['output']>;
};


/** Lab tests synced from API partners */
export type LabsTestAlertsArgs = {
  alertType: AlertTypeEnum;
};


/** Lab tests synced from API partners */
export type LabsTestCanOrderArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};


/** Lab tests synced from API partners */
export type LabsTestInOfficeStockArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};


/** Lab tests synced from API partners */
export type LabsTestIsFavoritedArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** The connection type for LabsTest. */
export type LabsTestConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LabsTestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LabsTest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LabsTestEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LabsTest>;
};

/** Type of a lab test (Marker/Panel/Kit) */
export type LabsTestType = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the lab test type */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Represents the signing practitioner for a treatment plan */
export type LabsTreatmentPlanSigningPractitioner = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The practitioner who is signing the treatment plan if present */
  practitioner?: Maybe<Practitioner>;
  /** The treatment plan the practitioner/auth network is signing for */
  treatmentPlan: TreatmentPlan;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Legacy ID for records. Should not be used unless strictly necessary. */
export type LegacyId = {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
};

/** Arguments for filtering library of contents. */
export type LibraryFilterObject = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['ID']['input']>;
};

/** LineItem */
export type LineItem = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The autoship variant for said line item */
  draftAutoshipVariant?: Maybe<SubscriptionsVariant>;
  id: Scalars['ID']['output'];
  /** Recommendation tests for the line item */
  labRecommendationTest?: Maybe<LabsRecommendationTest>;
  /** MSRP at that time for this item */
  msrpPrice?: Maybe<Scalars['Float']['output']>;
  /** Total MSRP at that time for this item */
  msrpTotal?: Maybe<Scalars['Float']['output']>;
  /** Obligations/fees that apply to this line item */
  obligations?: Maybe<Array<LineItemObligation>>;
  /** Retail cost of one item */
  price: Scalars['Float']['output'];
  /** Total cost of the item */
  priceTotal: Scalars['Float']['output'];
  /** Number of units of the item */
  quantity: Scalars['Int']['output'];
  /** Currently selected timed supply dropdown option */
  selectedTimedSupplyOption?: Maybe<TimedSupplyOption>;
  /** The dosage information when the line item was added to the cart */
  timedSupplyInformation?: Maybe<TimedSupplyInformation>;
  /** Dropdown options for the timed supply selector */
  timedSupplyOptions?: Maybe<Array<TimedSupplyOption>>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The SKU of the item */
  variant: Variant;
};

/** Obligations/fees that apply to a line item */
export type LineItemObligation = Timestamps & {
  /** Amount that applies to this obligation */
  amount: Scalars['Float']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the obligation */
  description?: Maybe<Scalars['String']['output']>;
  /** Fee type */
  feeType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Obligation type */
  obligationType: Scalars['String']['output'];
  /** If this obligation should be taxable */
  taxable?: Maybe<Scalars['Boolean']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** States of a lab line item */
export const LineItemStates = {
  Approved: 'approved',
  Canceled: 'canceled',
  PartialResults: 'partial_results',
  Pending: 'pending',
  PendingApproval: 'pending_approval',
  Processing: 'processing',
  Rejected: 'rejected',
  RequisitionReady: 'requisition_ready',
  ResultsReady: 'results_ready',
  Settled: 'settled'
} as const;

export type LineItemStates = typeof LineItemStates[keyof typeof LineItemStates];
/** Autogenerated error type of ManageDynamicDailyPack */
export type ManageDynamicDailyPackError = {
  /** Field errors */
  fields?: Maybe<ManageDynamicDailyPackFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of ManageDynamicDailyPack */
export type ManageDynamicDailyPackFieldError = {
  dailyPackId?: Maybe<Array<Scalars['String']['output']>>;
  multiPatientPlanId?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTemplateName?: Maybe<Array<Scalars['String']['output']>>;
  recommendationId?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  variantAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of ManageDynamicDailyPack */
export type ManageDynamicDailyPackInput = {
  attributes: OfficeDynamicDailyPackAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ManageDynamicDailyPack. */
export type ManageDynamicDailyPackPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: ManageDynamicDailyPackError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of ManageStaticDailyPack */
export type ManageStaticDailyPackError = {
  /** Field errors */
  fields?: Maybe<ManageStaticDailyPackFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of ManageStaticDailyPack */
export type ManageStaticDailyPackFieldError = {
  dailyPackId?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
  variantAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of ManageStaticDailyPack */
export type ManageStaticDailyPackInput = {
  attributes: OfficeStaticDailyPackAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ManageStaticDailyPack. */
export type ManageStaticDailyPackPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: ManageStaticDailyPackError;
};

/** Autogenerated error type of ManageTemplateDailyPack */
export type ManageTemplateDailyPackError = {
  /** Field errors */
  fields?: Maybe<ManageTemplateDailyPackFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of ManageTemplateDailyPack */
export type ManageTemplateDailyPackFieldError = {
  dailyPackId?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
  variantAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of ManageTemplateDailyPack */
export type ManageTemplateDailyPackInput = {
  attributes: OfficeTemplateDailyPackAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ManageTemplateDailyPack. */
export type ManageTemplateDailyPackPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: ManageTemplateDailyPackError;
};

/** Minimum advertised price (MAP) agreement */
export type MapAgreement = Node & Timestamps & {
  /** Datetime the agreement was activated */
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Type of MAP agreement (basic or strict) */
  agreementType: AgreementTypes;
  /** URL for the strict brand MAP agreement */
  agreementUrl?: Maybe<Scalars['String']['output']>;
  /** Brand MAP agreements associated with the MAP agreement */
  brandsMapAgreements: Array<BrandsMapAgreement>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Status for how the store has interacted with the MAP agreement */
  statusForStore?: Maybe<UsersMapAgreementStatusTypes>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of MassAssignPayee */
export type MassAssignPayeeError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MassAssignPayee */
export type MassAssignPayeeInput = {
  attributablePractitionerId: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderIds: Array<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of MassAssignPayee. */
export type MassAssignPayeePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<MassAssignPayeeError>;
  orders?: Maybe<Array<EarningsReportOrder>>;
};

/** Medications with their ingredient recommendations */
export type Medication = {
  /** The unique identifier for the medication_product */
  id: Scalars['ID']['output'];
  /** The product is manually added */
  isManuallyAdded: Scalars['Boolean']['output'];
  /** Medication product group id */
  medicationGroupId?: Maybe<Scalars['ID']['output']>;
  /** Name of the condition */
  name: Scalars['String']['output'];
  /** Array of ingredients recommended for the condition */
  recommendedIngredients: Array<RecommendedIngredient>;
  /** The date and time the medication was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

/** Medication products and their ingredient composition */
export type MedicationProduct = {
  id: Scalars['ID']['output'];
  /** The ingredients contained in the medication product */
  ingredients: Scalars['String']['output'];
  /** The name of the medication product */
  name: Scalars['String']['output'];
};

/** Medication products and their ingredient composition */
export type MedicationProductIngredients = {
  /** The unique identifier for the medication product */
  id: Scalars['ID']['output'];
  /** The ingredients contained in the medication product */
  ingredients: Scalars['String']['output'];
  /** The unique identifier for the medication product group */
  medicationGroupId: Scalars['ID']['output'];
  /** The name of the medication product */
  medicationProductName: Scalars['String']['output'];
};

/** Medication group info used for typeahead */
export type MedicationsTypeahead = {
  /** Brand name from Medication group's name */
  brandName?: Maybe<Scalars['String']['output']>;
  /** Medication group's name */
  fullName: Scalars['String']['output'];
  /** Generic name from Medication group's name */
  genericName?: Maybe<Scalars['String']['output']>;
  /**
   * Medication group's ID
   * @deprecated Use `ids` instead
   */
  id: Scalars['ID']['output'];
  /** Medication groups' IDs */
  ids: Array<Scalars['ID']['output']>;
};

/** The connection type for MedicationsTypeahead. */
export type MedicationsTypeaheadConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MedicationsTypeaheadEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MedicationsTypeahead>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MedicationsTypeaheadEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MedicationsTypeahead>;
};

/** Megamenu */
export type Megamenu = {
  id: Scalars['ID']['output'];
  /** Name of the megamenu for product */
  name?: Maybe<Scalars['String']['output']>;
  /** Reason why product is preferred for megamenu */
  preferredReason?: Maybe<Scalars['String']['output']>;
};

/** The base categories for the Catalog Menu */
export type MenuCategory = {
  /** The filter type to be applied for the Menu Category */
  filterType?: Maybe<Scalars['String']['output']>;
  /** The ID to be used in the filter for the Menu Category */
  id?: Maybe<Scalars['ID']['output']>;
  /** The name of the Menu Category to be displayed */
  name?: Maybe<Scalars['String']['output']>;
  /** The sub categories belonging to this Menu Category */
  subCategories?: Maybe<Array<MenuSubCategory>>;
};

/** Menu Category Types */
export const MenuCategoryType = {
  /** Returns the DTP Menu Categories */
  Dtp: 'DTP',
  /** Returns the Emerson Menu Categories */
  Emerson: 'EMERSON',
  /** Returns the Patient Menu Categories */
  Patient: 'PATIENT',
  /** Returns the Wholesale Menu Categories */
  Wholesale: 'WHOLESALE'
} as const;

export type MenuCategoryType = typeof MenuCategoryType[keyof typeof MenuCategoryType];
/** The sub categories for the Catalog Menu */
export type MenuSubCategory = {
  /** The filter type to be applied for the sub Menu Category */
  filterType?: Maybe<Scalars['String']['output']>;
  /** The ID to be used in the filter for the sub Menu Category */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether the Menu Sub Category should be highlighted */
  isHighlighted?: Maybe<Scalars['Boolean']['output']>;
  /** The items belonging to the Menu Sub Category */
  items?: Maybe<Array<MenuSubCategoryItem>>;
  /** The name of the Menu Sub Category to be displayed */
  name?: Maybe<Scalars['String']['output']>;
};

/** The items for sub categories for the Catalog Menu */
export type MenuSubCategoryItem = {
  /** The filter type to be applied for the Menu Sub Category Item */
  filterType?: Maybe<Scalars['String']['output']>;
  /** The ID to be used in the filter for the Menu Sub Category Item */
  id?: Maybe<Scalars['ID']['output']>;
  /** The name of the Menu Sub Category Item to be displayed */
  name?: Maybe<Scalars['String']['output']>;
  /** An optional static url for a Menu Sub Category Item */
  targetUrl?: Maybe<Scalars['String']['output']>;
};

/** Legal details relevant to a store owner. Used to create their Stripe account */
export type MerchantData = Node & Timestamps & {
  /** Marks the type of account they are. (eg. individual, personal, business, etc.) */
  accountType: Scalars['String']['output'];
  /** Day the store owner was born */
  birthDay?: Maybe<Scalars['String']['output']>;
  /** Month the store owner was born */
  birthMonth?: Maybe<Scalars['String']['output']>;
  /** Year the store owner was born */
  birthYear?: Maybe<Scalars['String']['output']>;
  /** Business address of the company if account is a Canadian business account */
  businessAddress?: Maybe<BaseAddress>;
  /** Legal name of the business */
  businessName?: Maybe<Scalars['String']['output']>;
  /**
   * Deprecated.
   * @deprecated Use spree_stores.business_number instead.
   */
  businessNumber?: Maybe<Scalars['String']['output']>;
  /** City on file with the store owner's financial institution */
  city?: Maybe<Scalars['String']['output']>;
  /**
   * Used if the account is a LLC business account. Asks for further classification
   * of the business (eg. c corporation, s corporation, partnership)
   */
  classification?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The store owner's email */
  email?: Maybe<Scalars['String']['output']>;
  /** The Employer identification number (EIN). Only used if account is a business */
  employerId?: Maybe<Scalars['String']['output']>;
  /** The store owner's legal first name */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Invoice address of the store */
  invoiceAddress?: Maybe<InvoiceAddress>;
  /** Does the store owner owns more than 25% of the company if account is a Canadian business account */
  isEquityOwner?: Maybe<Scalars['Boolean']['output']>;
  /** The store owner's job title */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** The store owner's legal last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Date used to note when user has manually verified their tax information */
  lastVerifiedTaxInformationAt?: Maybe<Scalars['DateTime']['output']>;
  /** The store owner's phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Postal code or ZIP code on file with the store owner's financial institution */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** State or province on file with the store owner's financial institution */
  region?: Maybe<Scalars['String']['output']>;
  /** The store the merchant data belongs to */
  store: Store;
  /** Street address on file with the store owner's financial institution */
  streetAddress?: Maybe<Scalars['String']['output']>;
  /** Street address 2 on file with the store owner's financial institution (often used for apartment numbers) */
  streetAddress2?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes for updating a store's merchant data's address. */
export type MerchantDataAddressAttributes = {
  accountType?: InputMaybe<Scalars['String']['input']>;
  bankAccountHeldBy?: InputMaybe<Scalars['String']['input']>;
  bankAccountHolder?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankAccountType?: InputMaybe<Scalars['String']['input']>;
  bankRoutingNumber?: InputMaybe<Scalars['String']['input']>;
  /** Business address of a canadian store with business account */
  businessAddress?: InputMaybe<BusinessAddressAttributes>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** Directors of a canadian store with business account */
  directors?: InputMaybe<Array<StripePersonAttributes>>;
  email?: InputMaybe<Scalars['String']['input']>;
  employerId?: InputMaybe<Scalars['String']['input']>;
  /** Owners of a canadian store with business account */
  equityOwners?: InputMaybe<Array<StripePersonAttributes>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Invoice address for a store. It will be null if using the same address as the merchant data address. */
  invoiceAddress?: InputMaybe<InvoiceAddressAttributes>;
  /** Whether the store representative holds more than 25% of the business */
  isEquityOwner?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  region: Scalars['String']['input'];
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  /** Setting this to true will delete the invoice address and use the merchant data address as the invoice address. */
  useBusinessAddressAsInvoiceAddress?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Attributes for updating a store's merchant data's address (region and phone) */
export type MerchantDataAddressConfirmationAttributes = {
  phone: Scalars['String']['input'];
  region: Scalars['String']['input'];
};

/** Autogenerated input type of MerchantDataAddressUpdate */
export type MerchantDataAddressUpdateInput = {
  attributes: MerchantDataAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MerchantDataAddressUpdate. */
export type MerchantDataAddressUpdatePayload = {
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeSettingsPaymentsAndTaxesErrors>;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
};

/** Attributes for updating a store's merchant data's financial information. */
export type MerchantDataFinancialAttributes = {
  accountType: Scalars['String']['input'];
  businessName?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  employerId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of MerchantDataFinancialUpdate */
export type MerchantDataFinancialUpdateError = {
  /** Field errors */
  fields?: Maybe<MerchantDataFinancialUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of MerchantDataFinancialUpdate */
export type MerchantDataFinancialUpdateFieldError = {
  accountType?: Maybe<Array<Scalars['String']['output']>>;
  businessName?: Maybe<Array<Scalars['String']['output']>>;
  classification?: Maybe<Array<Scalars['String']['output']>>;
  dateOfBirth?: Maybe<Array<Scalars['String']['output']>>;
  employerId?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  ssn?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of MerchantDataFinancialUpdate */
export type MerchantDataFinancialUpdateInput = {
  attributes: MerchantDataFinancialAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MerchantDataFinancialUpdate. */
export type MerchantDataFinancialUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MerchantDataFinancialUpdateError;
  merchantData: MerchantData;
};

/** Merchant data SSN attributes input object */
export type MerchantDataSsnAttributes = {
  ssn: Scalars['String']['input'];
};

/** Autogenerated error type of MerchantDataSsnUpdate */
export type MerchantDataSsnUpdateError = {
  /** Field errors */
  fields?: Maybe<MerchantDataSsnUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of MerchantDataSsnUpdate */
export type MerchantDataSsnUpdateFieldError = {
  ssn?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of MerchantDataSsnUpdate */
export type MerchantDataSsnUpdateInput = {
  attributes: MerchantDataSsnAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MerchantDataSsnUpdate. */
export type MerchantDataSsnUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MerchantDataSsnUpdateError;
  merchantData: MerchantData;
};

/** Banners displayed to the practitioners during the migration process. */
export const MigrationBanners = {
  /** Favorites' page banner */
  Favorites: 'FAVORITES',
  /** Patient's list page banner */
  PatientList: 'PATIENT_LIST',
  /** Protocol's page banner */
  Protocols: 'PROTOCOLS'
} as const;

export type MigrationBanners = typeof MigrationBanners[keyof typeof MigrationBanners];
/** Boolean fields for each of the values in the migration banners json field */
export type MigrationBannersVisibility = {
  favorites: Scalars['Boolean']['output'];
  patientList: Scalars['Boolean']['output'];
  protocols: Scalars['Boolean']['output'];
};

/** Migration status data for each practitioner */
export type MigrationStatus = Node & Timestamps & {
  /** Migration completion date */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The date in which the Wellevate account will go into read-only mode */
  cutoffAt?: Maybe<Scalars['DateTime']['output']>;
  /** Tracks the number of favorite products we are expecting to import. */
  expectedFavoriteProductsCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the number of Wellevate orders at the time of the data import. */
  expectedOrdersCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the number of patients we are expecting to import. */
  expectedPatientsCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the number of protocols we are expecting to import. */
  expectedProtocolsCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the number of treatment plans we are expecting to import. */
  expectedTreatmentPlansCount?: Maybe<Scalars['Int']['output']>;
  /** Whether the store has patients with wellevate source. */
  hasWellevatePatients: Scalars['Boolean']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** Tracks the id of the newly created category of favorite products from Wellevate */
  importedFavoriteCategoryId?: Maybe<Scalars['ID']['output']>;
  /** Tracks the actual number of favorite products we have converted from Wellevate into Fullscript records. */
  importedFavoriteProductsCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the actual number of patients we have converted from Wellevate into Fullscript records. */
  importedPatientsCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the actual number of protocols we have converted from Wellevate into Fullscript records. */
  importedProtocolsCount?: Maybe<Scalars['Int']['output']>;
  /** Json object with the visibility value for each banner (PATIENT_LIST, FAVORITES, and PROTOCOLS) */
  migrationBannersVisibility?: Maybe<MigrationBannersVisibility>;
  /** Practitioner's overall segment */
  segmentValue: Scalars['String']['output'];
  /** Status of the Migration (eg. PENDING, INITIATED, COMPLETED or FAILED) */
  status: MigrationStatuses;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Specific errors related to the Wellevate records that were unable to convert. */
  wellevateMigrationErrors?: Maybe<Array<WellevateMigrationErrorType>>;
};

/** All supported migration statuses. */
export const MigrationStatuses = {
  /** Migration successfully completed. */
  Completed: 'COMPLETED',
  /** Migration failed. */
  Failed: 'FAILED',
  /** Migration initiated by the practitioner. */
  Initiated: 'INITIATED',
  /** Migration in progress. */
  InProgress: 'IN_PROGRESS',
  /** Migration pending for practitioner to be initiated. */
  Pending: 'PENDING'
} as const;

export type MigrationStatuses = typeof MigrationStatuses[keyof typeof MigrationStatuses];
/** Autogenerated error type of MobileAppointmentCancel */
export type MobileAppointmentCancelError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MobileAppointmentCancel */
export type MobileAppointmentCancelInput = {
  cancellationReasonId?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labAppointmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of MobileAppointmentCancel. */
export type MobileAppointmentCancelPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MobileAppointmentCancelError;
  labAppointment?: Maybe<LabsAppointment>;
};

/** Autogenerated error type of MobileAppointmentCreate */
export type MobileAppointmentCreateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MobileAppointmentCreate */
export type MobileAppointmentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  parkingNotes?: InputMaybe<Scalars['String']['input']>;
  specialInstructions?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MobileAppointmentCreate. */
export type MobileAppointmentCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MobileAppointmentCreateError;
  labAppointment?: Maybe<LabsAppointment>;
};

/** Autogenerated error type of MobileAppointmentModify */
export type MobileAppointmentModifyError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MobileAppointmentModify */
export type MobileAppointmentModifyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  labAppointmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of MobileAppointmentModify. */
export type MobileAppointmentModifyPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MobileAppointmentModifyError;
  labAppointment?: Maybe<LabsAppointment>;
};

/** Autogenerated error type of MobileCollectionProviderValidateZipcode */
export type MobileCollectionProviderValidateZipcodeError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MobileCollectionProviderValidateZipcode */
export type MobileCollectionProviderValidateZipcodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labSampleCollectionProviderId: Scalars['ID']['input'];
  zipcode: Scalars['String']['input'];
};

/** Autogenerated return type of MobileCollectionProviderValidateZipcode. */
export type MobileCollectionProviderValidateZipcodePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MobileCollectionProviderValidateZipcodeError;
  /** Whether the zipcode is serviced by the mobile sample collection provider */
  isServiced?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated error type of Modify */
export type ModifyError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of Modify */
export type ModifyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dailyPackId: Scalars['ID']['input'];
  packType: PackTypes;
};

/** Autogenerated error type of ModifyLabAppointment */
export type ModifyLabAppointmentError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ModifyLabAppointment */
export type ModifyLabAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  labAppointmentId: Scalars['ID']['input'];
  /** Appointment location defaults to the existing appointment's location */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  timeSlot: Scalars['String']['input'];
};

/** Autogenerated return type of ModifyLabAppointment. */
export type ModifyLabAppointmentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ModifyLabAppointmentError;
  labAppointment?: Maybe<LabsAppointment>;
  redirectToClient?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of Modify. */
export type ModifyPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: ModifyError;
};

/** Attributes for moving to setup complete step. */
export type MoveToSetupCompleteAttributes = {
  storePlatform: Scalars['String']['input'];
};

/** The daily intake of an alternative's ingredients sorted by those shared and not shared with the base product */
export type MultiIngredientDailyIntake = {
  /** Daily intake of ingredients not shared with the base product */
  remainingIngredients?: Maybe<Array<PlanIngredient>>;
  /** Daily intake of ingredients shared with the base product */
  sharedIngredients?: Maybe<Array<PlanIngredient>>;
};

/** Multi patient plan */
export type MultiPatientPlan = Attachable & Node & Timestamps & {
  /** Aggregated list of ingredients in this plan */
  aggregatedIngredients: Array<PlanIngredient>;
  /** Fetch all patients who have access to the multi patient plan without pagination */
  allMultiPatientPlansPatients: Array<MultiPatientPlansPatient>;
  /** Date the multi patient plan was made available */
  availableAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** State of the multi patient plan. Can be template, active, draft, cancelled */
  currentState: Scalars['String']['output'];
  /** A paginated list of document attachments */
  documentAttachments: DocumentAttachmentConnection;
  /** Draft dynamic daily pack belonging to this multi patient plan, if any. */
  draftDynamicDailyPack?: Maybe<DraftDynamicDailyPack>;
  id: Scalars['ID']['output'];
  /** Marks whether the current patient has favorited the multi patient plan */
  isPatientFavorited: Scalars['Boolean']['output'];
  /** Used to identify if there is an invalid daily pack in the multi patient plan */
  isValidDailyPacks: Scalars['Boolean']['output'];
  /** Most recent plan message */
  message?: Maybe<Scalars['String']['output']>;
  /** Patients who have access to the multi patient plan */
  multiPatientPlansPatients: MultiPatientPlansPatientConnection;
  /** Name of the multi patient plan */
  name: Scalars['String']['output'];
  /** URL that can be used to share the multi-patient plan with patients */
  patientShareableUrl?: Maybe<Scalars['String']['output']>;
  /** Category type of the treatment plan */
  planOptimizationCategories?: Maybe<Array<PlanOptimizationCategory>>;
  /** Name of the plan type eg MultiPatientPlan */
  planType: Scalars['String']['output'];
  /** Practitioner that created this multi patient plan */
  practitioner: Practitioner;
  /** A list of products in this plan without curated ingredients */
  productsWithoutIngredients: Array<Scalars['String']['output']>;
  /** All recommendations for this multi patient plan */
  recommendations: Array<Recommendation>;
  /** Field that marks which patients the multi patient plan can be shared with (eg. my_patients, store_patients) */
  shareableWithPatients: Scalars['String']['output'];
  /** Indiciates if patients should be notified when plan is published/shared */
  shouldNotifyPatients: Scalars['Boolean']['output'];
  /** Unique identifier of the multi patient plan */
  slug: Scalars['String']['output'];
  /** Indicates where the multi patient plan was originally created */
  source: Scalars['String']['output'];
  /** Indicates the platform where the multi patient plan was originally created on */
  sourcePlatform: Scalars['String']['output'];
  /** Count of active recommendations in the treatment plan */
  totalActiveRecommendations: Scalars['Int']['output'];
  /** The discounted price of the multi patient plan */
  totalDiscountedPrice: Scalars['Float']['output'];
  /** The total price of the multi patient plan without any discounts applied */
  totalPrice: Scalars['Float']['output'];
  /**
   * The amount a patient will save on the price of the multi patient plan (wont
   * include patient discount unless patient context)
   */
  totalSavings: Scalars['Float']['output'];
  /** URL of the treatment plan in PDF form */
  treatmentPlanPdfUrl: Scalars['String']['output'];
  /** Type of plan the document was attached to */
  type: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Multi patient plan */
export type MultiPatientPlanDocumentAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderByAddedAt?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Multi patient plan */
export type MultiPatientPlanMultiPatientPlansPatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Multi patient plan */
export type MultiPatientPlanRecommendationsArgs = {
  filterObject?: InputMaybe<RecommendationFilterObject>;
};

/** The connection type for MultiPatientPlan. */
export type MultiPatientPlanConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MultiPatientPlanEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MultiPatientPlan>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MultiPatientPlanEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MultiPatientPlan>;
};

/** Arguments for filtering multi patient plans. */
export type MultiPatientPlanFilterObject = {
  /** Filter by name - whether the plan name matches the given query */
  nameQuery?: InputMaybe<Scalars['String']['input']>;
  /** Options for filtering multi patient plans */
  options?: InputMaybe<Array<MultiPatientPlanFilters>>;
  /** Filter by patient ID - whether that patient has favourited the plan */
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

/** Multi patient plan filters */
export const MultiPatientPlanFilters = {
  /** Filters only active multi patient plans */
  ScopeActive: 'SCOPE_ACTIVE'
} as const;

export type MultiPatientPlanFilters = typeof MultiPatientPlanFilters[keyof typeof MultiPatientPlanFilters];
/** Arguments for multi patient plan sorting. */
export type MultiPatientPlanSort = {
  direction: SortDirection;
  field: MultiPatientPlanSortField;
};

/** Multi patient plan sort field */
export const MultiPatientPlanSortField = {
  /** Sorts alphabetically */
  Alphabetical: 'ALPHABETICAL',
  /** Date on which multi patient plan was last updated */
  UpdatedAt: 'UPDATED_AT'
} as const;

export type MultiPatientPlanSortField = typeof MultiPatientPlanSortField[keyof typeof MultiPatientPlanSortField];
/** Autogenerated error type of MultiPatientPlansActivate */
export type MultiPatientPlansActivateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MultiPatientPlansActivate */
export type MultiPatientPlansActivateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of MultiPatientPlansActivate. */
export type MultiPatientPlansActivatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<MultiPatientPlansActivateError>;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

/** Attributes for creating a multi patient plan for given filter and excluded patient ids */
export type MultiPatientPlansCreateAllAttributes = {
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of MultiPatientPlansCreateAll */
export type MultiPatientPlansCreateAllError = {
  /** Field errors */
  fields?: Maybe<MultiPatientPlansCreateAllFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of MultiPatientPlansCreateAll */
export type MultiPatientPlansCreateAllFieldError = {
  excludedPatientIds?: Maybe<Array<Scalars['String']['output']>>;
  includeBlocked?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  query?: Maybe<Array<Scalars['String']['output']>>;
  source?: Maybe<Array<Scalars['String']['output']>>;
  sourcePlatform?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscounts?: Maybe<Array<Scalars['String']['output']>>;
  withDraftTreatmentPlan?: Maybe<Array<Scalars['String']['output']>>;
  withFirstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of MultiPatientPlansCreateAll */
export type MultiPatientPlansCreateAllInput = {
  attributes: MultiPatientPlansCreateAllAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MultiPatientPlansCreateAll. */
export type MultiPatientPlansCreateAllPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MultiPatientPlansCreateAllError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

/** Attributes for creating a multi patient plan for given patients */
export type MultiPatientPlansCreateAttributes = {
  attachmentsUploadableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  recommendationsAttributes?: InputMaybe<Array<OfficeRecommendationAttributes>>;
  source: Scalars['String']['input'];
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of MultiPatientPlansCreate */
export type MultiPatientPlansCreateError = {
  /** Field errors */
  fields?: Maybe<MultiPatientPlansCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of MultiPatientPlansCreate */
export type MultiPatientPlansCreateFieldError = {
  attachmentsUploadableIds?: Maybe<Array<Scalars['String']['output']>>;
  excludedPatientIds?: Maybe<Array<Scalars['String']['output']>>;
  includeBlocked?: Maybe<Array<Scalars['String']['output']>>;
  patientIds?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  query?: Maybe<Array<Scalars['String']['output']>>;
  recommendationsAttributes?: Maybe<Array<Scalars['String']['output']>>;
  source?: Maybe<Array<Scalars['String']['output']>>;
  sourcePlatform?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscounts?: Maybe<Array<Scalars['String']['output']>>;
  withDraftTreatmentPlan?: Maybe<Array<Scalars['String']['output']>>;
  withFirstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of MultiPatientPlansCreate */
export type MultiPatientPlansCreateInput = {
  attributes: MultiPatientPlansCreateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MultiPatientPlansCreate. */
export type MultiPatientPlansCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MultiPatientPlansCreateError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

/** Autogenerated error type of MultiPatientPlansDelete */
export type MultiPatientPlansDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MultiPatientPlansDelete */
export type MultiPatientPlansDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of MultiPatientPlansDelete. */
export type MultiPatientPlansDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<MultiPatientPlansDeleteError>;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

/** Autogenerated error type of MultiPatientPlansDraftReset */
export type MultiPatientPlansDraftResetError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MultiPatientPlansDraftReset */
export type MultiPatientPlansDraftResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of MultiPatientPlansDraftReset. */
export type MultiPatientPlansDraftResetPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MultiPatientPlansDraftResetError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

/** Multi patient plans patient */
export type MultiPatientPlansPatient = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Patient who has access to the multi patient plan */
  patient: Patient;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for MultiPatientPlansPatient. */
export type MultiPatientPlansPatientConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MultiPatientPlansPatientEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MultiPatientPlansPatient>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MultiPatientPlansPatientEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MultiPatientPlansPatient>;
};

/** Attributes for updating the access to a multi patient plan */
export type MultiPatientPlansUpdateAccessAttributes = {
  allCurrentPatients?: InputMaybe<Scalars['Boolean']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
  notifyPatients?: InputMaybe<Scalars['Boolean']['input']>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientIdsToAdd?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientIdsToRemove?: InputMaybe<Array<Scalars['ID']['input']>>;
  sharingLevel: Scalars['String']['input'];
};

/** Autogenerated error type of MultiPatientPlansUpdateAccess */
export type MultiPatientPlansUpdateAccessError = {
  /** Field errors */
  fields?: Maybe<MultiPatientPlansUpdateAccessFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of MultiPatientPlansUpdateAccess */
export type MultiPatientPlansUpdateAccessFieldError = {
  allCurrentPatients?: Maybe<Array<Scalars['String']['output']>>;
  multiPatientPlanId?: Maybe<Array<Scalars['String']['output']>>;
  notifyPatients?: Maybe<Array<Scalars['String']['output']>>;
  patientIds?: Maybe<Array<Scalars['String']['output']>>;
  patientIdsToAdd?: Maybe<Array<Scalars['String']['output']>>;
  patientIdsToRemove?: Maybe<Array<Scalars['String']['output']>>;
  sharingLevel?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of MultiPatientPlansUpdateAccess */
export type MultiPatientPlansUpdateAccessInput = {
  attributes: MultiPatientPlansUpdateAccessAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MultiPatientPlansUpdateAccess. */
export type MultiPatientPlansUpdateAccessPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MultiPatientPlansUpdateAccessError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

/** Attributes for updating a multi patient plan */
export type MultiPatientPlansUpdateAttributes = {
  multiPatientPlanId: Scalars['ID']['input'];
  planMessage?: InputMaybe<Scalars['String']['input']>;
  planName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of MultiPatientPlansUpdate */
export type MultiPatientPlansUpdateError = {
  /** Field errors */
  fields?: Maybe<MultiPatientPlansUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of MultiPatientPlansUpdate */
export type MultiPatientPlansUpdateFieldError = {
  multiPatientPlanId?: Maybe<Array<Scalars['String']['output']>>;
  planMessage?: Maybe<Array<Scalars['String']['output']>>;
  planName?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of MultiPatientPlansUpdate */
export type MultiPatientPlansUpdateInput = {
  attributes: MultiPatientPlansUpdateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MultiPatientPlansUpdate. */
export type MultiPatientPlansUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: MultiPatientPlansUpdateError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

/** Details about a multi-source product */
export type MultiSourceProduct = Node & {
  /** Name of the product brand */
  brandName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** URL of product image */
  image?: Maybe<Scalars['String']['output']>;
  /** Is the product from an internal or external source */
  internal: Scalars['Boolean']['output'];
  /** Name of the product */
  name: Scalars['String']['output'];
  /** Serving size (number of units that should be taken at a time ) as it appears in the label */
  servingSize?: Maybe<Scalars['Float']['output']>;
  /** Serving unit (e.g. scoops, tablets, etc.) as shown in the label next to serving size */
  servingUnit: Scalars['String']['output'];
};

/** The connection type for MultiSourceProduct. */
export type MultiSourceProductConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MultiSourceProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MultiSourceProduct>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MultiSourceProductEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MultiSourceProduct>;
};

/** All of the mutations for the app */
export type Mutation = {
  /** Mutation to log an analytics event */
  analyticsLogEvent?: Maybe<AnalyticsLogEventPayload>;
  /** Mutation to start an analytics experiment */
  analyticsStartExperiment?: Maybe<AnalyticsStartExperimentPayload>;
  /** Mutation to update analytics profile */
  analyticsUpdateProfile?: Maybe<AnalyticsUpdateProfilePayload>;
  /** Adds a patient to the android waitlist */
  androidAddToWaitlist?: Maybe<AndroidAddToWaitlistPayload>;
  auth?: Maybe<AuthMutation>;
  /** Cancels the subscription */
  autoshipCancel?: Maybe<AutoshipCancelPayload>;
  /** Places the autoship orders for all subscriptions within a group immediately via background jobs */
  autoshipGroupOrderNow?: Maybe<AutoshipGroupOrderNowPayload>;
  /** Places the autoship order now via a background job */
  autoshipOrderNow?: Maybe<AutoshipOrderNowPayload>;
  /** Reschedule an autoship to a given date in the future */
  autoshipReschedule?: Maybe<AutoshipReschedulePayload>;
  /** Reschedule the next order date for all subscriptions within a group to a given date in the future */
  autoshipRescheduleGroup?: Maybe<AutoshipRescheduleGroupPayload>;
  /** Skips the next order for a subscription group */
  autoshipSkipNextGroupOrder?: Maybe<AutoshipSkipNextGroupOrderPayload>;
  /** Skips the next order */
  autoshipSkipNextOrder?: Maybe<AutoshipSkipNextOrderPayload>;
  /** Activates a bank account */
  bankAccountActivate?: Maybe<BankAccountActivatePayload>;
  /** Creates a bank account */
  bankAccountCreate?: Maybe<BankAccountCreatePayload>;
  /** Create a commentary record */
  commentaryCreate?: Maybe<CommentaryCreatePayload>;
  /** Update a commentary record */
  commentaryUpdate?: Maybe<CommentaryUpdatePayload>;
  copilot?: Maybe<CopilotMutation>;
  /** Creates an audio chunk from a result object */
  copilotAudioChunkCreate?: Maybe<CopilotAudioChunkCreatePayload>;
  /** Ends an audio session and creates a summary for it in SOAP format */
  copilotAudioSessionEnd?: Maybe<CopilotAudioSessionEndPayload>;
  /** Start an audio session with a patient */
  copilotAudioSessionStart?: Maybe<CopilotAudioSessionStartPayload>;
  /** Create audio summary resources */
  copilotCreateResources?: Maybe<CopilotCreateResourcesPayload>;
  /** Create audio summary */
  copilotCreateSummary?: Maybe<CopilotCreateSummaryPayload>;
  /** Create audio summary resources */
  copilotCreateSummaryMessage?: Maybe<CreateSummaryMessagePayload>;
  /** Create audio supplements */
  copilotCreateSupplements?: Maybe<CopilotCreateSupplementsPayload>;
  /** Upload the transcript of an audio session with a patient */
  copilotUploadTranscript?: Maybe<CopilotUploadTranscriptPayload>;
  /** Create a product access request */
  createProductAccessRequest?: Maybe<CreateProductAccessRequestPayload>;
  /** Delete a credit card */
  creditCardDelete?: Maybe<CreditCardDeletePayload>;
  /** Set or unset a credit card as default */
  creditCardSetDefault?: Maybe<CreditCardSetDefaultPayload>;
  /** Updates a credit card's billing information and sets the credit card as default or on autoship */
  creditCardUpdate?: Maybe<CreditCardUpdatePayload>;
  /** Emancipates a dependent from their guardian */
  dependentEmancipation?: Maybe<EmancipationPayload>;
  /** Resends the emancipation invite email to an emancipated dependent */
  dependentEmancipationInviteResend?: Maybe<DependentEmancipationInviteResendPayload>;
  /**
   * Given a frequency and a line item it creates a draft autoship variant (and
   * maybe a draft subscription) to be used from the cart.It abandons any
   * previously existing draft autoship variants for said line item variant before
   * making a new one.
   */
  draftAutoshipVariant?: Maybe<DraftAutoshipVariantPayload>;
  /** Updates the store's 1099k consent for e-delivery. */
  eDeliveryConsent1099kUpdate?: Maybe<EDeliveryConsent1099kUpdatePayload>;
  /** Creates harmonized password to be used by both EE and FS logins. */
  emersonHarmonizedMigrationConsolidatePassword?: Maybe<EmersonHarmonizedMigrationConsolidatePasswordPayload>;
  /** Consolidates the data to be the same for EEv2 and their FS account for harmonized migration. */
  emersonHarmonizedMigrationConsolidatePractitionerData?: Maybe<EmersonHarmonizedMigrationConsolidatePractitionerDataPayload>;
  /** Update practitioner's notification email */
  emersonNotificationEmailUpdate?: Maybe<PractitionerEmersonNotificationEmailUpdatePayload>;
  /** Emerson user additional info migration */
  emersonUserAdditionalInfoMigration?: Maybe<EmersonUserAdditionalInfoMigrationPayload>;
  /** Activate a store platform for an existing user */
  enableSharedAccount?: Maybe<EnableSharedAccountPayload>;
  exchangeMigrationToken?: Maybe<ExchangeMigrationTokenPayload>;
  /** Add an entry to the experiments table */
  experimentCreate?: Maybe<ExperimentCreatePayload>;
  /** Confirms express checkout order */
  expressCheckoutConfirm?: Maybe<ExpressCheckoutConfirmPayload>;
  /** Add a product to favorites */
  favoriteProductsAdd?: Maybe<FavoriteProductsAddPayload>;
  /** Remove a product from favorites */
  favoriteProductsRemove?: Maybe<FavoriteProductsRemovePayload>;
  /** Update a flipper */
  flipperUpdate?: Maybe<FlipperUpdatePayload>;
  fsAssist?: Maybe<FsAssistMutation>;
  /** Marks the user as having moved into the Fullscript 2.0 upgrade */
  fsExperienceUpgradeStatus?: Maybe<FsExperienceUpgradeStatusUpdatePayload>;
  genAi?: Maybe<GenAiMutation>;
  /** Updates globalPatientPromotion of the current store. */
  globalPatientPromotionUpdate?: Maybe<GlobalPatientPromotionUpdatePayload>;
  /** Mark a patient notification as dismissed */
  inAppPatientNotificationMarkAsDismissed?: Maybe<InAppPatientNotificationMarkAsDismissedPayload>;
  /** Mark a patient notification as read */
  inAppPatientNotificationMarkAsRead?: Maybe<InAppPatientNotificationMarkAsReadPayload>;
  labsAppointment?: Maybe<AppointmentMutation>;
  /** Mass assign patient's or orders' payee */
  massAssignPayee?: Maybe<MassAssignPayeePayload>;
  /** Updates the store's merchant data's address along with addtional stripe rquirements and bank account. */
  merchantDataAddressUpdate?: Maybe<MerchantDataAddressUpdatePayload>;
  /** Update a store's legal/financial information */
  merchantDataFinancialUpdate?: Maybe<MerchantDataFinancialUpdatePayload>;
  /** Update merchant data SSN */
  merchantDataSsnUpdate?: Maybe<MerchantDataSsnUpdatePayload>;
  /** Unsubscribe from all */
  notificationSettingUnsubscribeAll?: Maybe<NotificationSettingUnsubscribeAllPayload>;
  office?: Maybe<OfficeMutation>;
  patient?: Maybe<PatientMutation>;
  /** Creates shipping or billing address for patient */
  patientAddressCreate?: Maybe<PatientAddressCreatePayload>;
  /** Removes patient's address */
  patientAddressDelete?: Maybe<PatientAddressDeletePayload>;
  /** Updates shipping or billing address for patient */
  patientAddressUpdate?: Maybe<PatientAddressUpdatePayload>;
  /** Authorizes a parent-child relationship for a dependent */
  patientAuthorizeGuardianship?: Maybe<PatientAuthorizeGuardianshipPayload>;
  /** Trigger Iterable browse_abandon event for a patient */
  patientBrowseAbandonTrigger?: Maybe<PatientBrowseAbandonTriggerPayload>;
  /** Cancels an order for a patient, initiated by ADA */
  patientCancelOrder?: Maybe<PatientCancelOrderPayload>;
  /** Adds a product to the patient's cart */
  patientCartAddToCart?: Maybe<PatientCartAddToCartPayload>;
  /** Resets the patient's cart */
  patientCartReset?: Maybe<PatientCartResetPayload>;
  /** Updates the patient cart */
  patientCartUpdate?: Maybe<PatientCartUpdatePayload>;
  /** Creates a credit card for a patient */
  patientCreateCreditCard?: Maybe<PatientCreateCreditCardPayload>;
  /** Creates an RMA for a patient with specific line items, initiated by ADA */
  patientCreatePartialRefund?: Maybe<PatientCreatePartialRefundPayload>;
  /** Creates an RMA for a patient, initiated by ADA */
  patientCreateRefund?: Maybe<PatientCreateRefundPayload>;
  /** Create a patient funnel logger event */
  patientFunnelLoggerCreate?: Maybe<PatientFunnelLoggerPayload>;
  /** Sends a request for the patient to fill out a patient intake form */
  patientIntakeSendRequest?: Maybe<PatientIntakeSendRequestPayload>;
  /** Updates the status of the current patient's intake */
  patientIntakeUpdateStatus?: Maybe<PatientIntakeUpdateStatusPayload>;
  /** Initiate push notification settings if they don't exist for the context user's patients */
  patientNotificationSettingPushInitiate?: Maybe<PatientNotificationSettingPushInitiatePayload>;
  /** Update the subscribed status for patient push notification settings */
  patientNotificationSettingPushUpdate?: Maybe<PatientNotificationSettingPushUpdatePayload>;
  /** Subscribe to all email and sms patient notification settings */
  patientNotificationSettingSubscribeAll?: Maybe<PatientNotificationSettingSubscribeAllPayload>;
  /** Toggle all email and sms patient notification settings */
  patientNotificationSettingToggleAll?: Maybe<PatientNotificationSettingToggleAllPayload>;
  /** Unsubscribe to all email and sms patient notification settings */
  patientNotificationSettingUnsubscribeAll?: Maybe<PatientNotificationSettingUnsubscribeAllPayload>;
  /** Update patient notification setting */
  patientNotificationSettingUpdate?: Maybe<PatientNotificationSettingUpdatePayload>;
  /** Refunds shipping, initiated by ADA */
  patientRefundShipping?: Maybe<PatientRefundShippingPayload>;
  /** Updates a patient */
  patientUpdate?: Maybe<PatientUpdatePayload>;
  /** Updates the last_login_date_mobile_app field for the patient in iterable */
  patientUpdateIterableLastAppLogin?: Maybe<PatientUpdateIterableLastAppLoginPayload>;
  /** Create patient variant notification */
  patientVariantNotificationCreate?: Maybe<PatientVariantNotificationPayload>;
  /** Deletes the current patients variant notification */
  patientVariantNotificationDelete?: Maybe<PatientVariantNotificationDeletePayload>;
  /** Initiates the RSO login flow. */
  platformRedirect?: Maybe<PlatformRedirectPayload>;
  /** Create a practitioner certification */
  practitionerCertificationCreate?: Maybe<PractitionerCertificationCreatePayload>;
  /** Update a practitioner certification */
  practitionerCertificationUpdate?: Maybe<PractitionerCertificationUpdatePayload>;
  /** Generates a Stripe account link to update additional information in Stripe for eligible Canadian store owners. */
  practitionerGenerateStripeAccountLink?: Maybe<GenerateStripeAccountLinkPayload>;
  /** Logs how much of the onboarding dispensing checklist is completed */
  practitionerOnboardingChecklistLogger?: Maybe<OnboardingChecklistLoggerPayload>;
  /** Sign up as a new practitioner */
  practitionerSignUp?: Maybe<PractitionerSignUpPayload>;
  /** Update practitioner type */
  practitionerTypeUpdate?: Maybe<PractitionerTypeUpdatePayload>;
  /** Creates a variant notification for the current practitioner or clerk */
  practitionerVariantNotificationCreate?: Maybe<PractitionerVariantNotificationPayload>;
  /** Deletes the variant notification for current practitioner or clerk */
  practitionerVariantNotificationDelete?: Maybe<PractitionerVariantNotificationDeletePayload>;
  /** Add the given product to the list of recently viewed products */
  recentlyViewedProductsAdd?: Maybe<RecentlyViewedProductsAddPayload>;
  /** Clear the list of recently viewed products */
  recentlyViewedProductsClear?: Maybe<RecentlyViewedProductsClearPayload>;
  /** Therapeutic switching request */
  recommendationTherapeuticSwitchingRequest?: Maybe<TherapeuticSwitchingRequestPayload>;
  /** Adds one or more variants to the order's line items */
  retailCheckoutAddToCart?: Maybe<RetailCheckoutAddToCartPayload>;
  /** Adds one or more variants to the order's express checkout cart */
  retailCheckoutExpressCheckoutCreate?: Maybe<RetailCheckoutExpressCheckoutCreatePayload>;
  /** Places the current order and optionally updates the payment information */
  retailCheckoutPlaceOrder?: Maybe<RetailCheckoutPlaceOrderPayload>;
  /** Runs all validations required before starting checkout */
  retailCheckoutPrevalidate?: Maybe<RetailCheckoutPrevalidatePayload>;
  /** Check and swap Prop65 items in retail cart */
  retailCheckoutProp65Swap?: Maybe<RetailCheckoutProp65SwapPayload>;
  /** Removes a lab from an order */
  retailCheckoutRemoveLab?: Maybe<RetailCheckoutRemoveLabPayload>;
  /** Handles the selection of the checkout shipping address validation modal */
  retailCheckoutSelectShippingAddress?: Maybe<RetailCheckoutSelectShippingAddressPayload>;
  /** Updates the shipping rate for the current order */
  retailCheckoutSelectShippingRate?: Maybe<RetailCheckoutSelectShippingRatePayload>;
  /** Updates the quantity of a single line item, removing it when 0. */
  retailCheckoutUpdateCart?: Maybe<RetailCheckoutUpdateCartPayload>;
  /** Updates the shipping address for the current order */
  retailCheckoutUpdateShippingAddress?: Maybe<RetailCheckoutUpdateShippingAddressPayload>;
  /** Saves default address of the patient */
  savePatientDefaultAddress?: Maybe<SavePatientDefaultAddressPayload>;
  /** Clears a user's search history */
  searchHistoriesClear?: Maybe<SearchHistoriesClearPayload>;
  /** Deletes a search history record */
  searchHistoriesDelete?: Maybe<SearchHistoriesDeletePayload>;
  /** Saves search history */
  searchHistoriesSave?: Maybe<SearchHistoriesSavePayload>;
  /** update the ship state */
  shipStateUpdate?: Maybe<ShipStateUpdatePayload>;
  /** Create an shipping address */
  shippingAddressCreate?: Maybe<ShippingAddressCreatePayload>;
  /** soft delete shipping address */
  shippingAddressDelete?: Maybe<ShippingAddressDeletePayload>;
  /** replace original address with a new address */
  shippingAddressUpdate?: Maybe<ShippingAddressUpdatePayload>;
  /** Updates the owner's store discount and additional discounts. */
  storeAdditionalDiscountsUpdate?: Maybe<StoreAdditionalDiscountsUpdatePayload>;
  /** Create a store address */
  storeAddressCreate?: Maybe<StoreAddressCreatePayload>;
  /** soft delete store address */
  storeAddressDelete?: Maybe<StoreAddressDeletePayload>;
  /** replace original address with a new address */
  storeAddressNewUpdate?: Maybe<StoreAddressNewUpdatePayload>;
  /**
   * temporarily deprecated, please use StoreAddressNewUpdate
   * @deprecated Use store_address_new_update instead.
   */
  storeAddressUpdate?: Maybe<StoreAddressUpdatePayload>;
  /** Updates the currentSetupPage field on Stores based on PractitionerOnboarding steps. */
  storeCurrentSetupPageUpdate?: Maybe<StoreCurrentSetupPageUpdatePayload>;
  /** Updates the owner's store profit margin. */
  storeProfitMarginUpdate?: Maybe<StoreProfitMarginUpdatePayload>;
  /** Resets the currentStore to PractitionerOnboarding account step. */
  storeResetToAccountStepUpdate?: Maybe<StoreResetToAccountStepUpdatePayload>;
  /** Disable a store's autosend interpretations setting. */
  storesAutosendInterpretationDisable?: Maybe<StoreLabAutosendInterpretationDisablePayload>;
  /** Create or update a store's autosend interpretations setting. */
  storesAutosendInterpretationUpdate?: Maybe<StoreLabAutosendInterpretationUpdatePayload>;
  /** Add a custom fee to a store */
  storesLabCustomFeeAddToStore?: Maybe<StoreLabCustomFeesAddToStorePayload>;
  /** Apply a custom fee to a plan */
  storesLabCustomFeeApplyToPlan?: Maybe<StoreLabCustomFeesApplyToPlanPayload>;
  /** Remove a custom fee from a store */
  storesLabCustomFeeRemoveFromStore?: Maybe<StoreLabCustomFeesRemoveFromStorePayload>;
  /** Updates the global custom fee of the store */
  storesLabCustomFeeUpdate?: Maybe<StoreLabCustomFeesUpdatePayload>;
  /** Add a signing practitioner to a store */
  storesLabSigningPractitionerAddToStore?: Maybe<StoreLabSigningPractitionersAddToStorePayload>;
  /** Remove a signing practitioner from a store */
  storesLabSigningPractitionerRemoveFromStore?: Maybe<StoreLabSigningPractitionersRemoveFromStorePayload>;
  /** Updates a stores landing page. */
  storesLandingPageUpdate?: Maybe<StoreLandingPageUpdatePayload>;
  /** Updates storesPatientPromotions in the current store. */
  storesPatientPromotionUpdate?: Maybe<StoresPatientPromotionUpdatePayload>;
  /** Create student profile */
  studentProfileCreate?: Maybe<StudentProfileCreatePayload>;
  /** Creates shipping and billing address given a shipping address */
  subscriptionAddressesCreate?: Maybe<SubscriptionAddressesCreatePayload>;
  /** Creates a new autoship */
  subscriptionCreate?: Maybe<SubscriptionCreatePayload>;
  /** Creates a credit card through autoship */
  subscriptionCreateCreditCard?: Maybe<SubscriptionCreateCreditCardPayload>;
  /** Check and swap Prop65 items in subscription */
  subscriptionProp65Swap?: Maybe<SubscriptionProp65SwapPayload>;
  /** Schedules multiple autoship subscriptions */
  subscriptionScheduleMultiple?: Maybe<SubscriptionScheduleMultiplePayload>;
  /** Updates subscription */
  subscriptionUpdate?: Maybe<SubscriptionUpdatePayload>;
  /** Updates subscriptions group */
  subscriptionsGroupUpdateShippingMethod?: Maybe<SubscriptionsGroupUpdatePayload>;
  /** Creates a Subscriptions Variant in an activated state(ie not a draft state) */
  subscriptionsVariantCreate?: Maybe<SubscriptionsVariantCreatePayload>;
  /** Updates a subscriptions variants frequency */
  subscriptionsVariantFrequencyUpdate?: Maybe<SubscriptionsVariantFrequencyUpdatePayload>;
  /** Updates a subscriptions variant */
  subscriptionsVariantUpdate?: Maybe<SubscriptionsVariantUpdatePayload>;
  /** Adds a custom medication to the patient's supplement plan */
  supplementPlanAddCustomMedication?: Maybe<SupplementPlanAddCustomMedicationPayload>;
  /** Adds a custom product to the patient's supplement plan */
  supplementPlanAddCustomProduct?: Maybe<SupplementPlanAddCustomProductPayload>;
  /** Adds a product(spree or external) to the patient's supplement plan */
  supplementPlanAddProduct?: Maybe<SupplementPlanAddProductPayload>;
  /** Adds products(spree or external) to the patient's supplement plan */
  supplementPlanAddProducts?: Maybe<SupplementPlanAddProductsPayload>;
  /** Archive a product from patient's supplement plan */
  supplementPlanArchiveProduct?: Maybe<SupplementPlanArchiveProductPayload>;
  /** Updates a product's information in the patient's supplement plan */
  supplementPlanUpdateProduct?: Maybe<SupplementPlanUpdateProductPayload>;
  /** Create user tour */
  toursUsersCreate?: Maybe<ToursUsersCreatePayload>;
  /** Creates or Updates user tour */
  toursUsersCreateOrUpdate?: Maybe<ToursUsersCreateOrUpdatePayload>;
  /** Cancel treatment plan */
  treatmentPlanCancel?: Maybe<TreatmentPlanCancelPayload>;
  /** Delete Draft TreatmentPlan */
  treatmentPlanDelete?: Maybe<TreatmentPlanDeletePayload>;
  /** Send treatment plan reminder */
  treatmentPlanSendReminder?: Maybe<TreatmentPlanSendReminderPayload>;
  /** Cancel treatment plan template */
  treatmentPlanTemplateCancel?: Maybe<TreatmentPlanTemplateCancelPayload>;
  /** Log copy URL action on treatment plan template */
  treatmentPlanTemplateLogCopyUrl?: Maybe<TreatmentPlanTemplateLogCopyUrlPayload>;
  /** Creates and updates avatar for user */
  userAvatarCreate?: Maybe<UserAvatarCreatePayload>;
  /** Deletes avatar for user */
  userAvatarDelete?: Maybe<UserAvatarDeletePayload>;
  /** Login user as another patient (same user different store) */
  userChangeStore?: Maybe<UserChangeStorePayload>;
  /** Update user notification setting */
  userNotificationSettingUpdate?: Maybe<UserNotificationSettingUpdatePayload>;
  /** Updates the password for a user */
  userPasswordUpdate?: Maybe<UserPasswordUpdatePayload>;
  /** Updates user's security fields (email and password) */
  userSecurityFieldsUpdate?: Maybe<UserSecurityFieldsUpdatePayload>;
  /** Sign in as a user with an optionally specified role */
  userSignIn?: Maybe<UserSignInPayload>;
  /** Delete user variant notification */
  userVariantNotificationDelete?: Maybe<UserVariantNotificationDeletePayload>;
  /** Create user map agreement */
  usersMapAgreementCreate?: Maybe<UsersMapAgreementCreatePayload>;
  /** Allows patients to send themselves the Wellevate new account activation email */
  wellevateNewAccountEmail?: Maybe<WellevateNewAccountEmailPayload>;
  /** Unlink wholesale billing address */
  wholesaleAddressUnlink?: Maybe<WholesaleAddressUnlinkPayload>;
  /** Update wholesale address */
  wholesaleAddressUpdate?: Maybe<WholesaleAddressUpdatePayload>;
  /** Add to wholesale cart */
  wholesaleCartAddToCart?: Maybe<WholesaleCartAddToCartPayload>;
  /** Creates an internal cart only accessible by admin */
  wholesaleCartAdminCreate?: Maybe<WholesaleCartAdminCreatePayload>;
  /** Delete an admin internal cart */
  wholesaleCartAdminDelete?: Maybe<WholesaleCartAdminDeletePayload>;
  /** Selects an internal cart only accessible by admin */
  wholesaleCartAdminSelect?: Maybe<WholesaleCartAdminSelectPayload>;
  /** Removes cbd products from wholesale cart if shipping to non-shippable state */
  wholesaleCartCbdNonShippableStateUpdate?: Maybe<WholesaleCartCbdNonShippableStateUpdatePayload>;
  /** Confirms a wholesale order */
  wholesaleCartConfirm?: Maybe<WholesaleCartConfirmPayload>;
  /** Removes cbd products from wholesale cart if shipping to non-shippable state */
  wholesaleCartNonShippableStateUpdate?: Maybe<WholesaleCartNonShippableStateUpdatePayload>;
  /** Update PO Number */
  wholesaleCartPoNumberUpdate?: Maybe<WholesaleCartPoNumberUpdatePayload>;
  /** Check and swap Prop65 items in wholesale cart */
  wholesaleCartProp65Swap?: Maybe<WholesaleCartProp65SwapPayload>;
  /** Resets the cart so that it's empty */
  wholesaleCartReset?: Maybe<WholesaleCartResetPayload>;
  /** Update wholesale cart */
  wholesaleCartUpdate?: Maybe<WholesaleCartUpdatePayload>;
  /** Update wholesale payment */
  wholesalePaymentUpdate?: Maybe<WholesalePaymentUpdatePayload>;
  /** Apply wholesale promotion */
  wholesalePromotionApply?: Maybe<WholesalePromotionApplyPayload>;
  /** Remove wholesale promotion */
  wholesalePromotionRemove?: Maybe<WholesalePromotionRemovePayload>;
  /** Request the cancellation of an inventory unit for a wholesale order. */
  wholesaleRequestBackorderCancellation?: Maybe<WholesaleRequestBackorderCancellationPayload>;
  /** Handles the selection of the checkout shipping address validation modal */
  wholesaleShippingAddressSelect?: Maybe<WholesaleShippingAddressSelectPayload>;
  /** Update wholesale shipping rate */
  wholesaleShippingRateUpdate?: Maybe<WholesaleShippingRateUpdatePayload>;
  /** Creates Zendesk ticket for patient deletion */
  zendeskPatientDelete?: Maybe<ZendeskPatientDeletePayload>;
};


/** All of the mutations for the app */
export type MutationAnalyticsLogEventArgs = {
  input: AnalyticsLogEventInput;
};


/** All of the mutations for the app */
export type MutationAnalyticsStartExperimentArgs = {
  input: AnalyticsStartExperimentInput;
};


/** All of the mutations for the app */
export type MutationAnalyticsUpdateProfileArgs = {
  input: AnalyticsUpdateProfileInput;
};


/** All of the mutations for the app */
export type MutationAndroidAddToWaitlistArgs = {
  input: AndroidAddToWaitlistInput;
};


/** All of the mutations for the app */
export type MutationAutoshipCancelArgs = {
  input: AutoshipCancelInput;
};


/** All of the mutations for the app */
export type MutationAutoshipGroupOrderNowArgs = {
  input: AutoshipGroupOrderNowInput;
};


/** All of the mutations for the app */
export type MutationAutoshipOrderNowArgs = {
  input: AutoshipOrderNowInput;
};


/** All of the mutations for the app */
export type MutationAutoshipRescheduleArgs = {
  input: AutoshipRescheduleInput;
};


/** All of the mutations for the app */
export type MutationAutoshipRescheduleGroupArgs = {
  input: AutoshipRescheduleGroupInput;
};


/** All of the mutations for the app */
export type MutationAutoshipSkipNextGroupOrderArgs = {
  input: AutoshipSkipNextGroupOrderInput;
};


/** All of the mutations for the app */
export type MutationAutoshipSkipNextOrderArgs = {
  input: AutoshipSkipNextOrderInput;
};


/** All of the mutations for the app */
export type MutationBankAccountActivateArgs = {
  input: BankAccountActivateInput;
};


/** All of the mutations for the app */
export type MutationBankAccountCreateArgs = {
  input: BankAccountCreateInput;
};


/** All of the mutations for the app */
export type MutationCommentaryCreateArgs = {
  input: CommentaryCreateInput;
};


/** All of the mutations for the app */
export type MutationCommentaryUpdateArgs = {
  input: CommentaryUpdateInput;
};


/** All of the mutations for the app */
export type MutationCopilotAudioChunkCreateArgs = {
  input: CopilotAudioChunkCreateInput;
};


/** All of the mutations for the app */
export type MutationCopilotAudioSessionEndArgs = {
  input: CopilotAudioSessionEndInput;
};


/** All of the mutations for the app */
export type MutationCopilotAudioSessionStartArgs = {
  input: CopilotAudioSessionStartInput;
};


/** All of the mutations for the app */
export type MutationCopilotCreateResourcesArgs = {
  input: CopilotCreateResourcesInput;
};


/** All of the mutations for the app */
export type MutationCopilotCreateSummaryArgs = {
  input: CopilotCreateSummaryInput;
};


/** All of the mutations for the app */
export type MutationCopilotCreateSummaryMessageArgs = {
  input: CreateSummaryMessageInput;
};


/** All of the mutations for the app */
export type MutationCopilotCreateSupplementsArgs = {
  input: CopilotCreateSupplementsInput;
};


/** All of the mutations for the app */
export type MutationCopilotUploadTranscriptArgs = {
  input: CopilotUploadTranscriptInput;
};


/** All of the mutations for the app */
export type MutationCreateProductAccessRequestArgs = {
  input: CreateProductAccessRequestInput;
};


/** All of the mutations for the app */
export type MutationCreditCardDeleteArgs = {
  input: CreditCardDeleteInput;
};


/** All of the mutations for the app */
export type MutationCreditCardSetDefaultArgs = {
  input: CreditCardSetDefaultInput;
};


/** All of the mutations for the app */
export type MutationCreditCardUpdateArgs = {
  input: CreditCardUpdateInput;
};


/** All of the mutations for the app */
export type MutationDependentEmancipationArgs = {
  input: EmancipationInput;
};


/** All of the mutations for the app */
export type MutationDependentEmancipationInviteResendArgs = {
  input: DependentEmancipationInviteResendInput;
};


/** All of the mutations for the app */
export type MutationDraftAutoshipVariantArgs = {
  input: DraftAutoshipVariantInput;
};


/** All of the mutations for the app */
export type MutationEDeliveryConsent1099kUpdateArgs = {
  input: EDeliveryConsent1099kUpdateInput;
};


/** All of the mutations for the app */
export type MutationEmersonHarmonizedMigrationConsolidatePasswordArgs = {
  input: EmersonHarmonizedMigrationConsolidatePasswordInput;
};


/** All of the mutations for the app */
export type MutationEmersonHarmonizedMigrationConsolidatePractitionerDataArgs = {
  input: EmersonHarmonizedMigrationConsolidatePractitionerDataInput;
};


/** All of the mutations for the app */
export type MutationEmersonNotificationEmailUpdateArgs = {
  input: PractitionerEmersonNotificationEmailUpdateInput;
};


/** All of the mutations for the app */
export type MutationEmersonUserAdditionalInfoMigrationArgs = {
  input: EmersonUserAdditionalInfoMigrationInput;
};


/** All of the mutations for the app */
export type MutationEnableSharedAccountArgs = {
  input: EnableSharedAccountInput;
};


/** All of the mutations for the app */
export type MutationExchangeMigrationTokenArgs = {
  input: ExchangeMigrationTokenInput;
};


/** All of the mutations for the app */
export type MutationExperimentCreateArgs = {
  input: ExperimentCreateInput;
};


/** All of the mutations for the app */
export type MutationExpressCheckoutConfirmArgs = {
  input: ExpressCheckoutConfirmInput;
};


/** All of the mutations for the app */
export type MutationFavoriteProductsAddArgs = {
  input: FavoriteProductsAddInput;
};


/** All of the mutations for the app */
export type MutationFavoriteProductsRemoveArgs = {
  input: FavoriteProductsRemoveInput;
};


/** All of the mutations for the app */
export type MutationFlipperUpdateArgs = {
  input: FlipperUpdateInput;
};


/** All of the mutations for the app */
export type MutationFsExperienceUpgradeStatusArgs = {
  input: FsExperienceUpgradeStatusUpdateInput;
};


/** All of the mutations for the app */
export type MutationGlobalPatientPromotionUpdateArgs = {
  input: GlobalPatientPromotionUpdateInput;
};


/** All of the mutations for the app */
export type MutationInAppPatientNotificationMarkAsDismissedArgs = {
  input: InAppPatientNotificationMarkAsDismissedInput;
};


/** All of the mutations for the app */
export type MutationInAppPatientNotificationMarkAsReadArgs = {
  input: InAppPatientNotificationMarkAsReadInput;
};


/** All of the mutations for the app */
export type MutationMassAssignPayeeArgs = {
  input: MassAssignPayeeInput;
};


/** All of the mutations for the app */
export type MutationMerchantDataAddressUpdateArgs = {
  input: MerchantDataAddressUpdateInput;
};


/** All of the mutations for the app */
export type MutationMerchantDataFinancialUpdateArgs = {
  input: MerchantDataFinancialUpdateInput;
};


/** All of the mutations for the app */
export type MutationMerchantDataSsnUpdateArgs = {
  input: MerchantDataSsnUpdateInput;
};


/** All of the mutations for the app */
export type MutationNotificationSettingUnsubscribeAllArgs = {
  input: NotificationSettingUnsubscribeAllInput;
};


/** All of the mutations for the app */
export type MutationPatientAddressCreateArgs = {
  input: PatientAddressCreateInput;
};


/** All of the mutations for the app */
export type MutationPatientAddressDeleteArgs = {
  input: PatientAddressDeleteInput;
};


/** All of the mutations for the app */
export type MutationPatientAddressUpdateArgs = {
  input: PatientAddressUpdateInput;
};


/** All of the mutations for the app */
export type MutationPatientAuthorizeGuardianshipArgs = {
  input: PatientAuthorizeGuardianshipInput;
};


/** All of the mutations for the app */
export type MutationPatientBrowseAbandonTriggerArgs = {
  input: PatientBrowseAbandonTriggerInput;
};


/** All of the mutations for the app */
export type MutationPatientCancelOrderArgs = {
  input: PatientCancelOrderInput;
};


/** All of the mutations for the app */
export type MutationPatientCartAddToCartArgs = {
  input: PatientCartAddToCartInput;
};


/** All of the mutations for the app */
export type MutationPatientCartResetArgs = {
  input: PatientCartResetInput;
};


/** All of the mutations for the app */
export type MutationPatientCartUpdateArgs = {
  input: PatientCartUpdateInput;
};


/** All of the mutations for the app */
export type MutationPatientCreateCreditCardArgs = {
  input: PatientCreateCreditCardInput;
};


/** All of the mutations for the app */
export type MutationPatientCreatePartialRefundArgs = {
  input: PatientCreatePartialRefundInput;
};


/** All of the mutations for the app */
export type MutationPatientCreateRefundArgs = {
  input: PatientCreateRefundInput;
};


/** All of the mutations for the app */
export type MutationPatientFunnelLoggerCreateArgs = {
  input: PatientFunnelLoggerInput;
};


/** All of the mutations for the app */
export type MutationPatientIntakeSendRequestArgs = {
  input: PatientIntakeSendRequestInput;
};


/** All of the mutations for the app */
export type MutationPatientIntakeUpdateStatusArgs = {
  input: PatientIntakeUpdateStatusInput;
};


/** All of the mutations for the app */
export type MutationPatientNotificationSettingPushInitiateArgs = {
  input: PatientNotificationSettingPushInitiateInput;
};


/** All of the mutations for the app */
export type MutationPatientNotificationSettingPushUpdateArgs = {
  input: PatientNotificationSettingPushUpdateInput;
};


/** All of the mutations for the app */
export type MutationPatientNotificationSettingSubscribeAllArgs = {
  input: PatientNotificationSettingSubscribeAllInput;
};


/** All of the mutations for the app */
export type MutationPatientNotificationSettingToggleAllArgs = {
  input: PatientNotificationSettingToggleAllInput;
};


/** All of the mutations for the app */
export type MutationPatientNotificationSettingUnsubscribeAllArgs = {
  input: PatientNotificationSettingUnsubscribeAllInput;
};


/** All of the mutations for the app */
export type MutationPatientNotificationSettingUpdateArgs = {
  input: PatientNotificationSettingUpdateInput;
};


/** All of the mutations for the app */
export type MutationPatientRefundShippingArgs = {
  input: PatientRefundShippingInput;
};


/** All of the mutations for the app */
export type MutationPatientUpdateArgs = {
  input: PatientUpdateInput;
};


/** All of the mutations for the app */
export type MutationPatientUpdateIterableLastAppLoginArgs = {
  input: PatientUpdateIterableLastAppLoginInput;
};


/** All of the mutations for the app */
export type MutationPatientVariantNotificationCreateArgs = {
  input: PatientVariantNotificationInput;
};


/** All of the mutations for the app */
export type MutationPatientVariantNotificationDeleteArgs = {
  input: PatientVariantNotificationDeleteInput;
};


/** All of the mutations for the app */
export type MutationPlatformRedirectArgs = {
  input: PlatformRedirectInput;
};


/** All of the mutations for the app */
export type MutationPractitionerCertificationCreateArgs = {
  input: PractitionerCertificationCreateInput;
};


/** All of the mutations for the app */
export type MutationPractitionerCertificationUpdateArgs = {
  input: PractitionerCertificationUpdateInput;
};


/** All of the mutations for the app */
export type MutationPractitionerGenerateStripeAccountLinkArgs = {
  input: GenerateStripeAccountLinkInput;
};


/** All of the mutations for the app */
export type MutationPractitionerOnboardingChecklistLoggerArgs = {
  input: OnboardingChecklistLoggerInput;
};


/** All of the mutations for the app */
export type MutationPractitionerSignUpArgs = {
  input: PractitionerSignUpInput;
};


/** All of the mutations for the app */
export type MutationPractitionerTypeUpdateArgs = {
  input: PractitionerTypeUpdateInput;
};


/** All of the mutations for the app */
export type MutationPractitionerVariantNotificationCreateArgs = {
  input: PractitionerVariantNotificationInput;
};


/** All of the mutations for the app */
export type MutationPractitionerVariantNotificationDeleteArgs = {
  input: PractitionerVariantNotificationDeleteInput;
};


/** All of the mutations for the app */
export type MutationRecentlyViewedProductsAddArgs = {
  input: RecentlyViewedProductsAddInput;
};


/** All of the mutations for the app */
export type MutationRecentlyViewedProductsClearArgs = {
  input: RecentlyViewedProductsClearInput;
};


/** All of the mutations for the app */
export type MutationRecommendationTherapeuticSwitchingRequestArgs = {
  input: TherapeuticSwitchingRequestInput;
};


/** All of the mutations for the app */
export type MutationRetailCheckoutAddToCartArgs = {
  input: RetailCheckoutAddToCartInput;
};


/** All of the mutations for the app */
export type MutationRetailCheckoutExpressCheckoutCreateArgs = {
  input: RetailCheckoutExpressCheckoutCreateInput;
};


/** All of the mutations for the app */
export type MutationRetailCheckoutPlaceOrderArgs = {
  input: RetailCheckoutPlaceOrderInput;
};


/** All of the mutations for the app */
export type MutationRetailCheckoutPrevalidateArgs = {
  input: RetailCheckoutPrevalidateInput;
};


/** All of the mutations for the app */
export type MutationRetailCheckoutProp65SwapArgs = {
  input: RetailCheckoutProp65SwapInput;
};


/** All of the mutations for the app */
export type MutationRetailCheckoutRemoveLabArgs = {
  input: RetailCheckoutRemoveLabInput;
};


/** All of the mutations for the app */
export type MutationRetailCheckoutSelectShippingAddressArgs = {
  input: RetailCheckoutSelectShippingAddressInput;
};


/** All of the mutations for the app */
export type MutationRetailCheckoutSelectShippingRateArgs = {
  input: RetailCheckoutSelectShippingRateInput;
};


/** All of the mutations for the app */
export type MutationRetailCheckoutUpdateCartArgs = {
  input: RetailCheckoutUpdateCartInput;
};


/** All of the mutations for the app */
export type MutationRetailCheckoutUpdateShippingAddressArgs = {
  input: RetailCheckoutUpdateShippingAddressInput;
};


/** All of the mutations for the app */
export type MutationSavePatientDefaultAddressArgs = {
  input: SavePatientDefaultAddressInput;
};


/** All of the mutations for the app */
export type MutationSearchHistoriesClearArgs = {
  input: SearchHistoriesClearInput;
};


/** All of the mutations for the app */
export type MutationSearchHistoriesDeleteArgs = {
  input: SearchHistoriesDeleteInput;
};


/** All of the mutations for the app */
export type MutationSearchHistoriesSaveArgs = {
  input: SearchHistoriesSaveInput;
};


/** All of the mutations for the app */
export type MutationShipStateUpdateArgs = {
  input: ShipStateUpdateInput;
};


/** All of the mutations for the app */
export type MutationShippingAddressCreateArgs = {
  input: ShippingAddressCreateInput;
};


/** All of the mutations for the app */
export type MutationShippingAddressDeleteArgs = {
  input: ShippingAddressDeleteInput;
};


/** All of the mutations for the app */
export type MutationShippingAddressUpdateArgs = {
  input: ShippingAddressUpdateInput;
};


/** All of the mutations for the app */
export type MutationStoreAdditionalDiscountsUpdateArgs = {
  input: StoreAdditionalDiscountsUpdateInput;
};


/** All of the mutations for the app */
export type MutationStoreAddressCreateArgs = {
  input: StoreAddressCreateInput;
};


/** All of the mutations for the app */
export type MutationStoreAddressDeleteArgs = {
  input: StoreAddressDeleteInput;
};


/** All of the mutations for the app */
export type MutationStoreAddressNewUpdateArgs = {
  input: StoreAddressNewUpdateInput;
};


/** All of the mutations for the app */
export type MutationStoreAddressUpdateArgs = {
  input: StoreAddressUpdateInput;
};


/** All of the mutations for the app */
export type MutationStoreCurrentSetupPageUpdateArgs = {
  input: StoreCurrentSetupPageUpdateInput;
};


/** All of the mutations for the app */
export type MutationStoreProfitMarginUpdateArgs = {
  input: StoreProfitMarginUpdateInput;
};


/** All of the mutations for the app */
export type MutationStoreResetToAccountStepUpdateArgs = {
  input: StoreResetToAccountStepUpdateInput;
};


/** All of the mutations for the app */
export type MutationStoresAutosendInterpretationDisableArgs = {
  input: StoreLabAutosendInterpretationDisableInput;
};


/** All of the mutations for the app */
export type MutationStoresAutosendInterpretationUpdateArgs = {
  input: StoreLabAutosendInterpretationUpdateInput;
};


/** All of the mutations for the app */
export type MutationStoresLabCustomFeeAddToStoreArgs = {
  input: StoreLabCustomFeesAddToStoreInput;
};


/** All of the mutations for the app */
export type MutationStoresLabCustomFeeApplyToPlanArgs = {
  input: StoreLabCustomFeesApplyToPlanInput;
};


/** All of the mutations for the app */
export type MutationStoresLabCustomFeeRemoveFromStoreArgs = {
  input: StoreLabCustomFeesRemoveFromStoreInput;
};


/** All of the mutations for the app */
export type MutationStoresLabCustomFeeUpdateArgs = {
  input: StoreLabCustomFeesUpdateInput;
};


/** All of the mutations for the app */
export type MutationStoresLabSigningPractitionerAddToStoreArgs = {
  input: StoreLabSigningPractitionersAddToStoreInput;
};


/** All of the mutations for the app */
export type MutationStoresLabSigningPractitionerRemoveFromStoreArgs = {
  input: StoreLabSigningPractitionersRemoveFromStoreInput;
};


/** All of the mutations for the app */
export type MutationStoresLandingPageUpdateArgs = {
  input: StoreLandingPageUpdateInput;
};


/** All of the mutations for the app */
export type MutationStoresPatientPromotionUpdateArgs = {
  input: StoresPatientPromotionUpdateInput;
};


/** All of the mutations for the app */
export type MutationStudentProfileCreateArgs = {
  input: StudentProfileCreateInput;
};


/** All of the mutations for the app */
export type MutationSubscriptionAddressesCreateArgs = {
  input: SubscriptionAddressesCreateInput;
};


/** All of the mutations for the app */
export type MutationSubscriptionCreateArgs = {
  input: SubscriptionCreateInput;
};


/** All of the mutations for the app */
export type MutationSubscriptionCreateCreditCardArgs = {
  input: SubscriptionCreateCreditCardInput;
};


/** All of the mutations for the app */
export type MutationSubscriptionProp65SwapArgs = {
  input: SubscriptionProp65SwapInput;
};


/** All of the mutations for the app */
export type MutationSubscriptionScheduleMultipleArgs = {
  input: SubscriptionScheduleMultipleInput;
};


/** All of the mutations for the app */
export type MutationSubscriptionUpdateArgs = {
  input: SubscriptionUpdateInput;
};


/** All of the mutations for the app */
export type MutationSubscriptionsGroupUpdateShippingMethodArgs = {
  input: SubscriptionsGroupUpdateInput;
};


/** All of the mutations for the app */
export type MutationSubscriptionsVariantCreateArgs = {
  input: SubscriptionsVariantCreateInput;
};


/** All of the mutations for the app */
export type MutationSubscriptionsVariantFrequencyUpdateArgs = {
  input: SubscriptionsVariantFrequencyUpdateInput;
};


/** All of the mutations for the app */
export type MutationSubscriptionsVariantUpdateArgs = {
  input: SubscriptionsVariantUpdateInput;
};


/** All of the mutations for the app */
export type MutationSupplementPlanAddCustomMedicationArgs = {
  input: SupplementPlanAddCustomMedicationInput;
};


/** All of the mutations for the app */
export type MutationSupplementPlanAddCustomProductArgs = {
  input: SupplementPlanAddCustomProductInput;
};


/** All of the mutations for the app */
export type MutationSupplementPlanAddProductArgs = {
  input: SupplementPlanAddProductInput;
};


/** All of the mutations for the app */
export type MutationSupplementPlanAddProductsArgs = {
  input: SupplementPlanAddProductsInput;
};


/** All of the mutations for the app */
export type MutationSupplementPlanArchiveProductArgs = {
  input: SupplementPlanArchiveProductInput;
};


/** All of the mutations for the app */
export type MutationSupplementPlanUpdateProductArgs = {
  input: SupplementPlanUpdateProductInput;
};


/** All of the mutations for the app */
export type MutationToursUsersCreateArgs = {
  input: ToursUsersCreateInput;
};


/** All of the mutations for the app */
export type MutationToursUsersCreateOrUpdateArgs = {
  input: ToursUsersCreateOrUpdateInput;
};


/** All of the mutations for the app */
export type MutationTreatmentPlanCancelArgs = {
  input: TreatmentPlanCancelInput;
};


/** All of the mutations for the app */
export type MutationTreatmentPlanDeleteArgs = {
  input: TreatmentPlanDeleteInput;
};


/** All of the mutations for the app */
export type MutationTreatmentPlanSendReminderArgs = {
  input: TreatmentPlanSendReminderInput;
};


/** All of the mutations for the app */
export type MutationTreatmentPlanTemplateCancelArgs = {
  input: TreatmentPlanTemplateCancelInput;
};


/** All of the mutations for the app */
export type MutationTreatmentPlanTemplateLogCopyUrlArgs = {
  input: TreatmentPlanTemplateLogCopyUrlInput;
};


/** All of the mutations for the app */
export type MutationUserAvatarCreateArgs = {
  input: UserAvatarCreateInput;
};


/** All of the mutations for the app */
export type MutationUserAvatarDeleteArgs = {
  input: UserAvatarDeleteInput;
};


/** All of the mutations for the app */
export type MutationUserChangeStoreArgs = {
  input: UserChangeStoreInput;
};


/** All of the mutations for the app */
export type MutationUserNotificationSettingUpdateArgs = {
  input: UserNotificationSettingUpdateInput;
};


/** All of the mutations for the app */
export type MutationUserPasswordUpdateArgs = {
  input: UserPasswordUpdateInput;
};


/** All of the mutations for the app */
export type MutationUserSecurityFieldsUpdateArgs = {
  input: UserSecurityFieldsUpdateInput;
};


/** All of the mutations for the app */
export type MutationUserSignInArgs = {
  input: UserSignInInput;
};


/** All of the mutations for the app */
export type MutationUserVariantNotificationDeleteArgs = {
  input: UserVariantNotificationDeleteInput;
};


/** All of the mutations for the app */
export type MutationUsersMapAgreementCreateArgs = {
  input: UsersMapAgreementCreateInput;
};


/** All of the mutations for the app */
export type MutationWellevateNewAccountEmailArgs = {
  input: WellevateNewAccountEmailInput;
};


/** All of the mutations for the app */
export type MutationWholesaleAddressUnlinkArgs = {
  input: WholesaleAddressUnlinkInput;
};


/** All of the mutations for the app */
export type MutationWholesaleAddressUpdateArgs = {
  input: WholesaleAddressUpdateInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartAddToCartArgs = {
  input: WholesaleCartAddToCartInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartAdminCreateArgs = {
  input: WholesaleCartAdminCreateInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartAdminDeleteArgs = {
  input: WholesaleCartAdminDeleteInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartAdminSelectArgs = {
  input: WholesaleCartAdminSelectInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartCbdNonShippableStateUpdateArgs = {
  input: WholesaleCartCbdNonShippableStateUpdateInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartConfirmArgs = {
  input: WholesaleCartConfirmInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartNonShippableStateUpdateArgs = {
  input: WholesaleCartNonShippableStateUpdateInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartPoNumberUpdateArgs = {
  input: WholesaleCartPoNumberUpdateInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartProp65SwapArgs = {
  input: WholesaleCartProp65SwapInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartResetArgs = {
  input: WholesaleCartResetInput;
};


/** All of the mutations for the app */
export type MutationWholesaleCartUpdateArgs = {
  input: WholesaleCartUpdateInput;
};


/** All of the mutations for the app */
export type MutationWholesalePaymentUpdateArgs = {
  input: WholesalePaymentUpdateInput;
};


/** All of the mutations for the app */
export type MutationWholesalePromotionApplyArgs = {
  input: WholesalePromotionApplyInput;
};


/** All of the mutations for the app */
export type MutationWholesalePromotionRemoveArgs = {
  input: WholesalePromotionRemoveInput;
};


/** All of the mutations for the app */
export type MutationWholesaleRequestBackorderCancellationArgs = {
  input: WholesaleRequestBackorderCancellationInput;
};


/** All of the mutations for the app */
export type MutationWholesaleShippingAddressSelectArgs = {
  input: WholesaleShippingAddressSelectInput;
};


/** All of the mutations for the app */
export type MutationWholesaleShippingRateUpdateArgs = {
  input: WholesaleShippingRateUpdateInput;
};


/** All of the mutations for the app */
export type MutationZendeskPatientDeleteArgs = {
  input: ZendeskPatientDeleteInput;
};

/** Net Term */
export type NetTerm = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the term (ex. Net 30) */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** An object that includes the frequency and the expected date of the next auto refill */
export type NextAutoRefillDate = {
  /** The frequency for the auto refill */
  frequency: Scalars['String']['output'];
  /** The expected next shipment date for the given frequency */
  nextAutoRefillDate: Scalars['Date']['output'];
};

/** Possible next steps */
export type NextStep = {
  /** Next step for a never-margin store */
  neverMargin?: Maybe<Scalars['String']['output']>;
  /** Next step for a variable-margin store */
  variableMargin?: Maybe<Scalars['String']['output']>;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

/** Notification setting type */
export type NotificationSettingType = Node & Timestamps & {
  /** The category of notification setting type */
  category: Scalars['String']['output'];
  /** The channel of notification setting type */
  channel: Scalars['String']['output'];
  /** Code of the notification setting type */
  code: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the notification setting type */
  description?: Maybe<Scalars['String']['output']>;
  /** Whether the notification setting type is specific to a dispensary */
  dispensarySpecific: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Title of the notification setting type */
  title: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of NotificationSettingUnsubscribeAll */
export type NotificationSettingUnsubscribeAllError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of NotificationSettingUnsubscribeAll */
export type NotificationSettingUnsubscribeAllInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of NotificationSettingUnsubscribeAll. */
export type NotificationSettingUnsubscribeAllPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<NotificationSettingUnsubscribeAllError>;
  patientNotificationSettings?: Maybe<Array<PatientNotificationSetting>>;
  userNotificationSettings?: Maybe<Array<UserNotificationSetting>>;
  variantNotifications?: Maybe<Array<VariantNotification>>;
};

/** practitioner notification settings for emails */
export type NotificationSettings = {
  /** Notify practitioner of labs results ready via email */
  notifyLabs?: Maybe<Scalars['Boolean']['output']>;
  /** Notify practitioner of orders via email */
  notifyOrders?: Maybe<Scalars['Boolean']['output']>;
  /** Notify practitioner of newly out of stock products in sent patient plans */
  notifyOutOfStock?: Maybe<Scalars['Boolean']['output']>;
  /** Notify practitioner of new patients via email */
  notifyPatients?: Maybe<Scalars['Boolean']['output']>;
  /** Notify practitioner of stock updates and how often */
  notifyProductAvailability?: Maybe<Scalars['String']['output']>;
  /** Notify practitioner of shipments via email */
  notifyShipments?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated error type of NotificationSettingsUpdate */
export type NotificationSettingsUpdateError = {
  /** Field errors */
  fields?: Maybe<NotificationSettingsUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of NotificationSettingsUpdate */
export type NotificationSettingsUpdateFieldError = {
  notifyLabs?: Maybe<Array<Scalars['String']['output']>>;
  notifyOrders?: Maybe<Array<Scalars['String']['output']>>;
  notifyOutOfStock?: Maybe<Array<Scalars['String']['output']>>;
  notifyPatients?: Maybe<Array<Scalars['String']['output']>>;
  notifyProductAvailability?: Maybe<Array<Scalars['String']['output']>>;
  notifyShipments?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of NotificationSettingsUpdate */
export type NotificationSettingsUpdateInput = {
  attributes: PractitionerNotificationSettingsAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of NotificationSettingsUpdate. */
export type NotificationSettingsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: NotificationSettingsUpdateError;
  /** practitioner whos notifcation settings is being updated. */
  practitioner?: Maybe<Practitioner>;
};

/** Natural Partners line item */
export type NpLineItem = Node & Timestamps & {
  /** Total price of items. */
  amount: Scalars['Float']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** General information about this item. */
  itemDescription: Scalars['String']['output'];
  /** Informs if the line item is only available on NP. */
  npOnly: Scalars['Boolean']['output'];
  /** Unit price of the item. */
  price: Scalars['Float']['output'];
  /** Number of items of this kind. */
  quantity: Scalars['Int']['output'];
  /** Number of items shipped. */
  totalShipped: Scalars['Int']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Variant associated with the item */
  variant?: Maybe<Variant>;
};

/** Orders completed on naturalpartners.com and transfered to Fullscript. */
export type NpOrder = Node & Timestamps & {
  /** The billing address information. */
  billingAddress?: Maybe<Address>;
  /** The timestamp of when the order was completed. */
  completedAt: Scalars['DateTime']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The sum of all the line items for this order. (subtotal) */
  itemTotal: Scalars['Float']['output'];
  /** Items in the order */
  lineItems: Array<NpLineItem>;
  /** The unique identifier for this order in NP. This number is shown to the users. */
  number: Scalars['String']['output'];
  /** The total value of all savings costs. */
  savingsTotal: Scalars['Float']['output'];
  /** The shipping address information. */
  shippingAddress: Address;
  /** The total value of all shipment's costs. */
  shippingTotal: Scalars['Float']['output'];
  /** The current state of the NP order. (ex: settle, complete, canceled, etc.) */
  state: Scalars['String']['output'];
  /** The taxes for this order. */
  taxTotal: Scalars['Float']['output'];
  /** Total cost of the order */
  total: Scalars['Float']['output'];
  /** Tracking numbers for shipments made for this order */
  trackingNumbers?: Maybe<Array<Scalars['String']['output']>>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for NpOrder. */
export type NpOrderConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NpOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NpOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NpOrderEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<NpOrder>;
};

/** Arguments for filtering NP orders. */
export type NpOrderFilterObject = {
  completedAt?: InputMaybe<DateRangeFilter>;
};

/** Arguments for sorting NP orders. */
export type NpOrderSort = {
  direction: SortDirection;
  field: NpOrderSortField;
};

/** Natural Partners order sort field */
export const NpOrderSortField = {
  /** Date on which the NP order was placed */
  CompletedAt: 'COMPLETED_AT',
  /** Total price of the NP order */
  Total: 'TOTAL'
} as const;

export type NpOrderSortField = typeof NpOrderSortField[keyof typeof NpOrderSortField];
/** OAuth scope description */
export type OauthScopeDescription = {
  /** Description of the OAuth scope */
  description: Scalars['String']['output'];
  /** Can include Read treatment plan history and Read order history) */
  includes?: Maybe<Array<Scalars['String']['output']>>;
  /** Type of scope (clinic or patient) */
  kind?: Maybe<Scalars['String']['output']>;
  /** Based on the scope (Read and write access or Read-only access) */
  subTitle?: Maybe<Scalars['String']['output']>;
  /** Based on the type (Patients or Clinic and Practitioner Account) */
  title?: Maybe<Scalars['String']['output']>;
};

/** Domain for practitioners and clerks (staff). */
export type Office = Timestamps & {
  /** Staff's saved addresses */
  addresses: Array<UserRoleAddress>;
  /** Count of at risk variants by plan_type applicable to the store or practitioner. */
  atRiskVariantsCountByPlanType: AtRiskVariantsCountByPlanType;
  /** Count of at risk variants by status applicable to the store or practitioner. */
  atRiskVariantsCountByStatus: AtRiskVariantsCountByStatus;
  /** At risk variants applicable to the store or practitioner. */
  atRiskVariantsV2?: Maybe<AtRiskVariantConnection>;
  /** List of commonly used medications */
  commonMedications: Array<CommonMedication>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The current staff's saved credit cards. Used for wholesale checkout. */
  creditCards?: Maybe<Array<CreditCard>>;
  /** Current patient import */
  currentPatientImport?: Maybe<PatientImport>;
  /** Brands participating in the Cyber Monday sale */
  cyberMondayBrands: BrandConnection;
  /** Find a daily pack pill by ID */
  dailyPackPill?: Maybe<DailyPackPill>;
  /** Pills available to be added to a Dynamic Daily Pack */
  dailyPackPills: DailyPackPillConnection;
  /** Find daily pack template by ID */
  dailyPackTemplate?: Maybe<DailyPack>;
  /** Pre-built templates available to be added to a Dynamic Daily Pack */
  dailyPackTemplates: DailyPackConnection;
  /** List of documents created by a practitioner */
  documents: DocumentConnection;
  /** Practitioner's latest draft multi patient plan */
  draftMultiPatientPlan?: Maybe<MultiPatientPlan>;
  /** Latest draft treatment plan between a practitioner and patient */
  draftTreatmentPlan?: Maybe<TreatmentPlan>;
  /** Find dynamic daily pack by ID or recommendation_id */
  dynamicDailyPack?: Maybe<DailyPack>;
  /** URL for example patient import csv file */
  examplePatientImportCsvUrl: Scalars['String']['output'];
  /** Interaction details for a given ingredient */
  fsAssistIngredientInteractionDetails?: Maybe<InteractionDetailsConnection>;
  /** Returns overview of all interactions for either a product, patient's previous treatment plans or current treatment plan. */
  fsAssistInteractions?: Maybe<OverallInteractionsOverview>;
  /** Interaction details for a given medication */
  fsAssistMedicationInteractionDetails?: Maybe<InteractionDetailsConnection>;
  /** Interaction details for a given product */
  fsAssistProductInteractionDetails?: Maybe<InteractionDetailsConnection>;
  id: Scalars['ID']['output'];
  /** Patient ingredient suggestions by category */
  ingredientSuggestions?: Maybe<IngredientSuggestions>;
  /** Find an integration listing by slug */
  integrationListing?: Maybe<IntegrationListing>;
  /** Integration listings */
  integrationListings: IntegrationListingConnection;
  /** Whether the user is part of the inoffice checkout graphql experiment group */
  isInofficeCheckoutGqlExperimentGroup: Scalars['Boolean']['output'];
  /** List of lab recommendations in this store */
  labRecommendations: LabsRecommendationConnection;
  /** The most recent draft Template, Treatment or Multi Patient Plan for the current Practitioner or Practitioner ID. */
  latestDraftPlan?: Maybe<PlanUnion>;
  /** List of multi_patient_plans */
  multiPatientPlans: MultiPatientPlanConnection;
  /**
   * A list of multi-patient plans that contain an at-risk variant (e.g.,
   * backordered, discontinued, unavailable, etc.).       Plans that are scheduled
   * to be swapped are filtered out.
   */
  multiPatientPlansWithAtRiskVariant: MultiPatientPlanConnection;
  /** Information about the peer referral program for the store */
  peerReferralProgram: PeerReferralProgram;
  /** The associated Template, Treatment or Multi Patient Plan for the given Treatment or Multi Patient Plan ID. */
  plan?: Maybe<PlanUnion>;
  /** Smart suggestions for each ingredient group */
  productSuggestions?: Maybe<ProductSuggestions>;
  /** Brands participating in sale */
  promotionBrands: BrandConnection;
  /**
   * All active promotion campaigns
   * @deprecated Use the promotionCampaigns field in ViewerType
   */
  promotionCampaigns: Array<PromotionCampaign>;
  /** List of all (staff) users for the current store, sorted by last_name, first_name */
  staff: Array<User>;
  /** Find static daily pack by ID */
  staticDailyPack?: Maybe<DailyPack>;
  /** Daily Packs created by Fullscript */
  staticDailyPacks?: Maybe<DailyPackConnection>;
  /** Current staff's store */
  store?: Maybe<Store>;
  /** The data to be displayed on the store analytics dashboard */
  storeAnalyticsDashboard?: Maybe<Dashboard>;
  /** List of protocols */
  treatmentPlanTemplates: TreatmentPlanTemplateConnection;
  /**
   * A list of treatment plans that contain an at-risk variant (e.g., backordered,
   * discontinued, unavailable, etc.).       Plans that are scheduled to be swapped
   * are filtered out.
   */
  treatmentPlansWithAtRiskVariant: TreatmentPlanConnection;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Current staff's user account */
  user: User;
  /** Current user's wholesale cart */
  wholesaleCart?: Maybe<WholesaleOrder>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeAtRiskVariantsCountByStatusArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeAtRiskVariantsV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters: AtRiskVariantsFilterObject;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AtRiskVariantsSort>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeCyberMondayBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeDailyPackPillArgs = {
  id: Scalars['ID']['input'];
};


/** Domain for practitioners and clerks (staff). */
export type OfficeDailyPackPillsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DailyPackPillFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeDailyPackTemplateArgs = {
  id: Scalars['ID']['input'];
};


/** Domain for practitioners and clerks (staff). */
export type OfficeDailyPackTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DailyPackTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<DailyPackTemplateSort>>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DocumentFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  sort?: InputMaybe<Array<DocumentSort>>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeDraftTreatmentPlanArgs = {
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeDynamicDailyPackArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeFsAssistIngredientInteractionDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ingredientId: Scalars['ID']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<InteractionSeverityLevel>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeFsAssistInteractionsArgs = {
  patientId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeFsAssistMedicationInteractionDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  medicationId: Scalars['ID']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<InteractionSeverityLevel>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeFsAssistProductInteractionDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<InteractionSeverityLevel>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeIngredientSuggestionsArgs = {
  attributes: IngredientSuggestionAttributes;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeIntegrationListingArgs = {
  slug: Scalars['String']['input'];
};


/** Domain for practitioners and clerks (staff). */
export type OfficeIntegrationListingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeLabRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LabRecommendationFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<LabRecommendationSort>>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeLatestDraftPlanArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeMultiPatientPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<MultiPatientPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MultiPatientPlanSort>>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeMultiPatientPlansWithAtRiskVariantArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  atRiskVariantId: Scalars['ID']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficePlanArgs = {
  id: Scalars['ID']['input'];
};


/** Domain for practitioners and clerks (staff). */
export type OfficeProductSuggestionsArgs = {
  attributes: ProductSuggestionsAttributes;
};


/** Domain for practitioners and clerks (staff). */
export type OfficePromotionBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeStaticDailyPackArgs = {
  id: Scalars['ID']['input'];
};


/** Domain for practitioners and clerks (staff). */
export type OfficeStaticDailyPacksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeTreatmentPlanTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TreatmentPlanTemplateSort>>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeTreatmentPlansWithAtRiskVariantArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  atRiskVariantId: Scalars['ID']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};


/** Domain for practitioners and clerks (staff). */
export type OfficeWholesaleCartArgs = {
  updatePromotions?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of OfficeAddressCreate */
export type OfficeAddressCreateError = {
  /** Field errors */
  fields?: Maybe<OfficeAddressCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeAddressCreate */
export type OfficeAddressCreateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeAddressCreate */
export type OfficeAddressCreateInput = {
  attributes: UserRoleAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeAddressCreate. */
export type OfficeAddressCreatePayload = {
  address?: Maybe<UserRoleAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeAddressCreateError;
};

/** Autogenerated error type of OfficeAddressDelete */
export type OfficeAddressDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeAddressDelete */
export type OfficeAddressDeleteInput = {
  addressId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeAddressDelete. */
export type OfficeAddressDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeAddressDeleteError;
};

/** Autogenerated error type of OfficeAddressUpdate */
export type OfficeAddressUpdateError = {
  /** Field errors */
  fields?: Maybe<OfficeAddressUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeAddressUpdate */
export type OfficeAddressUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeAddressUpdate */
export type OfficeAddressUpdateInput = {
  addressId: Scalars['ID']['input'];
  attributes: UserRoleAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeAddressUpdate. */
export type OfficeAddressUpdatePayload = {
  address?: Maybe<UserRoleAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeAddressUpdateError;
};

/** Autogenerated error type of OfficeApiEmbeddableEventPatientRelink */
export type OfficeApiEmbeddableEventPatientRelinkError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeApiEmbeddableEventPatientRelink */
export type OfficeApiEmbeddableEventPatientRelinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalPatientId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeApiEmbeddableEventPatientRelink. */
export type OfficeApiEmbeddableEventPatientRelinkPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeApiEmbeddableEventPatientRelinkError>;
  fsJsEventPayload?: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of OfficeApiEmbeddableEventPatientSelected */
export type OfficeApiEmbeddableEventPatientSelectedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalPatientId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeApiEmbeddableEventPatientSelected. */
export type OfficeApiEmbeddableEventPatientSelectedPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: BaseError;
  fsJsEventPayload: Scalars['JSON']['output'];
};

/** Autogenerated error type of OfficeBulkPatientAssignPractitioner */
export type OfficeBulkPatientAssignPractitionerError = {
  /** Field errors */
  fields?: Maybe<OfficeBulkPatientAssignPractitionerFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeBulkPatientAssignPractitioner */
export type OfficeBulkPatientAssignPractitionerFieldError = {
  allPatients?: Maybe<Array<Scalars['String']['output']>>;
  excludedPatientIds?: Maybe<Array<Scalars['String']['output']>>;
  includeBlocked?: Maybe<Array<Scalars['String']['output']>>;
  patientIds?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerIdToAssign?: Maybe<Array<Scalars['String']['output']>>;
  query?: Maybe<Array<Scalars['String']['output']>>;
  sourcePlatform?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscounts?: Maybe<Array<Scalars['String']['output']>>;
  withDraftTreatmentPlan?: Maybe<Array<Scalars['String']['output']>>;
  withFirstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
  withNoAssignedPractitioner?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeBulkPatientAssignPractitioner */
export type OfficeBulkPatientAssignPractitionerInput = {
  attributes: BulkPatientAssignPractitionerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeBulkPatientAssignPractitioner. */
export type OfficeBulkPatientAssignPractitionerPayload = {
  bulkEditProcess?: Maybe<BulkEditProcess>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: OfficeBulkPatientAssignPractitionerError;
};

/** Autogenerated error type of OfficeBulkPatientReassignPractitioner */
export type OfficeBulkPatientReassignPractitionerError = {
  /** Field errors */
  fields?: Maybe<OfficeBulkPatientReassignPractitionerFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeBulkPatientReassignPractitioner */
export type OfficeBulkPatientReassignPractitionerFieldError = {
  allPatients?: Maybe<Array<Scalars['String']['output']>>;
  excludedPatientIds?: Maybe<Array<Scalars['String']['output']>>;
  includeBlocked?: Maybe<Array<Scalars['String']['output']>>;
  patientIds?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerIdToRemove?: Maybe<Array<Scalars['String']['output']>>;
  query?: Maybe<Array<Scalars['String']['output']>>;
  sourcePlatform?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscounts?: Maybe<Array<Scalars['String']['output']>>;
  withDraftTreatmentPlan?: Maybe<Array<Scalars['String']['output']>>;
  withFirstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
  withNoAssignedPractitioner?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeBulkPatientReassignPractitioner */
export type OfficeBulkPatientReassignPractitionerInput = {
  attributes: BulkPatientReassignPractitionerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeBulkPatientReassignPractitioner. */
export type OfficeBulkPatientReassignPractitionerPayload = {
  bulkEditProcess?: Maybe<BulkEditProcess>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeBulkPatientReassignPractitionerError>;
};

/** Autogenerated error type of OfficeBulkSavingsUpdateAll */
export type OfficeBulkSavingsUpdateAllError = {
  /** Field errors */
  fields?: Maybe<OfficeBulkSavingsUpdateAllFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeBulkSavingsUpdateAll */
export type OfficeBulkSavingsUpdateAllFieldError = {
  discount?: Maybe<Array<Scalars['String']['output']>>;
  excludedPatientIds?: Maybe<Array<Scalars['String']['output']>>;
  includeBlocked?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  query?: Maybe<Array<Scalars['String']['output']>>;
  sourcePlatform?: Maybe<Array<Scalars['String']['output']>>;
  totalDiscounts?: Maybe<Array<Scalars['String']['output']>>;
  withDraftTreatmentPlan?: Maybe<Array<Scalars['String']['output']>>;
  withFirstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
  withNoAssignedPractitioner?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeBulkSavingsUpdateAll */
export type OfficeBulkSavingsUpdateAllInput = {
  attributes: BulkSavingsUpdateAllAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeBulkSavingsUpdateAll. */
export type OfficeBulkSavingsUpdateAllPayload = {
  bulkEditProcess?: Maybe<BulkEditProcess>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeBulkSavingsUpdateAllError>;
};

/** Autogenerated error type of OfficeBulkSavingsUpdate */
export type OfficeBulkSavingsUpdateError = {
  /** Field errors */
  fields?: Maybe<OfficeBulkSavingsUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeBulkSavingsUpdate */
export type OfficeBulkSavingsUpdateFieldError = {
  discount?: Maybe<Array<Scalars['String']['output']>>;
  patientIds?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeBulkSavingsUpdate */
export type OfficeBulkSavingsUpdateInput = {
  attributes: BulkSavingsUpdateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeBulkSavingsUpdate. */
export type OfficeBulkSavingsUpdatePayload = {
  bulkEditProcess?: Maybe<BulkEditProcess>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeBulkSavingsUpdateError>;
};

/** Autogenerated error type of OfficeCatalogGetProductDailyIntake */
export type OfficeCatalogGetProductDailyIntakeError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeCatalogGetProductDailyIntake */
export type OfficeCatalogGetProductDailyIntakeInput = {
  /** amount/daily_dosage - if using for daily_dosage, leave frequency empty/nil */
  amount: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** frequency is multiplied by amount - if nil default is 'once per day' */
  frequency?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeCatalogGetProductDailyIntake. */
export type OfficeCatalogGetProductDailyIntakePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** daily intake of ingredients with hierarchy */
  dailyIntake: Array<PlanIngredient>;
  errors: OfficeCatalogGetProductDailyIntakeError;
};

/** Autogenerated error type of OfficeCatalogGetProductMonthlyCost */
export type OfficeCatalogGetProductMonthlyCostError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeCatalogGetProductMonthlyCost */
export type OfficeCatalogGetProductMonthlyCostInput = {
  /** amount/daily_dosage - if using for daily_dosage, leave frequency empty/nil */
  amount: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** frequency is multiplied by amount - if nil default is 'once per day' */
  frequency?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeCatalogGetProductMonthlyCost. */
export type OfficeCatalogGetProductMonthlyCostPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeCatalogGetProductMonthlyCostError;
  /** Monthly cost of this variant to achieve the target intake */
  monthlyCost: Scalars['Float']['output'];
};

/** Autogenerated error type of OfficeCategoryDelete */
export type OfficeCategoryDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeCategoryDelete */
export type OfficeCategoryDeleteInput = {
  categoryId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeCategoryDelete. */
export type OfficeCategoryDeletePayload = {
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeCategoryDeleteError;
};

/** Attributes to save a category */
export type OfficeCategorySaveAttributes = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  isVisibleToPatients?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated error type of OfficeCategorySave */
export type OfficeCategorySaveError = {
  /** Field errors */
  fields?: Maybe<OfficeCategorySaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeCategorySave */
export type OfficeCategorySaveFieldError = {
  categoryId?: Maybe<Array<Scalars['String']['output']>>;
  isVisibleToPatients?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeCategorySave */
export type OfficeCategorySaveInput = {
  attributes: OfficeCategorySaveAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeCategorySave. */
export type OfficeCategorySavePayload = {
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeCategorySaveError;
};

/** Attributes for updating a clerk */
export type OfficeClerkUpdateAttributes = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes related to a default dosage instruction */
export type OfficeDefaultDosageInstructionAttributes = {
  defaultDosageInstructionId?: InputMaybe<Scalars['ID']['input']>;
  defaultVariantId: Scalars['ID']['input'];
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageDuration?: InputMaybe<Scalars['String']['input']>;
  dosageFormat?: InputMaybe<Scalars['String']['input']>;
  dosageFrequency?: InputMaybe<Scalars['String']['input']>;
  dosagePermutations?: InputMaybe<Scalars['String']['input']>;
  dosageQuantity?: InputMaybe<Scalars['Int']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
  withFood?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of OfficeDefaultDosageInstructionSave */
export type OfficeDefaultDosageInstructionSaveError = {
  /** Field errors */
  fields?: Maybe<OfficeDefaultDosageInstructionSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeDefaultDosageInstructionSave */
export type OfficeDefaultDosageInstructionSaveFieldError = {
  defaultDosageInstructionId?: Maybe<Array<Scalars['String']['output']>>;
  defaultVariantId?: Maybe<Array<Scalars['String']['output']>>;
  dosageAmount?: Maybe<Array<Scalars['String']['output']>>;
  dosageDuration?: Maybe<Array<Scalars['String']['output']>>;
  dosageFormat?: Maybe<Array<Scalars['String']['output']>>;
  dosageFrequency?: Maybe<Array<Scalars['String']['output']>>;
  dosagePermutations?: Maybe<Array<Scalars['String']['output']>>;
  dosageQuantity?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  productId?: Maybe<Array<Scalars['String']['output']>>;
  takeWith?: Maybe<Array<Scalars['String']['output']>>;
  timeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  withFood?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeDefaultDosageInstructionSave */
export type OfficeDefaultDosageInstructionSaveInput = {
  attributes: OfficeDefaultDosageInstructionAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeDefaultDosageInstructionSave. */
export type OfficeDefaultDosageInstructionSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  defaultDosageInstruction?: Maybe<DefaultDosageInstruction>;
  errors: OfficeDefaultDosageInstructionSaveError;
};

/** Attributes related to a document attachment */
export type OfficeDocumentAttachmentAttributes = {
  documentAttachmentId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
  uploadableId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated error type of OfficeDocumentAttachmentDelete */
export type OfficeDocumentAttachmentDeleteError = {
  /** Field errors */
  fields?: Maybe<OfficeDocumentAttachmentDeleteFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeDocumentAttachmentDelete */
export type OfficeDocumentAttachmentDeleteFieldError = {
  documentAttachmentId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  uploadableId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeDocumentAttachmentDelete */
export type OfficeDocumentAttachmentDeleteInput = {
  attributes: OfficeDocumentAttachmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeDocumentAttachmentDelete. */
export type OfficeDocumentAttachmentDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentAttachment?: Maybe<DocumentAttachment>;
  errors: OfficeDocumentAttachmentDeleteError;
  multiPatientPlan?: Maybe<MultiPatientPlan>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeDocumentAttachmentSave */
export type OfficeDocumentAttachmentSaveError = {
  /** Field errors */
  fields?: Maybe<OfficeDocumentAttachmentSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeDocumentAttachmentSave */
export type OfficeDocumentAttachmentSaveFieldError = {
  documentAttachmentId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  uploadableId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeDocumentAttachmentSave */
export type OfficeDocumentAttachmentSaveInput = {
  attributes: OfficeDocumentAttachmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeDocumentAttachmentSave. */
export type OfficeDocumentAttachmentSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentAttachment?: Maybe<DocumentAttachment>;
  errors: OfficeDocumentAttachmentSaveError;
};

/** Autogenerated error type of OfficeDocumentAttachmentUndo */
export type OfficeDocumentAttachmentUndoError = {
  /** Field errors */
  fields?: Maybe<OfficeDocumentAttachmentUndoFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeDocumentAttachmentUndo */
export type OfficeDocumentAttachmentUndoFieldError = {
  documentAttachmentId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  uploadableId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeDocumentAttachmentUndo */
export type OfficeDocumentAttachmentUndoInput = {
  attributes: OfficeDocumentAttachmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeDocumentAttachmentUndo. */
export type OfficeDocumentAttachmentUndoPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentAttachment?: Maybe<DocumentAttachment>;
  errors: OfficeDocumentAttachmentUndoError;
};

/** Attributes for updating a store's dynamic daily packs */
export type OfficeDynamicDailyPackAttributes = {
  dailyPackId?: InputMaybe<Scalars['ID']['input']>;
  multiPatientPlanId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  practitionerTemplateName?: InputMaybe<Scalars['String']['input']>;
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
  variantAttributes?: InputMaybe<Array<OfficeDynamicDailyPackVariantAttributes>>;
};

/** Attributes for updating a dynamic daily pack's contents */
export type OfficeDynamicDailyPackVariantAttributes = {
  amDosage?: InputMaybe<Scalars['Int']['input']>;
  pmDosage?: InputMaybe<Scalars['Int']['input']>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated error type of OfficeExternalMetadatumDeactivate */
export type OfficeExternalMetadatumDeactivateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeExternalMetadatumDeactivate */
export type OfficeExternalMetadatumDeactivateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalMetadatumId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeExternalMetadatumDeactivate. */
export type OfficeExternalMetadatumDeactivatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeExternalMetadatumDeactivateError;
  externalIntegration?: Maybe<ApiExternalMetadatum>;
};

/** Autogenerated error type of OfficeIntegrationListingDisconnect */
export type OfficeIntegrationListingDisconnectError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeIntegrationListingDisconnect */
export type OfficeIntegrationListingDisconnectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  integrationListingId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeIntegrationListingDisconnect. */
export type OfficeIntegrationListingDisconnectPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeIntegrationListingDisconnectError;
  integrationListing?: Maybe<IntegrationListing>;
  status: Scalars['String']['output'];
};

/** Autogenerated error type of OfficeLabsAddTestToPlan */
export type OfficeLabsAddTestToPlanError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsAddTestToPlanFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsAddTestToPlan */
export type OfficeLabsAddTestToPlanFieldError = {
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
  parentTestId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsAddTestToPlan */
export type OfficeLabsAddTestToPlanInput = {
  attributes: LabAddTestToPlanAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsAddTestToPlan. */
export type OfficeLabsAddTestToPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsAddTestToPlanError;
  labRecommendation?: Maybe<LabsRecommendation>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeLabsFavoritesAdd */
export type OfficeLabsFavoritesAddError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsFavoritesAddFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsFavoritesAdd */
export type OfficeLabsFavoritesAddFieldError = {
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsFavoritesAdd */
export type OfficeLabsFavoritesAddInput = {
  attributes: LabFavoritesAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsFavoritesAdd. */
export type OfficeLabsFavoritesAddPayload = {
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsFavoritesAddError;
  labTest?: Maybe<LabsTest>;
};

/** Autogenerated error type of OfficeLabsFavoritesKitsAdd */
export type OfficeLabsFavoritesKitsAddError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsFavoritesKitsAddFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsFavoritesKitsAdd */
export type OfficeLabsFavoritesKitsAddFieldError = {
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  spreeProductId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsFavoritesKitsAdd */
export type OfficeLabsFavoritesKitsAddInput = {
  attributes: LabKitFavoritesAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsFavoritesKitsAdd. */
export type OfficeLabsFavoritesKitsAddPayload = {
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsFavoritesKitsAddError;
  labKit?: Maybe<Product>;
};

/** Autogenerated error type of OfficeLabsFavoritesKitsRemove */
export type OfficeLabsFavoritesKitsRemoveError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsFavoritesKitsRemoveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsFavoritesKitsRemove */
export type OfficeLabsFavoritesKitsRemoveFieldError = {
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  spreeProductId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsFavoritesKitsRemove */
export type OfficeLabsFavoritesKitsRemoveInput = {
  attributes: LabKitFavoritesAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsFavoritesKitsRemove. */
export type OfficeLabsFavoritesKitsRemovePayload = {
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsFavoritesKitsRemoveError;
  labKit?: Maybe<Product>;
};

/** Autogenerated error type of OfficeLabsFavoritesRemove */
export type OfficeLabsFavoritesRemoveError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsFavoritesRemoveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsFavoritesRemove */
export type OfficeLabsFavoritesRemoveFieldError = {
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsFavoritesRemove */
export type OfficeLabsFavoritesRemoveInput = {
  attributes: LabFavoritesAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsFavoritesRemove. */
export type OfficeLabsFavoritesRemovePayload = {
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsFavoritesRemoveError;
  labTest?: Maybe<LabsTest>;
};

/** Autogenerated error type of OfficeLabsJourneysAddToPlan */
export type OfficeLabsJourneysAddToPlanError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsJourneysAddToPlan */
export type OfficeLabsJourneysAddToPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
  journeyVariantId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeLabsJourneysAddToPlan. */
export type OfficeLabsJourneysAddToPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysAddToPlanError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcern */
export type OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcern */
export type OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of an interpretation of a biomarker result. */
  interpretationId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcern. */
export type OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernError;
  /** The updated interpretation */
  interpretation?: Maybe<LabFulfillmentsBiomarkerResultInterpretation>;
};

/** Autogenerated error type of OfficeLabsJourneysBiomarkerResultInterpretationsUpdate */
export type OfficeLabsJourneysBiomarkerResultInterpretationsUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsJourneysBiomarkerResultInterpretationsUpdate */
export type OfficeLabsJourneysBiomarkerResultInterpretationsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labBiomarkerResultInterpretationId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsJourneysBiomarkerResultInterpretationsUpdate. */
export type OfficeLabsJourneysBiomarkerResultInterpretationsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysBiomarkerResultInterpretationsUpdateError;
  labBiomarkerResultInterpretation?: Maybe<LabFulfillmentsBiomarkerResultInterpretation>;
};

/** Autogenerated error type of OfficeLabsJourneysCancelSubscription */
export type OfficeLabsJourneysCancelSubscriptionError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsJourneysCancelSubscription */
export type OfficeLabsJourneysCancelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  journeySubscriptionId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeLabsJourneysCancelSubscription. */
export type OfficeLabsJourneysCancelSubscriptionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysCancelSubscriptionError;
  journeySubscription?: Maybe<LabsJourneysSubscription>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeLabsJourneysInterpretationsUpdate */
export type OfficeLabsJourneysInterpretationsUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsJourneysInterpretationsUpdate */
export type OfficeLabsJourneysInterpretationsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labResultInterpretationId: Scalars['ID']['input'];
  summary: Scalars['String']['input'];
};

/** Autogenerated return type of OfficeLabsJourneysInterpretationsUpdate. */
export type OfficeLabsJourneysInterpretationsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysInterpretationsUpdateError;
  labResultInterpretation?: Maybe<LabFulfillmentsLabResultInterpretation>;
};

/** Autogenerated error type of OfficeLabsJourneysLabResultInterpretationsGeneratePdf */
export type OfficeLabsJourneysLabResultInterpretationsGeneratePdfError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsJourneysLabResultInterpretationsGeneratePdf */
export type OfficeLabsJourneysLabResultInterpretationsGeneratePdfInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interpretationId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeLabsJourneysLabResultInterpretationsGeneratePdf. */
export type OfficeLabsJourneysLabResultInterpretationsGeneratePdfPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysLabResultInterpretationsGeneratePdfError;
  interpretation?: Maybe<LabFulfillmentsLabResultInterpretation>;
};

/** Autogenerated error type of OfficeLabsJourneysLabResultInterpretationsReleaseToPatient */
export type OfficeLabsJourneysLabResultInterpretationsReleaseToPatientError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsJourneysLabResultInterpretationsReleaseToPatient */
export type OfficeLabsJourneysLabResultInterpretationsReleaseToPatientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interpretationId: Scalars['ID']['input'];
  outOfRangeConsent?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of OfficeLabsJourneysLabResultInterpretationsReleaseToPatient. */
export type OfficeLabsJourneysLabResultInterpretationsReleaseToPatientPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysLabResultInterpretationsReleaseToPatientError;
  interpretation?: Maybe<LabFulfillmentsLabResultInterpretation>;
};

/** Autogenerated error type of OfficeLabsJourneysRemoveFromPlan */
export type OfficeLabsJourneysRemoveFromPlanError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsJourneysRemoveFromPlan */
export type OfficeLabsJourneysRemoveFromPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  journeySubscriptionId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeLabsJourneysRemoveFromPlan. */
export type OfficeLabsJourneysRemoveFromPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysRemoveFromPlanError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeLabsJourneysUpdateDetails */
export type OfficeLabsJourneysUpdateDetailsError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsJourneysUpdateDetails */
export type OfficeLabsJourneysUpdateDetailsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  journeySubscriptionId: Scalars['ID']['input'];
  notes: Scalars['String']['input'];
};

/** Autogenerated return type of OfficeLabsJourneysUpdateDetails. */
export type OfficeLabsJourneysUpdateDetailsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysUpdateDetailsError;
  journeySubscription?: Maybe<LabsJourneysSubscription>;
};

/** Autogenerated error type of OfficeLabsJourneysUpdateFrequency */
export type OfficeLabsJourneysUpdateFrequencyError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsJourneysUpdateFrequency */
export type OfficeLabsJourneysUpdateFrequencyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
  journeySubscriptionId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeLabsJourneysUpdateFrequency. */
export type OfficeLabsJourneysUpdateFrequencyPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsJourneysUpdateFrequencyError;
  journeySubscription?: Maybe<LabsJourneysSubscription>;
};

/** Autogenerated error type of OfficeLabsKitsActivate */
export type OfficeLabsKitsActivateError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsKitsActivateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsKitsActivate */
export type OfficeLabsKitsActivateFieldError = {
  activationCode?: Maybe<Array<Scalars['String']['output']>>;
  addonIds?: Maybe<Array<Scalars['String']['output']>>;
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsKitsActivate */
export type OfficeLabsKitsActivateInput = {
  attributes: LabKitActivationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsKitsActivate. */
export type OfficeLabsKitsActivatePayload = {
  activationCode?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsKitsActivateError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeLabsKitsVerifyCode */
export type OfficeLabsKitsVerifyCodeError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsKitsVerifyCode */
export type OfficeLabsKitsVerifyCodeInput = {
  activationCode: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsKitsVerifyCode. */
export type OfficeLabsKitsVerifyCodePayload = {
  activationCode?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsKitsVerifyCodeError;
  labKit?: Maybe<Product>;
  unverifiedReason?: Maybe<Scalars['String']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated error type of OfficeLabsPanelsActivate */
export type OfficeLabsPanelsActivateError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsPanelsActivateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsPanelsActivate */
export type OfficeLabsPanelsActivateFieldError = {
  instructions?: Maybe<Array<Scalars['String']['output']>>;
  labPanelId?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  requiresFasting?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsPanelsActivate */
export type OfficeLabsPanelsActivateInput = {
  attributes: LabPanelAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsPanelsActivate. */
export type OfficeLabsPanelsActivatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsActivateError;
  labPanel?: Maybe<LabsPanel>;
};

/** Autogenerated error type of OfficeLabsPanelsAddTest */
export type OfficeLabsPanelsAddTestError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsPanelsAddTestFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsPanelsAddTest */
export type OfficeLabsPanelsAddTestFieldError = {
  labPanelId?: Maybe<Array<Scalars['String']['output']>>;
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsPanelsAddTest */
export type OfficeLabsPanelsAddTestInput = {
  attributes: LabPanelTestAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsPanelsAddTest. */
export type OfficeLabsPanelsAddTestPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsAddTestError;
  labPanel?: Maybe<LabsPanel>;
};

/** Autogenerated error type of OfficeLabsPanelsApplyToPlan */
export type OfficeLabsPanelsApplyToPlanError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsPanelsApplyToPlan */
export type OfficeLabsPanelsApplyToPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labPanelId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeLabsPanelsApplyToPlan. */
export type OfficeLabsPanelsApplyToPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsApplyToPlanError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeLabsPanelsCancel */
export type OfficeLabsPanelsCancelError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsPanelsCancel */
export type OfficeLabsPanelsCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labPanelId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeLabsPanelsCancel. */
export type OfficeLabsPanelsCancelPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsCancelError;
  labPanel?: Maybe<LabsPanel>;
};

/** Autogenerated error type of OfficeLabsPanelsDuplicate */
export type OfficeLabsPanelsDuplicateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsPanelsDuplicate */
export type OfficeLabsPanelsDuplicateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labPanelId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeLabsPanelsDuplicate. */
export type OfficeLabsPanelsDuplicatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsDuplicateError;
  labPanel?: Maybe<LabsPanel>;
};

/** Autogenerated error type of OfficeLabsPanelsFindOrCreateDraft */
export type OfficeLabsPanelsFindOrCreateDraftError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsPanelsFindOrCreateDraft */
export type OfficeLabsPanelsFindOrCreateDraftInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of OfficeLabsPanelsFindOrCreateDraft. */
export type OfficeLabsPanelsFindOrCreateDraftPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsFindOrCreateDraftError;
  labPanel?: Maybe<LabsPanel>;
};

/** Autogenerated error type of OfficeLabsPanelsRemoveFromPlan */
export type OfficeLabsPanelsRemoveFromPlanError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsPanelsRemoveFromPlan */
export type OfficeLabsPanelsRemoveFromPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labPanelId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeLabsPanelsRemoveFromPlan. */
export type OfficeLabsPanelsRemoveFromPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsRemoveFromPlanError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeLabsPanelsRemoveTest */
export type OfficeLabsPanelsRemoveTestError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsPanelsRemoveTestFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsPanelsRemoveTest */
export type OfficeLabsPanelsRemoveTestFieldError = {
  labPanelId?: Maybe<Array<Scalars['String']['output']>>;
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsPanelsRemoveTest */
export type OfficeLabsPanelsRemoveTestInput = {
  attributes: LabPanelTestAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsPanelsRemoveTest. */
export type OfficeLabsPanelsRemoveTestPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsRemoveTestError;
  labPanel?: Maybe<LabsPanel>;
};

/** Autogenerated error type of OfficeLabsPanelsSave */
export type OfficeLabsPanelsSaveError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsPanelsSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsPanelsSave */
export type OfficeLabsPanelsSaveFieldError = {
  instructions?: Maybe<Array<Scalars['String']['output']>>;
  labPanelId?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  requiresFasting?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsPanelsSave */
export type OfficeLabsPanelsSaveInput = {
  attributes: LabPanelAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsPanelsSave. */
export type OfficeLabsPanelsSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsPanelsSaveError;
  labPanel?: Maybe<LabsPanel>;
};

/** Autogenerated error type of OfficeLabsRecommendationAddActivationCode */
export type OfficeLabsRecommendationAddActivationCodeError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsRecommendationAddActivationCode */
export type OfficeLabsRecommendationAddActivationCodeInput = {
  activationCode: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of OfficeLabsRecommendationAddActivationCode. */
export type OfficeLabsRecommendationAddActivationCodePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationAddActivationCodeError;
  labRecommendation?: Maybe<LabsRecommendation>;
};

/** Autogenerated error type of OfficeLabsRecommendationCustomFeesUpdate */
export type OfficeLabsRecommendationCustomFeesUpdateError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsRecommendationCustomFeesUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsRecommendationCustomFeesUpdate */
export type OfficeLabsRecommendationCustomFeesUpdateFieldError = {
  amount?: Maybe<Array<Scalars['String']['output']>>;
  labRecommendationId?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsRecommendationCustomFeesUpdate */
export type OfficeLabsRecommendationCustomFeesUpdateInput = {
  attributes: LabRecommendationCustomFeeAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsRecommendationCustomFeesUpdate. */
export type OfficeLabsRecommendationCustomFeesUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customFee?: Maybe<LabsRecommendationCustomFee>;
  errors?: Maybe<OfficeLabsRecommendationCustomFeesUpdateError>;
  labRecommendation?: Maybe<LabsRecommendation>;
};

/** Autogenerated error type of OfficeLabsRecommendationDelete */
export type OfficeLabsRecommendationDeleteError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsRecommendationDeleteFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsRecommendationDelete */
export type OfficeLabsRecommendationDeleteFieldError = {
  labRecommendationId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsRecommendationDelete */
export type OfficeLabsRecommendationDeleteInput = {
  attributes: LabRecommendationDeleteAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsRecommendationDelete. */
export type OfficeLabsRecommendationDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationDeleteError;
  labRecommendation?: Maybe<LabsRecommendation>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeLabsRecommendationDeleteTest */
export type OfficeLabsRecommendationDeleteTestError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsRecommendationDeleteTestFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsRecommendationDeleteTest */
export type OfficeLabsRecommendationDeleteTestFieldError = {
  labTestId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsRecommendationDeleteTest */
export type OfficeLabsRecommendationDeleteTestInput = {
  attributes: LabRecommendationDeleteTestAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsRecommendationDeleteTest. */
export type OfficeLabsRecommendationDeleteTestPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationDeleteTestError;
  labRecommendation?: Maybe<LabsRecommendation>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeLabsRecommendationRemoveActivationCode */
export type OfficeLabsRecommendationRemoveActivationCodeError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsRecommendationRemoveActivationCode */
export type OfficeLabsRecommendationRemoveActivationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of OfficeLabsRecommendationRemoveActivationCode. */
export type OfficeLabsRecommendationRemoveActivationCodePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationRemoveActivationCodeError;
  labRecommendation?: Maybe<LabsRecommendation>;
};

/** Autogenerated error type of OfficeLabsRecommendationSaveAsPanel */
export type OfficeLabsRecommendationSaveAsPanelError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsRecommendationSaveAsPanel */
export type OfficeLabsRecommendationSaveAsPanelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of OfficeLabsRecommendationSaveAsPanel. */
export type OfficeLabsRecommendationSaveAsPanelPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationSaveAsPanelError;
  labPanel?: Maybe<LabsPanel>;
};

/** Autogenerated error type of OfficeLabsRecommendationSave */
export type OfficeLabsRecommendationSaveError = {
  /** Field errors */
  fields?: Maybe<OfficeLabsRecommendationSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeLabsRecommendationSave */
export type OfficeLabsRecommendationSaveFieldError = {
  instructions?: Maybe<Array<Scalars['String']['output']>>;
  labRecommendationId?: Maybe<Array<Scalars['String']['output']>>;
  requiresFasting?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeLabsRecommendationSave */
export type OfficeLabsRecommendationSaveInput = {
  attributes: LabRecommendationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsRecommendationSave. */
export type OfficeLabsRecommendationSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationSaveError;
  labRecommendation?: Maybe<LabsRecommendation>;
};

/** Autogenerated error type of OfficeLabsRecommendationSendPatientInstructions */
export type OfficeLabsRecommendationSendPatientInstructionsError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsRecommendationSendPatientInstructions */
export type OfficeLabsRecommendationSendPatientInstructionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isText?: InputMaybe<Scalars['Boolean']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  patientNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsRecommendationSendPatientInstructions. */
export type OfficeLabsRecommendationSendPatientInstructionsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationSendPatientInstructionsError;
};

/** Autogenerated error type of OfficeLabsRecommendationSendRequisition */
export type OfficeLabsRecommendationSendRequisitionError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsRecommendationSendRequisition */
export type OfficeLabsRecommendationSendRequisitionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isText?: InputMaybe<Scalars['Boolean']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  patientNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsRecommendationSendRequisition. */
export type OfficeLabsRecommendationSendRequisitionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsRecommendationSendRequisitionError;
};

/** Autogenerated error type of OfficeLabsWaitlistsJoin */
export type OfficeLabsWaitlistsJoinError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeLabsWaitlistsJoin */
export type OfficeLabsWaitlistsJoinInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeLabsWaitlistsJoin. */
export type OfficeLabsWaitlistsJoinPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeLabsWaitlistsJoinError;
  practitioner?: Maybe<Practitioner>;
};

/** Autogenerated error type of OfficeMultiPatientPlanTemplateApply */
export type OfficeMultiPatientPlanTemplateApplyError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeMultiPatientPlanTemplateApply */
export type OfficeMultiPatientPlanTemplateApplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeMultiPatientPlanTemplateApply. */
export type OfficeMultiPatientPlanTemplateApplyPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Error object */
  errors: OfficeMultiPatientPlanTemplateApplyError;
  /** The treatment plan that the template is applied to */
  multiPatientPlan?: Maybe<MultiPatientPlan>;
};

/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutation = {
  /** Create an address for an office */
  addressCreate?: Maybe<OfficeAddressCreatePayload>;
  /** Delete an address for an office */
  addressDelete?: Maybe<OfficeAddressDeletePayload>;
  /** Update an address for an office */
  addressUpdate?: Maybe<OfficeAddressUpdatePayload>;
  /** Patient relink for API embedded events */
  apiEmbeddableEventPatientRelink?: Maybe<OfficeApiEmbeddableEventPatientRelinkPayload>;
  /** Patient selected for API embedded events */
  apiEmbeddableEventPatientSelected?: Maybe<OfficeApiEmbeddableEventPatientSelectedPayload>;
  /** Accept BAA */
  baaFullscriptAcceptAgreement?: Maybe<BaaFullscriptAcceptAgreementPayload>;
  /** Checks and updates if the document has been signed on Docusign */
  baaFullscriptCheckAgreementStatus?: Maybe<BaaFullscriptCheckAgreementStatusPayload>;
  /** Generates a docusign BAA document for the practitioner to sign */
  baaGenerateAgreement?: Maybe<BaaGenerateAgreementPayload>;
  /**
   * Assigns a practitioner to a list of patients.
   * It also supports assigning a practitioners with a given search filter and patients to exclude.
   */
  bulkPatientAssignPractitioner?: Maybe<OfficeBulkPatientAssignPractitionerPayload>;
  /**
   * Unassigns a practitioner from a list of patients.
   * It also supports removing a practitioners assignment with a given search filter and patients to exclude.
   */
  bulkPatientReassignPractitioner?: Maybe<OfficeBulkPatientReassignPractitionerPayload>;
  /** Bulk edit discount of given patient ids */
  bulkSavingsUpdate?: Maybe<OfficeBulkSavingsUpdatePayload>;
  /** Bulk edit discount of given filter with excluded patient ids */
  bulkSavingsUpdateAll?: Maybe<OfficeBulkSavingsUpdateAllPayload>;
  /** Asynchronously bulk swaps products for the current practitioner and optionally notifies all patients with affected plans. */
  bulkSwapVariant?: Maybe<OfficeProductSwappingBulkSwapVariantPayload>;
  /** Get product daily intake with hierarchy */
  catalogGetProductDailyIntake?: Maybe<OfficeCatalogGetProductDailyIntakePayload>;
  /** Get product monthly cost based on amount and frequency */
  catalogGetProductMonthlyCost?: Maybe<OfficeCatalogGetProductMonthlyCostPayload>;
  /** Delete a category for an office */
  categoryDelete?: Maybe<OfficeCategoryDeletePayload>;
  /** Saves a category of a store */
  categorySave?: Maybe<OfficeCategorySavePayload>;
  /** Archives a clerk */
  clerkArchive?: Maybe<ClerkArchivePayload>;
  /** Restores clerk (makes them unarchived) */
  clerkRestore?: Maybe<ClerkRestorePayload>;
  /** Updates clerk information */
  clerkUpdate?: Maybe<ClerkUpdatePayload>;
  /** Creates a draft template */
  createTemplateDraft?: Maybe<OfficeTemplateCreateDraftPayload>;
  /** Saves the default dosage instruction of a variant */
  defaultDosageInstructionSave?: Maybe<OfficeDefaultDosageInstructionSavePayload>;
  /** Delete a document attachment for an office */
  documentAttachmentDelete?: Maybe<OfficeDocumentAttachmentDeletePayload>;
  /** Saves the document attachment of a treatment plan */
  documentAttachmentSave?: Maybe<OfficeDocumentAttachmentSavePayload>;
  /** Undo a document attachment for an office */
  documentAttachmentUndo?: Maybe<OfficeDocumentAttachmentUndoPayload>;
  /** Resets a draft template */
  draftTemplateReset?: Maybe<OfficeTemplatesDraftResetPayload>;
  /** Resets a draft treatment plan */
  draftTreatmentPlanReset?: Maybe<OfficeTreatmentPlansDraftResetPayload>;
  /** Add dynamic daily pack templates to a treatment plan or a multi-patient plan */
  dynamicDailyPacksAddTemplateToPlan?: Maybe<AddDynamicDailyPackTemplateToPlanPayload>;
  /** Add dynamic daily packs to a treatment plan or a multi-patient plan */
  dynamicDailyPacksAddToTreatmentPlan?: Maybe<AddDynamicDailyPackToTreatmentPlanPayload>;
  /** Delete a daily pack template */
  dynamicDailyPacksDeleteDailyPackTemplate?: Maybe<DeleteDailyPackTemplatePayload>;
  /** Create and update dynamic daily packs */
  dynamicDailyPacksManage?: Maybe<ManageDynamicDailyPackPayload>;
  /** Modify the type of a daily pack */
  dynamicDailyPacksModifyType?: Maybe<ModifyPayload>;
  /** Save daily pack as a template */
  dynamicDailyPacksSaveDailyPackTemplate?: Maybe<SaveDailyPackTemplatePayload>;
  /** Unlink the current mapping of a FS patient, and link to a new Elation patient */
  elationLinkPatient?: Maybe<ElationLinkPatientPayload>;
  /** Link with external Elation Physician */
  elationLinkPhysician?: Maybe<ElationLinkPhysicianPayload>;
  /** Practitioner link with external Elation Physician */
  elationLinkPractitioner?: Maybe<ElationLinkPractitionerPayload>;
  /** Deactivates external EHR integrations */
  externalMetadatumDeactivate?: Maybe<OfficeExternalMetadatumDeactivatePayload>;
  /** Add a product to favorites */
  favoriteProductAdd?: Maybe<FavoriteProductAddPayload>;
  /** Add a product to favorites */
  favoriteProductRemove?: Maybe<FavoriteProductRemovePayload>;
  /** Submit an integration authorization consent form. */
  integrationAuthorizationConsentsCreate?: Maybe<IntegrationAuthorizationConsentsCreatePayload>;
  /** Disconnect an integration listing for an office */
  integrationListingDisconnect?: Maybe<OfficeIntegrationListingDisconnectPayload>;
  /** Generates a PDF of the interpretation */
  labFulfillemtsLabResultInterpretationGeneratePdf?: Maybe<OfficeLabsJourneysLabResultInterpretationsGeneratePdfPayload>;
  /** Toggles the area of concern of an interpretation of a biomarker result. */
  labFulfillmentsLabBiomarkerResultInterpretationToggleAreaOfConcern?: Maybe<OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernPayload>;
  /** Updates fields on the lab_biomarker_result_interpretation object. */
  labFulfillmentsLabBiomarkerResultInterpretationUpdate?: Maybe<OfficeLabsJourneysBiomarkerResultInterpretationsUpdatePayload>;
  /** Releases the interpretation to the patient */
  labFulfillmentsLabResultInterpretationReleaseToPatient?: Maybe<OfficeLabsJourneysLabResultInterpretationsReleaseToPatientPayload>;
  /** Updates fields on the lab_result_interpretation object. */
  labFulfillmentsLabResultInterpretationUpdate?: Maybe<OfficeLabsJourneysInterpretationsUpdatePayload>;
  /** Add a lab test to a plan */
  labsAddTestToPlan?: Maybe<OfficeLabsAddTestToPlanPayload>;
  /** Adds a test to a practitioner's favorites */
  labsFavoritesAdd?: Maybe<OfficeLabsFavoritesAddPayload>;
  /** Adds a kit to a practitioner's favorites */
  labsFavoritesKitsAdd?: Maybe<OfficeLabsFavoritesKitsAddPayload>;
  /** Rmoves a kit from a practitioner's favorites */
  labsFavoritesKitsRemove?: Maybe<OfficeLabsFavoritesKitsRemovePayload>;
  /** Rmoves a test from a practitioner's favorites */
  labsFavoritesRemove?: Maybe<OfficeLabsFavoritesRemovePayload>;
  /** Adds a Journey to a plan */
  labsJourneysAddToPlan?: Maybe<OfficeLabsJourneysAddToPlanPayload>;
  /** Cancels a Journey subscription */
  labsJourneysCancelSubscription?: Maybe<OfficeLabsJourneysCancelSubscriptionPayload>;
  /** Removes a Journey from a plan */
  labsJourneysRemoveFromPlan?: Maybe<OfficeLabsJourneysRemoveFromPlanPayload>;
  /** Updates the details of a Journey subscription */
  labsJourneysUpdateDetails?: Maybe<OfficeLabsJourneysUpdateDetailsPayload>;
  /** Updates the frequency of a Journey subscription */
  labsJourneysUpdateFrequency?: Maybe<OfficeLabsJourneysUpdateFrequencyPayload>;
  /** Activates a kit */
  labsKitsActivate?: Maybe<OfficeLabsKitsActivatePayload>;
  /** Verifies an activation code */
  labsKitsVerifyCode?: Maybe<OfficeLabsKitsVerifyCodePayload>;
  /** Records when a practitioner agrees to a labs agreement */
  labsOnboardingAcceptAgreement?: Maybe<LabsOnboardingAcceptAgreementPayload>;
  /** Generates a docusign BAA document for the practitioner to sign */
  labsOnboardingGenerateBaaAgreement?: Maybe<LabsOnboardingGenerateBaaAgreementPayload>;
  /** Activates a draft lab panel */
  labsPanelActivate?: Maybe<OfficeLabsPanelsActivatePayload>;
  /** Adds a test to a lab panel */
  labsPanelAddTest?: Maybe<OfficeLabsPanelsAddTestPayload>;
  /** Applys a panel to a plan */
  labsPanelApplyToPlan?: Maybe<OfficeLabsPanelsApplyToPlanPayload>;
  /** Cancels an active lab panel */
  labsPanelCancel?: Maybe<OfficeLabsPanelsCancelPayload>;
  /** Duplicates a lab panel */
  labsPanelDuplicate?: Maybe<OfficeLabsPanelsDuplicatePayload>;
  /** Finds or creates a draft lab panel */
  labsPanelFindOrCreateDraft?: Maybe<OfficeLabsPanelsFindOrCreateDraftPayload>;
  /** Removes a panel's tests from a plan */
  labsPanelRemoveFromPlan?: Maybe<OfficeLabsPanelsRemoveFromPlanPayload>;
  /** Removes a test from a lab panel */
  labsPanelRemoveTest?: Maybe<OfficeLabsPanelsRemoveTestPayload>;
  /** Saves lab panel */
  labsPanelSave?: Maybe<OfficeLabsPanelsSavePayload>;
  /** Trigger Iterable registration_viewed event for a practitioner */
  labsPractitionersRegistrationViewTrigger?: Maybe<LabsPractitionersRegistrationViewTriggerPayload>;
  /** Reviews a lab result */
  labsPractitionersReviewResult?: Maybe<LabsPractitionersReviewResultPayload>;
  /** Updates the practitioner settings for labs */
  labsPractitionersSettingsUpdate?: Maybe<LabsPractitionersSettingsUpdatePayload>;
  /** Add an activation_code to a recommendation */
  labsRecommendationAddActivationCode?: Maybe<OfficeLabsRecommendationAddActivationCodePayload>;
  /** Update the custom fee of a recommendation */
  labsRecommendationCustomFeesUpdate?: Maybe<OfficeLabsRecommendationCustomFeesUpdatePayload>;
  /** Delete a lab recommendation from a plan */
  labsRecommendationDelete?: Maybe<OfficeLabsRecommendationDeletePayload>;
  /** Delete a test from a treatment plan */
  labsRecommendationDeleteTest?: Maybe<OfficeLabsRecommendationDeleteTestPayload>;
  /** Removes the activation_code from a recommendation */
  labsRecommendationRemoveActivationCode?: Maybe<OfficeLabsRecommendationRemoveActivationCodePayload>;
  /** Save a treatment plan's lab recommendations */
  labsRecommendationSave?: Maybe<OfficeLabsRecommendationSavePayload>;
  /** Save a lab recommendation as a panel */
  labsRecommendationSaveAsPanel?: Maybe<OfficeLabsRecommendationSaveAsPanelPayload>;
  /** Sends patient instructions to patient for a lab recommendation */
  labsRecommendationSendPatientInstructions?: Maybe<OfficeLabsRecommendationSendPatientInstructionsPayload>;
  /** Sends requisition link to patient for a lab recommendation */
  labsRecommendationSendRequisition?: Maybe<OfficeLabsRecommendationSendRequisitionPayload>;
  /** Practitioners join the labs waitlist */
  labsWaitlistJoin?: Maybe<OfficeLabsWaitlistsJoinPayload>;
  /** Applies a template to an existing treatment plan */
  multiPatientPlanTemplateApply?: Maybe<OfficeMultiPatientPlanTemplateApplyPayload>;
  /** Activates a multi-patient plan plan */
  multiPatientPlansActivate?: Maybe<MultiPatientPlansActivatePayload>;
  /**
   * Creates a multi patient plan when given a list of patient ids.
   * It also supports creating multi patient plans when given a search filter and excluding patients.
   */
  multiPatientPlansCreate?: Maybe<MultiPatientPlansCreatePayload>;
  /** Creates a multi patient plan given a search filter and excluding patients */
  multiPatientPlansCreateAll?: Maybe<MultiPatientPlansCreateAllPayload>;
  /** Deletes a multi patient plan */
  multiPatientPlansDelete?: Maybe<MultiPatientPlansDeletePayload>;
  /** Resets a draft multi-patient plan */
  multiPatientPlansDraftReset?: Maybe<MultiPatientPlansDraftResetPayload>;
  /** Updates a multi-patient plan */
  multiPatientPlansUpdate?: Maybe<MultiPatientPlansUpdatePayload>;
  /** Updates who has access to a multi patient plan */
  multiPatientPlansUpdateAccess?: Maybe<MultiPatientPlansUpdateAccessPayload>;
  /** Logs when an interaction step happens with the onboarding modal */
  onboardingModalLog?: Maybe<OnboardingModalLogPayload>;
  /**
   * Deprecated
   * @deprecated Use retailCheckoutAddToCart instead.
   */
  orderPopulateFromTreatmentPlan?: Maybe<OfficeOrderPopulateFromTreatmentPlanPayload>;
  /**
   * Deprecated
   * @deprecated Use retailCheckoutAddToCart instead.
   */
  patientAddToBasket?: Maybe<OfficePatientAddToBasketPayload>;
  /** Update patient import entries */
  patientImportEntriesUpdate?: Maybe<OfficePatientImportEntriesUpdatePayload>;
  /** Expire patient import */
  patientImportExpire?: Maybe<OfficePatientImportExpirePayload>;
  /** Process a patient import */
  patientImportProcess?: Maybe<OfficePatientImportProcessPayload>;
  /** Save a patient import */
  patientImportSave?: Maybe<OfficePatientImportSavePayload>;
  /** Mutation to log the view cart event for in office checkout */
  patientLogViewCart?: Maybe<OfficePatientLogViewCartPayload>;
  /** Save changes to attributes of a patient */
  patientSave?: Maybe<OfficePatientSavePayload>;
  /** Deactivate (and unlink) a patient patient's metadata (used for External API integrations) from a patient. */
  patientUnlink?: Maybe<OfficePatientUnlinkPayload>;
  /** Creates a peer referral */
  peerReferralsCreate?: Maybe<PeerReferralsCreatePayload>;
  /** Deletes a peer referral */
  peerReferralsDelete?: Maybe<PeerReferralsDeletePayload>;
  /** Updates the BonusMarginEnabled field for a peer referral program */
  peerReferralsUpdateBonusMarginEnabled?: Maybe<PeerReferralsUpdateBonusMarginEnabledPayload>;
  /** Converts a plan from one type to another */
  plansConvert?: Maybe<OfficesPlansConvertPayload>;
  /**
   * Archives a practitioner. If a substitute practitioner is provided,
   * the patients assigned to the archived practitioner will be reassigned to the substitute practitioner.
   */
  practitionerArchive?: Maybe<PractitionerArchivePayload>;
  /** Destroys a document */
  practitionerDocumentDelete?: Maybe<OfficePractitionerDocumentDeletePayload>;
  /** Saves a practitioner's document */
  practitionerDocumentSave?: Maybe<OfficePractitionerDocumentSavePayload>;
  /** Updates practitioner email notifcations */
  practitionerNotificationSettingsUpdate?: Maybe<NotificationSettingsUpdatePayload>;
  /**
   * Update the prescribing personalization value for a practitioner
   * @deprecated Use prescribing_personalization_practitioner_update instead.
   */
  practitionerPrescribingPersonalizationToggle?: Maybe<OfficePrescribingPersonalizationPractitionerUpdatePayload>;
  /** Updates practitioner's product availability preference to the specified clerk, or back to themselves. */
  practitionerProductAvailabilityPreferenceUpdate?: Maybe<ProductAvailabilityPreferenceUpdatePayload>;
  /** Updates practitioner's product access request preference to the specified clerk, or back to themselves. */
  practitionerProductRequestPreferenceUpdate?: Maybe<ProductRequestPreferenceUpdatePayload>;
  /** Updates a public profile */
  practitionerPublicProfileUpdate?: Maybe<PublicProfileUpdatePayload>;
  /** Restores practitioner (makes them not archived) */
  practitionerRestore?: Maybe<PractitionerRestorePayload>;
  /** Updates practitioner and store merchant data information */
  practitionerStoreMerchantDataUpdate?: Maybe<PractitionerAndStoreMerchantDataUpdatePayload>;
  /** Toggle fullscript content favourite of practitioner */
  practitionerToggleFullscriptContentFavourite?: Maybe<OfficesPractitionersToggleFullscriptContentFavouritePayload>;
  /** Updates practitioner information */
  practitionerUpdate?: Maybe<PractitionerUpdatePayload>;
  /** Updates practitioner login email */
  practitionerUpdateLoginEmail?: Maybe<PractitionerUpdateLoginEmailPayload>;
  /** Update the prescribing personalization value for a practitioner */
  prescribingPersonalizationPractitionerUpdate?: Maybe<OfficePrescribingPersonalizationPractitionerUpdatePayload>;
  /** Update the prescribing personalization value for a practitioner */
  prescribingPersonalizationStoreUpdate?: Maybe<OfficePrescribingPersonalizationStoreUpdatePayload>;
  /** Associates product to a category */
  productCategorySave?: Maybe<OfficeProductsCategorySavePayload>;
  /** Swaps two products for the current practitioner and optionally notifies all patients with affected treatment plans. */
  productSwappingSwap?: Maybe<OfficeProductSwappingSwapPayload>;
  /** Unswaps two products for the current practitioner. */
  productSwappingUnswap?: Maybe<OfficeProductSwappingUnswapPayload>;
  /** Create feedback for base_product and its alternative_product */
  recommendationAlternativeFeedbacksCreate?: Maybe<OfficeRecommendationAlternativesFeedbackCreatePayload>;
  /** Delete existing feedback */
  recommendationAlternativeFeedbacksDelete?: Maybe<OfficeRecommendationAlternativesFeedbackDeletePayload>;
  /** Discover if 2 or more alternative products exist for the requested product */
  recommendationAlternativesExists?: Maybe<OfficeRecommendationAlternativesExistsPayload>;
  /** Get alternative products based on a recommended product */
  recommendationAlternativesGetProducts?: Maybe<OfficeRecommendationAlternativesGetProductsPayload>;
  /** Delete a plan's recommendations */
  recommendationDelete?: Maybe<OfficeRecommendationDeletePayload>;
  /** Swaps a recommendation's variant */
  recommendationReplaceVariant?: Maybe<OfficeRecommendationReplaceVariantPayload>;
  /** Save a plan's recommendations */
  recommendationSave?: Maybe<OfficeRecommendationSavePayload>;
  /** Asynchronously adds substitutes to multiple affected plans in bulk for the current practitioner */
  recommendationSubstitutesBulkSave?: Maybe<OfficeRecommendationSubstitutesBulkSavePayload>;
  /** Cancels a substitute recommendation. */
  recommendationSubstitutesCancel?: Maybe<OfficeRecommendationSubstitutesCancelPayload>;
  /**
   * Creates substitutes for the main recommendation and creates the main
   * recommendation if necessary (only when creating a recommendation with OOS variant)
   */
  recommendationSubstitutesSave?: Maybe<OfficeRecommendationSubstitutesSavePayload>;
  /** Apply a treatment plan */
  recommendationTreatmentPlanApply?: Maybe<OfficeRecommendationTreatmentPlanApplyPayload>;
  /** Request a new FS Assist feature */
  requestFeature?: Maybe<RequestFeaturePayload>;
  /** Resend invitation for a clerk or practitioner that haven't signed up */
  resendInvitation?: Maybe<OfficeStaffSettingResendInvitationPayload>;
  /** Updates the Store Catalog Permissions */
  settingsCatalogPermissionsUpdate?: Maybe<CatalogPermissionsUpdatePayload>;
  /** Updates the Store Dispensary Information */
  settingsDispensaryInfoUpdate?: Maybe<SettingsDispensaryInfoUpdatePayload>;
  /** Update store settings for friend invites */
  settingsFriendInvitesUpdate?: Maybe<OfficeSettingsFriendInvitesUpdatePayload>;
  /** Updates the Global Patient Access setting */
  settingsGlobalPatientAccessUpdate?: Maybe<GlobalPatientAccessUpdatePayload>;
  /** Update stripe account with TOS acceptance */
  settingsPaymentsAndTaxesAcceptStripeTos?: Maybe<OfficeSettingsPaymentsAndTaxesAcceptStripeTosPayload>;
  /** Setup Merchant data address and financial, along with bank account for the first time for a practitioner. */
  settingsPaymentsAndTaxesSetup?: Maybe<OfficeSettingsPaymentsAndTaxesSetupPayload>;
  /** Update store settings for profit disclosure in emails */
  settingsProfitDisclosureUpdate?: Maybe<OfficeSettingsProfitDisclosureUpdatePayload>;
  /** Updates the access to financial reports for staff */
  settingsReportAccessUpdate?: Maybe<ReportAccessUpdatePayload>;
  /** Update store settings */
  settingsUpdate?: Maybe<OfficeSettingsUpdatePayload>;
  /** Add clerk to store from staff settings */
  staffSettingsClerkCreate?: Maybe<OfficeStaffSettingClerkCreatePayload>;
  /** Create practitioner of store */
  staffSettingsPractitionerCreate?: Maybe<StaffSettingsPractitionerCreatePayload>;
  /** Update practitioner of a store */
  staffSettingsPractitionerUpdate?: Maybe<StaffSettingsPractitionerUpdatePayload>;
  /** Discontinues a static daily pack */
  staticDailyPacksDiscontinue?: Maybe<DiscontinuePayload>;
  /** Create and update static daily packs */
  staticDailyPacksManage?: Maybe<ManageStaticDailyPackPayload>;
  /** Update the current store's onboarding selections */
  storeOnboardingUpdate?: Maybe<StoreOnboardingUpdatePayload>;
  /** Store submits address information */
  storeSetupEmersonSubmitAddressInformation?: Maybe<StoreSetupEmersonSubmitAddressInformationPayload>;
  /** Store uploads certificate */
  storeSetupEmersonSubmitCertUpload?: Maybe<StoreSetupEmersonSubmitCertUploadPayload>;
  /** Completes setup for a store */
  storeSetupMoveSetupToComplete?: Maybe<StoreSetupMoveSetupToCompletePayload>;
  /** Store submits address information */
  storeSetupSubmitAddressInformation?: Maybe<StoreSetupSubmitAddressInformationPayload>;
  /** Store uploads certificate */
  storeSetupSubmitCertUploadV2?: Maybe<StoreSetupSubmitCertUploadV2Payload>;
  /** Store uploads certificate */
  storeSetupSubmitCertUploadV3?: Maybe<StoreSetupSubmitCertUploadV3Payload>;
  /** Wellevate Loyalist store onboarding step for magic cert */
  storeSetupSubmitLoyalistCert?: Maybe<StoreSetupSubmitLoyalistCertPayload>;
  /** Store submits phone and region info */
  storeSetupSubmitStateAndPhoneInformation?: Maybe<StoreSetupSubmitStateAndPhoneInformationPayload>;
  /** Swaps a variant on a specified plan. */
  swapVariant?: Maybe<OfficeProductSwappingSwapVariantPayload>;
  /** Create and update template daily packs */
  templateDailyPacksManage?: Maybe<ManageTemplateDailyPackPayload>;
  /** Create a treatment plan template from a multi patient plan */
  templateFromMultiPatientPlanCreate?: Maybe<OfficeTemplateCreateFromMultiPatientPlanPayload>;
  /** Create a treatment plan template from a treatment plan */
  templateFromTreatmentPlanCreate?: Maybe<OfficeTemplateCreateFromTreatmentPlanPayload>;
  /** Updates a template to be globally available */
  templateShareableGlobalUpdate?: Maybe<OfficeTemplateShareableGlobalUpdatePayload>;
  /** Update shareable with patients */
  templateShareableWithPatientsUpdate?: Maybe<OfficeTemplateShareableWithPatientsUpdatePayload>;
  /** Update shareable with practitioners */
  templateShareableWithPractitionersUpdate?: Maybe<OfficeTemplateShareableWithPractitionersUpdatePayload>;
  /** Activates a treatment plan */
  treatmentPlanActivate?: Maybe<OfficeTreatmentPlanActivatePayload>;
  /** Applies duration to all recommendations in a treatment plan */
  treatmentPlanApplyDurationToAll?: Maybe<OfficeTreatmentPlanApplyDurationToAllPayload>;
  /** Creates a draft treatment plan. */
  treatmentPlanCreateDraft?: Maybe<OfficeTreatmentPlansCreateDraftPayload>;
  /** Saves the message portion of a treatment plan as a draft */
  treatmentPlanDraftMessageSave?: Maybe<OfficeTreatmentPlanDraftMessageSavePayload>;
  /**
   * Find an existing draft treatment plan or creates a new one.
   * @deprecated Use OfficeTreatmentPlansCreateDraft instead.
   */
  treatmentPlanFindOrCreateDraft?: Maybe<OfficeTreatmentPlansFindOrCreateDraftPayload>;
  /** Mutation to log various user actions for treatment plan insights */
  treatmentPlanInsightsLogEvent?: Maybe<TreatmentPlanInsightsLogPayload>;
  /** Update an association between a signing practitioner and a treatment plan */
  treatmentPlanLabsSigningPractitionerUpdate?: Maybe<OfficeTreatmentPlanLabSigningPractitionerUpdatePayload>;
  /** Update an association between a patient and a treatment plan */
  treatmentPlanPatientUpdate?: Maybe<OfficeTreatmentPlanPatientUpdatePayload>;
  /** Delete a treatment plan snippet */
  treatmentPlanSnippetDelete?: Maybe<TreatmentPlanSnippetDeletePayload>;
  /** Create or update a treatment plan snippet */
  treatmentPlanSnippetSave?: Maybe<TreatmentPlanSnippetSavePayload>;
  /** Applies a template to an existing treatment plan */
  treatmentPlanTemplateApply?: Maybe<OfficeTreatmentPlanApplyTemplatePayload>;
  /** Makes a copy of the treatment plan template */
  treatmentPlanTemplateMakeCopy?: Maybe<OfficeTreatmentPlanTemplateMakeCopyPayload>;
  /** Removes a template to an existing treatment plan */
  treatmentPlanTemplateRemove?: Maybe<OfficeTreatmentPlanRemoveTemplatePayload>;
  /** Saves the treatment plan template attributes */
  treatmentPlanTemplateSave?: Maybe<OfficeTreatmentPlanTemplateSavePayload>;
  /** Authenticate practitioner's Wellevate credentials */
  wellevateAuthenticateCredentials?: Maybe<AuthenticateWellevateCredentialsPayload>;
  /** Hide practitioner's migration banner */
  wellevateHideMigrationBanner?: Maybe<HideMigrationBannerPayload>;
  /** Hide not in catalog banner */
  wellevateHideNotInCatalogBanner?: Maybe<HideNotInCatalogBannerPayload>;
  /** Kick-off Wellevate data import for Loyalist segment */
  wellevateImportLoyalistData?: Maybe<ImportLoyalistDataPayload>;
  /** Initiate practitioner's migration */
  wellevateInitiateMigration?: Maybe<InitiateMigrationPayload>;
  /** Create migration for Wellevate loyalists */
  wellevatePractitionerSelfEnroll?: Maybe<PractitionerSelfEnrollPayload>;
  /** Records when a practitioner agrees to a plan writing agreement */
  wholePersonPlanAcceptAgreement?: Maybe<WholePersonPlanAcceptAgreementPayload>;
  /** Generates CertExpress link of a store */
  wholesaleCertExpressLinkGenerate?: Maybe<OfficeWholesalesCertExpressLinkGeneratePayload>;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationAddressCreateArgs = {
  input: OfficeAddressCreateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationAddressDeleteArgs = {
  input: OfficeAddressDeleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationAddressUpdateArgs = {
  input: OfficeAddressUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationApiEmbeddableEventPatientRelinkArgs = {
  input: OfficeApiEmbeddableEventPatientRelinkInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationApiEmbeddableEventPatientSelectedArgs = {
  input: OfficeApiEmbeddableEventPatientSelectedInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationBaaFullscriptAcceptAgreementArgs = {
  input: BaaFullscriptAcceptAgreementInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationBaaFullscriptCheckAgreementStatusArgs = {
  input: BaaFullscriptCheckAgreementStatusInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationBaaGenerateAgreementArgs = {
  input: BaaGenerateAgreementInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationBulkPatientAssignPractitionerArgs = {
  input: OfficeBulkPatientAssignPractitionerInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationBulkPatientReassignPractitionerArgs = {
  input: OfficeBulkPatientReassignPractitionerInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationBulkSavingsUpdateArgs = {
  input: OfficeBulkSavingsUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationBulkSavingsUpdateAllArgs = {
  input: OfficeBulkSavingsUpdateAllInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationBulkSwapVariantArgs = {
  input: OfficeProductSwappingBulkSwapVariantInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationCatalogGetProductDailyIntakeArgs = {
  input: OfficeCatalogGetProductDailyIntakeInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationCatalogGetProductMonthlyCostArgs = {
  input: OfficeCatalogGetProductMonthlyCostInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationCategoryDeleteArgs = {
  input: OfficeCategoryDeleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationCategorySaveArgs = {
  input: OfficeCategorySaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationClerkArchiveArgs = {
  input: ClerkArchiveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationClerkRestoreArgs = {
  input: ClerkRestoreInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationClerkUpdateArgs = {
  input: ClerkUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationCreateTemplateDraftArgs = {
  input: OfficeTemplateCreateDraftInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDefaultDosageInstructionSaveArgs = {
  input: OfficeDefaultDosageInstructionSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDocumentAttachmentDeleteArgs = {
  input: OfficeDocumentAttachmentDeleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDocumentAttachmentSaveArgs = {
  input: OfficeDocumentAttachmentSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDocumentAttachmentUndoArgs = {
  input: OfficeDocumentAttachmentUndoInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDraftTemplateResetArgs = {
  input: OfficeTemplatesDraftResetInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDraftTreatmentPlanResetArgs = {
  input: OfficeTreatmentPlansDraftResetInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDynamicDailyPacksAddTemplateToPlanArgs = {
  input: AddDynamicDailyPackTemplateToPlanInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDynamicDailyPacksAddToTreatmentPlanArgs = {
  input: AddDynamicDailyPackToTreatmentPlanInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDynamicDailyPacksDeleteDailyPackTemplateArgs = {
  input: DeleteDailyPackTemplateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDynamicDailyPacksManageArgs = {
  input: ManageDynamicDailyPackInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDynamicDailyPacksModifyTypeArgs = {
  input: ModifyInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationDynamicDailyPacksSaveDailyPackTemplateArgs = {
  input: SaveDailyPackTemplateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationElationLinkPatientArgs = {
  input: ElationLinkPatientInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationElationLinkPhysicianArgs = {
  input: ElationLinkPhysicianInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationElationLinkPractitionerArgs = {
  input: ElationLinkPractitionerInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationExternalMetadatumDeactivateArgs = {
  input: OfficeExternalMetadatumDeactivateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationFavoriteProductAddArgs = {
  input: FavoriteProductAddInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationFavoriteProductRemoveArgs = {
  input: FavoriteProductRemoveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationIntegrationAuthorizationConsentsCreateArgs = {
  input: IntegrationAuthorizationConsentsCreateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationIntegrationListingDisconnectArgs = {
  input: OfficeIntegrationListingDisconnectInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabFulfillemtsLabResultInterpretationGeneratePdfArgs = {
  input: OfficeLabsJourneysLabResultInterpretationsGeneratePdfInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabFulfillmentsLabBiomarkerResultInterpretationToggleAreaOfConcernArgs = {
  input: OfficeLabsJourneysBiomarkerInterpretationsToggleAreaOfConcernInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabFulfillmentsLabBiomarkerResultInterpretationUpdateArgs = {
  input: OfficeLabsJourneysBiomarkerResultInterpretationsUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabFulfillmentsLabResultInterpretationReleaseToPatientArgs = {
  input: OfficeLabsJourneysLabResultInterpretationsReleaseToPatientInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabFulfillmentsLabResultInterpretationUpdateArgs = {
  input: OfficeLabsJourneysInterpretationsUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsAddTestToPlanArgs = {
  input: OfficeLabsAddTestToPlanInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsFavoritesAddArgs = {
  input: OfficeLabsFavoritesAddInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsFavoritesKitsAddArgs = {
  input: OfficeLabsFavoritesKitsAddInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsFavoritesKitsRemoveArgs = {
  input: OfficeLabsFavoritesKitsRemoveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsFavoritesRemoveArgs = {
  input: OfficeLabsFavoritesRemoveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsJourneysAddToPlanArgs = {
  input: OfficeLabsJourneysAddToPlanInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsJourneysCancelSubscriptionArgs = {
  input: OfficeLabsJourneysCancelSubscriptionInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsJourneysRemoveFromPlanArgs = {
  input: OfficeLabsJourneysRemoveFromPlanInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsJourneysUpdateDetailsArgs = {
  input: OfficeLabsJourneysUpdateDetailsInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsJourneysUpdateFrequencyArgs = {
  input: OfficeLabsJourneysUpdateFrequencyInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsKitsActivateArgs = {
  input: OfficeLabsKitsActivateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsKitsVerifyCodeArgs = {
  input: OfficeLabsKitsVerifyCodeInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsOnboardingAcceptAgreementArgs = {
  input: LabsOnboardingAcceptAgreementInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsOnboardingGenerateBaaAgreementArgs = {
  input: LabsOnboardingGenerateBaaAgreementInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPanelActivateArgs = {
  input: OfficeLabsPanelsActivateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPanelAddTestArgs = {
  input: OfficeLabsPanelsAddTestInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPanelApplyToPlanArgs = {
  input: OfficeLabsPanelsApplyToPlanInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPanelCancelArgs = {
  input: OfficeLabsPanelsCancelInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPanelDuplicateArgs = {
  input: OfficeLabsPanelsDuplicateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPanelFindOrCreateDraftArgs = {
  input: OfficeLabsPanelsFindOrCreateDraftInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPanelRemoveFromPlanArgs = {
  input: OfficeLabsPanelsRemoveFromPlanInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPanelRemoveTestArgs = {
  input: OfficeLabsPanelsRemoveTestInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPanelSaveArgs = {
  input: OfficeLabsPanelsSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPractitionersRegistrationViewTriggerArgs = {
  input: LabsPractitionersRegistrationViewTriggerInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPractitionersReviewResultArgs = {
  input: LabsPractitionersReviewResultInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsPractitionersSettingsUpdateArgs = {
  input: LabsPractitionersSettingsUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsRecommendationAddActivationCodeArgs = {
  input: OfficeLabsRecommendationAddActivationCodeInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsRecommendationCustomFeesUpdateArgs = {
  input: OfficeLabsRecommendationCustomFeesUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsRecommendationDeleteArgs = {
  input: OfficeLabsRecommendationDeleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsRecommendationDeleteTestArgs = {
  input: OfficeLabsRecommendationDeleteTestInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsRecommendationRemoveActivationCodeArgs = {
  input: OfficeLabsRecommendationRemoveActivationCodeInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsRecommendationSaveArgs = {
  input: OfficeLabsRecommendationSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsRecommendationSaveAsPanelArgs = {
  input: OfficeLabsRecommendationSaveAsPanelInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsRecommendationSendPatientInstructionsArgs = {
  input: OfficeLabsRecommendationSendPatientInstructionsInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsRecommendationSendRequisitionArgs = {
  input: OfficeLabsRecommendationSendRequisitionInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationLabsWaitlistJoinArgs = {
  input: OfficeLabsWaitlistsJoinInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationMultiPatientPlanTemplateApplyArgs = {
  input: OfficeMultiPatientPlanTemplateApplyInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationMultiPatientPlansActivateArgs = {
  input: MultiPatientPlansActivateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationMultiPatientPlansCreateArgs = {
  input: MultiPatientPlansCreateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationMultiPatientPlansCreateAllArgs = {
  input: MultiPatientPlansCreateAllInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationMultiPatientPlansDeleteArgs = {
  input: MultiPatientPlansDeleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationMultiPatientPlansDraftResetArgs = {
  input: MultiPatientPlansDraftResetInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationMultiPatientPlansUpdateArgs = {
  input: MultiPatientPlansUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationMultiPatientPlansUpdateAccessArgs = {
  input: MultiPatientPlansUpdateAccessInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationOnboardingModalLogArgs = {
  input: OnboardingModalLogInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationOrderPopulateFromTreatmentPlanArgs = {
  input: OfficeOrderPopulateFromTreatmentPlanInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPatientAddToBasketArgs = {
  input: OfficePatientAddToBasketInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPatientImportEntriesUpdateArgs = {
  input: OfficePatientImportEntriesUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPatientImportExpireArgs = {
  input: OfficePatientImportExpireInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPatientImportProcessArgs = {
  input: OfficePatientImportProcessInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPatientImportSaveArgs = {
  input: OfficePatientImportSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPatientLogViewCartArgs = {
  input: OfficePatientLogViewCartInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPatientSaveArgs = {
  input: OfficePatientSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPatientUnlinkArgs = {
  input: OfficePatientUnlinkInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPeerReferralsCreateArgs = {
  input: PeerReferralsCreateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPeerReferralsDeleteArgs = {
  input: PeerReferralsDeleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPeerReferralsUpdateBonusMarginEnabledArgs = {
  input: PeerReferralsUpdateBonusMarginEnabledInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPlansConvertArgs = {
  input: OfficesPlansConvertInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerArchiveArgs = {
  input: PractitionerArchiveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerDocumentDeleteArgs = {
  input: OfficePractitionerDocumentDeleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerDocumentSaveArgs = {
  input: OfficePractitionerDocumentSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerNotificationSettingsUpdateArgs = {
  input: NotificationSettingsUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerPrescribingPersonalizationToggleArgs = {
  input: OfficePrescribingPersonalizationPractitionerUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerProductAvailabilityPreferenceUpdateArgs = {
  input: ProductAvailabilityPreferenceUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerProductRequestPreferenceUpdateArgs = {
  input: ProductRequestPreferenceUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerPublicProfileUpdateArgs = {
  input: PublicProfileUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerRestoreArgs = {
  input: PractitionerRestoreInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerStoreMerchantDataUpdateArgs = {
  input: PractitionerAndStoreMerchantDataUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerToggleFullscriptContentFavouriteArgs = {
  input: OfficesPractitionersToggleFullscriptContentFavouriteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerUpdateArgs = {
  input: PractitionerUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPractitionerUpdateLoginEmailArgs = {
  input: PractitionerUpdateLoginEmailInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPrescribingPersonalizationPractitionerUpdateArgs = {
  input: OfficePrescribingPersonalizationPractitionerUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationPrescribingPersonalizationStoreUpdateArgs = {
  input: OfficePrescribingPersonalizationStoreUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationProductCategorySaveArgs = {
  input: OfficeProductsCategorySaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationProductSwappingSwapArgs = {
  input: OfficeProductSwappingSwapInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationProductSwappingUnswapArgs = {
  input: OfficeProductSwappingUnswapInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationAlternativeFeedbacksCreateArgs = {
  input: OfficeRecommendationAlternativesFeedbackCreateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationAlternativeFeedbacksDeleteArgs = {
  input: OfficeRecommendationAlternativesFeedbackDeleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationAlternativesExistsArgs = {
  input: OfficeRecommendationAlternativesExistsInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationAlternativesGetProductsArgs = {
  input: OfficeRecommendationAlternativesGetProductsInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationDeleteArgs = {
  input: OfficeRecommendationDeleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationReplaceVariantArgs = {
  input: OfficeRecommendationReplaceVariantInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationSaveArgs = {
  input: OfficeRecommendationSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationSubstitutesBulkSaveArgs = {
  input: OfficeRecommendationSubstitutesBulkSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationSubstitutesCancelArgs = {
  input: OfficeRecommendationSubstitutesCancelInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationSubstitutesSaveArgs = {
  input: OfficeRecommendationSubstitutesSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRecommendationTreatmentPlanApplyArgs = {
  input: OfficeRecommendationTreatmentPlanApplyInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationRequestFeatureArgs = {
  input: RequestFeatureInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationResendInvitationArgs = {
  input: OfficeStaffSettingResendInvitationInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationSettingsCatalogPermissionsUpdateArgs = {
  input: CatalogPermissionsUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationSettingsDispensaryInfoUpdateArgs = {
  input: SettingsDispensaryInfoUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationSettingsFriendInvitesUpdateArgs = {
  input: OfficeSettingsFriendInvitesUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationSettingsGlobalPatientAccessUpdateArgs = {
  input: GlobalPatientAccessUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationSettingsPaymentsAndTaxesAcceptStripeTosArgs = {
  input: OfficeSettingsPaymentsAndTaxesAcceptStripeTosInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationSettingsPaymentsAndTaxesSetupArgs = {
  input: OfficeSettingsPaymentsAndTaxesSetupInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationSettingsProfitDisclosureUpdateArgs = {
  input: OfficeSettingsProfitDisclosureUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationSettingsReportAccessUpdateArgs = {
  input: ReportAccessUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationSettingsUpdateArgs = {
  input: OfficeSettingsUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStaffSettingsClerkCreateArgs = {
  input: OfficeStaffSettingClerkCreateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStaffSettingsPractitionerCreateArgs = {
  input: StaffSettingsPractitionerCreateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStaffSettingsPractitionerUpdateArgs = {
  input: StaffSettingsPractitionerUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStaticDailyPacksDiscontinueArgs = {
  input: DiscontinueInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStaticDailyPacksManageArgs = {
  input: ManageStaticDailyPackInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStoreOnboardingUpdateArgs = {
  input: StoreOnboardingUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStoreSetupEmersonSubmitAddressInformationArgs = {
  input: StoreSetupEmersonSubmitAddressInformationInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStoreSetupEmersonSubmitCertUploadArgs = {
  input: StoreSetupEmersonSubmitCertUploadInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStoreSetupMoveSetupToCompleteArgs = {
  input: StoreSetupMoveSetupToCompleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStoreSetupSubmitAddressInformationArgs = {
  input: StoreSetupSubmitAddressInformationInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStoreSetupSubmitCertUploadV2Args = {
  input: StoreSetupSubmitCertUploadV2Input;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStoreSetupSubmitCertUploadV3Args = {
  input: StoreSetupSubmitCertUploadV3Input;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStoreSetupSubmitLoyalistCertArgs = {
  input: StoreSetupSubmitLoyalistCertInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationStoreSetupSubmitStateAndPhoneInformationArgs = {
  input: StoreSetupSubmitStateAndPhoneInformationInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationSwapVariantArgs = {
  input: OfficeProductSwappingSwapVariantInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTemplateDailyPacksManageArgs = {
  input: ManageTemplateDailyPackInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTemplateFromMultiPatientPlanCreateArgs = {
  input: OfficeTemplateCreateFromMultiPatientPlanInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTemplateFromTreatmentPlanCreateArgs = {
  input: OfficeTemplateCreateFromTreatmentPlanInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTemplateShareableGlobalUpdateArgs = {
  input: OfficeTemplateShareableGlobalUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTemplateShareableWithPatientsUpdateArgs = {
  input: OfficeTemplateShareableWithPatientsUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTemplateShareableWithPractitionersUpdateArgs = {
  input: OfficeTemplateShareableWithPractitionersUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanActivateArgs = {
  input: OfficeTreatmentPlanActivateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanApplyDurationToAllArgs = {
  input: OfficeTreatmentPlanApplyDurationToAllInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanCreateDraftArgs = {
  input: OfficeTreatmentPlansCreateDraftInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanDraftMessageSaveArgs = {
  input: OfficeTreatmentPlanDraftMessageSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanFindOrCreateDraftArgs = {
  input: OfficeTreatmentPlansFindOrCreateDraftInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanInsightsLogEventArgs = {
  input: TreatmentPlanInsightsLogInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanLabsSigningPractitionerUpdateArgs = {
  input: OfficeTreatmentPlanLabSigningPractitionerUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanPatientUpdateArgs = {
  input: OfficeTreatmentPlanPatientUpdateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanSnippetDeleteArgs = {
  input: TreatmentPlanSnippetDeleteInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanSnippetSaveArgs = {
  input: TreatmentPlanSnippetSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanTemplateApplyArgs = {
  input: OfficeTreatmentPlanApplyTemplateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanTemplateMakeCopyArgs = {
  input: OfficeTreatmentPlanTemplateMakeCopyInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanTemplateRemoveArgs = {
  input: OfficeTreatmentPlanRemoveTemplateInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationTreatmentPlanTemplateSaveArgs = {
  input: OfficeTreatmentPlanTemplateSaveInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationWellevateAuthenticateCredentialsArgs = {
  input: AuthenticateWellevateCredentialsInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationWellevateHideMigrationBannerArgs = {
  input: HideMigrationBannerInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationWellevateHideNotInCatalogBannerArgs = {
  input: HideNotInCatalogBannerInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationWellevateImportLoyalistDataArgs = {
  input: ImportLoyalistDataInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationWellevateInitiateMigrationArgs = {
  input: InitiateMigrationInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationWellevatePractitionerSelfEnrollArgs = {
  input: PractitionerSelfEnrollInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationWholePersonPlanAcceptAgreementArgs = {
  input: WholePersonPlanAcceptAgreementInput;
};


/** Mutation domain for practitioners, clerks and store owners */
export type OfficeMutationWholesaleCertExpressLinkGenerateArgs = {
  input: OfficeWholesalesCertExpressLinkGenerateInput;
};

/** Attributes for populating an order from a treatment plan. */
export type OfficeOrderPopulateFromTreatmentPlanAttributes = {
  resetOrder?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated error type of OfficeOrderPopulateFromTreatmentPlan */
export type OfficeOrderPopulateFromTreatmentPlanError = {
  /** Field errors */
  fields?: Maybe<OfficeOrderPopulateFromTreatmentPlanFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeOrderPopulateFromTreatmentPlan */
export type OfficeOrderPopulateFromTreatmentPlanFieldError = {
  resetOrder?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeOrderPopulateFromTreatmentPlan */
export type OfficeOrderPopulateFromTreatmentPlanInput = {
  attributes: OfficeOrderPopulateFromTreatmentPlanAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeOrderPopulateFromTreatmentPlan. */
export type OfficeOrderPopulateFromTreatmentPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeOrderPopulateFromTreatmentPlanError;
  order?: Maybe<Order>;
};

/** Autogenerated error type of OfficePatientAddToBasket */
export type OfficePatientAddToBasketError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficePatientAddToBasket */
export type OfficePatientAddToBasketInput = {
  cart: Array<CartItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of patient to add line items to */
  patientId: Scalars['String']['input'];
};

/** Autogenerated return type of OfficePatientAddToBasket. */
export type OfficePatientAddToBasketPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficePatientAddToBasketError;
  inOfficeBasket?: Maybe<Order>;
};

/** Attributes for updating a store's patient */
export type OfficePatientAttributes = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstOrderDiscount?: InputMaybe<Scalars['Int']['input']>;
  intake?: InputMaybe<Scalars['Boolean']['input']>;
  isMinorDependent?: InputMaybe<Scalars['Boolean']['input']>;
  labsTosAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  sex?: InputMaybe<Scalars['String']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of OfficePatientImportEntriesUpdate */
export type OfficePatientImportEntriesUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficePatientImportEntriesUpdate */
export type OfficePatientImportEntriesUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientImportEntries?: Array<PatientImportEntryAttributes>;
};

/** Autogenerated return type of OfficePatientImportEntriesUpdate. */
export type OfficePatientImportEntriesUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficePatientImportEntriesUpdateError>;
  patientImportEntries?: Maybe<Array<PatientImportEntry>>;
};

/** Autogenerated error type of OfficePatientImportExpire */
export type OfficePatientImportExpireError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficePatientImportExpire */
export type OfficePatientImportExpireInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientImportId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficePatientImportExpire. */
export type OfficePatientImportExpirePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficePatientImportExpireError;
  patientImport?: Maybe<PatientImport>;
};

/** Attributes related to process a patient import */
export type OfficePatientImportProcessAttributes = {
  patientImportId: Scalars['ID']['input'];
  sendInvitation: Scalars['Boolean']['input'];
};

/** Autogenerated error type of OfficePatientImportProcess */
export type OfficePatientImportProcessError = {
  /** Field errors */
  fields?: Maybe<OfficePatientImportProcessFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficePatientImportProcess */
export type OfficePatientImportProcessFieldError = {
  patientImportId?: Maybe<Array<Scalars['String']['output']>>;
  sendInvitation?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficePatientImportProcess */
export type OfficePatientImportProcessInput = {
  attributes: OfficePatientImportProcessAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficePatientImportProcess. */
export type OfficePatientImportProcessPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficePatientImportProcessError>;
  patientImport?: Maybe<PatientImport>;
};

/** Autogenerated error type of OfficePatientImportSave */
export type OfficePatientImportSaveError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficePatientImportSave */
export type OfficePatientImportSaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficePatientImportSave. */
export type OfficePatientImportSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficePatientImportSaveError>;
  patientImport?: Maybe<PatientImport>;
};

/** Autogenerated error type of OfficePatientLogViewCart */
export type OfficePatientLogViewCartError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficePatientLogViewCart */
export type OfficePatientLogViewCartInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Entry point of event */
  entryPoint: Scalars['String']['input'];
  /** ID of patient */
  patientId: Scalars['String']['input'];
};

/** Autogenerated return type of OfficePatientLogViewCart. */
export type OfficePatientLogViewCartPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficePatientLogViewCartError;
};

/** Autogenerated error type of OfficePatientSave */
export type OfficePatientSaveError = {
  /** Field errors */
  fields?: Maybe<OfficePatientSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficePatientSave */
export type OfficePatientSaveFieldError = {
  archived?: Maybe<Array<Scalars['String']['output']>>;
  dateOfBirth?: Maybe<Array<Scalars['String']['output']>>;
  discount?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  firstOrderDiscount?: Maybe<Array<Scalars['String']['output']>>;
  intake?: Maybe<Array<Scalars['String']['output']>>;
  isMinorDependent?: Maybe<Array<Scalars['String']['output']>>;
  labsTosAgreement?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerIds?: Maybe<Array<Scalars['String']['output']>>;
  sendWelcomeEmail?: Maybe<Array<Scalars['String']['output']>>;
  sex?: Maybe<Array<Scalars['String']['output']>>;
  smsNumber?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficePatientSave */
export type OfficePatientSaveInput = {
  attributes: OfficePatientAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createFsJsEvent?: InputMaybe<Scalars['Boolean']['input']>;
  externalPatientId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficePatientSave. */
export type OfficePatientSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficePatientSaveError>;
  fsJsEventPayload?: Maybe<Scalars['JSON']['output']>;
  patient?: Maybe<Patient>;
};

/** Autogenerated error type of OfficePatientUnlink */
export type OfficePatientUnlinkError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficePatientUnlink */
export type OfficePatientUnlinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  externalPatientId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
};

/** Autogenerated return type of OfficePatientUnlink. */
export type OfficePatientUnlinkPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficePatientUnlinkError>;
};

/** Autogenerated error type of OfficePractitionerDocumentDelete */
export type OfficePractitionerDocumentDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficePractitionerDocumentDelete */
export type OfficePractitionerDocumentDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficePractitionerDocumentDelete. */
export type OfficePractitionerDocumentDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  documentId?: Maybe<Scalars['String']['output']>;
  errors: OfficePractitionerDocumentDeleteError;
};

/** Attributes for uploading a practitioner document */
export type OfficePractitionerDocumentSaveAttributes = {
  attachment?: InputMaybe<Scalars['Upload']['input']>;
  documentCategoryId?: InputMaybe<Scalars['ID']['input']>;
  documentId?: InputMaybe<Scalars['ID']['input']>;
  documentLibrary?: InputMaybe<Scalars['Boolean']['input']>;
  documentTypeId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated error type of OfficePractitionerDocumentSave */
export type OfficePractitionerDocumentSaveError = {
  /** Field errors */
  fields?: Maybe<OfficePractitionerDocumentSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficePractitionerDocumentSave */
export type OfficePractitionerDocumentSaveFieldError = {
  attachment?: Maybe<Array<Scalars['String']['output']>>;
  documentCategoryId?: Maybe<Array<Scalars['String']['output']>>;
  documentId?: Maybe<Array<Scalars['String']['output']>>;
  documentLibrary?: Maybe<Array<Scalars['String']['output']>>;
  documentTypeId?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficePractitionerDocumentSave */
export type OfficePractitionerDocumentSaveInput = {
  attributes: OfficePractitionerDocumentSaveAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficePractitionerDocumentSave. */
export type OfficePractitionerDocumentSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  errors: OfficePractitionerDocumentSaveError;
};

/** Attributes for updating an office prescribing personalization */
export type OfficePrescribingPersonalizationPractitionerUpdateAttributes = {
  isEnabledForPractitioner: Scalars['Boolean']['input'];
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  prescribingPersonalizationId: Scalars['ID']['input'];
};

/** Autogenerated error type of OfficePrescribingPersonalizationPractitionerUpdate */
export type OfficePrescribingPersonalizationPractitionerUpdateError = {
  /** Field errors */
  fields?: Maybe<OfficePrescribingPersonalizationPractitionerUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficePrescribingPersonalizationPractitionerUpdate */
export type OfficePrescribingPersonalizationPractitionerUpdateFieldError = {
  isEnabledForPractitioner?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  prescribingPersonalizationId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficePrescribingPersonalizationPractitionerUpdate */
export type OfficePrescribingPersonalizationPractitionerUpdateInput = {
  attributes: OfficePrescribingPersonalizationPractitionerUpdateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficePrescribingPersonalizationPractitionerUpdate. */
export type OfficePrescribingPersonalizationPractitionerUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficePrescribingPersonalizationPractitionerUpdateError;
  personalization?: Maybe<PrescribingPersonalizationValue>;
  /** @deprecated Use 'personalization' field instead */
  prescribingPersonalization?: Maybe<PrescribingPersonalization>;
};

/** Autogenerated error type of OfficePrescribingPersonalizationStoreUpdate */
export type OfficePrescribingPersonalizationStoreUpdateError = {
  /** Field errors */
  fields?: Maybe<OfficePrescribingPersonalizationStoreUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficePrescribingPersonalizationStoreUpdate */
export type OfficePrescribingPersonalizationStoreUpdateFieldError = {
  isEnabled?: Maybe<Array<Scalars['String']['output']>>;
  overridable?: Maybe<Array<Scalars['String']['output']>>;
  prescribingPersonalizationId?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficePrescribingPersonalizationStoreUpdate */
export type OfficePrescribingPersonalizationStoreUpdateInput = {
  attributes: OfficePrescribingPersonalizationUpdateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficePrescribingPersonalizationStoreUpdate. */
export type OfficePrescribingPersonalizationStoreUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficePrescribingPersonalizationStoreUpdateError;
  personalization?: Maybe<PrescribingPersonalizationValue>;
  /** @deprecated Use 'personalization' field instead */
  prescribingPersonalization?: Maybe<PrescribingPersonalization>;
};

/** Attributes for updating the store prescribing personalization */
export type OfficePrescribingPersonalizationUpdateAttributes = {
  isEnabled: Scalars['Boolean']['input'];
  overridable: Scalars['Boolean']['input'];
  prescribingPersonalizationId: Scalars['ID']['input'];
  storeId: Scalars['ID']['input'];
};

/** Attributes for saving a product to a category */
export type OfficeProductCategoryAttributes = {
  categoryId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

/** Attributes for bulk swapping variants. */
export type OfficeProductSwappingBulkSwapVariantAttributes = {
  excludedPlanIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  message?: InputMaybe<Scalars['String']['input']>;
  notifyPatients?: InputMaybe<Scalars['Boolean']['input']>;
  planIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  planType: PlanTypes;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  previousVariantId: Scalars['ID']['input'];
  recommendationAttributes?: InputMaybe<OfficeRecommendationAttributes>;
  replacementVariantId: Scalars['ID']['input'];
  selectAllPlans?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of OfficeProductSwappingBulkSwapVariant */
export type OfficeProductSwappingBulkSwapVariantError = {
  /** Field errors */
  fields?: Maybe<OfficeProductSwappingBulkSwapVariantFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeProductSwappingBulkSwapVariant */
export type OfficeProductSwappingBulkSwapVariantFieldError = {
  excludedPlanIds?: Maybe<Array<Scalars['String']['output']>>;
  message?: Maybe<Array<Scalars['String']['output']>>;
  notifyPatients?: Maybe<Array<Scalars['String']['output']>>;
  planIds?: Maybe<Array<Scalars['String']['output']>>;
  planType?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  previousVariantId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationAttributes?: Maybe<Array<Scalars['String']['output']>>;
  replacementVariantId?: Maybe<Array<Scalars['String']['output']>>;
  selectAllPlans?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeProductSwappingBulkSwapVariant */
export type OfficeProductSwappingBulkSwapVariantInput = {
  attributes: OfficeProductSwappingBulkSwapVariantAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeProductSwappingBulkSwapVariant. */
export type OfficeProductSwappingBulkSwapVariantPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeProductSwappingBulkSwapVariantError;
};

/** Autogenerated error type of OfficeProductSwappingSwap */
export type OfficeProductSwappingSwapError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeProductSwappingSwap */
export type OfficeProductSwappingSwapInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
  replacementProductId: Scalars['ID']['input'];
  sendEmails: Scalars['Boolean']['input'];
};

/** Autogenerated return type of OfficeProductSwappingSwap. */
export type OfficeProductSwappingSwapPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeProductSwappingSwapError;
  replaceVariant?: Maybe<ReplaceVariant>;
};

/** Attributes for swapping a variant. */
export type OfficeProductSwappingSwapVariantAttributes = {
  planId: Scalars['ID']['input'];
  planType: PlanTypes;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  recommendationId: Scalars['ID']['input'];
  replacementRecommendationAttributes: OfficeRecommendationAttributes;
  replacementVariantId: Scalars['ID']['input'];
};

/** Autogenerated error type of OfficeProductSwappingSwapVariant */
export type OfficeProductSwappingSwapVariantError = {
  /** Field errors */
  fields?: Maybe<OfficeProductSwappingSwapVariantFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeProductSwappingSwapVariant */
export type OfficeProductSwappingSwapVariantFieldError = {
  planId?: Maybe<Array<Scalars['String']['output']>>;
  planType?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationId?: Maybe<Array<Scalars['String']['output']>>;
  replacementRecommendationAttributes?: Maybe<Array<Scalars['String']['output']>>;
  replacementVariantId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeProductSwappingSwapVariant */
export type OfficeProductSwappingSwapVariantInput = {
  attributes: OfficeProductSwappingSwapVariantAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeProductSwappingSwapVariant. */
export type OfficeProductSwappingSwapVariantPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeProductSwappingSwapVariantError;
  recommendation?: Maybe<Recommendation>;
};

/** Autogenerated error type of OfficeProductSwappingUnswap */
export type OfficeProductSwappingUnswapError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeProductSwappingUnswap */
export type OfficeProductSwappingUnswapInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  replaceVariantId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeProductSwappingUnswap. */
export type OfficeProductSwappingUnswapPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeProductSwappingUnswapError;
  replaceVariant?: Maybe<ReplaceVariant>;
};

/** Autogenerated error type of OfficeProductsCategorySave */
export type OfficeProductsCategorySaveError = {
  /** Field errors */
  fields?: Maybe<OfficeProductsCategorySaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeProductsCategorySave */
export type OfficeProductsCategorySaveFieldError = {
  categoryId?: Maybe<Array<Scalars['String']['output']>>;
  productId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeProductsCategorySave */
export type OfficeProductsCategorySaveInput = {
  attributes: OfficeProductCategoryAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeProductsCategorySave. */
export type OfficeProductsCategorySavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeProductsCategorySaveError;
  product?: Maybe<Product>;
};

/** Attributes for creating a feedback for base_product and its alternative_product */
export type OfficeRecommendationAlternativeFeedbackAttributes = {
  baseProductId: Scalars['ID']['input'];
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  otherOption?: InputMaybe<Scalars['String']['input']>;
  similarProductId: Scalars['ID']['input'];
  thumbsUp: Scalars['Boolean']['input'];
};

/** Autogenerated error type of OfficeRecommendationAlternativesExists */
export type OfficeRecommendationAlternativesExistsError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeRecommendationAlternativesExists */
export type OfficeRecommendationAlternativesExistsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeRecommendationAlternativesExists. */
export type OfficeRecommendationAlternativesExistsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationAlternativesExistsError;
  exists: Scalars['Boolean']['output'];
};

/** Autogenerated error type of OfficeRecommendationAlternativesFeedbackCreate */
export type OfficeRecommendationAlternativesFeedbackCreateError = {
  /** Field errors */
  fields?: Maybe<OfficeRecommendationAlternativesFeedbackCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeRecommendationAlternativesFeedbackCreate */
export type OfficeRecommendationAlternativesFeedbackCreateFieldError = {
  baseProductId?: Maybe<Array<Scalars['String']['output']>>;
  options?: Maybe<Array<Scalars['String']['output']>>;
  otherOption?: Maybe<Array<Scalars['String']['output']>>;
  similarProductId?: Maybe<Array<Scalars['String']['output']>>;
  thumbsUp?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeRecommendationAlternativesFeedbackCreate */
export type OfficeRecommendationAlternativesFeedbackCreateInput = {
  attributes: OfficeRecommendationAlternativeFeedbackAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeRecommendationAlternativesFeedbackCreate. */
export type OfficeRecommendationAlternativesFeedbackCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationAlternativesFeedbackCreateError;
  /** feedback for base_product and its alternative_product */
  feedback?: Maybe<RecommendationAlternativeFeedback>;
};

/** Autogenerated error type of OfficeRecommendationAlternativesFeedbackDelete */
export type OfficeRecommendationAlternativesFeedbackDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeRecommendationAlternativesFeedbackDelete */
export type OfficeRecommendationAlternativesFeedbackDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  recommendationAlternativeFeedbackId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeRecommendationAlternativesFeedbackDelete. */
export type OfficeRecommendationAlternativesFeedbackDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationAlternativesFeedbackDeleteError;
  feedback?: Maybe<RecommendationAlternativeFeedback>;
};

/** Autogenerated error type of OfficeRecommendationAlternativesGetProducts */
export type OfficeRecommendationAlternativesGetProductsError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeRecommendationAlternativesGetProducts */
export type OfficeRecommendationAlternativesGetProductsInput = {
  amount: Scalars['String']['input'];
  baseVariantId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
};

/** Autogenerated return type of OfficeRecommendationAlternativesGetProducts. */
export type OfficeRecommendationAlternativesGetProductsPayload = {
  alternativeProducts?: Maybe<Array<VariantWithDosage>>;
  baseProduct?: Maybe<VariantWithDosage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationAlternativesGetProductsError;
};

/** Attributes for updating a store's treatment plan's recommendation */
export type OfficeRecommendationAttributes = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['String']['input']>;
  currentState?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  isFilledOnSite?: InputMaybe<Scalars['Boolean']['input']>;
  multiPatientPlanId?: InputMaybe<Scalars['ID']['input']>;
  recommendationAlternativeSource?: InputMaybe<Scalars['String']['input']>;
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
  refill?: InputMaybe<Scalars['Boolean']['input']>;
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated Removing practitioner ability to select dosage quantity. */
  unitsToPurchase?: InputMaybe<Scalars['Int']['input']>;
  variantId?: InputMaybe<Scalars['ID']['input']>;
  withFood?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of OfficeRecommendationDelete */
export type OfficeRecommendationDeleteError = {
  /** Field errors */
  fields?: Maybe<OfficeRecommendationDeleteFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeRecommendationDelete */
export type OfficeRecommendationDeleteFieldError = {
  additionalInfo?: Maybe<Array<Scalars['String']['output']>>;
  amount?: Maybe<Array<Scalars['String']['output']>>;
  currentState?: Maybe<Array<Scalars['String']['output']>>;
  duration?: Maybe<Array<Scalars['String']['output']>>;
  format?: Maybe<Array<Scalars['String']['output']>>;
  frequency?: Maybe<Array<Scalars['String']['output']>>;
  isFilledOnSite?: Maybe<Array<Scalars['String']['output']>>;
  multiPatientPlanId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationAlternativeSource?: Maybe<Array<Scalars['String']['output']>>;
  recommendationId?: Maybe<Array<Scalars['String']['output']>>;
  refill?: Maybe<Array<Scalars['String']['output']>>;
  searchSessionId?: Maybe<Array<Scalars['String']['output']>>;
  takeWith?: Maybe<Array<Scalars['String']['output']>>;
  timeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  unitsToPurchase?: Maybe<Array<Scalars['String']['output']>>;
  variantId?: Maybe<Array<Scalars['String']['output']>>;
  withFood?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeRecommendationDelete */
export type OfficeRecommendationDeleteInput = {
  attributes: OfficeRecommendationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeRecommendationDelete. */
export type OfficeRecommendationDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationDeleteError;
  recommendation?: Maybe<Recommendation>;
};

/** Attributes for replacing a recommendation variant */
export type OfficeRecommendationReplaceVariantAttributes = {
  alternativeVariantId: Scalars['ID']['input'];
  amount?: InputMaybe<Scalars['String']['input']>;
  currentRecommendationId: Scalars['ID']['input'];
  format?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of OfficeRecommendationReplaceVariant */
export type OfficeRecommendationReplaceVariantError = {
  /** Field errors */
  fields?: Maybe<OfficeRecommendationReplaceVariantFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeRecommendationReplaceVariant */
export type OfficeRecommendationReplaceVariantFieldError = {
  alternativeVariantId?: Maybe<Array<Scalars['String']['output']>>;
  amount?: Maybe<Array<Scalars['String']['output']>>;
  currentRecommendationId?: Maybe<Array<Scalars['String']['output']>>;
  format?: Maybe<Array<Scalars['String']['output']>>;
  frequency?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeRecommendationReplaceVariant */
export type OfficeRecommendationReplaceVariantInput = {
  attributes: OfficeRecommendationReplaceVariantAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeRecommendationReplaceVariant. */
export type OfficeRecommendationReplaceVariantPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationReplaceVariantError;
  recommendation?: Maybe<Recommendation>;
};

/** Autogenerated error type of OfficeRecommendationSave */
export type OfficeRecommendationSaveError = {
  /** Field errors */
  fields?: Maybe<OfficeRecommendationSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeRecommendationSave */
export type OfficeRecommendationSaveFieldError = {
  additionalInfo?: Maybe<Array<Scalars['String']['output']>>;
  amount?: Maybe<Array<Scalars['String']['output']>>;
  currentState?: Maybe<Array<Scalars['String']['output']>>;
  duration?: Maybe<Array<Scalars['String']['output']>>;
  format?: Maybe<Array<Scalars['String']['output']>>;
  frequency?: Maybe<Array<Scalars['String']['output']>>;
  isFilledOnSite?: Maybe<Array<Scalars['String']['output']>>;
  multiPatientPlanId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationAlternativeSource?: Maybe<Array<Scalars['String']['output']>>;
  recommendationId?: Maybe<Array<Scalars['String']['output']>>;
  refill?: Maybe<Array<Scalars['String']['output']>>;
  searchSessionId?: Maybe<Array<Scalars['String']['output']>>;
  takeWith?: Maybe<Array<Scalars['String']['output']>>;
  timeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
  unitsToPurchase?: Maybe<Array<Scalars['String']['output']>>;
  variantId?: Maybe<Array<Scalars['String']['output']>>;
  withFood?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeRecommendationSave */
export type OfficeRecommendationSaveInput = {
  attributes: OfficeRecommendationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeRecommendationSave. */
export type OfficeRecommendationSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationSaveError;
  recommendation?: Maybe<Recommendation>;
};

/** Attributes for saving recommendation substitutes and main recommendation if it doesn't exist yet (for OOS variants) */
export type OfficeRecommendationSubstitutesAttributes = {
  mainRecommendationAttributes: OfficeRecommendationAttributes;
  substituteRecommendationAttributes: Array<OfficeRecommendationAttributes>;
  substituteRecommendationIdsToRemove?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Attributes for bulk saving recommendation substitutes for selected plans */
export type OfficeRecommendationSubstitutesBulkSaveAttributes = {
  excludedPlanIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  mainVariantId: Scalars['ID']['input'];
  notifyPatients?: InputMaybe<Scalars['Boolean']['input']>;
  planIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  planType: PlanTypes;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  selectAllPlans?: InputMaybe<Scalars['Boolean']['input']>;
  substituteRecommendationAttributes: Array<OfficeRecommendationAttributes>;
};

/** Autogenerated error type of OfficeRecommendationSubstitutesBulkSave */
export type OfficeRecommendationSubstitutesBulkSaveError = {
  /** Field errors */
  fields?: Maybe<OfficeRecommendationSubstitutesBulkSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeRecommendationSubstitutesBulkSave */
export type OfficeRecommendationSubstitutesBulkSaveFieldError = {
  excludedPlanIds?: Maybe<Array<Scalars['String']['output']>>;
  mainVariantId?: Maybe<Array<Scalars['String']['output']>>;
  notifyPatients?: Maybe<Array<Scalars['String']['output']>>;
  planIds?: Maybe<Array<Scalars['String']['output']>>;
  planType?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  selectAllPlans?: Maybe<Array<Scalars['String']['output']>>;
  substituteRecommendationAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeRecommendationSubstitutesBulkSave */
export type OfficeRecommendationSubstitutesBulkSaveInput = {
  attributes: OfficeRecommendationSubstitutesBulkSaveAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeRecommendationSubstitutesBulkSave. */
export type OfficeRecommendationSubstitutesBulkSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationSubstitutesBulkSaveError;
};

/** Autogenerated error type of OfficeRecommendationSubstitutesCancel */
export type OfficeRecommendationSubstitutesCancelError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeRecommendationSubstitutesCancel */
export type OfficeRecommendationSubstitutesCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  substituteRecommendationId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeRecommendationSubstitutesCancel. */
export type OfficeRecommendationSubstitutesCancelPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationSubstitutesCancelError;
  substituteRecommendation?: Maybe<Recommendation>;
};

/** Autogenerated error type of OfficeRecommendationSubstitutesSave */
export type OfficeRecommendationSubstitutesSaveError = {
  /** Field errors */
  fields?: Maybe<OfficeRecommendationSubstitutesSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeRecommendationSubstitutesSave */
export type OfficeRecommendationSubstitutesSaveFieldError = {
  mainRecommendationAttributes?: Maybe<Array<Scalars['String']['output']>>;
  substituteRecommendationAttributes?: Maybe<Array<Scalars['String']['output']>>;
  substituteRecommendationIdsToRemove?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeRecommendationSubstitutesSave */
export type OfficeRecommendationSubstitutesSaveInput = {
  attributes: OfficeRecommendationSubstitutesAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeRecommendationSubstitutesSave. */
export type OfficeRecommendationSubstitutesSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationSubstitutesSaveError;
  mainRecommendation?: Maybe<Recommendation>;
  recommendationSubstitutes?: Maybe<Array<RecommendationSubstitute>>;
};

/** Autogenerated error type of OfficeRecommendationTreatmentPlanApply */
export type OfficeRecommendationTreatmentPlanApplyError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeRecommendationTreatmentPlanApply */
export type OfficeRecommendationTreatmentPlanApplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fromTreatmentPlanId: Scalars['ID']['input'];
  toTreatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeRecommendationTreatmentPlanApply. */
export type OfficeRecommendationTreatmentPlanApplyPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeRecommendationTreatmentPlanApplyError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Union of practitioner & clerk type for office mutations */
export type OfficeRoleUnion = Clerk | Practitioner;

/** Autogenerated input type of OfficeSettingsFriendInvitesUpdate */
export type OfficeSettingsFriendInvitesUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  friendInvitesSetting: Scalars['Boolean']['input'];
};

/** Autogenerated return type of OfficeSettingsFriendInvitesUpdate. */
export type OfficeSettingsFriendInvitesUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  store?: Maybe<Store>;
};

/** Autogenerated error type of OfficeSettingsPaymentsAndTaxesAcceptStripeTos */
export type OfficeSettingsPaymentsAndTaxesAcceptStripeTosError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeSettingsPaymentsAndTaxesAcceptStripeTos */
export type OfficeSettingsPaymentsAndTaxesAcceptStripeTosInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeSettingsPaymentsAndTaxesAcceptStripeTos. */
export type OfficeSettingsPaymentsAndTaxesAcceptStripeTosPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeSettingsPaymentsAndTaxesAcceptStripeTosError;
  store?: Maybe<Store>;
};

/** OfficeSettingsPaymentsAndTaxes error type. */
export type OfficeSettingsPaymentsAndTaxesErrors = {
  /** Error's fields. */
  fields?: Maybe<OfficeSettingsPaymentsAndTaxesFieldErrors>;
  /** Error's message. */
  message?: Maybe<Scalars['String']['output']>;
};

/** Field error type for OfficeSettingsPaymentsAndTaxesErrors. */
export type OfficeSettingsPaymentsAndTaxesFieldErrors = {
  /** MerchantData financial account type. */
  accountType?: Maybe<Array<Scalars['String']['output']>>;
  /** Bank account held by. */
  bankAccountHeldBy?: Maybe<Array<Scalars['String']['output']>>;
  /** Bank account holder. */
  bankAccountHolder?: Maybe<Array<Scalars['String']['output']>>;
  /** Bank account number. */
  bankAccountNumber?: Maybe<Array<Scalars['String']['output']>>;
  /** Bank account type. */
  bankAccountType?: Maybe<Array<Scalars['String']['output']>>;
  /** Bank account routing number. */
  bankRoutingNumber?: Maybe<Array<Scalars['String']['output']>>;
  /** Business address field errors. */
  businessAddress?: Maybe<BusinessAddressFieldErrors>;
  /** MerchantData financial business name. */
  businessName?: Maybe<Array<Scalars['String']['output']>>;
  /** MerchantData Address city. */
  city?: Maybe<Array<Scalars['String']['output']>>;
  /** MerchantData financial classification. */
  classification?: Maybe<Array<Scalars['String']['output']>>;
  /** MerchantMerchantData financial date of birth. */
  dateOfBirth?: Maybe<Array<Scalars['String']['output']>>;
  /** Directors field errors. */
  directors?: Maybe<Array<OfficeSettingsStripePersonErrors>>;
  /** MerchantData financial email. */
  email?: Maybe<Array<Scalars['String']['output']>>;
  /** MerchantData financial employer id. */
  employerId?: Maybe<Array<Scalars['String']['output']>>;
  /** Owners field errors. */
  equityOwners?: Maybe<Array<OfficeSettingsStripePersonErrors>>;
  /** MerchantData financial first name. */
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  /** Invoice address field errors. */
  invoiceAddress?: Maybe<BusinessAddressFieldErrors>;
  /** MerchantData financial job title. */
  jobTitle?: Maybe<Array<Scalars['String']['output']>>;
  /** MerchantData financial last name. */
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  /** MerchantData address phone. */
  phone?: Maybe<Array<Scalars['String']['output']>>;
  /** MerchantData address postal code. */
  postalCode?: Maybe<Array<Scalars['String']['output']>>;
  /** Store profit margin */
  profitMargin?: Maybe<Array<Scalars['String']['output']>>;
  /** MerchantData address region. */
  region?: Maybe<Array<Scalars['String']['output']>>;
  /** MerchantData ssn. */
  ssn?: Maybe<Array<Scalars['String']['output']>>;
  /** MerchantData address street address. */
  streetAddress?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeSettingsPaymentsAndTaxesSetup */
export type OfficeSettingsPaymentsAndTaxesSetupInput = {
  attributes: SettingsPaymentsAndTaxesAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeSettingsPaymentsAndTaxesSetup. */
export type OfficeSettingsPaymentsAndTaxesSetupPayload = {
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeSettingsPaymentsAndTaxesErrors>;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
};

/** Autogenerated input type of OfficeSettingsProfitDisclosureUpdate */
export type OfficeSettingsProfitDisclosureUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  profitDisclosureSetting: Scalars['Boolean']['input'];
};

/** Autogenerated return type of OfficeSettingsProfitDisclosureUpdate. */
export type OfficeSettingsProfitDisclosureUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  store?: Maybe<Store>;
};

/** Field error type for OfficeSettingsStripePersonErrors. */
export type OfficeSettingsStripePersonErrors = {
  /** Stripe person field errors. */
  errors?: Maybe<OfficeSettingsStripePersonFieldErrors>;
  /** Index of the record */
  index?: Maybe<Scalars['Int']['output']>;
  /** Error message. */
  message?: Maybe<Scalars['String']['output']>;
};

/** Field error type for OfficeSettingsStripePersonFieldErrors. */
export type OfficeSettingsStripePersonFieldErrors = {
  /** Stripe person city. */
  city?: Maybe<Array<Scalars['String']['output']>>;
  /** Stripe person date of birth. */
  dateOfBirth?: Maybe<Array<Scalars['String']['output']>>;
  /** Stripe person email. */
  email?: Maybe<Array<Scalars['String']['output']>>;
  /** Stripe person first name. */
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  /** Person's id */
  id?: Maybe<Array<Scalars['String']['output']>>;
  /** Stripe person last name. */
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  /** Stripe person postal code. */
  postalCode?: Maybe<Array<Scalars['String']['output']>>;
  /** Stripe person region. */
  region?: Maybe<Array<Scalars['String']['output']>>;
  /** Stripe person street address. */
  streetAddress?: Maybe<Array<Scalars['String']['output']>>;
  /** Stripe person street address 2. */
  streetAddress2?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated error type of OfficeSettingsUpdate */
export type OfficeSettingsUpdateError = {
  /** Field errors */
  fields?: Maybe<OfficeSettingsUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeSettingsUpdate */
export type OfficeSettingsUpdateFieldError = {
  hasAdvancedSecurity?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeSettingsUpdate */
export type OfficeSettingsUpdateInput = {
  attributes: StoreSettingsAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeSettingsUpdate. */
export type OfficeSettingsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeSettingsUpdateError;
  store?: Maybe<Store>;
};

/** Autogenerated error type of OfficeStaffSettingClerkCreate */
export type OfficeStaffSettingClerkCreateError = {
  /** Field errors */
  fields?: Maybe<OfficeStaffSettingClerkCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeStaffSettingClerkCreate */
export type OfficeStaffSettingClerkCreateFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeStaffSettingClerkCreate */
export type OfficeStaffSettingClerkCreateInput = {
  attributes: StoreClerkAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeStaffSettingClerkCreate. */
export type OfficeStaffSettingClerkCreatePayload = {
  clerk?: Maybe<Clerk>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeStaffSettingClerkCreateError;
};

/** Autogenerated error type of OfficeStaffSettingResendInvitation */
export type OfficeStaffSettingResendInvitationError = {
  /** Field errors */
  fields?: Maybe<OfficeStaffSettingResendInvitationFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeStaffSettingResendInvitation */
export type OfficeStaffSettingResendInvitationFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  isClerk?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeStaffSettingResendInvitation */
export type OfficeStaffSettingResendInvitationInput = {
  attributes: StaffSettingsResendInvitationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeStaffSettingResendInvitation. */
export type OfficeStaffSettingResendInvitationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeStaffSettingResendInvitationError;
};

/** Attributes for updating a static daily pack */
export type OfficeStaticDailyPackAttributes = {
  dailyPackId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  variantAttributes?: InputMaybe<Array<OfficeDynamicDailyPackVariantAttributes>>;
};

/** Autogenerated error type of OfficeTemplateCreateDraft */
export type OfficeTemplateCreateDraftError = {
  /** Field errors */
  fields?: Maybe<OfficeTemplateCreateDraftFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeTemplateCreateDraft */
export type OfficeTemplateCreateDraftFieldError = {
  attachmentsUploadableIds?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationsAttributes?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanTemplateIdToApply?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeTemplateCreateDraft */
export type OfficeTemplateCreateDraftInput = {
  attributes: TemplateCreateDraftAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeTemplateCreateDraft. */
export type OfficeTemplateCreateDraftPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateCreateDraftError;
  treatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
};

/** Autogenerated error type of OfficeTemplateCreateFromMultiPatientPlan */
export type OfficeTemplateCreateFromMultiPatientPlanError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeTemplateCreateFromMultiPatientPlan */
export type OfficeTemplateCreateFromMultiPatientPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  multiPatientPlanId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeTemplateCreateFromMultiPatientPlan. */
export type OfficeTemplateCreateFromMultiPatientPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateCreateFromMultiPatientPlanError;
  template?: Maybe<TreatmentPlanTemplate>;
};

/** Autogenerated error type of OfficeTemplateCreateFromTreatmentPlan */
export type OfficeTemplateCreateFromTreatmentPlanError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeTemplateCreateFromTreatmentPlan */
export type OfficeTemplateCreateFromTreatmentPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publishTemplatePlan?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeTemplateCreateFromTreatmentPlan. */
export type OfficeTemplateCreateFromTreatmentPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateCreateFromTreatmentPlanError;
  template?: Maybe<TreatmentPlanTemplate>;
};

/** Attributes for updating a store's template daily packs */
export type OfficeTemplateDailyPackAttributes = {
  dailyPackId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  variantAttributes?: InputMaybe<Array<OfficeDynamicDailyPackVariantAttributes>>;
};

/** Attributes to reset a draft template */
export type OfficeTemplateDraftResetAttributes = {
  templateId: Scalars['ID']['input'];
};

/** Autogenerated error type of OfficeTemplateShareableGlobalUpdate */
export type OfficeTemplateShareableGlobalUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeTemplateShareableGlobalUpdate */
export type OfficeTemplateShareableGlobalUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shareable: Scalars['Boolean']['input'];
  templateId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeTemplateShareableGlobalUpdate. */
export type OfficeTemplateShareableGlobalUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateShareableGlobalUpdateError;
  template?: Maybe<TreatmentPlanTemplate>;
};

/** Autogenerated error type of OfficeTemplateShareableWithPatientsUpdate */
export type OfficeTemplateShareableWithPatientsUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeTemplateShareableWithPatientsUpdate */
export type OfficeTemplateShareableWithPatientsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sharingLevel: Scalars['String']['input'];
  templateId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeTemplateShareableWithPatientsUpdate. */
export type OfficeTemplateShareableWithPatientsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateShareableWithPatientsUpdateError;
  template?: Maybe<TreatmentPlanTemplate>;
};

/** Autogenerated error type of OfficeTemplateShareableWithPractitionersUpdate */
export type OfficeTemplateShareableWithPractitionersUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeTemplateShareableWithPractitionersUpdate */
export type OfficeTemplateShareableWithPractitionersUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sharingLevel: Scalars['String']['input'];
  templateId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeTemplateShareableWithPractitionersUpdate. */
export type OfficeTemplateShareableWithPractitionersUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplateShareableWithPractitionersUpdateError;
  template?: Maybe<TreatmentPlanTemplate>;
};

/** Autogenerated error type of OfficeTemplatesDraftReset */
export type OfficeTemplatesDraftResetError = {
  /** Field errors */
  fields?: Maybe<OfficeTemplatesDraftResetFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeTemplatesDraftReset */
export type OfficeTemplatesDraftResetFieldError = {
  templateId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeTemplatesDraftReset */
export type OfficeTemplatesDraftResetInput = {
  attributes: OfficeTemplateDraftResetAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeTemplatesDraftReset. */
export type OfficeTemplatesDraftResetPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTemplatesDraftResetError;
  template?: Maybe<TreatmentPlanTemplate>;
};

/** Autogenerated error type of OfficeTreatmentPlanActivate */
export type OfficeTreatmentPlanActivateError = {
  /** Field errors */
  fields?: Maybe<OfficeTreatmentPlanActivateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeTreatmentPlanActivate */
export type OfficeTreatmentPlanActivateFieldError = {
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  signingPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeTreatmentPlanActivate */
export type OfficeTreatmentPlanActivateInput = {
  attributes: OfficeTreatmentPlanAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createFsJsEvent?: InputMaybe<Scalars['Boolean']['input']>;
  externalIntegration?: InputMaybe<Scalars['JSON']['input']>;
};

/** Autogenerated return type of OfficeTreatmentPlanActivate. */
export type OfficeTreatmentPlanActivatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeTreatmentPlanActivateError>;
  fsJsEventPayload?: Maybe<Scalars['JSON']['output']>;
  store?: Maybe<Store>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeTreatmentPlanApplyDurationToAll */
export type OfficeTreatmentPlanApplyDurationToAllError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeTreatmentPlanApplyDurationToAll */
export type OfficeTreatmentPlanApplyDurationToAllInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['String']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeTreatmentPlanApplyDurationToAll. */
export type OfficeTreatmentPlanApplyDurationToAllPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeTreatmentPlanApplyDurationToAllError>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeTreatmentPlanApplyTemplate */
export type OfficeTreatmentPlanApplyTemplateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeTreatmentPlanApplyTemplate */
export type OfficeTreatmentPlanApplyTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeTreatmentPlanApplyTemplate. */
export type OfficeTreatmentPlanApplyTemplatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Error object */
  errors: OfficeTreatmentPlanApplyTemplateError;
  /** The treatment plan that the template is applied to */
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Attributes for updating a store's treatment plan */
export type OfficeTreatmentPlanAttributes = {
  patientId?: InputMaybe<Scalars['ID']['input']>;
  signingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated error type of OfficeTreatmentPlanDraftMessageSave */
export type OfficeTreatmentPlanDraftMessageSaveError = {
  /** Field errors */
  fields?: Maybe<OfficeTreatmentPlanDraftMessageSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeTreatmentPlanDraftMessageSave */
export type OfficeTreatmentPlanDraftMessageSaveFieldError = {
  fromPrePopulated?: Maybe<Array<Scalars['String']['output']>>;
  message?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeTreatmentPlanDraftMessageSave */
export type OfficeTreatmentPlanDraftMessageSaveInput = {
  attributes: OfficeTreatmentPlanMessageAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeTreatmentPlanDraftMessageSave. */
export type OfficeTreatmentPlanDraftMessageSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlanDraftMessageSaveError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Attributes to reset a draft treatment plan */
export type OfficeTreatmentPlanDraftResetAttributes = {
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated error type of OfficeTreatmentPlanLabSigningPractitionerUpdate */
export type OfficeTreatmentPlanLabSigningPractitionerUpdateError = {
  /** Field errors */
  fields?: Maybe<OfficeTreatmentPlanLabSigningPractitionerUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeTreatmentPlanLabSigningPractitionerUpdate */
export type OfficeTreatmentPlanLabSigningPractitionerUpdateFieldError = {
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  signingPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeTreatmentPlanLabSigningPractitionerUpdate */
export type OfficeTreatmentPlanLabSigningPractitionerUpdateInput = {
  attributes: OfficeTreatmentPlanAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeTreatmentPlanLabSigningPractitionerUpdate. */
export type OfficeTreatmentPlanLabSigningPractitionerUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeTreatmentPlanLabSigningPractitionerUpdateError>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Attributes related to a treatment plan's message */
export type OfficeTreatmentPlanMessageAttributes = {
  fromPrePopulated?: InputMaybe<Scalars['Boolean']['input']>;
  message: Scalars['String']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated error type of OfficeTreatmentPlanPatientUpdate */
export type OfficeTreatmentPlanPatientUpdateError = {
  /** Field errors */
  fields?: Maybe<OfficeTreatmentPlanPatientUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeTreatmentPlanPatientUpdate */
export type OfficeTreatmentPlanPatientUpdateFieldError = {
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  signingPractitionerId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeTreatmentPlanPatientUpdate */
export type OfficeTreatmentPlanPatientUpdateInput = {
  attributes: OfficeTreatmentPlanAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeTreatmentPlanPatientUpdate. */
export type OfficeTreatmentPlanPatientUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<OfficeTreatmentPlanPatientUpdateError>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeTreatmentPlanRemoveTemplate */
export type OfficeTreatmentPlanRemoveTemplateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeTreatmentPlanRemoveTemplate */
export type OfficeTreatmentPlanRemoveTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeTreatmentPlanRemoveTemplate. */
export type OfficeTreatmentPlanRemoveTemplatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Error object */
  errors: OfficeTreatmentPlanRemoveTemplateError;
  /** The treatment plan that the template is applied to */
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Attributes related to a treatment plan's template */
export type OfficeTreatmentPlanTemplateAttributes = {
  attachmentsUploadableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  currentState?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  recommendationsAttributes?: InputMaybe<Array<OfficeRecommendationAttributes>>;
  treatmentPlanTemplateId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanTemplateIdToApply?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated error type of OfficeTreatmentPlanTemplateMakeCopy */
export type OfficeTreatmentPlanTemplateMakeCopyError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeTreatmentPlanTemplateMakeCopy */
export type OfficeTreatmentPlanTemplateMakeCopyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficeTreatmentPlanTemplateMakeCopy. */
export type OfficeTreatmentPlanTemplateMakeCopyPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlanTemplateMakeCopyError;
  template?: Maybe<TreatmentPlanTemplate>;
};

/** Autogenerated error type of OfficeTreatmentPlanTemplateSave */
export type OfficeTreatmentPlanTemplateSaveError = {
  /** Field errors */
  fields?: Maybe<OfficeTreatmentPlanTemplateSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeTreatmentPlanTemplateSave */
export type OfficeTreatmentPlanTemplateSaveFieldError = {
  attachmentsUploadableIds?: Maybe<Array<Scalars['String']['output']>>;
  currentState?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationsAttributes?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanTemplateId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanTemplateIdToApply?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeTreatmentPlanTemplateSave */
export type OfficeTreatmentPlanTemplateSaveInput = {
  attributes: OfficeTreatmentPlanTemplateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeTreatmentPlanTemplateSave. */
export type OfficeTreatmentPlanTemplateSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlanTemplateSaveError;
  treatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
};

/** Autogenerated error type of OfficeTreatmentPlansCreateDraft */
export type OfficeTreatmentPlansCreateDraftError = {
  /** Field errors */
  fields?: Maybe<OfficeTreatmentPlansCreateDraftFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeTreatmentPlansCreateDraft */
export type OfficeTreatmentPlansCreateDraftFieldError = {
  attachmentsUploadableIds?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationsAttributes?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeTreatmentPlansCreateDraft */
export type OfficeTreatmentPlansCreateDraftInput = {
  attributes: TreatmentPlansCreateDraftAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeTreatmentPlansCreateDraft. */
export type OfficeTreatmentPlansCreateDraftPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlansCreateDraftError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeTreatmentPlansDraftReset */
export type OfficeTreatmentPlansDraftResetError = {
  /** Field errors */
  fields?: Maybe<OfficeTreatmentPlansDraftResetFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeTreatmentPlansDraftReset */
export type OfficeTreatmentPlansDraftResetFieldError = {
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeTreatmentPlansDraftReset */
export type OfficeTreatmentPlansDraftResetInput = {
  attributes: OfficeTreatmentPlanDraftResetAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeTreatmentPlansDraftReset. */
export type OfficeTreatmentPlansDraftResetPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlansDraftResetError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeTreatmentPlansFindOrCreateDraft */
export type OfficeTreatmentPlansFindOrCreateDraftError = {
  /** Field errors */
  fields?: Maybe<OfficeTreatmentPlansFindOrCreateDraftFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficeTreatmentPlansFindOrCreateDraft */
export type OfficeTreatmentPlansFindOrCreateDraftFieldError = {
  attachmentsUploadableIds?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
  recommendationsAttributes?: Maybe<Array<Scalars['String']['output']>>;
  searchSessionId?: Maybe<Array<Scalars['String']['output']>>;
  templateId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficeTreatmentPlansFindOrCreateDraft */
export type OfficeTreatmentPlansFindOrCreateDraftInput = {
  attributes: TreatmentPlansFindOrCreateDraftAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeTreatmentPlansFindOrCreateDraft. */
export type OfficeTreatmentPlansFindOrCreateDraftPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeTreatmentPlansFindOrCreateDraftError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of OfficeWholesalesCertExpressLinkGenerate */
export type OfficeWholesalesCertExpressLinkGenerateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OfficeWholesalesCertExpressLinkGenerate */
export type OfficeWholesalesCertExpressLinkGenerateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficeWholesalesCertExpressLinkGenerate. */
export type OfficeWholesalesCertExpressLinkGeneratePayload = {
  avataxCustomer?: Maybe<AvataxCustomer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficeWholesalesCertExpressLinkGenerateError;
};

/** Autogenerated error type of OfficesPlansConvert */
export type OfficesPlansConvertError = {
  /** Field errors */
  fields?: Maybe<OfficesPlansConvertFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OfficesPlansConvert */
export type OfficesPlansConvertFieldError = {
  convertTo?: Maybe<Array<Scalars['String']['output']>>;
  planId?: Maybe<Array<Scalars['String']['output']>>;
  planName?: Maybe<Array<Scalars['String']['output']>>;
  planType?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OfficesPlansConvert */
export type OfficesPlansConvertInput = {
  attributes: PlansConvertAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OfficesPlansConvert. */
export type OfficesPlansConvertPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OfficesPlansConvertError;
  plan?: Maybe<PlanUnion>;
};

/** Autogenerated input type of OfficesPractitionersToggleFullscriptContentFavourite */
export type OfficesPractitionersToggleFullscriptContentFavouriteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fullscriptContentId: Scalars['ID']['input'];
};

/** Autogenerated return type of OfficesPractitionersToggleFullscriptContentFavourite. */
export type OfficesPractitionersToggleFullscriptContentFavouritePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors if the action was unsuccessful */
  errors: Array<Scalars['String']['output']>;
  fullscriptContent?: Maybe<ImatContent>;
};

/** Attributes for collecting data on the patient orders */
export type OnboardingChecklistLoggerAttributes = {
  action: Actions;
  actionStep: ActionSteps;
};

/** Autogenerated error type of OnboardingChecklistLogger */
export type OnboardingChecklistLoggerError = {
  /** Field errors */
  fields?: Maybe<OnboardingChecklistLoggerFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OnboardingChecklistLogger */
export type OnboardingChecklistLoggerFieldError = {
  action?: Maybe<Array<Scalars['String']['output']>>;
  actionStep?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OnboardingChecklistLogger */
export type OnboardingChecklistLoggerInput = {
  attributes: OnboardingChecklistLoggerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OnboardingChecklistLogger. */
export type OnboardingChecklistLoggerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OnboardingChecklistLoggerError;
};

/** Values for the source field of the OnboardingModalLogger mutation. */
export const OnboardingExperimentSources = {
  /** Green complete account setup banner */
  Banner: 'BANNER',
  /** Expanded checklist step */
  ChecklistStep: 'CHECKLIST_STEP',
  /** Green dropdown menu complete account setup button */
  Dropdown: 'DROPDOWN',
  /** Complete account setup message box button */
  MessageBox: 'MESSAGE_BOX',
  /** New checklist complete account setup button */
  NewChecklist: 'NEW_CHECKLIST',
  /** New checklist complete account setup button */
  NewChecklistStep: 'NEW_CHECKLIST_STEP',
  /** Practitioner box complete account setup button */
  PractitionerBox: 'PRACTITIONER_BOX',
  /** Rx sent message button */
  RxSent: 'RX_SENT'
} as const;

export type OnboardingExperimentSources = typeof OnboardingExperimentSources[keyof typeof OnboardingExperimentSources];
/** Values for the page_version of the OnboardingModalLogger mutation. */
export const OnboardingExperiments = {
  /** control group with no changes */
  Control: 'CONTROL',
  /** Experiment 2 for changing the complete account setup banner to a message box */
  MessageBox: 'MESSAGE_BOX',
  /** Experiment 1 for changing only the complete account setup banner */
  OrangeBanner: 'ORANGE_BANNER',
  /** Experiment 3 for changing the banner + adding a new checklist */
  OrangeBannerNewChecklist: 'ORANGE_BANNER_NEW_CHECKLIST'
} as const;

export type OnboardingExperiments = typeof OnboardingExperiments[keyof typeof OnboardingExperiments];
/** Loggable steps for the practitioner onboarding modal */
export const OnboardingModalActionSteps = {
  /** 'Certificate' step  */
  Certificate: 'CERTIFICATE',
  /** 'Student identification' step  */
  StudentIdentification: 'STUDENT_IDENTIFICATION'
} as const;

export type OnboardingModalActionSteps = typeof OnboardingModalActionSteps[keyof typeof OnboardingModalActionSteps];
/** Logged user actions on the onboarding modal */
export const OnboardingModalActions = {
  /** Practitioner clicks "I'll finish later button" */
  Dismiss: 'DISMISS',
  /** Practitioner clicks any of the CTAs to open */
  OpenModal: 'OPEN_MODAL'
} as const;

export type OnboardingModalActions = typeof OnboardingModalActions[keyof typeof OnboardingModalActions];
/** Autogenerated error type of OnboardingModalLog */
export type OnboardingModalLogError = {
  /** Field errors */
  fields?: Maybe<OnboardingModalLogFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of OnboardingModalLog */
export type OnboardingModalLogFieldError = {
  action?: Maybe<Array<Scalars['String']['output']>>;
  actionStep?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  source?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of OnboardingModalLog */
export type OnboardingModalLogInput = {
  attributes: OnboardingModalLoggerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OnboardingModalLog. */
export type OnboardingModalLogPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: OnboardingModalLogError;
};

/** Attributes for collecting data when the practitioner interacts with the onboarding modal */
export type OnboardingModalLoggerAttributes = {
  action: OnboardingModalActions;
  actionStep: OnboardingModalActionSteps;
  pageVersion?: InputMaybe<OnboardingExperiments>;
  source?: InputMaybe<OnboardingExperimentSources>;
};

/** The attributes for the optimized Catalog Menu (L3) */
export type OptimizedMenuAttribute = {
  /** The IDs to be used in the filter for the Attribute */
  ids?: Maybe<Array<Scalars['ID']['output']>>;
  /** The name of the Attribute to be displayed */
  name?: Maybe<Scalars['String']['output']>;
};

/** The categories for the Optimized Megamenu */
export type OptimizedMenuCategory = {
  /** The ID to be used in the filter for the Menu Category */
  id?: Maybe<Scalars['ID']['output']>;
  /** The name of the Menu Category to be displayed */
  name?: Maybe<Scalars['String']['output']>;
  /** The sub categories belonging to the Menu Category */
  subCategories?: Maybe<Array<OptimizedMenuSubCategory>>;
};

/** Optimized Menu Category Types */
export const OptimizedMenuCategoryKind = {
  /** Returns the Patient Optimized Menu Categories */
  Patient: 'PATIENT',
  /** Returns the Patient Optimized Menu Categories */
  Practitioner: 'PRACTITIONER'
} as const;

export type OptimizedMenuCategoryKind = typeof OptimizedMenuCategoryKind[keyof typeof OptimizedMenuCategoryKind];
/** The sub categories for the optimized Catalog Menu (L2) */
export type OptimizedMenuSubCategory = {
  /** The attributes belonging to the Menu Sub Category */
  attributes?: Maybe<Array<OptimizedMenuAttribute>>;
  /** The number of the column the sub category should be shown in */
  column?: Maybe<Scalars['Int']['output']>;
  /** The IDs to be used in the filter for the sub Menu Category */
  ids?: Maybe<Array<Scalars['ID']['output']>>;
  /** The name of the Menu Sub Category to be displayed */
  name?: Maybe<Scalars['String']['output']>;
};

/** Order fields */
export type Order = Node & Timestamps & {
  /** Additional tax total */
  additionalTaxTotal?: Maybe<Scalars['Float']['output']>;
  /** Adjustment total */
  adjustmentTotal?: Maybe<Scalars['Float']['output']>;
  /** Indicates if the order can be cancelled */
  allowOrderCancellation: Scalars['Boolean']['output'];
  /** Indicates if the order can be quick re-ordered */
  allowQuickReorder: Scalars['Boolean']['output'];
  /** In terms of cost, how much shippable product is still needed order to qualify for free shipping */
  awayFromFreeShippingThreshold?: Maybe<Scalars['Float']['output']>;
  /** Billing address */
  billingAddress?: Maybe<Address>;
  /** Additional comment */
  commentary?: Maybe<Commentary>;
  /** Order completion date */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Consolidated status of lab specific orders based on order, payment and delivery statuses */
  consolidatedLabStatus?: Maybe<Scalars['String']['output']>;
  /** Consolidated status based on order, payment and delivery statuses */
  consolidatedStatus: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Credit card */
  creditCard?: Maybe<CreditCard>;
  /** Threshold amount of shippable product needed to qualify for free shipping */
  freeShippingThreshold: Scalars['Float']['output'];
  /** What kind of free shipping is available when passing the threshold, 'all' or 'standard_only' */
  freeShippingThresholdType: Scalars['String']['output'];
  /** Order completion date formatted for third party clients. */
  friendlyCompletedOn?: Maybe<Scalars['String']['output']>;
  /** Promotion name and discount value from the fs funded discount */
  fsFundedPromotionName?: Maybe<Scalars['String']['output']>;
  /** Fullscript funded discount total */
  fsFundedPromotionTotal?: Maybe<Scalars['String']['output']>;
  /** Indicates if the order contains any lab items */
  hasLabs: Scalars['Boolean']['output'];
  /** Indicates if the order contains any pill packs */
  hasPillPacks: Scalars['Boolean']['output'];
  /** Does the order have shippable items */
  hasShippables: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Is the order linked to an autoship subscription? */
  isAutoship: Scalars['Boolean']['output'];
  /** Is the order to be paid for by practitioner? */
  isLabsPractitionerPay: Scalars['Boolean']['output'];
  /** Is the order trackable */
  isTrackable: Scalars['Boolean']['output'];
  /** Lab shipment total */
  labShipmentTotal?: Maybe<Scalars['Float']['output']>;
  /** total for labs including any fees */
  labTotal: Scalars['Float']['output'];
  /** Custom fees grouped by name, and summed price */
  labsCustomFeesSummary?: Maybe<Array<LabsRecommendationCustomFee>>;
  /** Lab item total */
  labsSubTotal: Scalars['Float']['output'];
  /** Total tax for labs */
  labsTaxTotal: Scalars['Float']['output'];
  /** Array with line items */
  lineItems: Array<LineItem>;
  /** Minimum recommended selling price total */
  msrpTotal?: Maybe<Scalars['Float']['output']>;
  /** Array of inventory units not available for the refund */
  nonRefundableInventoryUnits?: Maybe<Array<InventoryUnit>>;
  /** Order number */
  number: Scalars['String']['output'];
  /** Url for the labs receipt document */
  orderLabsReceiptUrl: Scalars['String']['output'];
  /** Url for the order pdf */
  orderPdfUrl: Scalars['String']['output'];
  /** Url for the supplements receipt document */
  orderReceiptUrl: Scalars['String']['output'];
  /** Patient */
  patient: Patient;
  /** Patient promotion discount percentage applied to order */
  patientPromotionDiscount?: Maybe<Scalars['Int']['output']>;
  /** Patient promotion discount amount applied to order */
  patientPromotionDiscountTotal?: Maybe<Scalars['Float']['output']>;
  /** State of the payment */
  paymentState?: Maybe<Scalars['String']['output']>;
  /** Store/patient discount amount applied to order */
  previousStoreDiscountTotal?: Maybe<Scalars['Float']['output']>;
  /** Product shipment total */
  productShipmentTotal?: Maybe<Scalars['Float']['output']>;
  /** Total of items that count towards the free shipping threshold */
  qualifyingItemTotal?: Maybe<Scalars['Float']['output']>;
  /** Array of inventory units available for the refund */
  refundableInventoryUnits?: Maybe<Array<InventoryUnit>>;
  /** Array of line items available for the refund */
  refundableLineItems?: Maybe<Array<LineItem>>;
  /** Quantity of different line items available for the refund */
  refundableLineItemsQuantity?: Maybe<Scalars['Int']['output']>;
  /** The model score returned by Amazon Fraud Detector when this order was analyzed for compliance */
  resellerModelScore?: Maybe<Scalars['Float']['output']>;
  /** Total saved amount */
  savingsTotal?: Maybe<Scalars['Float']['output']>;
  /** Selected shipping address id on the order. This corresponds with an ::Address record - NOT  Spree::Address */
  selectedShippingAddressId?: Maybe<Scalars['ID']['output']>;
  /** State of the shipment */
  shipmentState?: Maybe<Scalars['String']['output']>;
  /** Shipments total tax */
  shipmentTax?: Maybe<Scalars['Float']['output']>;
  /** Shipment total */
  shipmentTotal?: Maybe<Scalars['Float']['output']>;
  /** Array with shipments */
  shipments?: Maybe<Array<Shipment>>;
  /** Array with shipper addresses */
  shipperAddresses?: Maybe<Array<ShipperAddress>>;
  /** Shipping address */
  shippingAddress?: Maybe<Address>;
  /** The number of days after the estimated delivery date that the last shipment was delivered */
  shippingDaysLate?: Maybe<Scalars['Int']['output']>;
  /** Shipping method */
  shippingMethod?: Maybe<Scalars['String']['output']>;
  /** Total amount of applied spree promotions */
  spreePromoTotal?: Maybe<Scalars['Float']['output']>;
  /** Order state */
  state: Scalars['String']['output'];
  /** Promotion name and discount value from the store funded discount */
  storeFundedPromotionName?: Maybe<Scalars['String']['output']>;
  /** Store funded discount total */
  storeFundedPromotionTotal?: Maybe<Scalars['String']['output']>;
  /** Promotions from stores */
  storesPatientPromotion?: Maybe<StoresPatientPromotion>;
  /** Subtotal */
  subTotal?: Maybe<Scalars['Float']['output']>;
  /** Supplement item total (subtotal) */
  supplementsItemTotal: Scalars['Float']['output'];
  /** Total MSRP on supplements */
  supplementsMsrpTotal: Scalars['Float']['output'];
  /** Total for supplement shipment (i.e. excluding lab shipment if any) */
  supplementsShipmentTotal?: Maybe<Scalars['Float']['output']>;
  /** Total tax for supplements */
  supplementsTaxTotal: Scalars['Float']['output'];
  /** Total for supplements only (i.e. excluding lab total if any) */
  supplementsTotal?: Maybe<Scalars['Float']['output']>;
  /** Tax total */
  taxTotal?: Maybe<Scalars['Float']['output']>;
  /** Whether to hide taxes because they will be recalculated later */
  taxesDeferred: Scalars['Boolean']['output'];
  /** Total */
  total?: Maybe<Scalars['Float']['output']>;
  /** Total for lab companies fee (e.g. Quest requisition fee) */
  totalCompanyRequisitionFee?: Maybe<Scalars['Float']['output']>;
  /** Total for the physician/auth network */
  totalPhysicianNetworkFee?: Maybe<Scalars['Float']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for Order. */
export type OrderConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrderEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

/** Arguments for filtering retail and wholesale orders. */
export type OrderFilterObject = {
  completedBeforeDate?: InputMaybe<Scalars['Date']['input']>;
  completedWithinDays?: InputMaybe<Scalars['Int']['input']>;
  createdBeforeDate?: InputMaybe<Scalars['Date']['input']>;
  hasLineItems?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** Autoship frequencies our system allows. */
export const OrderFrequencies = {
  /** Returns the four months frequency */
  FourMonths: 'FOUR_MONTHS',
  /** Returns the month frequency */
  Month: 'MONTH',
  /** Returns the six months frequency */
  SixMonths: 'SIX_MONTHS',
  /** Returns the six weeks frequency */
  SixWeeks: 'SIX_WEEKS',
  /** Returns the three months frequency */
  ThreeMonths: 'THREE_MONTHS',
  /** Returns the three week frequency */
  ThreeWeeks: 'THREE_WEEKS',
  /** Returns the two months frequency */
  TwoMonths: 'TWO_MONTHS',
  /** Returns the two week frequency */
  TwoWeeks: 'TWO_WEEKS'
} as const;

export type OrderFrequencies = typeof OrderFrequencies[keyof typeof OrderFrequencies];
/** Arguments for sorting orders. */
export type OrderSort = {
  direction: SortDirection;
  field: OrderSortField;
};

/** Sortable fields for orders */
export const OrderSortField = {
  /** Date on which the order was completed */
  CompletedAt: 'COMPLETED_AT',
  /** Date on which the wellevate order was completed */
  CreatedAt: 'CREATED_AT'
} as const;

export type OrderSortField = typeof OrderSortField[keyof typeof OrderSortField];
/** Orders Patients data for the store */
export type OrderingPatientsData = {
  /** The month to date number of patients with orders for the store */
  monthToDate: Scalars['Int']['output'];
  /** orders breakdown for the store */
  storeOrderAnalyticsData: Array<StoreOrderAnalyticsData>;
};

/** Ordering permission for ordering a specific lab test. */
export const OrderingPermissions = {
  Allowed: 'allowed',
  Blocked: 'blocked',
  NotAllowed: 'not_allowed',
  RequiresAuthorization: 'requires_authorization'
} as const;

export type OrderingPermissions = typeof OrderingPermissions[keyof typeof OrderingPermissions];
/** Orders Placed data for the store */
export type OrdersPlacedData = {
  /** The last 12 months number of orders placed for the store (excluding the current month) */
  lastTwelveMonths: Scalars['Float']['output'];
  /** The month to date number of orders placed for the store */
  monthToDate: Scalars['Int']['output'];
  /** orders breakdown for the store */
  storeOrderAnalyticsData: Array<StoreOrderAnalyticsData>;
  /** The year to date number of orders placed for the store */
  yearToDate: Scalars['Int']['output'];
};

/** An organization entity, grouping multiple spree stores under a single entity */
export type Organization = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The managers of the organization */
  managers: Array<OrganizationManager>;
  /** The name of the organization */
  name: Scalars['String']['output'];
  /** The stores associated with the organization */
  stores: Array<Store>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Organization manager */
export type OrganizationManager = Node & Roleable & Timestamps & {
  /** The access tiers this manager has */
  accessTiers: Array<AccessTier>;
  /**
   * Returns true if the owner practitioner's health care credentials or provider
   * id (NPI number) is valid and has been approved
   */
  anyCredentialApproved: Scalars['Boolean']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The email of the manager */
  email: Scalars['String']['output'];
  /** The name of the manager */
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Whether the role's user account is locked */
  isLocked: Scalars['Boolean']['output'];
  /** The organization this manager manages */
  organization: Organization;
  /** The id of the store the role belongs to */
  storeId?: Maybe<Scalars['ID']['output']>;
  /** The name of the store the role belongs to */
  storeName?: Maybe<Scalars['String']['output']>;
  /** The role type: Admin, Patient, Practitioner, Clerk, or OrganizationManager */
  type: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The user this manager belongs to */
  user: User;
};

/** Overall overview of all interactions for a product, patient's previous treatment plans and current treatment plan. */
export type OverallInteractionsOverview = {
  /** Interactions overview of currently taking products */
  currentlyTaking: Array<ProductInteractionsOverview>;
  /** Interactions overview of treatment plan in progress */
  planInProgress: Array<ProductInteractionsOverview>;
  /** Interactions overview of selected product */
  selectedProduct?: Maybe<SelectedProductInteractionsOverview>;
  /** Count of interactions overview of currently taking products */
  totalCount: Scalars['Int']['output'];
};

/** Pack Pill Type */
export type PackPill = Timestamps & {
  /** The am dosage of this pack pill. */
  amDosage?: Maybe<Scalars['Int']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The Spree::Variant record that contains the product data. */
  pill: DailyPackPill;
  /** The pm dosage of this pack pill. */
  pmDosage?: Maybe<Scalars['Int']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for PackPill. */
export type PackPillConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PackPillEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PackPill>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PackPillEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PackPill>;
};

/** Types of Daily Packs */
export const PackTypes = {
  StaticDailyPack: 'STATIC_DAILY_PACK',
  TemplateDailyPack: 'TEMPLATE_DAILY_PACK'
} as const;

export type PackTypes = typeof PackTypes[keyof typeof PackTypes];
/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Attributes for collecting events for treatment plan page views */
export type PageViewAttributes = {
  pageVersion: Scalars['String']['input'];
};

/** Arguments for sorting TemplateUnions. */
export type PanelTemplateSort = {
  direction: SortDirection;
  field: PanelTemplateSortField;
};

/** PanelTemplate sort field */
export const PanelTemplateSortField = {
  /** Date on which template union was made available */
  CreatedAt: 'CREATED_AT'
} as const;

export type PanelTemplateSortField = typeof PanelTemplateSortField[keyof typeof PanelTemplateSortField];
/** Attributes for requesting partial self-serve refund */
export type PartialRefundAttributes = {
  lineItems?: InputMaybe<Array<RefundLineItemAttributes>>;
  orderNumber: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

/** The connection type for partnerStore. */
export type PartnerStoreConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PartnerStoreEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PartnerStore>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** Arguments for filtering partner stores */
export type PartnerStoreFilterObject = {
  /** Filters by partner stores that are active and not revoked */
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Patient */
export type Patient = Actionable & LegacyId & Node & Roleable & Timestamps & {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
  /** List of practitioners assigned to the patient (with active PatientCareRelationship) */
  activePractitioners: Array<Practitioner>;
  /** List of variants considered active by the patient (previously ordered, recommended, or replaced) */
  activeVariants: VariantConnection;
  /** List of patient's addresses */
  addresses: Array<PatientAddress>;
  /**
   * Returns true if the owner practitioner's health care credentials or provider
   * id (NPI number) is valid and has been approved
   */
  anyCredentialApproved: Scalars['Boolean']['output'];
  /** Whether the patient has been blocked by their practitioner */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** Audio sessions */
  audioSessions: AudioSessionConnection;
  /** Get experiment group the user belongs to */
  autoshipDiscountExperimentGroup?: Maybe<Scalars['String']['output']>;
  /** Patient's avatar */
  avatar?: Maybe<Avatar>;
  /** Whether the patient can order labs */
  canOrderLabs: Scalars['Boolean']['output'];
  /** List of patient's credit cards */
  checkoutCreditCards: Array<CreditCard>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** List of patient's credit cards */
  creditCards: Array<CreditCard>;
  /** Sign in timestamp of the user's current session */
  currentSignInAt?: Maybe<Scalars['DateTime']['output']>;
  /** Sign in timestamp of the patient's current session */
  currentStoreSignInAt?: Maybe<Scalars['DateTime']['output']>;
  /** Patient's date of birth */
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  /** Dependent object associated with the patient */
  dependent?: Maybe<Dependent>;
  /** An ID that uniquely identifies a dependent patient individual */
  dependentId?: Maybe<Scalars['String']['output']>;
  /** Dependents of the patient's user */
  dependents: Array<Dependent>;
  /**
   * DEPRECATED: Uncapped patient discount
   * @deprecated Use capped displayDiscount instead
   */
  discount: Scalars['Int']['output'];
  /** Effective (capped) discount including First Order discount */
  displayDiscount: Scalars['Int']['output'];
  /** Effective (capped) First Order discount */
  displayFirstOrderDiscount: Scalars['Int']['output'];
  /** Whether the patient is eligible for self-served refunds */
  eligibleForSelfServeRefunds: Scalars['Boolean']['output'];
  /** Patient's email. This may be different from email used to sign in. */
  email?: Maybe<Scalars['String']['output']>;
  /** List of categories for the explore page */
  exploreCategories: Array<ExploreMenuCategory>;
  /** Patient's external EHR integrations */
  externalIntegrations?: Maybe<Array<ApiExternalMetadatum>>;
  /** Patient's first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The full name used for the role */
  fullName: Scalars['String']['output'];
  /** This patient's guardian patient */
  guardianPatient?: Maybe<Patient>;
  /** Whether the patient has an active fullscript treatment plan. */
  hasActiveFullscriptTreatmentPlan: Scalars['Boolean']['output'];
  /** Whether the patient has a wellevate treatment plan. */
  hasActiveWellevateTreatmentPlan: Scalars['Boolean']['output'];
  /** Whether the patient has a draft treatment plan. (not visible to patients) */
  hasDraftTreatmentPlan: Scalars['Boolean']['output'];
  /** Whether the patient has a completed order */
  hasFirstOrder: Scalars['Boolean']['output'];
  /** Whether the patient has completed an order with labs */
  hasOrderedLabs: Scalars['Boolean']['output'];
  /** Whether the patient has pending lab appointments */
  hasPendingLabAppointments: Scalars['Boolean']['output'];
  /** Whether the patient has a wellevate order. */
  hasWellevateOrder: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Associated image for the object */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Patient's mobile and web app notifications */
  inAppNotifications: InAppPatientNotificationConnection;
  /** Patient's in-office cart */
  inOfficeBasket?: Maybe<Order>;
  /** List of friends this patient has invited to Fullscript */
  invitedFriends: Array<Friend>;
  /** Whether the patient has access to the catalog */
  isCatalogAccessible: Scalars['Boolean']['output'];
  /** Whether the patient is the current user */
  isCurrentPatient: Scalars['Boolean']['output'];
  /** Whether the patient is a dependent */
  isDependent: Scalars['Boolean']['output'];
  /** Whether the patient was a dependent that has been emancipated */
  isEmancipatedDependent: Scalars['Boolean']['output'];
  /** Whether the role's user account is locked */
  isLocked: Scalars['Boolean']['output'];
  /** Whether the patient is part of the retail checkout graphql experiment group */
  isRetailCheckoutGqlExperimentGroup: Scalars['Boolean']['output'];
  /** Wether the patient is part of the wellevate migration */
  isWellevate: Scalars['Boolean']['output'];
  /** List of lab recommendations prescribed to the patient */
  labRecommendations: LabsRecommendationConnection;
  /** Number of active lab recommendations for this patient. For use in the user context. */
  labRecommendationsCount?: Maybe<Scalars['Int']['output']>;
  /** When the practitioner agreed to the labs ToS */
  labsTosAgreedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Patient's last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The patient's latest treatment plan from unread notifications */
  latestTreatmentPlan?: Maybe<TreatmentPlan>;
  /** The state of the Wellevate migration */
  migrationStatus?: Maybe<WellevatePatientMigrationStatus>;
  /** List of multi patient plans */
  multiPatientPlans: MultiPatientPlanConnection;
  /** Patient's full name */
  name: Scalars['String']['output'];
  /** Array of all frequencies and next refill dates for the patient */
  nextAutoRefillDates: Array<NextAutoRefillDate>;
  /** List of notification settings */
  notificationSettings: Array<PatientNotificationSetting>;
  /** Number of lab recommendations sent to the patient */
  numberOfActiveLabRecommendations: Scalars['Int']['output'];
  /** Number of practitioners assigned to the patient (with active PatientCareRelationship) */
  numberOfActivePractitioners: Scalars['Int']['output'];
  /** List of patient's past orders */
  orders: OrderConnection;
  /** Patient's cart */
  patientCart?: Maybe<Order>;
  /** Patient's intake form */
  patientIntake?: Maybe<PatientIntakeType>;
  /** Just the patient discount, no store or first-order discount */
  patientSpecificDiscount: Scalars['Int']['output'];
  /** Patient's practitioner pay cart */
  practitionerPayBasket?: Maybe<Order>;
  /** List of practitioners with active treatment plans */
  practitioners?: Maybe<Array<Practitioner>>;
  /** List of variants previously ordered by the patient that are not part of their autoship */
  previouslyOrderedVariantsNotInAutoship: VariantConnection;
  /** Requests to access products created by patient */
  productAccessRequests?: Maybe<ProductAccessRequestConnection>;
  /** Sessions notes */
  sessionNotes: SessionNoteConnection;
  /** Patient's sex */
  sex?: Maybe<Scalars['String']['output']>;
  /** Patient's shipping state */
  shipState?: Maybe<State>;
  /** Patient's SMS notification number */
  smsNumber: Scalars['String']['output'];
  /** Indicates the platform where the patient was originally created */
  sourcePlatform: Scalars['String']['output'];
  /** Store that the patient belongs to */
  store: Store;
  /** The id of the store the role belongs to */
  storeId?: Maybe<Scalars['ID']['output']>;
  /** The name of the store the role belongs to */
  storeName?: Maybe<Scalars['String']['output']>;
  /**
   * Deprecated
   * @deprecated Use subscriptions, subscriptions_groups, subscription_credit_card_id, subscription_billing_address_id, or subscription_shipping_address_id instead
   */
  subscription?: Maybe<Autoship>;
  /** Id of the address the subscription will be billed to */
  subscriptionBillingAddressId?: Maybe<Scalars['ID']['output']>;
  /** Id of the credit card used for subscription payment */
  subscriptionCreditCardId?: Maybe<Scalars['ID']['output']>;
  /** Number of active products the patient has across all active subscriptions */
  subscriptionProductsCount?: Maybe<Scalars['Int']['output']>;
  /** Id of the address the subscription will be shipped to */
  subscriptionShippingAddressId?: Maybe<Scalars['ID']['output']>;
  /** Autoship subscriptions */
  subscriptions: Array<Autoship>;
  /** Autoship subscriptions group */
  subscriptionsGroups?: Maybe<Array<AutoshipGroup>>;
  /** Products in the given order which are not in the current patient's supplement plan */
  suggestSupplementPlanProducts?: Maybe<Array<Product>>;
  /** The patient's supplement plan */
  supplementPlan?: Maybe<SupplementPlanType>;
  /** Number of active supplement plan products the patient has */
  supplementPlanProductsCount?: Maybe<Scalars['Int']['output']>;
  /** List of variants that have been swapped by practitioner */
  swappedVariants: Array<ReplaceVariant>;
  /** Whether the patient has SMS notifications enabled */
  textMessageNotification: Scalars['Boolean']['output'];
  /**
   * Deprecated
   * @deprecated Patient dispatch is deprecated
   */
  textMessageSubscription: Scalars['Boolean']['output'];
  /**
   * Patient's total effective discount for autoship orders (capped). Includes
   * store, patient-specific, autoship and first order discounts.
   */
  totalAutoshipDiscount: Scalars['Int']['output'];
  /** Patient's total effective discount (capped). Includes store, patient-specific and first order discounts. */
  totalDiscount: Scalars['Int']['output'];
  /** List of protocols shared with patient */
  treatmentPlanTemplates: TreatmentPlanTemplateConnection;
  /** List of patient's treatment plans */
  treatmentPlans: TreatmentPlanConnection;
  /** Number of active treatment plans for this patient */
  treatmentPlansCount?: Maybe<Scalars['Int']['output']>;
  /** The type of object. (aka the object's class name) */
  type: Scalars['String']['output'];
  /** Number of unread notifications for the patient */
  unreadInAppNotificationCount: Scalars['Int']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** List of variant notifications the patient has subscribed to */
  variantNotifications: VariantNotificationConnection;
  /** List of active variants that require refill */
  variantsDueForRefill: VariantConnection;
  /** Orders originally created in Wellevate */
  wellevateOrders: WellevateOrderConnection;
  /** Treatment plans originally created in Wellevate */
  wellevateTreatmentPlans: WellevateTreatmentPlanConnection;
};


/** Patient */
export type PatientActiveVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterObject?: InputMaybe<ActiveVariantFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Patient */
export type PatientAddressesArgs = {
  filterObject?: InputMaybe<AddressFilterObject>;
};


/** Patient */
export type PatientAudioSessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Patient */
export type PatientCheckoutCreditCardsArgs = {
  orderSource: Scalars['String']['input'];
};


/** Patient */
export type PatientCreditCardsArgs = {
  includeExpired?: InputMaybe<Scalars['Boolean']['input']>;
  latestCopy?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Patient */
export type PatientDependentsArgs = {
  filters?: InputMaybe<DependentFilterObject>;
};


/** Patient */
export type PatientInAppNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<InAppPatientNotificationFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Patient */
export type PatientLabRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LabRecommendationFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<LabRecommendationSort>>;
};


/** Patient */
export type PatientMultiPatientPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<MultiPatientPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MultiPatientPlanSort>>;
};


/** Patient */
export type PatientNotificationSettingsArgs = {
  channel?: InputMaybe<Scalars['String']['input']>;
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** Patient */
export type PatientOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrderSort>>;
};


/** Patient */
export type PatientPreviouslyOrderedVariantsNotInAutoshipArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Patient */
export type PatientProductAccessRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<ProductAccessRequestStatuses>;
};


/** Patient */
export type PatientSessionNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Patient */
export type PatientSubscriptionsArgs = {
  filters?: InputMaybe<AutoshipFilterObject>;
};


/** Patient */
export type PatientSubscriptionsGroupsArgs = {
  filters?: InputMaybe<AutoshipGroupFilterObject>;
};


/** Patient */
export type PatientSuggestSupplementPlanProductsArgs = {
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};


/** Patient */
export type PatientTreatmentPlanTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Patient */
export type PatientTreatmentPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TreatmentPlanSort>>;
};


/** Patient */
export type PatientVariantNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterObject?: InputMaybe<VariantNotificationFilterObject>;
  filters?: InputMaybe<Array<VariantNotificationFilters>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Patient */
export type PatientVariantsDueForRefillArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Patient */
export type PatientWellevateOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrderSort>>;
};


/** Patient */
export type PatientWellevateTreatmentPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TreatmentPlanSort>>;
};

/** Attributes for adding shipping or billing addresses to a patient */
export type PatientAccountAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  countryId?: InputMaybe<Scalars['ID']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  patientAddressType?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  shippingAndBilling?: InputMaybe<Scalars['Boolean']['input']>;
  stateId: Scalars['ID']['input'];
  zipcode: Scalars['String']['input'];
};

/** Patient address details */
export type PatientAddress = Timestamps & {
  /** Address line 1 */
  address1: Scalars['String']['output'];
  /** Address line 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** Patient address type. (billing or shipping) */
  addressType?: Maybe<Scalars['String']['output']>;
  /** Name of city */
  city: Scalars['String']['output'];
  /** Full name of country */
  country: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** A value indicating whether the address is set as default for the store */
  default?: Maybe<Scalars['Boolean']['output']>;
  /** A value indicating whether the address is set as default for the patient */
  defaultPatientAddress?: Maybe<Scalars['Boolean']['output']>;
  /** First Name */
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** A value indicating whether the address is a dropship address */
  isDropship?: Maybe<Scalars['Boolean']['output']>;
  /** Last Name */
  lastname?: Maybe<Scalars['String']['output']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Whether the address is used for both billing and shipping */
  shippingAndBilling: Scalars['Boolean']['output'];
  /** State or province */
  state: State;
  /** Id of the state or province of the address. */
  stateId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Zipcode or postal code */
  zipcode: Scalars['String']['output'];
};

/** Attributes for adding shipping and billing addresses to a patient */
export type PatientAddressAttributes = {
  billingAddress1?: InputMaybe<Scalars['String']['input']>;
  billingAddress2?: InputMaybe<Scalars['String']['input']>;
  billingAddressId?: InputMaybe<Scalars['ID']['input']>;
  billingCity?: InputMaybe<Scalars['String']['input']>;
  billingStateId?: InputMaybe<Scalars['ID']['input']>;
  billingZipcode?: InputMaybe<Scalars['String']['input']>;
  shippingAddress1?: InputMaybe<Scalars['String']['input']>;
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
  shippingAndBilling: Scalars['Boolean']['input'];
  shippingCity?: InputMaybe<Scalars['String']['input']>;
  shippingStateId?: InputMaybe<Scalars['ID']['input']>;
  shippingZipcode?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of PatientAddressCreate */
export type PatientAddressCreateError = {
  /** Field errors */
  fields?: Maybe<PatientAddressCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of PatientAddressCreate */
export type PatientAddressCreateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  countryId?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  shippingAndBilling?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PatientAddressCreate */
export type PatientAddressCreateInput = {
  attributes: PatientAccountAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  setAutoship?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of PatientAddressCreate. */
export type PatientAddressCreatePayload = {
  address?: Maybe<PatientAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientAddressCreateError;
  patient?: Maybe<Patient>;
};

/** Autogenerated input type of PatientAddressDelete */
export type PatientAddressDeleteInput = {
  addressId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PatientAddressDelete. */
export type PatientAddressDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
};

/** Autogenerated error type of PatientAddressUpdate */
export type PatientAddressUpdateError = {
  /** Field errors */
  fields?: Maybe<PatientAddressUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of PatientAddressUpdate */
export type PatientAddressUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  countryId?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  shippingAndBilling?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PatientAddressUpdate */
export type PatientAddressUpdateInput = {
  addressId: Scalars['ID']['input'];
  attributes: PatientAccountAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  setAutoship?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of PatientAddressUpdate. */
export type PatientAddressUpdatePayload = {
  address?: Maybe<PatientAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientAddressUpdateError;
  patient?: Maybe<Patient>;
};

/** Attributes for updating a patient */
export type PatientAttributes = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Scalars['String']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
  textMessageNotification?: InputMaybe<Scalars['Boolean']['input']>;
  textMessageSubscription?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of PatientAuthorizeGuardianship */
export type PatientAuthorizeGuardianshipError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientAuthorizeGuardianship */
export type PatientAuthorizeGuardianshipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dependentId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientAuthorizeGuardianship. */
export type PatientAuthorizeGuardianshipPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** dependent that has been authorized */
  dependent?: Maybe<Dependent>;
  /** Errors if the action was unsuccessful */
  errors: PatientAuthorizeGuardianshipError;
};

/** Autogenerated error type of PatientBrowseAbandonTrigger */
export type PatientBrowseAbandonTriggerError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientBrowseAbandonTrigger */
export type PatientBrowseAbandonTriggerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientBrowseAbandonTrigger. */
export type PatientBrowseAbandonTriggerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientBrowseAbandonTriggerError;
  patient?: Maybe<Patient>;
};

/** Arguments for selecting all patients to bulk edit. */
export type PatientBulkEditAllFilterObject = {
  excludedPatientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Patient bulk edit filter object */
export type PatientBulkEditFilterObject = {
  patientIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated input type of PatientCancelOrder */
export type PatientCancelOrderInput = {
  cancelAttributes: SelfServeCancelOrderAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PatientCancelOrder. */
export type PatientCancelOrderPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors if the action was unsuccessful */
  errors: Array<Scalars['String']['output']>;
  /** Boolean indicating if the order was cancelled successfully */
  result: Scalars['Boolean']['output'];
};

/** Autogenerated input type of PatientCartAddToCart */
export type PatientCartAddToCartInput = {
  cart: Array<CartItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Where the 'add to cart' button was clicked */
  source?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanTemplateId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of PatientCartAddToCart. */
export type PatientCartAddToCartPayload = {
  cart?: Maybe<Order>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientCartReset */
export type PatientCartResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PatientCartReset. */
export type PatientCartResetPayload = {
  cart?: Maybe<Order>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientCartUpdate */
export type PatientCartUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lineItemId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  /** @deprecated No longer used. */
  timeSelected?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PatientCartUpdate. */
export type PatientCartUpdatePayload = {
  cart?: Maybe<Order>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientCreateAddresses */
export type PatientCreateAddressesInput = {
  attributes: PatientAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PatientCreateAddresses. */
export type PatientCreateAddressesPayload = {
  addresses: Array<PatientAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: BaseAddressMutationError;
};

/** Field error type for PatientCreateCreditCardAddressFieldErrors. */
export type PatientCreateCreditCardAddressFieldErrors = {
  /** Billing Address line 1 */
  address1?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address line 2 */
  address2?: Maybe<Array<Scalars['String']['output']>>;
  /** Address type (always 'billing' for this mutation) */
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address city */
  city?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address country identifier */
  countryId?: Maybe<Array<Scalars['ID']['output']>>;
  /** Billing Address recipient first name */
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address recipient last name */
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  /** Patient Address type (always 'billing' for this mutation) */
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address phone number */
  phone?: Maybe<Array<Scalars['String']['output']>>;
  /** Indicator if address is for both shipping and billing (always 'false' for this mutation) */
  shippingAndBilling?: Maybe<Array<Scalars['Boolean']['output']>>;
  /** State identifier */
  stateId?: Maybe<Array<Scalars['ID']['output']>>;
  /** Billing Address zip code */
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** PatientCreateCreditCard error type. */
export type PatientCreateCreditCardError = {
  /** Error fields. */
  fields?: Maybe<PatientCreateCreditCardFieldError>;
  /** Error message. */
  message?: Maybe<Scalars['String']['output']>;
};

/** Field error type for PatientCreateCreditCardFieldError. */
export type PatientCreateCreditCardFieldError = {
  /** Billing Address line 1 */
  address1?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address line 2 */
  address2?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address type (always 'billing' for this mutation) */
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  /** AVS state */
  avsState?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address field errors. */
  billingAddress?: Maybe<PatientCreateCreditCardAddressFieldErrors>;
  /** The kind of Credit Card (ie. Visa, MasterCard, etc.) */
  ccType?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address city */
  city?: Maybe<Array<Scalars['String']['output']>>;
  /** The first name of the Credit Card holder. */
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address recipient first name */
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  /** The Gateway payment profile id. */
  gatewayPaymentProfileId?: Maybe<Array<Scalars['String']['output']>>;
  /** The last 4 digits of the Credit Card. */
  lastDigits?: Maybe<Array<Scalars['String']['output']>>;
  /** The last name of the Credit Card holder. */
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address recipient last name */
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  /** The Credit Card expiry month. */
  month?: Maybe<Array<Scalars['String']['output']>>;
  /** The full name of the Credit Card holder. */
  name?: Maybe<Array<Scalars['String']['output']>>;
  /** The one time use permission of the Credit Card. */
  oneTimeUse?: Maybe<Array<Scalars['String']['output']>>;
  /** Patient Billing Address type (always 'billing' for this mutation) */
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address phone number */
  phone?: Maybe<Array<Scalars['String']['output']>>;
  /** Indicator if billing address is for both shipping and billing (always 'false' for this mutation) */
  shippingAndBilling?: Maybe<Array<Scalars['Boolean']['output']>>;
  /** State identifier */
  stateId?: Maybe<Array<Scalars['ID']['output']>>;
  /** The Credit Card expiry year. */
  year?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address zip code */
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PatientCreateCreditCard */
export type PatientCreateCreditCardInput = {
  addressAttributes?: InputMaybe<PatientCreditCardAddressAttributes>;
  addressId?: InputMaybe<Scalars['ID']['input']>;
  attributes: PatientCreditCardAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  setAutoship?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of PatientCreateCreditCard. */
export type PatientCreateCreditCardPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  creditCard?: Maybe<CreditCard>;
  errors: PatientCreateCreditCardError;
  patient?: Maybe<Patient>;
};

/** Autogenerated input type of PatientCreatePartialRefund */
export type PatientCreatePartialRefundInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  refundAttributes: PartialRefundAttributes;
};

/** Autogenerated return type of PatientCreatePartialRefund. */
export type PatientCreatePartialRefundPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors if the action was unsuccessful */
  errors: Array<Scalars['String']['output']>;
  /** List of RMA numbers created after the refund was successfully processed */
  results?: Maybe<Array<Scalars['String']['output']>>;
  /** List of RMA details created after the refund was successfully processed */
  rmas?: Maybe<Array<SelfServeRefundRma>>;
};

/** Autogenerated input type of PatientCreateRefund */
export type PatientCreateRefundInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  refundAttributes: SelfServeRefundAttributes;
};

/** Autogenerated return type of PatientCreateRefund. */
export type PatientCreateRefundPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors if the action was unsuccessful */
  errors: Array<Scalars['String']['output']>;
  /** List of RMA numbers created after the refund was successfully processed */
  results?: Maybe<Array<Scalars['String']['output']>>;
  /** List of RMA details created after the refund was successfully processed */
  rmas?: Maybe<Array<SelfServeRefundRma>>;
};

/** Attributes for adding a new billing address to a new credit card */
export type PatientCreditCardAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  countryId?: InputMaybe<Scalars['ID']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  stateId: Scalars['ID']['input'];
  zipcode: Scalars['String']['input'];
};

/** Attributes for creating a credit card for a patient */
export type PatientCreditCardAttributes = {
  avsState?: InputMaybe<Scalars['String']['input']>;
  ccType?: InputMaybe<Scalars['String']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  gatewayPaymentProfileId?: InputMaybe<Scalars['String']['input']>;
  lastDigits?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oneTimeUse?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

/** An edge in a connection. */
export type PatientEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Patient>;
};

/** Autogenerated error type of PatientFavouriteCreate */
export type PatientFavouriteCreateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientFavouriteCreate */
export type PatientFavouriteCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  favouriteableId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientFavouriteCreate. */
export type PatientFavouriteCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The errors if there was a problem creating a favourite */
  errors: PatientFavouriteCreateError;
  /** The object that was favourited */
  favouriteable?: Maybe<PlanUnion>;
};

/** Autogenerated error type of PatientFavouriteDelete */
export type PatientFavouriteDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientFavouriteDelete */
export type PatientFavouriteDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  favouriteableId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientFavouriteDelete. */
export type PatientFavouriteDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The errors if there was a problem deleting a favourite */
  errors: PatientFavouriteDeleteError;
  /** The object that was favourited */
  favouriteable?: Maybe<PlanUnion>;
};

/** Autogenerated input type of PatientFunnelLogger */
export type PatientFunnelLoggerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientFunnelLogger. */
export type PatientFunnelLoggerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

export type PatientImport = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** State of the import */
  currentState: Scalars['String']['output'];
  /** Patients created by the import */
  entries: PatientImportEntryConnection;
  /** URL storing the failure csv for the import */
  failureCsvUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Pre-signed URL for the import */
  presignedUrl: Scalars['String']['output'];
  /** Whether an invitation should be sent to created patients */
  sendInvitation: Scalars['Boolean']['output'];
  /** Store associated with the import */
  store: Store;
  /** number of patients created */
  totalRecords?: Maybe<Scalars['Int']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Role of the user who uploaded (ex. Practitioner, Clerk, etc.) */
  userRole: Roleable;
};


export type PatientImportEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PatientImportEntryFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Patient import entry fields */
export type PatientImportEntry = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Imported patient's discount */
  discount?: Maybe<Scalars['String']['output']>;
  /** Imported patient's email */
  email?: Maybe<Scalars['String']['output']>;
  /** Message explaining an import failure */
  failureMessage?: Maybe<Scalars['String']['output']>;
  /** Imported patient's first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Imported patient's first order discount */
  firstOrderDiscount?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Imported patient's last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The patient import associated with this patient import entry */
  patientImport: PatientImport;
  /** Imported patient's phone number */
  smsNumber?: Maybe<Scalars['String']['output']>;
  /** State of the patient import */
  state: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Patient import entry attributes input object */
export type PatientImportEntryAttributes = {
  discount?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** The connection type for PatientImportEntry. */
export type PatientImportEntryConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientImportEntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PatientImportEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PatientImportEntryEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PatientImportEntry>;
};

/** Patient import entry filter object */
export type PatientImportEntryFilterObject = {
  state?: InputMaybe<Array<PatientImportEntryFilters>>;
};

/** Patient import entry filters */
export const PatientImportEntryFilters = {
  /** Filters only failed patient import entries */
  ScopeFailed: 'SCOPE_FAILED',
  /** Filters only not started patient import entries */
  ScopeNotStarted: 'SCOPE_NOT_STARTED',
  /** Filters only processed patient import entries */
  ScopeProcessed: 'SCOPE_PROCESSED',
  /** Filters only removed patient import entries */
  ScopeRemoved: 'SCOPE_REMOVED',
  /** Filters only started patient import entries */
  ScopeStarted: 'SCOPE_STARTED'
} as const;

export type PatientImportEntryFilters = typeof PatientImportEntryFilters[keyof typeof PatientImportEntryFilters];
/** Autogenerated input type of PatientIntakeSendRequest */
export type PatientIntakeSendRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  practitionerId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientIntakeSendRequest. */
export type PatientIntakeSendRequestPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient: Patient;
};

/** Patient intake status */
export const PatientIntakeStatus = {
  /** The patient intake is completed */
  Completed: 'COMPLETED',
  /** The patient intake is initiated */
  Initiated: 'INITIATED'
} as const;

export type PatientIntakeStatus = typeof PatientIntakeStatus[keyof typeof PatientIntakeStatus];
/** PatientIntakeType */
export type PatientIntakeType = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The practitioner who created the patient intake */
  requestedPractitioner?: Maybe<Practitioner>;
  /** The status of the patient intake */
  status?: Maybe<PatientIntakeStatus>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated input type of PatientIntakeUpdateStatus */
export type PatientIntakeUpdateStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  status: PatientIntakeStatus;
};

/** Autogenerated return type of PatientIntakeUpdateStatus. */
export type PatientIntakeUpdateStatusPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patientIntake: PatientIntakeType;
};

/** Attributes for a patient to agree to labs TOS agreement */
export type PatientLabTosAttributes = {
  dateOfBirth: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  labsTosAgreement: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  sex: Scalars['String']['input'];
  smsNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of PatientLabsJourneysCancelSubscription */
export type PatientLabsJourneysCancelSubscriptionError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientLabsJourneysCancelSubscription */
export type PatientLabsJourneysCancelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  journeySubscriptionId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientLabsJourneysCancelSubscription. */
export type PatientLabsJourneysCancelSubscriptionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientLabsJourneysCancelSubscriptionError;
  journeySubscription?: Maybe<LabsJourneysSubscription>;
};

/** Autogenerated error type of PatientLabsRecommendationsUpdateSampleCollectionProvider */
export type PatientLabsRecommendationsUpdateSampleCollectionProviderError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientLabsRecommendationsUpdateSampleCollectionProvider */
export type PatientLabsRecommendationsUpdateSampleCollectionProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labRecommendationId: Scalars['ID']['input'];
  labSampleCollectionProviderId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientLabsRecommendationsUpdateSampleCollectionProvider. */
export type PatientLabsRecommendationsUpdateSampleCollectionProviderPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientLabsRecommendationsUpdateSampleCollectionProviderError;
  labRecommendation?: Maybe<LabsRecommendation>;
};

/** Autogenerated error type of PatientLabsUpdate */
export type PatientLabsUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientLabsUpdate */
export type PatientLabsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientAttributes: PatientLabTosAttributes;
};

/** Autogenerated return type of PatientLabsUpdate. */
export type PatientLabsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientLabsUpdateError;
  patient?: Maybe<Patient>;
};

/** Banners and modals displayed to the patients during the migration process. */
export const PatientMigrationBanners = {
  /** Patient's onboarding modal */
  OnboardingModal: 'ONBOARDING_MODAL'
} as const;

export type PatientMigrationBanners = typeof PatientMigrationBanners[keyof typeof PatientMigrationBanners];
/** Boolean fields for each of the values in the patient migration banners json field */
export type PatientMigrationBannersVisibility = {
  onboardingModal: Scalars['Boolean']['output'];
};

/** Patient mutations */
export type PatientMutation = {
  /** Creates an address for a patient */
  createAddresses?: Maybe<PatientCreateAddressesPayload>;
  /** Cancels a Journey subscription */
  labsJourneysCancelSubscription?: Maybe<PatientLabsJourneysCancelSubscriptionPayload>;
  /** Update the sample collection provider for a lab recommendation */
  labsRecommendationsUpdateSampleCollectionProvider?: Maybe<PatientLabsRecommendationsUpdateSampleCollectionProviderPayload>;
  /** Updates patient attributes for labs TOS agreement */
  labsUpdate?: Maybe<PatientLabsUpdatePayload>;
  /** Logs patient order events */
  orderLogger?: Maybe<PatientOrderLoggerPayload>;
  /** Cancels an order for a patient, initiated by ADA */
  patientCancelOrder?: Maybe<PatientCancelOrderPayload>;
  /** Creates an RMA for a patient, initiated by ADA */
  patientCreateRefund?: Maybe<PatientCreateRefundPayload>;
  /** Creates a favourite for a patient */
  patientFavouriteCreate?: Maybe<PatientFavouriteCreatePayload>;
  /** Removes a favourite for a patient */
  patientFavouriteDelete?: Maybe<PatientFavouriteDeletePayload>;
  /** Refunds shipping, initiated by ADA */
  patientRefundShipping?: Maybe<PatientRefundShippingPayload>;
  /** Creates a treatment plan template for a patient */
  treatmentPlanTemplateFavoriteCreate?: Maybe<TreatmentPlanTemplateFavoriteCreatePayload>;
  /** Removes a treatment plan template for a patient */
  treatmentPlanTemplateFavoriteDelete?: Maybe<TreatmentPlanTemplateFavoriteDeletePayload>;
  /** Logs treatment plan events */
  treatmentPlanTemplateLogView?: Maybe<TreatmentPlanTemplateLogViewPayload>;
  /** Updates an address for a patient */
  updateAddresses?: Maybe<PatientUpdateAddressesPayload>;
  /** Allows Wellevate patients to activate their Fullscript account */
  wellevateAccountActivation?: Maybe<AccountActivationPayload>;
  /** Allows Wellevate patients to dismiss the onboarding modal */
  wellevateHidePatientMigrationBanner?: Maybe<HidePatientMigrationBannerPayload>;
  /** Allows send themselves the Wellevate authorization email */
  wellevateSendEmail?: Maybe<SendWellevatePatientEmailPayload>;
};


/** Patient mutations */
export type PatientMutationCreateAddressesArgs = {
  input: PatientCreateAddressesInput;
};


/** Patient mutations */
export type PatientMutationLabsJourneysCancelSubscriptionArgs = {
  input: PatientLabsJourneysCancelSubscriptionInput;
};


/** Patient mutations */
export type PatientMutationLabsRecommendationsUpdateSampleCollectionProviderArgs = {
  input: PatientLabsRecommendationsUpdateSampleCollectionProviderInput;
};


/** Patient mutations */
export type PatientMutationLabsUpdateArgs = {
  input: PatientLabsUpdateInput;
};


/** Patient mutations */
export type PatientMutationOrderLoggerArgs = {
  input: PatientOrderLoggerInput;
};


/** Patient mutations */
export type PatientMutationPatientCancelOrderArgs = {
  input: PatientCancelOrderInput;
};


/** Patient mutations */
export type PatientMutationPatientCreateRefundArgs = {
  input: PatientCreateRefundInput;
};


/** Patient mutations */
export type PatientMutationPatientFavouriteCreateArgs = {
  input: PatientFavouriteCreateInput;
};


/** Patient mutations */
export type PatientMutationPatientFavouriteDeleteArgs = {
  input: PatientFavouriteDeleteInput;
};


/** Patient mutations */
export type PatientMutationPatientRefundShippingArgs = {
  input: PatientRefundShippingInput;
};


/** Patient mutations */
export type PatientMutationTreatmentPlanTemplateFavoriteCreateArgs = {
  input: TreatmentPlanTemplateFavoriteCreateInput;
};


/** Patient mutations */
export type PatientMutationTreatmentPlanTemplateFavoriteDeleteArgs = {
  input: TreatmentPlanTemplateFavoriteDeleteInput;
};


/** Patient mutations */
export type PatientMutationTreatmentPlanTemplateLogViewArgs = {
  input: TreatmentPlanTemplateLogViewInput;
};


/** Patient mutations */
export type PatientMutationUpdateAddressesArgs = {
  input: PatientUpdateAddressesInput;
};


/** Patient mutations */
export type PatientMutationWellevateAccountActivationArgs = {
  input: AccountActivationInput;
};


/** Patient mutations */
export type PatientMutationWellevateHidePatientMigrationBannerArgs = {
  input: HidePatientMigrationBannerInput;
};


/** Patient mutations */
export type PatientMutationWellevateSendEmailArgs = {
  input: SendWellevatePatientEmailInput;
};

/** Information about whether a patient is subscribed to receive a certain notification. */
export type PatientNotificationSetting = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The patient the notification is for */
  patient: Patient;
  /** Whether or not the patient is subscribed to the notification */
  subscribed?: Maybe<Scalars['Boolean']['output']>;
  /** The type of notification */
  type: NotificationSettingType;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of PatientNotificationSettingPushInitiate */
export type PatientNotificationSettingPushInitiateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientNotificationSettingPushInitiate */
export type PatientNotificationSettingPushInitiateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscribed: Scalars['Boolean']['input'];
};

/** Autogenerated return type of PatientNotificationSettingPushInitiate. */
export type PatientNotificationSettingPushInitiatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientNotificationSettingPushInitiateError;
  notificationSettings: Array<PatientNotificationSetting>;
  userNotificationSettings: Array<UserNotificationSetting>;
};

/** Autogenerated error type of PatientNotificationSettingPushUpdate */
export type PatientNotificationSettingPushUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientNotificationSettingPushUpdate */
export type PatientNotificationSettingPushUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notificationSettingIds: Array<Scalars['ID']['input']>;
  subscribed: Scalars['Boolean']['input'];
};

/** Autogenerated return type of PatientNotificationSettingPushUpdate. */
export type PatientNotificationSettingPushUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientNotificationSettingPushUpdateError;
  notificationSettings: Array<PatientNotificationSetting>;
  userNotificationSettings: Array<UserNotificationSetting>;
};

/** Autogenerated error type of PatientNotificationSettingSubscribeAll */
export type PatientNotificationSettingSubscribeAllError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientNotificationSettingSubscribeAll */
export type PatientNotificationSettingSubscribeAllInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientNotificationSettingSubscribeAll. */
export type PatientNotificationSettingSubscribeAllPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientNotificationSettingSubscribeAllError;
  notificationSettings: Array<PatientNotificationSetting>;
};

/** Autogenerated error type of PatientNotificationSettingToggleAll */
export type PatientNotificationSettingToggleAllError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientNotificationSettingToggleAll */
export type PatientNotificationSettingToggleAllInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientNotificationSettingToggleAll. */
export type PatientNotificationSettingToggleAllPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientNotificationSettingToggleAllError;
  notificationSettings: Array<PatientNotificationSetting>;
};

/** Autogenerated error type of PatientNotificationSettingUnsubscribeAll */
export type PatientNotificationSettingUnsubscribeAllError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientNotificationSettingUnsubscribeAll */
export type PatientNotificationSettingUnsubscribeAllInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientNotificationSettingUnsubscribeAll. */
export type PatientNotificationSettingUnsubscribeAllPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientNotificationSettingUnsubscribeAllError;
  notificationSettings: Array<PatientNotificationSetting>;
};

/** Autogenerated error type of PatientNotificationSettingUpdate */
export type PatientNotificationSettingUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PatientNotificationSettingUpdate */
export type PatientNotificationSettingUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
  subscribed: Scalars['Boolean']['input'];
};

/** Autogenerated return type of PatientNotificationSettingUpdate. */
export type PatientNotificationSettingUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<PatientNotificationSettingUpdateError>;
  setting?: Maybe<PatientNotificationSetting>;
};

/** Attributes for collecting data on the patient orders */
export type PatientOrderLoggerAttributes = {
  eventType: EventTypes;
  orderId: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  trackingId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of PatientOrderLogger */
export type PatientOrderLoggerError = {
  /** Field errors */
  fields?: Maybe<PatientOrderLoggerFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of PatientOrderLogger */
export type PatientOrderLoggerFieldError = {
  eventType?: Maybe<Array<Scalars['String']['output']>>;
  orderId?: Maybe<Array<Scalars['String']['output']>>;
  pageVersion?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  trackingId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PatientOrderLogger */
export type PatientOrderLoggerInput = {
  attributes: PatientOrderLoggerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PatientOrderLogger. */
export type PatientOrderLoggerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PatientOrderLoggerError;
};

/** Details of a patient promotion */
export type PatientPromotion = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Cutoff date for opting-in to the promotion */
  cutoffDate: Scalars['DateTime']['output'];
  /** Description of the promotion */
  description?: Maybe<Scalars['String']['output']>;
  /** Duration of the promotion in days */
  durationDays: Scalars['Int']['output'];
  /** End date of the promotion */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Name of the promotion */
  name: Scalars['String']['output'];
  /** Background color for the banner (hex, RGB, or name) */
  patientBannerBackgroundColor?: Maybe<Scalars['String']['output']>;
  /** URL for native app banner image with date (2x resolution) */
  patientBannerImageNativeWithDate2xUrl?: Maybe<Scalars['String']['output']>;
  /** URL for desktop web banner image (2x resolution) */
  patientBannerImageWebDesktop2xUrl?: Maybe<Scalars['String']['output']>;
  /** URL for mobile web banner image (2x resolution) */
  patientBannerImageWebMobile2xUrl?: Maybe<Scalars['String']['output']>;
  /** URL for medium web banner image with date (2x resolution) */
  patientBannerImageWebWithDateMedium2xUrl?: Maybe<Scalars['String']['output']>;
  /** URL for medium web banner image with date */
  patientBannerImageWebWithDateMediumUrl?: Maybe<Scalars['String']['output']>;
  /** URL for small web banner image with date (2x resolution) */
  patientBannerImageWebWithDateSmall2xUrl?: Maybe<Scalars['String']['output']>;
  /** URL for small web banner image with date */
  patientBannerImageWebWithDateSmallUrl?: Maybe<Scalars['String']['output']>;
  /** Name identifier for the banner */
  patientBannerName?: Maybe<Scalars['String']['output']>;
  /** Text content displayed on the banner */
  patientBannerText?: Maybe<Scalars['String']['output']>;
  /** Start date of the promotion */
  startDate: Scalars['DateTime']['output'];
  /** Default suggested additional discount for stores */
  targetedDiscount: Scalars['Int']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for PatientPromotion. */
export type PatientPromotionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientPromotionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PatientPromotion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PatientPromotionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PatientPromotion>;
};

/** States of a lab recommendation for patients */
export const PatientRecommendationStates = {
  AppointmentScheduled: 'appointment_scheduled',
  Cancelled: 'cancelled',
  Delivered: 'delivered',
  Expired: 'expired',
  Incomplete: 'incomplete',
  InterpretationShared: 'interpretation_shared',
  NotPurchased: 'not_purchased',
  PartialResults: 'partial_results',
  PendingAppointment: 'pending_appointment',
  Processing: 'processing',
  Purchased: 'purchased',
  PurchasedInOffice: 'purchased_in_office',
  Refunded: 'refunded',
  Rejected: 'rejected',
  RequisitionPending: 'requisition_pending',
  RequisitionReady: 'requisition_ready',
  ResultsAmended: 'results_amended',
  ResultsReady: 'results_ready',
  ScheduleAppointment: 'schedule_appointment',
  Shipped: 'shipped',
  SpecimenIssue: 'specimen_issue',
  UpcomingAppointment: 'upcoming_appointment'
} as const;

export type PatientRecommendationStates = typeof PatientRecommendationStates[keyof typeof PatientRecommendationStates];
/** Autogenerated input type of PatientRefundShipping */
export type PatientRefundShippingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderNumber: Scalars['String']['input'];
};

/** Autogenerated return type of PatientRefundShipping. */
export type PatientRefundShippingPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors if the action was unsuccessful */
  errors: Array<Scalars['String']['output']>;
  /** Statement if refund succeeded */
  result?: Maybe<Scalars['String']['output']>;
};

/** The connection type for Patient. */
export type PatientSearchConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Patient>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** Arguments for sorting patients. */
export type PatientSort = {
  direction: SortDirection;
  field: PatientSortField;
};

/** Patient sort field */
export const PatientSortField = {
  /** The patient's created_at date  */
  CreatedAt: 'CREATED_AT',
  /** The patient's first name */
  FirstName: 'FIRST_NAME',
  /** The patient's last name */
  LastName: 'LAST_NAME',
  /** Count of treatment plans associated to the patient */
  TreatmentPlansCount: 'TREATMENT_PLANS_COUNT'
} as const;

export type PatientSortField = typeof PatientSortField[keyof typeof PatientSortField];
/** Autogenerated input type of PatientUpdateAddresses */
export type PatientUpdateAddressesInput = {
  attributes: PatientAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PatientUpdateAddresses. */
export type PatientUpdateAddressesPayload = {
  addresses: Array<PatientAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: BaseAddressMutationError;
};

/** Autogenerated input type of PatientUpdate */
export type PatientUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientAttributes: PatientAttributes;
};

/** Autogenerated input type of PatientUpdateIterableLastAppLogin */
export type PatientUpdateIterableLastAppLoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PatientUpdateIterableLastAppLogin. */
export type PatientUpdateIterableLastAppLoginPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors if the action was unsuccessful */
  errors: Array<Scalars['String']['output']>;
  /** The patient being updated */
  patient?: Maybe<Patient>;
};

/** Autogenerated return type of PatientUpdate. */
export type PatientUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors if the action was unsuccessful */
  errors: Array<Scalars['String']['output']>;
  /** The patient being updated */
  patient?: Maybe<Patient>;
  /** The user being updated */
  user?: Maybe<User>;
};

/** Autogenerated input type of PatientVariantNotificationDelete */
export type PatientVariantNotificationDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientVariantNotificationDelete. */
export type PatientVariantNotificationDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Any errors that occur */
  errors: Array<Scalars['String']['output']>;
  /** The variant with subscription removed */
  variant?: Maybe<Variant>;
};

/** Autogenerated input type of PatientVariantNotification */
export type PatientVariantNotificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of PatientVariantNotification. */
export type PatientVariantNotificationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  variant?: Maybe<Variant>;
};

/** Arguments for filtering a list of patients. */
export type PatientsFilterObject = {
  allByLatestTp?: InputMaybe<Scalars['Boolean']['input']>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<PatientSort>>;
  sourcePlatform?: InputMaybe<Scalars['String']['input']>;
  totalDiscounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  withDraftTreatmentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  withFirstOrderDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  withNoAssignedPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Patient Address Validation Record associated to a Spree Address used as the shipping address of an order */
export type Pavr = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Date at which the user proceeded with their original address */
  enteredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Date at which the user selected to not be asked about this address anymore */
  ignoredAt?: Maybe<Scalars['DateTime']['output']>;
  /** Patient ID */
  patientId: Scalars['ID']['output'];
  /** Address line 1 */
  suggestedAddress1?: Maybe<Scalars['String']['output']>;
  /** Address line 2 */
  suggestedAddress2?: Maybe<Scalars['String']['output']>;
  /** Name of the city */
  suggestedCity?: Maybe<Scalars['String']['output']>;
  /** Id of the country */
  suggestedCountryId?: Maybe<Scalars['ID']['output']>;
  /** Name of the state or province */
  suggestedState?: Maybe<Scalars['String']['output']>;
  /** Zip or postal code */
  suggestedZipcode?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Date at which the user proceeded with the validated address */
  validatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** A union of the different payment source types: Credit Card, Net Term */
export type PaymentSourceUnion = CreditCard | NetTerm;

/** Returns payout information (ID and date) */
export type PayoutDate = {
  /** Payout date */
  payoutDate: Scalars['Date']['output'];
  /** Payout id */
  payoutId: Scalars['ID']['output'];
};

/** Information about a specific payout entry */
export type PayoutEntryType = {
  /** Bonus proceeds */
  bonusProceeds?: Maybe<Scalars['Currency']['output']>;
  /** The cost of the items ordered (COGS) */
  costOfGoods?: Maybe<Scalars['Currency']['output']>;
  /** Date */
  date?: Maybe<Scalars['String']['output']>;
  /** Minimum recommended selling price total */
  msrp?: Maybe<Scalars['Currency']['output']>;
  /** Net proceeds */
  netProceeds?: Maybe<Scalars['Currency']['output']>;
  /** Order ID */
  orderId?: Maybe<Scalars['String']['output']>;
  /** Order number */
  orderNumber?: Maybe<Scalars['String']['output']>;
  /** Discount the patient received */
  patientDiscount?: Maybe<Scalars['Currency']['output']>;
  /** Email of the patient */
  patientEmail?: Maybe<Scalars['String']['output']>;
  /** ID of the patient */
  patientId?: Maybe<Scalars['String']['output']>;
  /** Full name of the patient */
  patientName?: Maybe<Scalars['String']['output']>;
  /** Selling price */
  sellingPrice?: Maybe<Scalars['Currency']['output']>;
  /** Shipment number */
  shipmentNumber?: Maybe<Scalars['String']['output']>;
};

/** Information about a payout report */
export type PayoutReportsType = {
  /** Cancelled orders during the payout period */
  cancellations?: Maybe<Array<PayoutEntryType>>;
  /** Subtotal of all the cancelled orders */
  cancellationsSubtotal?: Maybe<Scalars['Currency']['output']>;
  /** Total amount deposited for the payout */
  depositTotal?: Maybe<Scalars['Currency']['output']>;
  /** Orders during the payout period */
  earnings?: Maybe<Array<PayoutEntryType>>;
  /** Subtotal for all complete orders */
  earningsSubtotal?: Maybe<Scalars['Currency']['output']>;
  /** Whether or not the payout has bonus proceeds in earings, returns or cancellations */
  hasBonusProceeds?: Maybe<Scalars['Boolean']['output']>;
  /** Return authorizations during the payout period */
  returns?: Maybe<Array<PayoutEntryType>>;
  /** Subtotal for all returned orders */
  returnsSubtotal?: Maybe<Scalars['Currency']['output']>;
  /** Statement number for the payout */
  statementNumber?: Maybe<Scalars['String']['output']>;
  /** Subtotal for the entire payout */
  subtotal?: Maybe<Scalars['Currency']['output']>;
  /** Taxes for the entire payout */
  taxes?: Maybe<Scalars['Currency']['output']>;
  /** Total for the entire payout */
  total?: Maybe<Scalars['Currency']['output']>;
};

/** Peer referral info */
export type PeerReferral = Node & Timestamps & {
  /** Margin percentage earned from this peer referral */
  bonusMarginEarned?: Maybe<Scalars['Int']['output']>;
  /** Date the peer referral stops generating margin */
  bonusMarginExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  /** Date the peer referral starts generating margin */
  bonusMarginStartDate?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Date the peer referral expires */
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  /** Date the store's cert is approved */
  firstCertApprovedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Whether or not the referral can be deleted */
  isDeleteable: Scalars['Boolean']['output'];
  /** Targeted referral link */
  link?: Maybe<Scalars['String']['output']>;
  /** Email of the referral */
  referredEmail?: Maybe<Scalars['String']['output']>;
  /** First name of referral */
  referredFirstName?: Maybe<Scalars['String']['output']>;
  /** Last name of referral */
  referredLastName?: Maybe<Scalars['String']['output']>;
  /** The referrer (practitioner or clerk) */
  referrer: OfficeRoleUnion;
  /** Date the referred store was created */
  signedUpAt?: Maybe<Scalars['DateTime']['output']>;
  /** The peer referral's status */
  status: PeerReferralStatuses;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for PeerReferral. */
export type PeerReferralConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PeerReferralEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PeerReferral>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** Attributes for creating a peer referral */
export type PeerReferralCreateAttributes = {
  referredEmail: Scalars['String']['input'];
  referredFirstName: Scalars['String']['input'];
  referredLastName: Scalars['String']['input'];
};

/** An edge in a connection. */
export type PeerReferralEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PeerReferral>;
};

/** Information about the peer referral program for a given store */
export type PeerReferralProgram = {
  /** Whether or not the store has enabled bonus margin */
  bonusMarginEnabled: Scalars['Boolean']['output'];
  /** Whether or not the cap amount for the current bonus margin has been reached */
  capAmountReached?: Maybe<Scalars['Boolean']['output']>;
  /** Bonus margin currently being earned (percentage) */
  currentBonusMargin: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Paginated list of peer referrals */
  peerReferrals: PeerReferralConnection;
  /** Non-targeted referral link that can be shared on social media */
  shareableReferralLink: Scalars['String']['output'];
  /** Whether or not to display the 'bonus margin is disabled by default' banner */
  showBonusMarginDisabledBanner: Scalars['Boolean']['output'];
  /** Expiration date for all bonus margin periods (stacked periods) */
  stackedBonusMarginExpirationDate?: Maybe<Scalars['DateTime']['output']>;
};


/** Information about the peer referral program for a given store */
export type PeerReferralProgramPeerReferralsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PeerReferralSort>>;
};

/** Global information about the peer referrals program */
export type PeerReferralProgramConfiguration = Node & {
  /** Bonus margin percentage given for an eligible referral */
  bonusMarginPercentage: Scalars['Int']['output'];
  /** Period limit for bonus margin from an eligible referral */
  bonusMarginPeriodPerReferral: Scalars['Int']['output'];
  /** Count limit of referrals eligible for bonus margin */
  eligibleReferralsCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Numbers of days after the store bonus margin expires */
  referralInviteExpiration: Scalars['Int']['output'];
  /** Total dollar limit of bonus margin a store can earn from referrals */
  totalAmountLimit: Scalars['Int']['output'];
};

/** Arguments for sorting peer referrals. */
export type PeerReferralSort = {
  direction: SortDirection;
  field: PeerReferralSortField;
};

/** Arguments for sorting peer referrals */
export const PeerReferralSortField = {
  /** Date on which peer referral was created */
  CreatedAt: 'CREATED_AT'
} as const;

export type PeerReferralSortField = typeof PeerReferralSortField[keyof typeof PeerReferralSortField];
/** Statuses of a peer referral */
export const PeerReferralStatuses = {
  /** Account completion in progress */
  CompletingAccount: 'COMPLETING_ACCOUNT',
  /** Referral has expired */
  Expired: 'EXPIRED',
  /** Bonus margin has been applied through this referral */
  MarginApplied: 'MARGIN_APPLIED',
  /** Referral email sent */
  ReferralSent: 'REFERRAL_SENT',
  /** Referral complete (user has signed up and completed account setup) */
  Referred: 'REFERRED'
} as const;

export type PeerReferralStatuses = typeof PeerReferralStatuses[keyof typeof PeerReferralStatuses];
/** Autogenerated error type of PeerReferralsCreate */
export type PeerReferralsCreateError = {
  /** Field errors */
  fields?: Maybe<PeerReferralsCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of PeerReferralsCreate */
export type PeerReferralsCreateFieldError = {
  referredEmail?: Maybe<Array<Scalars['String']['output']>>;
  referredFirstName?: Maybe<Array<Scalars['String']['output']>>;
  referredLastName?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PeerReferralsCreate */
export type PeerReferralsCreateInput = {
  attributes: PeerReferralCreateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PeerReferralsCreate. */
export type PeerReferralsCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PeerReferralsCreateError;
  referral?: Maybe<PeerReferral>;
};

/** Autogenerated error type of PeerReferralsDelete */
export type PeerReferralsDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PeerReferralsDelete */
export type PeerReferralsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  peerReferralId: Scalars['ID']['input'];
};

/** Autogenerated return type of PeerReferralsDelete. */
export type PeerReferralsDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<PeerReferralsDeleteError>;
  peerReferralProgram?: Maybe<PeerReferralProgram>;
  referral?: Maybe<PeerReferral>;
};

/** Autogenerated error type of PeerReferralsUpdateBonusMarginEnabled */
export type PeerReferralsUpdateBonusMarginEnabledError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PeerReferralsUpdateBonusMarginEnabled */
export type PeerReferralsUpdateBonusMarginEnabledInput = {
  bonusMarginEnabled: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PeerReferralsUpdateBonusMarginEnabled. */
export type PeerReferralsUpdateBonusMarginEnabledPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<PeerReferralsUpdateBonusMarginEnabledError>;
  peerReferralProgram?: Maybe<PeerReferralProgram>;
};

/** Photo size filters */
export const PhotoSizeFilters = {
  /** Filters for large photo size */
  LargeSize: 'LARGE_SIZE',
  /** Filters for small photo size */
  SmallSize: 'SMALL_SIZE'
} as const;

export type PhotoSizeFilters = typeof PhotoSizeFilters[keyof typeof PhotoSizeFilters];
/** Category of pills (building blocks of Daily Packs) */
export type PillCategory = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Pill Category display name */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Pill Variants in a category */
  variants?: Maybe<VariantConnection>;
};


/** Category of pills (building blocks of Daily Packs) */
export type PillCategoryVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Plain Patient */
export type PlainPatient = {
  /** Patient's date of birth */
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  /** Patient's email. This may be different from email used to sign in. */
  email?: Maybe<Scalars['String']['output']>;
  /** Patient's first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Patient's id */
  id?: Maybe<Scalars['String']['output']>;
  /** Patient's last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Patient's sex */
  sex?: Maybe<Scalars['String']['output']>;
  /** Patient's phone number */
  smsNumber?: Maybe<Scalars['String']['output']>;
};

/** An aggregated ingredient with dosage as it is prescribed in a plan */
export type PlanIngredient = {
  /** The daily amount of the secondary dosage according to the plan */
  alternativeDosage?: Maybe<Scalars['Float']['output']>;
  /** The secondary unit of the given dosage */
  alternativeDosageUnit?: Maybe<Scalars['String']['output']>;
  /** The child ingredients grouped under this ingredient */
  childIngredients?: Maybe<Array<PlanIngredient>>;
  /** The daily amount of the ingredient according to the plan */
  dosage?: Maybe<Scalars['Float']['output']>;
  /** The unit of the given dosage */
  dosageUnit?: Maybe<Scalars['String']['output']>;
  /** The ingredient */
  ingredient: Ingredient;
  /** The count of the products that include this ingredient */
  productCount?: Maybe<Scalars['Int']['output']>;
  /** The names of the products that include this ingredient */
  productNames?: Maybe<Array<Scalars['String']['output']>>;
};

/** product comparison category for Plan Optimization */
export type PlanOptimizationCategory = {
  /** name for plan optimization category */
  name: Scalars['String']['output'];
  /** The recommendation alternatives for the plan optimization category */
  recommendationAlternatives?: Maybe<Array<RecommendationAlternative>>;
};

/** Plan type filtering options. */
export const PlanTypes = {
  MultiPatientPlan: 'MULTI_PATIENT_PLAN',
  TreatmentPlan: 'TREATMENT_PLAN'
} as const;

export type PlanTypes = typeof PlanTypes[keyof typeof PlanTypes];
/** A union of the different plan types: Treatment Plan, Treatment Plan Template, and Multi Patient Plan. */
export type PlanUnion = MultiPatientPlan | TreatmentPlan | TreatmentPlanTemplate;

/** The plans activated data to be displayed on the store analytics dashboard */
export type PlansActivatedData = {
  /** The last 12 months number of plans activated for the store (excluding the current month) */
  lastTwelveMonths: Scalars['Float']['output'];
  /** The month to date number of plans activated for the store */
  monthToDate: Scalars['Int']['output'];
  /** The plan analytics data for the store */
  storePlanAnalyticsData: Array<StorePlanAnalyticsData>;
  /** The year to date number of plans activated for the store */
  yearToDate: Scalars['Int']['output'];
};

/** Attributes for converting between plans */
export type PlansConvertAttributes = {
  /** TreatmentPlan, TreatmentPlanTemplate, MultiPatientPlan */
  convertTo: Scalars['String']['input'];
  planId: Scalars['ID']['input'];
  planName?: InputMaybe<Scalars['String']['input']>;
  /** TreatmentPlan, TreatmentPlanTemplate, MultiPatientPlan */
  planType: Scalars['String']['input'];
};

/** Autogenerated error type of PlatformRedirect */
export type PlatformRedirectError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PlatformRedirect */
export type PlatformRedirectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  targetPlatformName: StorePlatformType;
};

/** Autogenerated return type of PlatformRedirect. */
export type PlatformRedirectPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PlatformRedirectError;
  redirectPath?: Maybe<Scalars['String']['output']>;
};

/** Type for population (aka demographic) product filters. */
export type Population = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The number of items in a search result that include this population type */
  esResultCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** Name of the population/demographic filter. */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Arguments for sorting populations. */
export type PopulationSort = {
  direction: SortDirection;
  field: PopulationSortField;
};

/** Population sort field */
export const PopulationSortField = {
  /** How often an population is used as a filter during search */
  SearchPopularity: 'SEARCH_POPULARITY'
} as const;

export type PopulationSortField = typeof PopulationSortField[keyof typeof PopulationSortField];
/** Details of a practitioner */
export type Practitioner = Node & Roleable & Timestamps & {
  /** Agreements accepted by the practitioner */
  acceptedAgreements?: Maybe<Array<PractitionerAgreement>>;
  /** Active lab promotions for the practitioner */
  activeLabPromotions?: Maybe<Array<PromotionType>>;
  /** Number of active plans with labs the practitioner has sent */
  activePlansWithLabsCount: Scalars['Int']['output'];
  /** Agreement URL */
  agreementUrl?: Maybe<Scalars['String']['output']>;
  /**
   * Returns true if the owner practitioner's health care credentials or provider
   * id (NPI number) is valid and has been approved
   */
  anyCredentialApproved: Scalars['Boolean']['output'];
  /** At risk products applicable to the practitioner */
  atRiskProducts?: Maybe<Array<Product>>;
  /** Count of at risk variants applicable to the practitioner. */
  atRiskVariantsCount: Scalars['Int']['output'];
  /** Count of at risk variants by plan_type applicable to the practitioner. */
  atRiskVariantsCountByPlanType: AtRiskVariantsCountByPlanType;
  /** Count of at risk variants by status applicable to the practitioner. */
  atRiskVariantsCountByStatus: AtRiskVariantsCountByStatus;
  /** At risk variants applicable to the practitioner. */
  atRiskVariantsV2?: Maybe<AtRiskVariantConnection>;
  /** Avatar for the practitioner */
  avatar?: Maybe<Avatar>;
  /** Whether the practitioner is able to access whole person plan or not */
  canAccessWpp: Scalars['Boolean']['output'];
  /** Whether the practitioner is able to order labs or not */
  canOrderLabs: Scalars['Boolean']['output'];
  /** Certifications/credentials associated with the practitioner. (ex: Chiropractor diploma) */
  certifications: Array<Certification>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** State of the practitioner's onboarding dispensing checklist */
  dispensingChecklist: PractitionerDispensingChecklist;
  /** Practitioner's email. This may be different from email used to sign in. */
  email: Scalars['String']['output'];
  /** emerson practitoner notification email */
  emersonNotificationEmail: Scalars['String']['output'];
  /** Practitioner's external EHR integrations */
  externalIntegrations?: Maybe<Array<ApiExternalMetadatum>>;
  /** All feature requests for the practitioner */
  featureRequests?: Maybe<Array<FeatureRequest>>;
  /** Practitioner's first name */
  firstName: Scalars['String']['output'];
  /** The full name used for the role */
  fullName: Scalars['String']['output'];
  /** Fullscript templates the practitioner has access to */
  fullscriptTemplates: TreatmentPlanTemplateConnection;
  /** Whether the practitioner has accepted the BAA */
  hasAcceptedLabsBaa: Scalars['Boolean']['output'];
  /** Whether the Practitioner has one or more Labs::PractitionerVerification objects in a failed or under_review state */
  hasFailedLabsVerification: Scalars['Boolean']['output'];
  /** Whether the Practitioner is associated to multiple StorePlatforms */
  hasMultipleStorePlatforms: Scalars['Boolean']['output'];
  /** Whether the practitioner has access to financial reports */
  hasReportAccess: Scalars['Boolean']['output'];
  /** Whether the practitioner has sent their first treatment plan with labs */
  hasSentFirstTpWithLabs: Scalars['Boolean']['output'];
  /** Whether the practitioner has sent their fourth treatment plan with labs */
  hasSentFourthTpWithLabs: Scalars['Boolean']['output'];
  /** Whether the practitioner has sent their second treatment plan with labs */
  hasSentSecondTpWithLabs: Scalars['Boolean']['output'];
  /** Whether the practitioner has sent their sixth treatment plan with labs */
  hasSentSixthTpWithLabs: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** All current user's wholesale carts including admin internal carts */
  internalWholesaleCarts?: Maybe<Array<WholesaleOrder>>;
  /** Whether the practitioner is archived */
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the role's user account is locked */
  isLocked: Scalars['Boolean']['output'];
  /** Whether the practitioner is on the labs waitlist */
  isOnLabsWaitlist: Scalars['Boolean']['output'];
  /** Whether the practitioner does not have an associated user */
  isSignupPending: Scalars['Boolean']['output'];
  /** Whether the practitioner's associated user is the owner of the current store */
  isStoreOwner: Scalars['Boolean']['output'];
  /** Indicates whether the practitioner has wellevate mapping or not */
  isWellevate: Scalars['Boolean']['output'];
  /** Whether the practitioner is licensed to order lab tests. */
  labsLicenseStatus?: Maybe<LabsLicenseStatuses>;
  /** The permission level for the practitioner to order labs. */
  labsOrderingPermissionLevel: LabsOrderingPermissionLevels;
  /** Practitioner's last name */
  lastName: Scalars['String']['output'];
  /** Last license information */
  latestLicense?: Maybe<PractitionerLicense>;
  /** Library of Fullscript content */
  libraryContents: ImatContentConnection;
  /** The state of the Wellevate data migration */
  migrationStatus?: Maybe<MigrationStatus>;
  /**
   * A list of multi-patient plans that contain an at-risk variant (e.g.,
   * backordered, discontinued, unavailable, etc.).         Plans that are
   * scheduled to be swapped are filtered out.
   */
  multiPatientPlansWithAtRiskVariant: MultiPatientPlanConnection;
  /** Practitioner's full name including title */
  name: Scalars['String']['output'];
  /** practitoner notification settings */
  notificationSettings?: Maybe<NotificationSettings>;
  /**
   * Practitioner's NPI number
   * @deprecated Use npiNumber instead
   */
  npi?: Maybe<Scalars['String']['output']>;
  /** Practitioner blocked from NPI status */
  npiApprovalState?: Maybe<Scalars['Int']['output']>;
  /** Practitioner's NPI number */
  npiNumber?: Maybe<Scalars['String']['output']>;
  /** Practitioner's active custom panels */
  panels?: Maybe<LabsPanelConnection>;
  /** Panels and Templates the practitioner has access to */
  panelsAndTemplates?: Maybe<TemplateUnionConnection>;
  /** Variants the practitioner has swapped */
  pastSwaps?: Maybe<Array<ReplaceVariant>>;
  /** Number of patients */
  patientCount: Scalars['Int']['output'];
  /** Term used by practitioner either patient or client */
  patientTerm?: Maybe<Scalars['String']['output']>;
  /** State of prescribing personalizations for the practitioner. Used to customize their in-app experience. */
  personalizations: Array<PrescribingPersonalizationValue>;
  /** practitioner product availability preference */
  practitionerProductAvailabilityPreference?: Maybe<PractitionerProductAvailabilityPreference>;
  /** practitioner product request preference */
  practitionerProductRequestPreference?: Maybe<PractitionerProductRequestPreference>;
  /** The specialization of the practitioner. (ex: chiropractor, medical doctor, naturopathic doctor, health coach, etc.) */
  practitionerType: PractitionerType;
  /**
   * Deprecated
   * @deprecated Use 'personalizations' field instead
   */
  prescribingPersonalizations: Array<PrescribingPersonalization>;
  /** Various state and metadata about the practitioner */
  properties?: Maybe<PractitionerProperties>;
  /** Public Profile */
  publicProfile?: Maybe<PublicProfile>;
  /** The Rupa migration configuration for this practitioner */
  rupaMigrationConfiguration?: Maybe<RupaMigrationConfiguration>;
  /** Shared templates the practitioner has access to */
  sharedTemplates: TreatmentPlanTemplateConnection;
  /** Practitioner's shipping state */
  shipState?: Maybe<State>;
  /** Store that the practitioner belongs to */
  store: Store;
  /** The id of the store the role belongs to */
  storeId?: Maybe<Scalars['ID']['output']>;
  /** The name of the store the role belongs to */
  storeName?: Maybe<Scalars['String']['output']>;
  /** The platforms (Fullscript|Emerson) that the practitioner has access to. */
  storePlatforms: Array<StorePlatform>;
  /** Details of the practitioner's student status */
  studentProfile?: Maybe<StudentProfile>;
  /** Practitioner's suffix */
  suffix?: Maybe<Scalars['String']['output']>;
  /** Practitioner's title */
  title?: Maybe<Scalars['String']['output']>;
  /** Full list of recommendations/prescription created by the practitioner but with fewer details */
  treatmentPlanList: Array<TreatmentPlanList>;
  /** Reusable message templates for the practitioner */
  treatmentPlanSnippets: Array<TreatmentPlanSnippet>;
  /**
   * Deprecated
   * @deprecated Use treatmentPlanTemplates in currentOffice instead.
   */
  treatmentPlanTemplates: TreatmentPlanTemplateConnection;
  /** Term used by practitioner either prescription or recommendation */
  treatmentPlanTerm?: Maybe<Scalars['String']['output']>;
  /** Filterable list of recommendations/prescriptions created by the practitioner */
  treatmentPlans: TreatmentPlanConnection;
  /**
   * A list of treatment plans that contain an at-risk variant (e.g., backordered,
   * discontinued, unavailable, etc.).         Plans that are scheduled to be
   * swapped are filtered out.
   */
  treatmentPlansWithAtRiskVariant: TreatmentPlanConnection;
  /** The role type: Admin, Patient, Practitioner, Clerk, or OrganizationManager */
  type: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** UUID */
  uuid: Scalars['String']['output'];
  /** The mapping practitioner from Wellevate */
  wellevatePractitioner?: Maybe<WellevatePractitioner>;
};


/** Details of a practitioner */
export type PractitionerAgreementUrlArgs = {
  agreementType: PractitionerAgreementTypes;
};


/** Details of a practitioner */
export type PractitionerAtRiskVariantsCountArgs = {
  filters?: InputMaybe<AtRiskVariantsFilterObject>;
};


/** Details of a practitioner */
export type PractitionerAtRiskVariantsV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters: AtRiskVariantsFilterObject;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AtRiskVariantsSort>;
};


/** Details of a practitioner */
export type PractitionerCertificationsArgs = {
  storePlatform?: InputMaybe<StorePlatformType>;
};


/** Details of a practitioner */
export type PractitionerFullscriptTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Details of a practitioner */
export type PractitionerLibraryContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LibraryFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FullscriptContentSort>>;
};


/** Details of a practitioner */
export type PractitionerMultiPatientPlansWithAtRiskVariantArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  atRiskVariantId: Scalars['ID']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Details of a practitioner */
export type PractitionerPanelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Details of a practitioner */
export type PractitionerPanelsAndTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PanelTemplateSort>>;
  templateFilters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
};


/** Details of a practitioner */
export type PractitionerPatientCountArgs = {
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Details of a practitioner */
export type PractitionerPersonalizationsArgs = {
  filters?: InputMaybe<PrescribingPersonalizationFilterObject>;
};


/** Details of a practitioner */
export type PractitionerPrescribingPersonalizationsArgs = {
  filters?: InputMaybe<PrescribingPersonalizationFilterObject>;
};


/** Details of a practitioner */
export type PractitionerSharedTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Details of a practitioner */
export type PractitionerTreatmentPlanListArgs = {
  filterObject?: InputMaybe<TreatmentPlanListFilterObject>;
  filters?: InputMaybe<Array<TreatmentPlanFilters>>;
  sort?: InputMaybe<Array<TreatmentPlanSort>>;
};


/** Details of a practitioner */
export type PractitionerTreatmentPlanSnippetsArgs = {
  filters?: InputMaybe<TreatmentPlanSnippetFilterObject>;
  sort?: InputMaybe<Array<TreatmentPlanSnippetSort>>;
};


/** Details of a practitioner */
export type PractitionerTreatmentPlanTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanTemplateFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Details of a practitioner */
export type PractitionerTreatmentPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TreatmentPlanSort>>;
};


/** Details of a practitioner */
export type PractitionerTreatmentPlansWithAtRiskVariantArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  atRiskVariantId: Scalars['ID']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An agreement signed by a practitioner. */
export type PractitionerAgreement = Timestamps & {
  /** Whether this agreement was accepted or not. */
  accepted: Scalars['Boolean']['output'];
  /** Time this agreement was accepted. */
  acceptedAt: Scalars['DateTime']['output'];
  /** The type of agreement. */
  agreementType: PractitionerAgreementTypes;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The practitioner that accepted this agreement. */
  practitioner?: Maybe<Practitioner>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Types of agreements that a practitioner can accept. */
export const PractitionerAgreementTypes = {
  IntegrationBusinessAssociateAgreement: 'integration_business_associate_agreement',
  LabsBusinessAssociateAgreement: 'labs_business_associate_agreement',
  LabsFullscriptTos: 'labs_fullscript_tos',
  LabsNpiSubmission: 'labs_npi_submission',
  LabsPhysicianNetworkTos: 'labs_physician_network_tos',
  PlanWritingAssistantAgreement: 'plan_writing_assistant_agreement'
} as const;

export type PractitionerAgreementTypes = typeof PractitionerAgreementTypes[keyof typeof PractitionerAgreementTypes];
/** Attributes for updating a practitioner and store merchant data */
export type PractitionerAndStoreMerchantDataAttributes = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of PractitionerAndStoreMerchantDataUpdate */
export type PractitionerAndStoreMerchantDataUpdateError = {
  /** Field errors */
  fields?: Maybe<PractitionerAndStoreMerchantDataUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of PractitionerAndStoreMerchantDataUpdate */
export type PractitionerAndStoreMerchantDataUpdateFieldError = {
  businessName?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  postalCode?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress2?: Maybe<Array<Scalars['String']['output']>>;
  suffix?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PractitionerAndStoreMerchantDataUpdate */
export type PractitionerAndStoreMerchantDataUpdateInput = {
  attributes: PractitionerAndStoreMerchantDataAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PractitionerAndStoreMerchantDataUpdate. */
export type PractitionerAndStoreMerchantDataUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: PractitionerAndStoreMerchantDataUpdateError;
  merchantData?: Maybe<MerchantData>;
  practitioner?: Maybe<Practitioner>;
  store?: Maybe<Store>;
};

/** Autogenerated error type of PractitionerArchive */
export type PractitionerArchiveError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PractitionerArchive */
export type PractitionerArchiveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerId: Scalars['ID']['input'];
  /** The ID of the practitioner to reassign the archived practitioner's patients to. */
  substitutePractitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of PractitionerArchive. */
export type PractitionerArchivePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: PractitionerArchiveError;
  practitioner?: Maybe<Practitioner>;
};

/** Attributes for updating a practitioner */
export type PractitionerAttributes = {
  /** Practitioner's email for notifications */
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  npiApprovalState?: InputMaybe<Scalars['Int']['input']>;
  /** Term used by practitioner either patient or client */
  patientTerm?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** Term used by practitioner either prescription or recommendation */
  treatmentPlanTerm?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for uploading a practitioner certification. */
export type PractitionerCertificationAttributes = {
  certificate: Scalars['Upload']['input'];
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for uploading a practitioner certification. */
export type PractitionerCertificationAttributesV2 = {
  certificate?: InputMaybe<Scalars['Upload']['input']>;
  graduationDate?: InputMaybe<Scalars['String']['input']>;
  npiNumber?: InputMaybe<Scalars['String']['input']>;
  overwrites?: InputMaybe<CertificationOverwrites>;
  phone: Scalars['String']['input'];
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
  region: Scalars['String']['input'];
};

/** Attributes for uploading a practitioner certification. */
export type PractitionerCertificationAttributesV3 = {
  certificate?: InputMaybe<Scalars['Upload']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  graduationDate?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  npiNumber?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of PractitionerCertificationCreate */
export type PractitionerCertificationCreateError = {
  /** Field errors */
  fields?: Maybe<PractitionerCertificationCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of PractitionerCertificationCreate */
export type PractitionerCertificationCreateFieldError = {
  certificate?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PractitionerCertificationCreate */
export type PractitionerCertificationCreateInput = {
  attributes: PractitionerCertificationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PractitionerCertificationCreate. */
export type PractitionerCertificationCreatePayload = {
  certification?: Maybe<Certification>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerCertificationCreateError;
};

/** Attributes a loyalist practitioner certification step. */
export type PractitionerCertificationLoyalistAttributes = {
  phone: Scalars['String']['input'];
  region: Scalars['String']['input'];
};

/** Attributes for updating a practitioner certification information. */
export type PractitionerCertificationUpdateAttributes = {
  id: Scalars['ID']['input'];
  /** ID of the State/Province that licensed this certificate */
  licensingStateId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated error type of PractitionerCertificationUpdate */
export type PractitionerCertificationUpdateError = {
  /** Field errors */
  fields?: Maybe<PractitionerCertificationUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of PractitionerCertificationUpdate */
export type PractitionerCertificationUpdateFieldError = {
  id?: Maybe<Array<Scalars['String']['output']>>;
  licensingStateId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PractitionerCertificationUpdate */
export type PractitionerCertificationUpdateInput = {
  attributes: PractitionerCertificationUpdateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PractitionerCertificationUpdate. */
export type PractitionerCertificationUpdatePayload = {
  certification?: Maybe<Certification>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerCertificationUpdateError;
};

/** The connection type for Practitioner. */
export type PractitionerConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PractitionerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Practitioner>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** State of the practitioner's onboarding dispensing checklist */
export type PractitionerDispensingChecklist = {
  /** Whether to render the checklist */
  shouldRenderChecklist: Scalars['Boolean']['output'];
};

/** An edge in a connection. */
export type PractitionerEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Practitioner>;
};

/** Attributes for uploading a practitioner certification. */
export type PractitionerEmersonCertificationAttributes = {
  certificate: Scalars['Upload']['input'];
};

/** Autogenerated error type of PractitionerEmersonNotificationEmailUpdate */
export type PractitionerEmersonNotificationEmailUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PractitionerEmersonNotificationEmailUpdate */
export type PractitionerEmersonNotificationEmailUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of PractitionerEmersonNotificationEmailUpdate. */
export type PractitionerEmersonNotificationEmailUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<PractitionerEmersonNotificationEmailUpdateError>;
  notificationEmail?: Maybe<Scalars['String']['output']>;
};

/** Arguments for filtering practitioners. */
export type PractitionerFilterObject = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  nameAndEmailQuery?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

/** License information a practitioner. */
export type PractitionerLicense = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Expiry date for the license. */
  licenseExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  /** License number */
  licenseNumber?: Maybe<Scalars['String']['output']>;
  /** State abbreviation of the license. */
  licenseState?: Maybe<Scalars['String']['output']>;
  /** Phone number for the practitioner. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The practitioner that accepted this agreement. */
  practitioner: Practitioner;
  /** The degree (practitioner type) for this license */
  practitionerType?: Maybe<PractitionerType>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes for updating a practitioner */
export type PractitionerNotificationSettingsAttributes = {
  notifyLabs?: InputMaybe<Scalars['Boolean']['input']>;
  notifyOrders?: InputMaybe<Scalars['Boolean']['input']>;
  notifyOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  notifyPatients?: InputMaybe<Scalars['Boolean']['input']>;
  notifyProductAvailability?: InputMaybe<Scalars['String']['input']>;
  notifyShipments?: InputMaybe<Scalars['Boolean']['input']>;
};

/** practitioner product availability preference for emails */
export type PractitionerProductAvailabilityPreference = {
  /** the clerk who will receive the product availability emails */
  clerk: Clerk;
};

/** practitioner product request preference for emails */
export type PractitionerProductRequestPreference = {
  /** the clerk who will receive the product access request emails */
  clerk: Clerk;
};

/** Details of practitioners properties */
export type PractitionerProperties = {
  /** Determines whether the 1099k banner is visible */
  canView1099kBanner: Scalars['Boolean']['output'];
  /** Determines whether the tax report is visible */
  canViewTaxReport: Scalars['Boolean']['output'];
  /** Determines whether the practitioner's store has global patients */
  hasGlobalPatients: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Determines whether the practitioner is eligible for a tax exemption */
  isEligibleForTaxExemption: Scalars['Boolean']['output'];
  /** Determines whether the add bank info banner is displayed */
  isGlobalAlertVisible: Scalars['Boolean']['output'];
  /** Determines whether the practitioner's store is never margin */
  isNeverMarginStore: Scalars['Boolean']['output'];
  /** Determines whether the practitioner is the store owner */
  isStoreOwner: Scalars['Boolean']['output'];
  /** Determines whether the practitioner is a student */
  isStudent: Scalars['Boolean']['output'];
  /** Determines whether the practitioner is a student who has yet to graduate */
  isStudentGraduationDateInTheFuture: Scalars['Boolean']['output'];
};

/** States of a lab recommendation, for practitioners */
export const PractitionerRecommendationStates = {
  Approved: 'approved',
  Cancelled: 'cancelled',
  Complete: 'complete',
  Delivered: 'delivered',
  Expired: 'expired',
  Incomplete: 'incomplete',
  InterpretationReady: 'interpretation_ready',
  InterpretationShared: 'interpretation_shared',
  NotPurchased: 'not_purchased',
  PartialResults: 'partial_results',
  Processing: 'processing',
  Purchased: 'purchased',
  PurchasedInOffice: 'purchased_in_office',
  Refunded: 'refunded',
  Rejected: 'rejected',
  ResultsAmended: 'results_amended',
  ResultsReady: 'results_ready',
  ScheduleAppointment: 'schedule_appointment',
  Shipped: 'shipped',
  SpecimenIssue: 'specimen_issue',
  UpcomingAppointment: 'upcoming_appointment'
} as const;

export type PractitionerRecommendationStates = typeof PractitionerRecommendationStates[keyof typeof PractitionerRecommendationStates];
/** Autogenerated error type of PractitionerRequestHarmonizedMigrationVerificationEmail */
export type PractitionerRequestHarmonizedMigrationVerificationEmailError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PractitionerRequestHarmonizedMigrationVerificationEmail */
export type PractitionerRequestHarmonizedMigrationVerificationEmailInput = {
  captchaToken: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of PractitionerRequestHarmonizedMigrationVerificationEmail. */
export type PractitionerRequestHarmonizedMigrationVerificationEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerRequestHarmonizedMigrationVerificationEmailError;
  sent?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated error type of PractitionerRequestSharedAccountVerificationEmail */
export type PractitionerRequestSharedAccountVerificationEmailError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PractitionerRequestSharedAccountVerificationEmail */
export type PractitionerRequestSharedAccountVerificationEmailInput = {
  captchaToken: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  requestedPlatform: Scalars['String']['input'];
};

/** Autogenerated return type of PractitionerRequestSharedAccountVerificationEmail. */
export type PractitionerRequestSharedAccountVerificationEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerRequestSharedAccountVerificationEmailError;
  sent?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated error type of PractitionerResendAccountConsolidationEmail */
export type PractitionerResendAccountConsolidationEmailError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PractitionerResendAccountConsolidationEmail */
export type PractitionerResendAccountConsolidationEmailInput = {
  captchaToken: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of PractitionerResendAccountConsolidationEmail. */
export type PractitionerResendAccountConsolidationEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerResendAccountConsolidationEmailError;
  sent?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated error type of PractitionerRestore */
export type PractitionerRestoreError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PractitionerRestore */
export type PractitionerRestoreInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerId: Scalars['ID']['input'];
};

/** Autogenerated return type of PractitionerRestore. */
export type PractitionerRestorePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: PractitionerRestoreError;
  practitioner?: Maybe<Practitioner>;
};

/** Autogenerated error type of PractitionerSelfEnroll */
export type PractitionerSelfEnrollError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PractitionerSelfEnroll */
export type PractitionerSelfEnrollInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PractitionerSelfEnroll. */
export type PractitionerSelfEnrollPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: PractitionerSelfEnrollError;
  migrationStatus?: Maybe<MigrationStatus>;
};

/** The healthcare field the practitioner is qualified in. */
export type PractitionerType = Timestamps & {
  /** The identifying code for this practitioner type (ex. OD, ND).  */
  code: Scalars['String']['output'];
  /** Whether this type is considered a main type by Fullscript. */
  core: Scalars['Boolean']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The name of the type (ex. Optometrist, Naturopathic Doctor) */
  description: Scalars['String']['output'];
  /** List of popular searches for this practitioner type for explore page. */
  explorePopularSearches?: Maybe<Array<ExplorePopularSearch>>;
  /** Returns true if the practitioner modality has top recommended products */
  hasTopRecommendedProducts?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** The top recommended products for this practitioner type. */
  topRecommendedProducts?: Maybe<ProductConnection>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** The healthcare field the practitioner is qualified in. */
export type PractitionerTypeTopRecommendedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TopRecommendedProductSort>;
};

/** Autogenerated error type of PractitionerTypeUpdate */
export type PractitionerTypeUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PractitionerTypeUpdate */
export type PractitionerTypeUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  practitionerTypeId: Scalars['ID']['input'];
};

/** Autogenerated return type of PractitionerTypeUpdate. */
export type PractitionerTypeUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<PractitionerTypeUpdateError>;
  practitioner?: Maybe<Practitioner>;
};

/** Autogenerated error type of PractitionerUpdate */
export type PractitionerUpdateError = {
  /** Field errors */
  fields?: Maybe<PractitionerUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of PractitionerUpdate */
export type PractitionerUpdateFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  npiApprovalState?: Maybe<Array<Scalars['String']['output']>>;
  patientTerm?: Maybe<Array<Scalars['String']['output']>>;
  suffix?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanTerm?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PractitionerUpdate */
export type PractitionerUpdateInput = {
  attributes: PractitionerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of PractitionerUpdateLoginEmail */
export type PractitionerUpdateLoginEmailError = {
  /** Field errors */
  fields?: Maybe<PractitionerUpdateLoginEmailFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of PractitionerUpdateLoginEmail */
export type PractitionerUpdateLoginEmailFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PractitionerUpdateLoginEmail */
export type PractitionerUpdateLoginEmailInput = {
  attributes: UserEmailAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PractitionerUpdateLoginEmail. */
export type PractitionerUpdateLoginEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: PractitionerUpdateLoginEmailError;
  user?: Maybe<User>;
};

/** Autogenerated return type of PractitionerUpdate. */
export type PractitionerUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: PractitionerUpdateError;
  practitioner?: Maybe<Practitioner>;
};

/** Autogenerated input type of PractitionerVariantNotificationDelete */
export type PractitionerVariantNotificationDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of PractitionerVariantNotificationDelete. */
export type PractitionerVariantNotificationDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Any errors that occur */
  errors: Array<Scalars['String']['output']>;
  /** The variant with subscription removed */
  variant?: Maybe<Variant>;
};

/** Autogenerated input type of PractitionerVariantNotification */
export type PractitionerVariantNotificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of PractitionerVariantNotification. */
export type PractitionerVariantNotificationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  variant?: Maybe<Variant>;
};

/** Options for personalizing the prescribing experience */
export type PrescribingPersonalization = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  /**
   * Whether the setting is enabled for the practitioner
   * @deprecated Use the value on PrescribingPersonalizationValue instead
   */
  isEnabledForPractitioner?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Whether the setting is enabled for the store
   * @deprecated Use the value on PrescribingPersonalizationValue instead
   */
  isEnabledForStore?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the personalization */
  name: Scalars['String']['output'];
  /**
   * Whether the store owner allows sub-practitioners to override this personalization
   * @deprecated Use the value on PrescribingPersonalizationValue instead
   */
  overridableByPractitioner?: Maybe<Scalars['Boolean']['output']>;
  /** Short-hand for the personalization name */
  slug: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Arguments for filtering prescribing personalizations */
export type PrescribingPersonalizationFilterObject = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Prescribing Personalization with a value for a particular practitioner or store */
export type PrescribingPersonalizationValue = {
  /** Identifier for the unique combination of personalization and role */
  id: Scalars['ID']['output'];
  /** Value of the personalization for the practitioner. This may be affected by the store value */
  isEnabledForPractitioner?: Maybe<Scalars['Boolean']['output']>;
  /** Value of the personalization for the store */
  isEnabledForStore?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the store owner allows practitioners to override this personalization's value */
  overridableByPractitioner?: Maybe<Scalars['Boolean']['output']>;
  /** Personalization details */
  prescribingPersonalization: PrescribingPersonalization;
};

/** Details about a product excluding the package size and price */
export type Product = LegacyId & Node & Timestamps & {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
  /** Products that are restricted in some state based on age */
  ageRestrictedStates?: Maybe<Array<AgeRestrictedProductState>>;
  /** Number of all variants including master (includes discontinued variants) */
  allVariantCount: Scalars['Int']['output'];
  /** List of all variants of this product including master (includes discontinued variants) */
  allVariants: Array<Variant>;
  /** Array of allergens aka dietary restrictions */
  allergens: Array<Allergen>;
  /** Number or range for the recommended morning dosage. Only applies for dynamic daily packs. */
  amDosageTotal?: Maybe<Scalars['Int']['output']>;
  /** Checks if the product has any similar products */
  anySimilarProductAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** The available or master variant for this product */
  availableOrMasterVariant: Variant;
  /** The brand that produces the product */
  brand: Brand;
  /** Brand of the variant with personalization message for daily packs */
  brandName: Scalars['String']['output'];
  /** Categories/group of favorites that contain this product */
  categories?: Maybe<CategoryConnection>;
  /** products that are prescribed together */
  complementaryProducts: Array<Product>;
  /** Array of health conditions */
  conditions: Array<HealthCondition>;
  /** Whether the product contains CBD */
  containsCbd: Scalars['Boolean']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The most recent default dosage instruction for the product and the current practitioner */
  defaultDosageInstruction?: Maybe<DefaultDosageInstruction>;
  /** The variant to default to based on patient context */
  defaultPatientVariant?: Maybe<Variant>;
  /** Description of the product. Rich text with html. */
  description?: Maybe<Scalars['String']['output']>;
  /** Number or range for the recommended dosage. (ex: 2-3, 12.5, 1 1/2) */
  dosageAmount?: Maybe<Scalars['String']['output']>;
  /** Format of the physical product. (ex: tablet, capsule, drop) */
  dosageFormat?: Maybe<Scalars['String']['output']>;
  /** Recommended frequency for the dosage. (ex: once per day, three times per day) */
  dosageFrequency?: Maybe<Scalars['String']['output']>;
  /** Usage instructions associate with the product. Rich text with html. */
  dosageInstructions?: Maybe<Scalars['String']['output']>;
  /** Additional dosage instructions. (ex: with meals, take between meals, dissolve in mouth) */
  dosagePermutations?: Maybe<Scalars['String']['output']>;
  /** Description of the Emerson product */
  emersonDescription?: Maybe<Scalars['String']['output']>;
  /** Warnings for an emerson product */
  emersonWarnings?: Maybe<Scalars['String']['output']>;
  /** EQP level of the product's brand . (ex: Gold, Silver, NULL) */
  eqpLevel?: Maybe<EqpLevelType>;
  /** Whether the product has dosage information */
  hasDosage: Scalars['Boolean']['output'];
  /** Whether the product is sensitive to heat */
  heatSensitive?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** URL of product image */
  image: Scalars['String']['output'];
  /** Whether the current office stocks this product in office */
  inOffice: Scalars['Boolean']['output'];
  /** Ingredients for this product */
  ingredients: Array<ProductIngredient>;
  /** Whether the product is featured */
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the product is FS tested */
  isFsTested?: Maybe<Scalars['Boolean']['output']>;
  /** Whether any of the current store's categories/favorites contain this product */
  isInStoreCategories: Scalars['Boolean']['output'];
  /** Consolidated details of the kit_tests of the product */
  kitTestInformation?: Maybe<LabsKitTestInformationType>;
  /** Patient's most recent recommendation for any of the product's variants */
  latestPatientRecommendation?: Maybe<Recommendation>;
  /**
   * The main variant for this product
   * @deprecated Use `availableOrMasterVariant` instead.
   */
  masterVariant: Variant;
  /** megamenu for a product */
  megamenu?: Maybe<Megamenu>;
  /** What method the prop65 product is taken (eg. Consuming or topical) */
  methodOfExposure?: Maybe<Scalars['String']['output']>;
  /** Name of the product */
  name: Scalars['String']['output'];
  /** States where the product cannot be shipped */
  nonShippableStates: Array<State>;
  /** Parsed other ingredients of the product */
  otherIngredients?: Maybe<Scalars['String']['output']>;
  /** Statistics on patients who have been prescribed this product. */
  patientStatistics?: Maybe<ProductPatientStatisticConnection>;
  /** Number or range for the recommended evening dosage. Only applies for dynamic daily packs. */
  pmDosageTotal?: Maybe<Scalars['Int']['output']>;
  /** Whether this product requires a prescription */
  prescriptionOnly: Scalars['Boolean']['output'];
  /** Returns data on products order history */
  productOrderHistory: ProductOrderHistory;
  /** chemicals for a product */
  prop65Chemicals?: Maybe<Array<Prop65Chemical>>;
  /** primary chemical for a product */
  prop65PrimaryChemical?: Maybe<Prop65Chemical>;
  /** risks for a product */
  prop65Risks?: Maybe<Array<Prop65Risk>>;
  /** Residents of California must be notified that this product may contain chemicals that cause cancer or reproductive harm. */
  proposition65: Scalars['Boolean']['output'];
  /** Feedback for recommendation alternatives for this product and given similar product pair */
  recommendationAlternativeFeedback?: Maybe<RecommendationAlternativeFeedback>;
  /** Serving size (number of units that should be taken at a time ) as it appears in the label */
  servingSize?: Maybe<Scalars['Float']['output']>;
  /** Serving unit (e.g. scoops, tablets, etc.) as shown in the label next to serving size */
  servingUnit?: Maybe<Scalars['String']['output']>;
  /** Short form description of the product, in plain text */
  shortDescription?: Maybe<Scalars['String']['output']>;
  /** Whether the product has a short shelf life */
  shortShelfLife?: Maybe<Scalars['Boolean']['output']>;
  /** Products that are similar to this one */
  similarProducts: ProductConnection;
  /** A shorthand code used to identify this product */
  slug?: Maybe<Scalars['String']['output']>;
  /** Whether the current store can purchase this product as wholesale */
  storeAllowedWholesale: Scalars['Boolean']['output'];
  /** Suggested dosage amount */
  suggestedAmount?: Maybe<Scalars['String']['output']>;
  /** Suggested dosage format eg: capsule */
  suggestedFormat?: Maybe<Scalars['String']['output']>;
  /** How often dosage should be taken */
  suggestedFrequency?: Maybe<Scalars['String']['output']>;
  /** Returns the product taxonomy hierarchy */
  taxonomies: Array<ProductTaxonomy>;
  /** product third party certifications */
  thirdPartyCertifications: Array<ThirdPartyCertification>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Number of available variants including master (does not include discontinued variants) */
  variantCount: Scalars['Int']['output'];
  /** The discontinued or at_risk variant for this product */
  variantForSwapOrMaster: Variant;
  /** List of all available variants of this product including master (does not include discontinued variants) */
  variants: Array<Variant>;
  /** warnings for a product */
  warnings?: Maybe<Scalars['String']['output']>;
};


/** Details about a product excluding the package size and price */
export type ProductAllVariantsArgs = {
  filters?: InputMaybe<VariantFilterObject>;
};


/** Details about a product excluding the package size and price */
export type ProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Details about a product excluding the package size and price */
export type ProductPatientStatisticsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Details about a product excluding the package size and price */
export type ProductRecommendationAlternativeFeedbackArgs = {
  similarProductId: Scalars['ID']['input'];
};


/** Details about a product excluding the package size and price */
export type ProductSimilarProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Details about a product excluding the package size and price */
export type ProductVariantsArgs = {
  filters?: InputMaybe<VariantFilterObject>;
};

/** Patients request access to a product */
export type ProductAccessRequest = Timestamps & {
  /** Completed at */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Patient */
  patient: Patient;
  /** Product */
  product: Product;
  /** Recommendation */
  recommendation?: Maybe<Recommendation>;
  /** Requested at */
  requestedAt: Scalars['DateTime']['output'];
  /** Status */
  status: ProductAccessRequestStatuses;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for ProductAccessRequest. */
export type ProductAccessRequestConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductAccessRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProductAccessRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductAccessRequestEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ProductAccessRequest>;
};

/** Product access request statuses */
export const ProductAccessRequestStatuses = {
  Approved: 'APPROVED',
  Pending: 'PENDING',
  Rejected: 'REJECTED'
} as const;

export type ProductAccessRequestStatuses = typeof ProductAccessRequestStatuses[keyof typeof ProductAccessRequestStatuses];
/** Autogenerated error type of ProductAvailabilityPreferenceUpdate */
export type ProductAvailabilityPreferenceUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ProductAvailabilityPreferenceUpdate */
export type ProductAvailabilityPreferenceUpdateInput = {
  /**
   * The clerk to set as the practitioner's product availability preference. If
   * nil, the practitioner will be set as the preference.
   */
  clerkId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ProductAvailabilityPreferenceUpdate. */
export type ProductAvailabilityPreferenceUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: ProductAvailabilityPreferenceUpdateError;
  /** practitioner whos product availability preference are being updated. */
  practitioner?: Maybe<Practitioner>;
};

/** The connection type for Product. */
export type ProductConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Product>;
};

/** Product filter list field */
export const ProductFilterListField = {
  /** Filters for new products on Fullscript */
  NewOnFullscript: 'NEW_ON_FULLSCRIPT',
  /** Filters for popular products by week */
  PopularByWeek: 'POPULAR_BY_WEEK',
  /** Filters for popular wholesale products by week */
  WholesalePopularByWeek: 'WHOLESALE_POPULAR_BY_WEEK'
} as const;

export type ProductFilterListField = typeof ProductFilterListField[keyof typeof ProductFilterListField];
/** Arguments for filtering products. */
export type ProductFilterObject = {
  byBrand?: InputMaybe<Scalars['ID']['input']>;
  list?: InputMaybe<ProductFilterListField>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

/** Details about a product's ingredients */
export type ProductIngredient = Node & Timestamps & {
  /** The amount of the ingredient */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The ingredient of the product */
  ingredient: Ingredient;
  /** The parent ingredient of the ingredient */
  parentIngredient?: Maybe<Ingredient>;
  /** The unit of the ingredient */
  unit?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Overview of interactions for a product */
export type ProductInteractionsOverview = {
  /** Count of high severity interactions */
  majorInteractionCount: Scalars['Int']['output'];
  /** Count of moderate severity interactions */
  moderateInteractionCount: Scalars['Int']['output'];
  /** Product for which interactions are being shown */
  product: Product;
  /** Count of total interaction */
  totalInteractionCount: Scalars['Int']['output'];
};

/** Data on product order history for a patient */
export type ProductOrderHistory = {
  /** Marks if a product has been ordered before */
  hasBeenOrderedBefore: Scalars['Boolean']['output'];
  /** Returns the date that the product was last ordered if it was ordered */
  lastOrdered?: Maybe<Scalars['DateTime']['output']>;
  /** Recommended length we think the user should select based on last ordered */
  recommendedAutoshipLength?: Maybe<Scalars['String']['output']>;
};

/** Provides patient statistics per product */
export type ProductPatientStatistic = Node & {
  id: Scalars['ID']['output'];
  /** Datetime of when the product was last prescribed to the patient */
  lastPrescribedAt: Scalars['DateTime']['output'];
  /** Patient associated with the statistic */
  patient: Patient;
  /** Number of times the product has been prescribed the to the patient */
  prescribedCount: Scalars['Int']['output'];
  /** Product associated with the statistic */
  product: Product;
};

/** The connection type for ProductPatientStatistic. */
export type ProductPatientStatisticConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductPatientStatisticEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProductPatientStatistic>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductPatientStatisticEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ProductPatientStatistic>;
};

/** Autogenerated error type of ProductRequestPreferenceUpdate */
export type ProductRequestPreferenceUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ProductRequestPreferenceUpdate */
export type ProductRequestPreferenceUpdateInput = {
  /**
   * The clerk to set as the practitioner's product access request preference. If
   * nil, the practitioner will be set as the preference.
   */
  clerkId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ProductRequestPreferenceUpdate. */
export type ProductRequestPreferenceUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: ProductRequestPreferenceUpdateError;
  /** practitioner whos product access request preference are being updated. */
  practitioner?: Maybe<Practitioner>;
};

/** The connection type for Product. */
export type ProductSearchConnection = RecordCounts & {
  /** Aggregations of products returned for a search */
  aggregations: Scalars['String']['output'];
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Encoded IDs of products that are pinned */
  pinnedIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Type of search query */
  searchQueryType: ProductSearchQueryType;
  /** Search session ID */
  searchSessionId?: Maybe<Scalars['String']['output']>;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** Types of product search queries */
export const ProductSearchQueryType = {
  /** Organic */
  Organic: 'ORGANIC',
  /** Pinned */
  Pinned: 'PINNED'
} as const;

export type ProductSearchQueryType = typeof ProductSearchQueryType[keyof typeof ProductSearchQueryType];
/** Arguments for product search sorting. */
export type ProductSearchSort = {
  direction: SortDirection;
  field: ProductSearchSortField;
};

/** Product search sort field */
export const ProductSearchSortField = {
  /** sort alphabetically */
  Alphabetical: 'ALPHABETICAL',
  /** Sort by eqp level */
  EqpLevel: 'EQP_LEVEL',
  /** Sort by most prescribed */
  MostPrescribed: 'MOST_PRESCRIBED',
  /** Sort by price */
  Price: 'PRICE',
  /** Sort by price per unit */
  PricePerUnit: 'PRICE_PER_UNIT',
  /** Sort by best match only */
  Relevance: 'RELEVANCE',
  /** Sort by wholesale times ordered */
  WholesaleTimesOrdered: 'WHOLESALE_TIMES_ORDERED'
} as const;

export type ProductSearchSortField = typeof ProductSearchSortField[keyof typeof ProductSearchSortField];
/** The source of the product search */
export const ProductSearchSourceType = {
  /** Direct to Patient Catalog */
  Dtp: 'DTP',
  /** Wholesale Catalog */
  Wholesale: 'WHOLESALE'
} as const;

export type ProductSearchSourceType = typeof ProductSearchSourceType[keyof typeof ProductSearchSourceType];
/** Arguments for filtering product search v2. */
export type ProductSearchV2FilterObject = {
  allergenIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  brandIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  brandSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  childTags?: InputMaybe<Array<Scalars['String']['input']>>;
  conditionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dosageFormatIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  eeNew?: InputMaybe<Scalars['Boolean']['input']>;
  emersonVariantId?: InputMaybe<Scalars['ID']['input']>;
  eqpLevels?: InputMaybe<Array<EqpLevelType>>;
  excludedIngredients?: InputMaybe<Array<IngredientSearchAttributes>>;
  inStockOnly?: InputMaybe<Scalars['Boolean']['input']>;
  includeWholesale?: InputMaybe<Scalars['Boolean']['input']>;
  ingredients?: InputMaybe<Array<IngredientSearchAttributes>>;
  isFsTested?: InputMaybe<Scalars['Boolean']['input']>;
  isPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  onlyIngredient?: InputMaybe<Scalars['Boolean']['input']>;
  onlyShowFavorites?: InputMaybe<Scalars['Boolean']['input']>;
  onlyWholesaleHistory?: InputMaybe<Scalars['Boolean']['input']>;
  optimizedTaxonomyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  populationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sku?: InputMaybe<Scalars['String']['input']>;
  supplementTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  taxonomyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  thirdPartyCertificationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  wholesaleDiscounted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Spotlight for recommending products */
export type ProductSpotlight = Node & {
  id: Scalars['ID']['output'];
  /** categories in the product spotlight */
  productSpotlightCategories: Array<ProductSpotlightCategory>;
  /** Subtitle or description blurb of the spotlight */
  subtitle: Scalars['String']['output'];
  /** Title of the spotlight */
  title: Scalars['String']['output'];
};

/** Categories of recommended products within a spotlight */
export type ProductSpotlightCategory = Node & {
  id: Scalars['ID']['output'];
  /** Products in the category */
  products: ProductConnection;
  /** Title of the category */
  title: Scalars['String']['output'];
};


/** Categories of recommended products within a spotlight */
export type ProductSpotlightCategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Product suggestions for each ingredient */
export type ProductSuggestions = {
  /** Group of ingredient suggestions */
  suggestionGroups: Array<SuggestionGroup>;
};

/** Attributes for product suggestions */
export type ProductSuggestionsAttributes = {
  includeDemographics?: InputMaybe<Scalars['Boolean']['input']>;
  ingredientAttributes?: Array<IngredientAttributes>;
  patientId: Scalars['ID']['input'];
};

/** Type for a product taxonomy record */
export type ProductTaxonomy = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the taxonomy record */
  name: Scalars['String']['output'];
  /** Parent of the taxonomy record */
  parent?: Maybe<ProductTaxonomy>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Profit margin data for the store */
export type ProfitMarginData = {
  /** The last 12 months profit for the store */
  lastTwelveMonths: Scalars['Float']['output'];
  /** The month to date profit margin for the store (excluding the current month) */
  monthToDate: Scalars['Float']['output'];
  /** revenue breakdown for the store */
  storeRevenueAnalyticsData: Array<StoreRevenueAnalyticsData>;
  /** The year to date profit margin for the store */
  yearToDate: Scalars['Float']['output'];
};

/** Promotion */
export type Promotion = Node & Timestamps & {
  /** Campaign associated with the promotion */
  campaign?: Maybe<PromotionCampaign>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the promotion */
  description?: Maybe<Scalars['String']['output']>;
  /** Datetime the promotion expires at */
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Name of the promotion */
  name: Scalars['String']['output'];
  /** Datetime the promotion starts at */
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Promotion campaign */
export type PromotionCampaign = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Datetime the campaign expires at */
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Whether the campaign is active */
  isActive: Scalars['Boolean']['output'];
  /** Name of the campaign */
  name?: Maybe<Scalars['String']['output']>;
  /** Promotions associated with the campaign */
  promotions: Array<Promotion>;
  /** Slug for the campaign */
  slug: Scalars['String']['output'];
  /** Datetime the campaign starts at */
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Promotions for lab tests */
export type PromotionType = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Whether the promotion is active */
  isActive: Scalars['Boolean']['output'];
  /** Whether the promotion is complete for the practitioner */
  isComplete: Scalars['Boolean']['output'];
  /** Name of the promotion */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Union type between different wholesale promotions */
export type PromotionTypeUnion = WholesalePercentOnLineItem | WholesaleTieredPercentItemQuantity | WholesaleTieredPercentItemTotal;

/** The Prop65 Chemical */
export type Prop65Chemical = {
  id: Scalars['ID']['output'];
  /** The Chemical's name */
  name: Scalars['String']['output'];
  /** The Chemical's regulation */
  regulation: Scalars['String']['output'];
};

/** The Prop65 Risk */
export type Prop65Risk = {
  id: Scalars['ID']['output'];
  /** The Risk's name */
  name: Scalars['String']['output'];
  /** The Risk's regulation */
  regulation: Scalars['String']['output'];
};

/** Pubmed conditions with their ingredient recommendations */
export type PubMedCondition = {
  /** The health area the condition falls under */
  healthArea: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Name of the condition */
  name: Scalars['String']['output'];
};

/** Pub med condition information used for typeahead */
export type PubMedConditionTypeahead = {
  /** Pub med condition ID */
  id: Scalars['ID']['output'];
  /** Pub med condition name */
  name: Scalars['String']['output'];
};

/** Public profile of a profileable entity. (practitioner only, store is deprecated) */
export type PublicProfile = Timestamps & {
  /** Public address of profileable */
  address?: Maybe<BaseAddress>;
  /** Biography or description of profileable (practitioner only) */
  bio?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Practitioner's public email address */
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Dispensary name */
  name?: Maybe<Scalars['String']['output']>;
  /** Practitioner's public phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Profileable's website */
  website?: Maybe<Scalars['String']['output']>;
};

/** Attributes for updating a public profile */
export type PublicProfileAttributes = {
  bio?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of PublicProfileUpdate */
export type PublicProfileUpdateError = {
  /** Field errors */
  fields?: Maybe<PublicProfileUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of PublicProfileUpdate */
export type PublicProfileUpdateFieldError = {
  bio?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  publicName?: Maybe<Array<Scalars['String']['output']>>;
  website?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of PublicProfileUpdate */
export type PublicProfileUpdateInput = {
  attributes: PublicProfileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PublicProfileUpdate. */
export type PublicProfileUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: PublicProfileUpdateError;
  /** Public Profile being udpated */
  practitioner?: Maybe<Practitioner>;
};

/** Root of our GraphQL API */
export type Query = {
  /** Admin account of the current user */
  currentAdmin?: Maybe<Admin>;
  /** Clerk account of the current user */
  currentClerk?: Maybe<Clerk>;
  /** Patient account of the current in-office patient */
  currentInOfficePatient?: Maybe<Patient>;
  /** Staff role of the current user */
  currentOffice?: Maybe<Office>;
  /** Organization manager role of the current user */
  currentOrganizationManager?: Maybe<OrganizationManager>;
  /** Patient account of the current user */
  currentPatient?: Maybe<Patient>;
  /** Practitioner account of the current user */
  currentPractitioner?: Maybe<Practitioner>;
  /** Role of the current user */
  currentRole?: Maybe<Roleable>;
  /** Store of the currently logged-in user/role */
  currentStore?: Maybe<Store>;
  /** The current user */
  currentUser?: Maybe<User>;
  /** All available practitioner types */
  practitionerTypes: Array<PractitionerType>;
  /** URL for the sign-in page depending on the user's email. A different sign-in page is used for Fullscript admins */
  signInPath: Scalars['String']['output'];
  /** Domain for fields that are not user-specific */
  viewer?: Maybe<Viewer>;
};


/** Root of our GraphQL API */
export type QuerySignInPathArgs = {
  email: Scalars['String']['input'];
};

/** Search query origin types */
export const QueryOriginTypes = {
  /** Popular Search */
  PopularSearch: 'POPULAR_SEARCH',
  /** Recent Search */
  RecentSearch: 'RECENT_SEARCH',
  /** Standard Search */
  StandardSearch: 'STANDARD_SEARCH'
} as const;

export type QueryOriginTypes = typeof QueryOriginTypes[keyof typeof QueryOriginTypes];
/** Arguments for sorting the recently recommended products for a patient. */
export type RecentlyRecommendedProductSort = {
  direction: SortDirection;
  field?: InputMaybe<RecentlyRecommendedProductSortField>;
};

/** Arguments for sorting recently recommended products. */
export const RecentlyRecommendedProductSortField = {
  /** Sorts alphabetically. */
  Alphabetical: 'ALPHABETICAL',
  /** Sorts by the date on which product was recommended. */
  CreatedAt: 'CREATED_AT',
  /** Sorts by price. */
  Price: 'PRICE'
} as const;

export type RecentlyRecommendedProductSortField = typeof RecentlyRecommendedProductSortField[keyof typeof RecentlyRecommendedProductSortField];
/** Autogenerated input type of RecentlyViewedProductsAdd */
export type RecentlyViewedProductsAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of RecentlyViewedProductsAdd. */
export type RecentlyViewedProductsAddPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  recentlyViewedProducts: ProductConnection;
};


/** Autogenerated return type of RecentlyViewedProductsAdd. */
export type RecentlyViewedProductsAddPayloadRecentlyViewedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of RecentlyViewedProductsClear */
export type RecentlyViewedProductsClearInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RecentlyViewedProductsClear. */
export type RecentlyViewedProductsClearPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  recentlyViewedProducts: ProductConnection;
};


/** Autogenerated return type of RecentlyViewedProductsClear. */
export type RecentlyViewedProductsClearPayloadRecentlyViewedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Details of a recommendation */
export type Recommendation = Node & Timestamps & {
  /** Any additional information from the practitioner */
  additionalInfo?: Maybe<Scalars['String']['output']>;
  /** When a request for a variant has already been filled */
  alreadyRequestedTherapeuticSwitching: Scalars['Boolean']['output'];
  /** Dosage amount */
  amount?: Maybe<Scalars['String']['output']>;
  /** Date on which the recommendation was made available */
  availableAt?: Maybe<Scalars['DateTime']['output']>;
  /** The base dosage format, eg: tablet. Used for selecting structured dosage iconography */
  baseFormat?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** State of the recommendation */
  currentState: Scalars['String']['output'];
  /** How long dosage should be taken for */
  duration?: Maybe<Scalars['String']['output']>;
  /** Dosage format eg: capsule */
  format?: Maybe<Scalars['String']['output']>;
  /** How often dosage should be taken */
  frequency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** String representation of the dosage information */
  instructions?: Maybe<Scalars['String']['output']>;
  /** When recommendation is filled on site by the practitioner */
  isFilledOnSite?: Maybe<Scalars['Boolean']['output']>;
  /** When practitioner dosage information is the same as supplier product dosage */
  isSupplierDosage: Scalars['Boolean']['output'];
  /** When advanced dosage information is missing (dosage_amount, dosage_frequency, dosage_duration, dosage_format) */
  isTextOnly: Scalars['Boolean']['output'];
  /** Multi-patient plan the recommendation belongs to */
  multiPatientPlan?: Maybe<MultiPatientPlan>;
  /** Original recommendation if this is a replacement */
  originalSource?: Maybe<Recommendation>;
  /** ID of the original recommendation if this is a replacement recommendation */
  originalSourceId?: Maybe<Scalars['ID']['output']>;
  /** Patient the recommendation belongs to */
  patient?: Maybe<Patient>;
  /** Practitioner who made the recommendation */
  practitioner: Practitioner;
  /** Recommendation alternative data for given base variant and recommendation alternative's variant */
  recommendationAlternative?: Maybe<VariantWithAlternativesType>;
  /** Substitute recommendations for this recommendation */
  recommendationSubstitutes?: Maybe<Array<RecommendationSubstitute>>;
  /** Shows whether the recommendation can be refilled */
  refillable?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Replace variant record for product swap
   * @deprecated Use swappedRecommendation instead.
   */
  replaceVariant?: Maybe<ReplaceVariant>;
  /**
   * Replacement recommendation if this recommendation has been replaced by a newer one
   * @deprecated Use swappedRecommendation instead.
   */
  replacement?: Maybe<Recommendation>;
  /** The recommendation that preceded this one. */
  swappedRecommendation?: Maybe<Recommendation>;
  /** The take with instructions for the recommendation */
  takeWith?: Maybe<Scalars['String']['output']>;
  /** The dosage time of day list for the recommendation */
  timeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  /** Treatment plan the recommendation belongs to */
  treatmentPlan?: Maybe<TreatmentPlan>;
  /**
   * Dosage quantity
   * @deprecated Removing practitioner ability to select dosage quantity.
   */
  unitsToPurchase?: Maybe<Scalars['Int']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Variant for the recommendation */
  variant: Variant;
  /** Shows whether the recommendation should be taken with food */
  withFood: Scalars['Boolean']['output'];
};


/** Details of a recommendation */
export type RecommendationRecommendationAlternativeArgs = {
  alternativeVariantId: Scalars['ID']['input'];
};

/** Suggesting an alternative product for a given product */
export type RecommendationAlternative = {
  /** The variant to be used for recommendation alternative */
  alternativeVariant: Variant;
  /** The alternative product metric being compared to the base variant */
  alternativeVariantValue?: Maybe<Scalars['String']['output']>;
  /** The product to be used for recommendation alternative */
  baseVariant: Variant;
  /** The base product metric being compared to the recommendation alternative */
  baseVariantValue?: Maybe<Scalars['String']['output']>;
  /** Daily dosage of the alternative_variant (eg. 2 capsules) */
  dailyDosage?: Maybe<Scalars['String']['output']>;
  /** The Recommendation to be used for recommendation alternative */
  recommendation: Recommendation;
};

/** Feedback for a recommendation alternative */
export type RecommendationAlternativeFeedback = Node & Timestamps & {
  /** base product */
  baseProduct: Product;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** array of feedback options provided by user */
  options?: Maybe<Array<Scalars['String']['output']>>;
  /** other option provided by user */
  otherOption?: Maybe<Scalars['String']['output']>;
  /** similar product (alternative product to base product) */
  similarProduct: Product;
  /** thumbs up/down feedback (true -> thumbs up, false -> thumbs down) */
  thumbsUp: Scalars['Boolean']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Delivery methods for lab recommendations */
export const RecommendationDeliveryMethods = {
  BloodLab: 'BloodLab',
  InOffice: 'InOffice',
  ShipFromWarehouse: 'ShipFromWarehouse'
} as const;

export type RecommendationDeliveryMethods = typeof RecommendationDeliveryMethods[keyof typeof RecommendationDeliveryMethods];
/** Event types for a lab recommendation */
export const RecommendationEventTypes = {
  AllResultsReceived: 'all_results_received',
  AuthorizedByNetwork: 'authorized_by_network',
  AutosendPatientRelease: 'autosend_patient_release',
  CancelledAppointment: 'cancelled_appointment',
  Delivered: 'delivered',
  InitialPatientRelease: 'initial_patient_release',
  Processing: 'processing',
  Purchased: 'purchased',
  PurchasedInOffice: 'purchased_in_office',
  RejectedByNetwork: 'rejected_by_network',
  RequisitionReady: 'requisition_ready',
  ResultsAmended: 'results_amended',
  ResultsReceived: 'results_received',
  ResultsSentToPatient: 'results_sent_to_patient',
  ScheduleAppointment: 'schedule_appointment',
  SentToPatient: 'sent_to_patient',
  Shipped: 'shipped',
  SpecimenIssue: 'specimen_issue',
  SubsequentPatientRelease: 'subsequent_patient_release',
  UpcomingAppointment: 'upcoming_appointment'
} as const;

export type RecommendationEventTypes = typeof RecommendationEventTypes[keyof typeof RecommendationEventTypes];
/** Arguments for filtering recommendations. */
export type RecommendationFilterObject = {
  state?: InputMaybe<Array<RecommendationFilters>>;
  variantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Labs Recommendation filter type field */
export const RecommendationFilterTypeObject = {
  /** Returns all Labs::Recommendations */
  All: 'ALL',
  /** Returns only Labs::Recommendations that are part of a Journey */
  OnlyJourneys: 'ONLY_JOURNEYS',
  /** Returns only Labs::Recommendations associated to second orders of Journeys */
  OnlyUpcomingJourneys: 'ONLY_UPCOMING_JOURNEYS',
  /** Returns only Labs::Recommendations that are not part of a Journey */
  WithoutJourneys: 'WITHOUT_JOURNEYS',
  /**
   * Returns both Labs::Recommendations that are not part of a Journey and the
   * prescribed Labs::Recommendation that is part of a Journey
   */
  WithInitialJourneys: 'WITH_INITIAL_JOURNEYS'
} as const;

export type RecommendationFilterTypeObject = typeof RecommendationFilterTypeObject[keyof typeof RecommendationFilterTypeObject];
/** Recommendation filters */
export const RecommendationFilters = {
  /** Filters for active recommendations */
  Active: 'ACTIVE',
  /** Filters for cancelled recommendations */
  Cancelled: 'CANCELLED',
  /** Filters for inactive recommendations */
  Inactive: 'INACTIVE'
} as const;

export type RecommendationFilters = typeof RecommendationFilters[keyof typeof RecommendationFilters];
/** A substitute for an unavailable recommendation. */
export type RecommendationSubstitute = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The current state of the substitute recommendation. */
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The substitute recommendation. */
  recommendation: Recommendation;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Recommended ingredients for a patient */
export type RecommendedIngredient = {
  /** Combination of the parent_id & the ingredient_id used for the cache */
  cacheId?: Maybe<Scalars['String']['output']>;
  /** For when the dosage & evidence vary by demographic */
  demographicVariations?: Maybe<Array<DemographicVariation>>;
  /** Why the ingredient is recommended */
  description: Scalars['String']['output'];
  /** Dosage amount of the ingredient */
  dosageAmount?: Maybe<Scalars['String']['output']>;
  /** Dosage unit of the ingredient */
  dosageUnit?: Maybe<Scalars['String']['output']>;
  /** Articles supporting the recommendation */
  evidence?: Maybe<Array<Evidence>>;
  /** ID of the ingredient */
  id: Scalars['ID']['output'];
  /** When an ingredient is recommended multiple times, is_main will be true for the highest dosage */
  isMain?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the ingredient */
  name: Scalars['String']['output'];
};

/** returns the count of a collection of records */
export type RecordCounts = {
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** Attributes for line items to be refunded */
export type RefundLineItemAttributes = {
  inventoryUnitId: Scalars['ID']['input'];
  returnQuantity?: InputMaybe<Scalars['Int']['input']>;
};

/** Details replaced variants */
export type ReplaceVariant = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Message accompanying the variant replacement */
  message?: Maybe<Scalars['String']['output']>;
  /** Multi-patient plan variant replacement was performed */
  multiPatientPlan?: Maybe<MultiPatientPlan>;
  /** New variant that was swapped */
  newVariant: Variant;
  /** Old variant that was swapped */
  oldVariant: Variant;
  /** Practitioner that performed variant swap */
  practitioner: Practitioner;
  /** Treatment plan where variant replacement was performed */
  treatmentPlan?: Maybe<TreatmentPlan>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes for updating report access for a store's staff */
export type ReportAccessAttributes = {
  clerks?: InputMaybe<Array<Scalars['ID']['input']>>;
  practitioners?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated error type of ReportAccessUpdate */
export type ReportAccessUpdateError = {
  /** Field errors */
  fields?: Maybe<ReportAccessUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of ReportAccessUpdate */
export type ReportAccessUpdateFieldError = {
  clerks?: Maybe<Array<Scalars['String']['output']>>;
  practitioners?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of ReportAccessUpdate */
export type ReportAccessUpdateInput = {
  attributes: ReportAccessAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ReportAccessUpdate. */
export type ReportAccessUpdatePayload = {
  clerks?: Maybe<Array<Clerk>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ReportAccessUpdateError;
  practitioners?: Maybe<Array<Practitioner>>;
};

/** Autogenerated error type of RequestFeature */
export type RequestFeatureError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RequestFeature */
export type RequestFeatureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Type of feature: NUTRIENT_DEPLETION, LAB_RESULTS, CONDITION_SUGGESTIONS */
  featureName: Scalars['String']['input'];
};

/** Autogenerated return type of RequestFeature. */
export type RequestFeaturePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: RequestFeatureError;
  practitioner?: Maybe<Practitioner>;
};

/** details of requested ingredient for whole person plan */
export type RequestedIngredientDetails = {
  /** dosage amount */
  dosageAmount?: Maybe<Scalars['String']['output']>;
  /** dosage unit */
  dosageUnit?: Maybe<Scalars['String']['output']>;
  /** requested ingredient */
  ingredient: Ingredient;
};

/** Article citation that verify the ingredient for the demographic */
export type ResearchAssistantArticle = {
  /** Authors of the article */
  authors: Scalars['String']['output'];
  /** Link to the article */
  link: Scalars['String']['output'];
  /** Title of the article */
  title: Scalars['String']['output'];
};

/** Condition and its recommended ingredients */
export type ResearchAssistantConditionIngredient = {
  /** Name of condition */
  condition: Scalars['String']['output'];
  /** Recommended ingredients for the condition */
  ingredients: Array<ResearchAssistantIngredient>;
};

/** Demographic information for a recommended ingredient */
export type ResearchAssistantDemographic = {
  /** Articles that verify the ingredient for the demographic */
  articles: Array<ResearchAssistantArticle>;
  /** Name of the demographic */
  demographicName: Scalars['String']['output'];
  /** Daily dosage range of ingredient to take */
  dosageRange?: Maybe<Scalars['String']['output']>;
  /** Unit of the dosage */
  dosageUnit?: Maybe<Scalars['String']['output']>;
};

/** Recommended ingredient with demographic information */
export type ResearchAssistantIngredient = {
  /** Demographics for which this ingredient is recommended */
  demographics: Array<ResearchAssistantDemographic>;
  /** How the ingredient helps */
  howItHelps: Scalars['String']['output'];
  /** Name of the ingredient */
  ingredientName: Scalars['String']['output'];
};

/** Prop65 Swap Error */
export type RetailCartProp65SwapError = {
  /** Error type */
  type?: Maybe<Scalars['String']['output']>;
  /** variant */
  variant?: Maybe<Variant>;
};

/** Autogenerated input type of RetailCheckoutAddToCart */
export type RetailCheckoutAddToCartInput = {
  cart: Array<CartItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Practitioner ID needed for InOffice Checkout only when impersonated by a clerk */
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Patient ID needed for InOffice Checkout only */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Flag to indicate if order is for practitioner pay */
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  /** Where the 'add to cart' button was clicked */
  source?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['ID']['input']>;
  treatmentPlanTemplateId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of RetailCheckoutAddToCart. */
export type RetailCheckoutAddToCartPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of RetailCheckoutExpressCheckoutCreate */
export type RetailCheckoutExpressCheckoutCreateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RetailCheckoutExpressCheckoutCreate */
export type RetailCheckoutExpressCheckoutCreateInput = {
  cart: Array<CartAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RetailCheckoutExpressCheckoutCreate. */
export type RetailCheckoutExpressCheckoutCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<RetailCheckoutExpressCheckoutCreateError>;
  order?: Maybe<Order>;
};

/** Autogenerated input type of RetailCheckoutPlaceOrder */
export type RetailCheckoutPlaceOrderInput = {
  billAddressAttributes?: InputMaybe<AddressAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  creditCardAttributes?: InputMaybe<RetailCreditCardAttributes>;
  /** Practitioner ID needed for InOffice Checkout only when impersonated by a clerk */
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Patient ID needed for InOffice Checkout only */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Flag to indicate if order is for practitioner pay */
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  selectedCreditCardId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of RetailCheckoutPlaceOrder. */
export type RetailCheckoutPlaceOrderPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RetailCheckoutPrevalidate */
export type RetailCheckoutPrevalidateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Practitioner ID needed for InOffice Checkout only when impersonated by a clerk */
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Patient ID needed for InOffice Checkout only */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Flag to indicate if order is for practitioner pay */
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of RetailCheckoutPrevalidate. */
export type RetailCheckoutPrevalidatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RetailCheckoutProp65Swap */
export type RetailCheckoutProp65SwapInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Practitioner ID needed for InOffice Checkout only when impersonated by a clerk */
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Patient ID needed for InOffice Checkout only */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Flag to indicate if order is for practitioner pay */
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of RetailCheckoutProp65Swap. */
export type RetailCheckoutProp65SwapPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<RetailCartProp65SwapError>>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RetailCheckoutRemoveLab */
export type RetailCheckoutRemoveLabInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Practitioner ID needed for InOffice Checkout only when impersonated by a clerk */
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of the lab recommendation to remove from the order */
  labRecommendationId: Scalars['ID']['input'];
  /** Patient ID needed for InOffice Checkout only */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Flag to indicate if order is for practitioner pay */
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of RetailCheckoutRemoveLab. */
export type RetailCheckoutRemoveLabPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RetailCheckoutSelectShippingAddress */
export type RetailCheckoutSelectShippingAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  expectedPavrId: Scalars['ID']['input'];
  ignore?: InputMaybe<Scalars['Boolean']['input']>;
  /** Practitioner ID needed for InOffice Checkout only when impersonated by a clerk */
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Patient ID needed for InOffice Checkout only */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Flag to indicate if order is for practitioner pay */
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  selectedOption: Scalars['String']['input'];
};

/** Autogenerated return type of RetailCheckoutSelectShippingAddress. */
export type RetailCheckoutSelectShippingAddressPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RetailCheckoutSelectShippingRate */
export type RetailCheckoutSelectShippingRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Practitioner ID needed for InOffice Checkout only when impersonated by a clerk */
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Patient ID needed for InOffice Checkout only */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Flag to indicate if order is for practitioner pay */
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  shippingMethodId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of RetailCheckoutSelectShippingRate. */
export type RetailCheckoutSelectShippingRatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RetailCheckoutUpdateCart */
export type RetailCheckoutUpdateCartInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Practitioner ID needed for InOffice Checkout only when impersonated by a clerk */
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  lineItemId: Scalars['ID']['input'];
  /** Patient ID needed for InOffice Checkout only */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Flag to indicate if order is for practitioner pay */
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  quantity: Scalars['Int']['input'];
  /** @deprecated No longer used. */
  timeSelected?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RetailCheckoutUpdateCart. */
export type RetailCheckoutUpdateCartPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RetailCheckoutUpdateShippingAddress */
export type RetailCheckoutUpdateShippingAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Practitioner ID needed for InOffice Checkout only when impersonated by a clerk */
  impersonatingPractitionerId?: InputMaybe<Scalars['ID']['input']>;
  /** Patient ID needed for InOffice Checkout only */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Flag to indicate if order is for practitioner pay */
  practitionerPay?: InputMaybe<Scalars['Boolean']['input']>;
  shipAddressAttributes: AddressAttributes;
};

/** Autogenerated return type of RetailCheckoutUpdateShippingAddress. */
export type RetailCheckoutUpdateShippingAddressPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Attributes for adding a credit card to a patient through retail checkout */
export type RetailCreditCardAttributes = {
  avsState?: InputMaybe<Scalars['String']['input']>;
  ccType?: InputMaybe<Scalars['String']['input']>;
  gatewayPaymentProfileId?: InputMaybe<Scalars['String']['input']>;
  lastDigits?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oneTimeUse?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

/** Arguments for filtering roles associated to a user */
export type RoleFilterObject = {
  /** Only retrieve clerk if not archived */
  excludeArchivedClerk?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only retrieve practitioner if not archived */
  excludeArchivedPractitioner?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only retrieve the patients that are not archived */
  notArchivedPatients?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve specified roles */
  roles?: InputMaybe<Array<RoleFilterRoleField>>;
};

/** Filter role field */
export const RoleFilterRoleField = {
  Admin: 'ADMIN',
  Clerk: 'CLERK',
  OrganizationManagers: 'ORGANIZATION_MANAGERS',
  Patients: 'PATIENTS',
  Practitioner: 'PRACTITIONER'
} as const;

export type RoleFilterRoleField = typeof RoleFilterRoleField[keyof typeof RoleFilterRoleField];
/** Role types */
export const RoleTypes = {
  /** Admin role */
  Admin: 'ADMIN',
  /** Clerk role */
  Clerk: 'CLERK',
  /** Organization manager role */
  OrganizationManager: 'ORGANIZATION_MANAGER',
  /** Patient role */
  Patient: 'PATIENT',
  /** Practitioner role */
  Practitioner: 'PRACTITIONER'
} as const;

export type RoleTypes = typeof RoleTypes[keyof typeof RoleTypes];
/** Union type between the different user roles */
export type RoleUnion = Clerk | OrganizationManager | Patient | Practitioner;

/** The roleable classes that users can act as */
export type Roleable = {
  /**
   * Returns true if the owner practitioner's health care credentials or provider
   * id (NPI number) is valid and has been approved
   */
  anyCredentialApproved: Scalars['Boolean']['output'];
  /** The email used for the role */
  email?: Maybe<Scalars['String']['output']>;
  /** The full name used for the role */
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Whether the role's user account is locked */
  isLocked: Scalars['Boolean']['output'];
  /** The id of the store the role belongs to */
  storeId?: Maybe<Scalars['ID']['output']>;
  /** The name of the store the role belongs to */
  storeName?: Maybe<Scalars['String']['output']>;
  /** The role type: Admin, Patient, Practitioner, Clerk, or OrganizationManager */
  type: Scalars['String']['output'];
};

/** Rupa Migration Configuration for a Practitioner */
export type RupaMigrationConfiguration = Node & Timestamps & {
  /** When the configuration was authorized */
  authorizedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the practitioner accounts were confirmed */
  confirmedAccountsAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the connection was confirmed */
  confirmedConnectionAt?: Maybe<Scalars['DateTime']['output']>;
  /** When the migration was confirmed */
  confirmedMigrationAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Whether there are unmapped practitioners */
  needsPractitionerMappings: Scalars['Boolean']['output'];
  /** The Fullscript practitioner */
  practitioner: Practitioner;
  /** Count of practitioner mappings */
  practitionerMappingsCount: Scalars['Int']['output'];
  /** The Rupa practitioner */
  rupaPractitioner: RupaPractitioner;
  /** The Rupa store */
  rupaStore: RupaStore;
  /** When migration is scheduled for */
  scheduledFor?: Maybe<Scalars['DateTime']['output']>;
  /** Current state of the migration configuration */
  state: Scalars['String']['output'];
  /** The Fullscript store */
  store: Store;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Rupa Migration Status for each Store */
export type RupaMigrationStatus = Node & Timestamps & {
  /** Migration completion date */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Tracks the number of favourites we are expecting to import. */
  expectedFavouritesCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the number of lab orders we are expecting to import. */
  expectedLabOrderCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the number of panels we are expecting to import. */
  expectedPanelCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the number of patients we are expecting to import. */
  expectedPatientCount?: Maybe<Scalars['Int']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** Tracks the actual number of favourites we have converted from Rupa into Fullscript records. */
  importedFavouritesCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the actual number of lab orders we have converted from Rupa into Fullscript records. */
  importedLabOrderCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the actual number of panels we have converted from Rupa into Fullscript records. */
  importedPanelCount?: Maybe<Scalars['Int']['output']>;
  /** Tracks the actual number of patients we have converted from Rupa into Fullscript records. */
  importedPatientCount?: Maybe<Scalars['Int']['output']>;
  /** When Migration is scheduled to run */
  scheduledFor?: Maybe<Scalars['DateTime']['output']>;
  /** Status of the Migration (eg. PENDING, SCHEDULED, PROCESSING, COMPLETED or FAILED) */
  status: RupaMigrationStatuses;
  /** The Store associated to the migration */
  store: Store;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** All supported Rupa Migration statuses. */
export const RupaMigrationStatuses = {
  /** Migration successfully completed. */
  Completed: 'COMPLETED',
  /** Migration failed. */
  Failed: 'FAILED',
  /** Migration pending for practitioner to be initiated. */
  Pending: 'PENDING',
  /** Migration in progress. */
  Processing: 'PROCESSING',
  /** Migration has been scheduled . */
  Scheduled: 'SCHEDULED'
} as const;

export type RupaMigrationStatuses = typeof RupaMigrationStatuses[keyof typeof RupaMigrationStatuses];
/** Rupa Practitioner representation */
export type RupaPractitioner = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Associated migration configuration */
  migrationConfiguration?: Maybe<RupaMigrationConfiguration>;
  /** Rupa practitioner identifier */
  rupaId: Scalars['ID']['output'];
  /** Associated Rupa store */
  store?: Maybe<RupaStore>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Associated Rupa user */
  user?: Maybe<RupaUser>;
};

/** Rupa Store representation */
export type RupaStore = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Associated migration configuration */
  migrationConfiguration?: Maybe<RupaMigrationConfiguration>;
  /** Rupa store name */
  name?: Maybe<Scalars['String']['output']>;
  /** Practitioners associated with this store */
  practitioners: Array<RupaPractitioner>;
  /** Rupa store identifier */
  rupaId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Rupa User representation */
export type RupaUser = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Rupa user email */
  email?: Maybe<Scalars['String']['output']>;
  /** Rupa user first name */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Rupa user last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Rupa user phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Associated Rupa practitioner */
  practitioner?: Maybe<RupaPractitioner>;
  /** Rupa user identifier */
  rupaId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The data to be displayed on the store analytics dashboard */
export type SalesData = {
  /** The last 12 months sales for the store (excluding the current month) */
  lastTwelveMonths: Scalars['Float']['output'];
  /** The month to date sales for the store */
  monthToDate: Scalars['Float']['output'];
  /** The sales data for the store */
  storeRevenueAnalyticsData: Array<StoreRevenueAnalyticsData>;
  /** The year to date sales for the store */
  yearToDate: Scalars['Float']['output'];
};

/** Autogenerated error type of SaveDailyPackTemplate */
export type SaveDailyPackTemplateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SaveDailyPackTemplate */
export type SaveDailyPackTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dailyPackId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of SaveDailyPackTemplate. */
export type SaveDailyPackTemplatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dailyPack?: Maybe<DailyPack>;
  errors: SaveDailyPackTemplateError;
};

/** Autogenerated input type of SavePatientDefaultAddress */
export type SavePatientDefaultAddressInput = {
  billingAddressId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of SavePatientDefaultAddress. */
export type SavePatientDefaultAddressPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
};

/** Attributes for collecting events for search filter changes */
export type SearchFilterAttributes = {
  filterContent: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
};

/** Arguments for filtering typeahead search. */
export type SearchFilterObject = {
  list?: InputMaybe<Array<TypeaheadFilterListField>>;
};

/** Autogenerated error type of SearchHistoriesClear */
export type SearchHistoriesClearError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SearchHistoriesClear */
export type SearchHistoriesClearInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SearchHistoriesClear. */
export type SearchHistoriesClearPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SearchHistoriesClearError>;
};

/** Autogenerated error type of SearchHistoriesDelete */
export type SearchHistoriesDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SearchHistoriesDelete */
export type SearchHistoriesDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  searchHistoryId: Scalars['ID']['input'];
};

/** Autogenerated return type of SearchHistoriesDelete. */
export type SearchHistoriesDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SearchHistoriesDeleteError>;
};

/** Autogenerated error type of SearchHistoriesSave */
export type SearchHistoriesSaveError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SearchHistoriesSave */
export type SearchHistoriesSaveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filterId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<ProductSearchSourceType>;
};

/** Autogenerated return type of SearchHistoriesSave. */
export type SearchHistoriesSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: SearchHistoriesSaveError;
};

/** SearchHistory */
export type SearchHistory = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the entity to be used in a filter */
  filterableId?: Maybe<Scalars['ID']['output']>;
  /** The name of the entity to be used in a filter */
  filterableName?: Maybe<Scalars['String']['output']>;
  /** The entity type of filterable_id */
  filterableType?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** The query string */
  query: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Aggregated search session filters */
export type SearchSessionFilters = Node & Timestamps & {
  /** Allergens filter */
  allergens?: Maybe<Array<Allergen>>;
  /** Brands filter */
  brands?: Maybe<Array<Brand>>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Demographics filter */
  demographics?: Maybe<Array<Population>>;
  /** Forms filter */
  forms?: Maybe<Array<DosageFormat>>;
  /** Number of products with fs_tested flag set to true */
  fsTested?: Maybe<Scalars['Int']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** Third-party certifications filter */
  thirdPartyCertifications?: Maybe<Array<ThirdPartyCertification>>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Overview of interactions for a selected product */
export type SelectedProductInteractionsOverview = {
  /** Overview of product interactions */
  generalProductOverview?: Maybe<ProductInteractionsOverview>;
  /** Overview of product interactions with patient's current medications */
  patientRelatedProductOverview?: Maybe<ProductInteractionsOverview>;
};

/** Attributes for requesting self-serve order cancel */
export type SelfServeCancelOrderAttributes = {
  orderNumber: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

/** Attributes for requesting self-serve refund */
export type SelfServeRefundAttributes = {
  orderNumber: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

/** Result of a self-serve refund */
export type SelfServeRefundRma = {
  /** RMA distributor number */
  distributorRmaNumber: Scalars['String']['output'];
  /** Return address indicating to which warehouse items should be returned */
  returnAddress?: Maybe<ShipperAddress>;
};

/** Autogenerated error type of SendWellevatePatientEmail */
export type SendWellevatePatientEmailError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SendWellevatePatientEmail */
export type SendWellevatePatientEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SendWellevatePatientEmail. */
export type SendWellevatePatientEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: SendWellevatePatientEmailError;
};

/** Session note containing information about the patient and prescription/plan */
export type SessionNote = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Patient for this audio session */
  patient: Patient;
  /** patient background info like soap notes/medical history */
  patientInfo?: Maybe<Scalars['String']['output']>;
  /** Patient friendly summary generated */
  patientMessage?: Maybe<Scalars['String']['output']>;
  /** Specific information about the prescription or plan including supplement recommendations */
  prescription?: Maybe<Scalars['String']['output']>;
  /** FS/Imat Resources in JSON format */
  resources?: Maybe<Scalars['String']['output']>;
  /** Supplement list in JSON format */
  supplementList?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for SessionNote. */
export type SessionNoteConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SessionNoteEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SessionNote>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SessionNoteEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SessionNote>;
};

/** Autogenerated error type of SessionNotesCreateResources */
export type SessionNotesCreateResourcesError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SessionNotesCreateResources */
export type SessionNotesCreateResourcesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotSessionNoteId: Scalars['ID']['input'];
};

/** Autogenerated return type of SessionNotesCreateResources. */
export type SessionNotesCreateResourcesPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SessionNotesCreateResourcesError>;
  sessionNote?: Maybe<SessionNote>;
};

/** Autogenerated error type of SessionNotesCreateSummaryMessage */
export type SessionNotesCreateSummaryMessageError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SessionNotesCreateSummaryMessage */
export type SessionNotesCreateSummaryMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotSessionNoteId: Scalars['ID']['input'];
};

/** Autogenerated return type of SessionNotesCreateSummaryMessage. */
export type SessionNotesCreateSummaryMessagePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SessionNotesCreateSummaryMessageError>;
  sessionNote?: Maybe<SessionNote>;
};

/** Autogenerated error type of SessionNotesCreateSupplements */
export type SessionNotesCreateSupplementsError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SessionNotesCreateSupplements */
export type SessionNotesCreateSupplementsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copilotSessionNoteId: Scalars['ID']['input'];
};

/** Autogenerated return type of SessionNotesCreateSupplements. */
export type SessionNotesCreateSupplementsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SessionNotesCreateSupplementsError>;
  sessionNote?: Maybe<SessionNote>;
};

/** Attributes for saving a session note */
export type SessionNotesSaveAttributes = {
  copilotSessionNoteId?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
  /** Patient background info/medical history */
  patientInfo?: InputMaybe<Scalars['String']['input']>;
  /** Specific information about the prescription or plan including supplement recommendations */
  prescription?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of SessionNotesSave */
export type SessionNotesSaveError = {
  /** Field errors */
  fields?: Maybe<SessionNotesSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of SessionNotesSave */
export type SessionNotesSaveFieldError = {
  copilotSessionNoteId?: Maybe<Array<Scalars['String']['output']>>;
  patientId?: Maybe<Array<Scalars['String']['output']>>;
  patientInfo?: Maybe<Array<Scalars['String']['output']>>;
  prescription?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of SessionNotesSave */
export type SessionNotesSaveInput = {
  attributes: SessionNotesSaveAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SessionNotesSave. */
export type SessionNotesSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SessionNotesSaveError>;
  sessionNote?: Maybe<SessionNote>;
};

/** Autogenerated error type of SettingsDispensaryInfoUpdate */
export type SettingsDispensaryInfoUpdateError = {
  /** Field errors */
  fields?: Maybe<SettingsDispensaryInfoUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of SettingsDispensaryInfoUpdate */
export type SettingsDispensaryInfoUpdateFieldError = {
  businessNumber?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  merchantDataAddressAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of SettingsDispensaryInfoUpdate */
export type SettingsDispensaryInfoUpdateInput = {
  attributes: StoreDispensaryInfoAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SettingsDispensaryInfoUpdate. */
export type SettingsDispensaryInfoUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: SettingsDispensaryInfoUpdateError;
  store?: Maybe<Store>;
};

/** Attributes for updating a store's payment and taxes data. */
export type SettingsPaymentsAndTaxesAttributes = {
  accountType: Scalars['String']['input'];
  bankAccountHeldBy?: InputMaybe<Scalars['String']['input']>;
  bankAccountHolder?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankAccountType?: InputMaybe<Scalars['String']['input']>;
  bankRoutingNumber?: InputMaybe<Scalars['String']['input']>;
  /** Business address of a canadian store with business account */
  businessAddress?: InputMaybe<BusinessAddressAttributes>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  classification?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['String']['input'];
  /** Directors of a canadian store with business account */
  directors?: InputMaybe<Array<StripePersonAttributes>>;
  email?: InputMaybe<Scalars['String']['input']>;
  employerId?: InputMaybe<Scalars['String']['input']>;
  /** Owners of a canadian store with business account */
  equityOwners?: InputMaybe<Array<StripePersonAttributes>>;
  firstName: Scalars['String']['input'];
  /** Invoice address for a store. It will be null if using the same address as the merchant data address. */
  invoiceAddress?: InputMaybe<InvoiceAddressAttributes>;
  /** Whether the store representative holds more than 25% of the business */
  isEquityOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isOauth?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  profitMargin?: InputMaybe<Scalars['Int']['input']>;
  region: Scalars['String']['input'];
  ssn: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  /** Setting this to true will delete the invoice address and use the merchant data address as the invoice address. */
  useBusinessAddressAsInvoiceAddress?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Alert severity levels */
export const SeverityEnum = {
  /** Critical alert */
  Critical: 'CRITICAL',
  /** Informational alert */
  Info: 'INFO',
  /** Warning alert */
  Warning: 'WARNING'
} as const;

export type SeverityEnum = typeof SeverityEnum[keyof typeof SeverityEnum];
/** Autogenerated error type of ShipStateUpdate */
export type ShipStateUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ShipStateUpdate */
export type ShipStateUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  stateId: Scalars['ID']['input'];
};

/** Autogenerated return type of ShipStateUpdate. */
export type ShipStateUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ShipStateUpdateError;
  state?: Maybe<State>;
};

/** Shipment of a retail (D2P) order */
export type Shipment = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Date we estimate the shipment will be sent to the customer */
  estimatedShippingDate?: Maybe<Scalars['String']['output']>;
  /** Shipping date formatted for third party clients. */
  friendlyShippedOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** All the inventory units belonging to the shipment */
  inventoryUnits: Array<InventoryUnit>;
  /** All the line items belonging to the shipment */
  lineItems?: Maybe<Array<LineItem>>;
  /** Unique number identifying the shipment */
  number: Scalars['String']['output'];
  /** Date the shipment was sent to the customer */
  shippedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Available shipping rates for the shipment */
  shippingRates?: Maybe<Array<ShippingRate>>;
  /** State of the shipment (eg. shipped, ready, pending) */
  state: Scalars['String']['output'];
  /** The tracking number of the shipment */
  tracking?: Maybe<Scalars['String']['output']>;
  /** Tracking information for the shipment */
  trackingInstance?: Maybe<TrackingType>;
  /** URL with shipment tracking information */
  trackingUrl?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Address of the warehouse from which the products were shipped. */
export type ShipperAddress = Timestamps & {
  /** Address line 1 */
  address1?: Maybe<Scalars['String']['output']>;
  /** Address line 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** Name of city */
  city?: Maybe<Scalars['String']['output']>;
  /** Full name of country */
  country?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** A value indicating whether the address is set as default for the store */
  default?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** A value indicating whether the address is a dropship address */
  isDropship?: Maybe<Scalars['Boolean']['output']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Full name of state or province */
  state?: Maybe<Scalars['String']['output']>;
  /** Id of the state or province of the address. */
  stateId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Zipcode or postal code */
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** Attributes for adding a shipping address to store */
export type ShippingAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  firstname: Scalars['String']['input'];
  isDropship?: InputMaybe<Scalars['Boolean']['input']>;
  lastname: Scalars['String']['input'];
  stateId: Scalars['ID']['input'];
  /** @deprecated Use context.current_store_platform instead */
  storePlatform?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};

/** The connection type for StoreAddress. */
export type ShippingAddressConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StoreAddressEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<StoreAddress>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated error type of ShippingAddressCreate */
export type ShippingAddressCreateError = {
  /** Field errors */
  fields?: Maybe<ShippingAddressCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of ShippingAddressCreate */
export type ShippingAddressCreateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  country?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  isDropship?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of ShippingAddressCreate */
export type ShippingAddressCreateInput = {
  attributes: ShippingAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of ShippingAddressCreate. */
export type ShippingAddressCreatePayload = {
  address?: Maybe<StoreAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ShippingAddressCreateError;
};

/** Autogenerated error type of ShippingAddressDelete */
export type ShippingAddressDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ShippingAddressDelete */
export type ShippingAddressDeleteInput = {
  addressId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ShippingAddressDelete. */
export type ShippingAddressDeletePayload = {
  address?: Maybe<StoreAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ShippingAddressDeleteError;
};

/** Filter arguments for shipping addresses */
export type ShippingAddressFilterObject = {
  isDropship?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Arguments for sorting shipping addresses. */
export type ShippingAddressSort = {
  direction: SortDirection;
  field: ShippingAddressSortField;
};

/** Shipping address sort field */
export const ShippingAddressSortField = {
  /** The shipping address' created_at date */
  CreatedAt: 'CREATED_AT'
} as const;

export type ShippingAddressSortField = typeof ShippingAddressSortField[keyof typeof ShippingAddressSortField];
/** Autogenerated error type of ShippingAddressUpdate */
export type ShippingAddressUpdateError = {
  /** Field errors */
  fields?: Maybe<ShippingAddressUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of ShippingAddressUpdate */
export type ShippingAddressUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  country?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  isDropship?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of ShippingAddressUpdate */
export type ShippingAddressUpdateInput = {
  addressId: Scalars['ID']['input'];
  attributes: ShippingAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of ShippingAddressUpdate. */
export type ShippingAddressUpdatePayload = {
  address?: Maybe<StoreAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ShippingAddressUpdateError;
};

/** Shipping rate for a shipment */
export type ShippingRate = Node & Timestamps & {
  /** The calculated cost of this shipping method */
  cost: Scalars['Float']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The shipping method description, which includes the shipping time */
  description: Scalars['String']['output'];
  /** A date or from+to date range when the shipment is estimated to arrive */
  estimatedShippingDates?: Maybe<Array<Scalars['Date']['output']>>;
  id: Scalars['ID']['output'];
  /** Whether a promotion has granted free shipping */
  isShippingFree: Scalars['Boolean']['output'];
  /** Whether this is the selected shipping rate for the shipment */
  selected: Scalars['Boolean']['output'];
  /** The name of the shipping method */
  shippingMethod: Scalars['String']['output'];
  /** The id of the shipping method */
  shippingMethodId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Set to `true` when shipping method has attached free shipping reward */
  usingReward: Scalars['Boolean']['output'];
};

/** Sort direction */
export const SortDirection = {
  /** Ascending */
  Asc: 'ASC',
  /** Descending */
  Desc: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
/** Attributes for creating a practitioner */
export type StaffSettingsCreatePractitionerAttributes = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  practitionerTypeId: Scalars['ID']['input'];
};

/** Autogenerated error type of StaffSettingsPractitionerCreate */
export type StaffSettingsPractitionerCreateError = {
  /** Field errors */
  fields?: Maybe<StaffSettingsPractitionerCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StaffSettingsPractitionerCreate */
export type StaffSettingsPractitionerCreateFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StaffSettingsPractitionerCreate */
export type StaffSettingsPractitionerCreateInput = {
  attributes: StaffSettingsCreatePractitionerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StaffSettingsPractitionerCreate. */
export type StaffSettingsPractitionerCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StaffSettingsPractitionerCreateError;
  practitioner?: Maybe<Practitioner>;
};

/** Autogenerated error type of StaffSettingsPractitionerUpdate */
export type StaffSettingsPractitionerUpdateError = {
  /** Field errors */
  fields?: Maybe<StaffSettingsPractitionerUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StaffSettingsPractitionerUpdate */
export type StaffSettingsPractitionerUpdateFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  npi?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StaffSettingsPractitionerUpdate */
export type StaffSettingsPractitionerUpdateInput = {
  attributes: StaffSettingsUpdatePractitionerAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StaffSettingsPractitionerUpdate. */
export type StaffSettingsPractitionerUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StaffSettingsPractitionerUpdateError;
  practitioner?: Maybe<Practitioner>;
};

/** Attributes for resending an invitation to a practitioner or clerk that has not signed up */
export type StaffSettingsResendInvitationAttributes = {
  email: Scalars['String']['input'];
  isClerk: Scalars['Boolean']['input'];
};

/** Attributes for updating a practitioner */
export type StaffSettingsUpdatePractitionerAttributes = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  npi?: InputMaybe<Scalars['String']['input']>;
  practitionerTypeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Information related to a state or province */
export type State = Node & Timestamps & {
  /** Abbreviated name of the state or province. */
  abbr: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Full name of the state or province. */
  name?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Alert status values */
export const StatusEnum = {
  /** Alert is currently active */
  Active: 'ACTIVE',
  /** Alert is in draft state */
  Draft: 'DRAFT',
  /** Alert has passed its end date */
  Expired: 'EXPIRED',
  /** Alert has been manually resolved */
  Resolved: 'RESOLVED',
  /** Alert is scheduled to be activated */
  Scheduled: 'SCHEDULED'
} as const;

export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];
/** Details of a given step */
export type StepDetails = {
  /** Name of the step */
  name: Scalars['String']['output'];
  /** Whether or not the step has been completed */
  stepCompleted: Scalars['Boolean']['output'];
};

/** Store */
export type Store = LegacyId & Node & Timestamps & {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
  /** Store's account modality */
  accountModality: Scalars['String']['output'];
  /** Current processing bulk edit process of store */
  activeBulkEditProcess?: Maybe<BulkEditProcess>;
  /** Physical addresses of the store. Used for potential wholesale deliveries. */
  addresses: Array<StoreAddress>;
  /** Avatax account for the store. */
  avataxCustomer?: Maybe<AvataxCustomer>;
  /** Store's bank accounts. */
  bankAccounts: Array<BankAccount>;
  /** Bulk edit processes of store */
  bulkEditProcesses?: Maybe<Array<BulkEditProcess>>;
  /** Summary of patients being bulk edited */
  bulkEditSummary: BulkEditSummary;
  /** The store's 9 digit business number. */
  businessNumber?: Maybe<Scalars['String']['output']>;
  /** The level of visibility that patients have into the catalog */
  catalogPermission: CatalogPermissionTypes;
  /** Groups of store's favorite products. */
  categories: CategoryConnection;
  /** Certifications/credentials associated with the store. (ex: Chiropractor diploma) */
  certifications?: Maybe<Array<Certification>>;
  /** Store's clerks */
  clerks: Array<Clerk>;
  /** Union of both Emerson's Wholesale Orders and Emerson Classic Orders with backordered items */
  combinedEmersonBackorders: EmersonClassicOrderConnection;
  /** Union of both Emerson's Wholesale Orders and Emerson Classic Orders */
  combinedEmersonHistoricOrders: EmersonClassicOrderConnection;
  /** List of years with either Wholesale or Emerson Classic orders. */
  combinedEmersonHistoricOrdersActiveYears: Array<Scalars['String']['output']>;
  /** List of both wholesale & emerson classic line items from completed Wholesale and Emerson Classic orders for the store. */
  combinedEmersonReorderLineItems: EmersonClassicReorderLineItemConnection;
  /** List of products commonly prescribed/recommended by a particular practitioner. Current practitioner is the default. */
  commonlyPrescribedProducts: ProductConnection;
  /**
   * List of variants commonly prescribed/recommended by a particular practitioner. Current practitioner is the default.
   * @deprecated Being replaced by commonly_prescribed_products
   */
  commonlyPrescribedVariants?: Maybe<Array<Variant>>;
  /** Latest completed bulk edit process of store */
  completedBulkEditProcess?: Maybe<BulkEditProcess>;
  /** Email address and phone number for the store. */
  contactDetails: StoreContactDetails;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Current step of on-boarding for the store. (ex: complete, address_information, certificate) */
  currentSetupPage: Scalars['String']['output'];
  /** Directors of the store */
  directors?: Maybe<Array<StripeAccountPersonType>>;
  /** Discount applied to all products for patients of the store (percentage). */
  discount: Scalars['Int']['output'];
  /** Whether store should be prompted about enlarged drawer */
  displayEnlargedDrawer: Scalars['Boolean']['output'];
  /** indicates whether or not the practitioner IA in-app messaging should display automatically for the store */
  displayPracIaMessaging: Scalars['Boolean']['output'];
  /** Whether the store has given consent to have their 1099k forms e-delivered */
  eDeliveryConsent1099k?: Maybe<Scalars['Boolean']['output']>;
  /** Equity owners of the store */
  equityOwners?: Maybe<Array<StripeAccountPersonType>>;
  /** Search for favorited products */
  favoriteSearch: ProductSearchConnection;
  /** Favorite variants (in categories) for the store sorted by name or number of recommendations */
  favoriteVariants: VariantConnection;
  /** The store's current balance formatted as a currency */
  formattedCurrentBalance?: Maybe<Scalars['String']['output']>;
  /** The store's payout date formatted in a readable short form */
  formattedPayoutDate?: Maybe<Scalars['String']['output']>;
  /** Whether the store's patients receive free priority shipping. (Only a handful of stores have this) */
  freePriorityShipping: Scalars['Boolean']['output'];
  /** Whether the store has friend invites enabled */
  friendInvites: Scalars['Boolean']['output'];
  /** Information about the store's upgrade to the new FullScript experience */
  fsExperienceUpgradeStatus?: Maybe<FsExperienceUpgradeStatus>;
  /** global_patient_promotion */
  globalPatientPromotion?: Maybe<GlobalPatientPromotion>;
  /** Whether the store has the 1099k capability enabled */
  has1099kCapability: Scalars['Boolean']['output'];
  /** Whether the store has an active ongoing patient promotion */
  hasActivePatientPromotion: Scalars['Boolean']['output'];
  /** Advanced security enabled for store? */
  hasAdvancedSecurity?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the store has an ready to be activated patient promotion */
  hasAfterCutoffPromotion: Scalars['Boolean']['output'];
  /** Whether the store has at least one approved certification. Includes expired certifications. */
  hasAtLeastOneApprovedCertification?: Maybe<Scalars['Boolean']['output']>;
  /** deprecated */
  hasCertificationForLabs?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the store has certifications that are being reviewed. */
  hasCertificationsUnderReview?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the store has synced wholesale orders with their NP account. */
  hasCompletedNpSync: Scalars['Boolean']['output'];
  /** Is global patient access enabled for store? */
  hasGlobalPatientAccess: Scalars['Boolean']['output'];
  /** Whether the store has grandfather bonus margin */
  hasGrandfatherBonusMargin: Scalars['Boolean']['output'];
  /** Whether the store is associated to multiple StorePlatforms */
  hasMultipleStorePlatforms: Scalars['Boolean']['output'];
  /** Whether the store has synced with their NP account. */
  hasNpCustomer: Scalars['Boolean']['output'];
  /** Whether the store has at least one NPI approved certification. Includes expired certifications. */
  hasNpiApprovedCertification?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the store has unexpired tax certificates (Avatax). */
  hasUnexpiredWholesaleTaxCertificate?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** submitted A&C form submitted by oauth application external type */
  integrationAuthorizationConsent?: Maybe<IntegrationAuthorizationConsent>;
  /** Whether a store should be prompted to select an intention or not */
  intentionSelectionRequired: Scalars['Boolean']['output'];
  /** Indicates whether the store owner has updated the address information */
  isAddressInfoCompleted: Scalars['Boolean']['output'];
  /** is_approved_to_send_orders_to_distributor */
  isApprovedToSendOrdersToDistributor?: Maybe<Scalars['Boolean']['output']>;
  /** Whether checkout is enabled for patients of this store. */
  isCheckoutEnabled: Scalars['Boolean']['output'];
  /** Indicates whether the store is currently in a cohort being prompted to move to the FS 2.0 experience */
  isInFs2UpgradePeriod?: Maybe<Scalars['Boolean']['output']>;
  /**
   * deprecated
   * @deprecated All stores are now supplement plan stores
   */
  isSupplementPlanStore?: Maybe<Scalars['Boolean']['output']>;
  /** Global custom fee for the store */
  labGlobalCustomFee?: Maybe<StoreGlobalCustomFee>;
  /** List of signing practitioners that have been set on the store. */
  labSigningPractitioners: Array<Practitioner>;
  /** Indicates whether the store makes a profit on sold products. (ex: never, variable) */
  marginType: Scalars['String']['output'];
  /** Store's business owner's details. */
  merchantData: MerchantData;
  /** informational values from the store for a range of days from current day (e.g. last 30 days) */
  metricsForRange?: Maybe<StoreMetrics>;
  /** Name of the store */
  name: Scalars['String']['output'];
  /** Net terms associated with the store */
  netTerms?: Maybe<Array<NetTerm>>;
  /** List of synced orders placed on NP. */
  npOrders: NpOrderConnection;
  /** Number of Avatax certificates about to expired within the next month. */
  numberOfExpiringAvataxCertificates: Scalars['Int']['output'];
  /** Details about store's onboarding completion. */
  onboarding?: Maybe<StoreOnboarding>;
  /** The organization the store belongs to */
  organization?: Maybe<Organization>;
  /** partner_stores */
  partnerStores: PartnerStoreConnection;
  /** List of patient discount options for a store. */
  patientDiscountIncrements: Array<Scalars['Int']['output']>;
  /** List of complete patient orders for the store. */
  patientOrders: OrderConnection;
  /** Opt_in status for future/active store's patient promotion */
  patientPromotionOptInStatus?: Maybe<Scalars['Boolean']['output']>;
  /** Searchable list of patients for the store. */
  patients: PatientSearchConnection;
  /** State of prescribing personalizations for the store. Used to customize their in-app experience. */
  personalizations: Array<PrescribingPersonalizationValue>;
  /** Searchable list of practitioners for the store. */
  practitionerSearch: PractitionerConnection;
  /** Store's practitioners */
  practitioners: Array<Practitioner>;
  /**
   * Deprecated
   * @deprecated Use 'personalizations' instead
   */
  prescribingPersonalizations: Array<PrescribingPersonalization>;
  /** Whether the store has profit disclosure in emails enabled */
  profitDisclosure: Scalars['Boolean']['output'];
  /** Practitioner profit margin value. (Returns null if its a no profit store) */
  profitMargin?: Maybe<Scalars['Float']['output']>;
  /** List of profit margin options available for a store. */
  profitMarginIncrements?: Maybe<Array<Scalars['Int']['output']>>;
  /**
   * Deprecated
   * @deprecated Public profiles for stores are deprecated
   */
  publicProfile?: Maybe<PublicProfile>;
  /** whether the store is eligible for 1099k this year */
  reached1099kThreshold: Scalars['Boolean']['output'];
  /** The same as marginType but includes 'pending_variable' */
  realMarginType: Scalars['String']['output'];
  /** List of products recently recommended to a particular patient. */
  recentlyRecommendedProducts: ProductConnection;
  /** Referral link for the store. (URL) */
  referralLink: Scalars['String']['output'];
  /** The state of the Rupa data migration */
  rupaMigrationStatus?: Maybe<RupaMigrationStatus>;
  /** Store settings */
  settings: StoreSettings;
  /** List of shipping addresses saved for store. */
  shippingAddresses: ShippingAddressConnection;
  /** Short-hand identifier for the store. Used in the store's welcome page URL. */
  slug: Scalars['String']['output'];
  /** Additional discounts for the store */
  storeAdditionalDiscounts?: Maybe<Array<StoreAdditionalDiscount>>;
  /** Details about store's in-office kit stock */
  storeIokStockDetails: StoreIokStockDetails;
  /** Details for the store's landing page */
  storeLandingPage?: Maybe<StoreLandingPage>;
  /** Full name of the store's owner. (on practitioner account) */
  storeOwnerFullName: Scalars['String']['output'];
  /** Full name of the store's owner. (on user account) */
  storeOwnerName: Scalars['String']['output'];
  /** Patient term the store owner uses. (patient, client) */
  storeOwnerPatientTerm: Scalars['String']['output'];
  /** Treatment plan term the store owner uses. (prescription, recommendation) */
  storeOwnerTreatmentPlanTerm: Scalars['String']['output'];
  /** Details about store's setup progress. */
  storeSetup: StoreSetup;
  /** Information about the store's tax reports */
  storeTaxReports?: Maybe<StoreTaxReports>;
  /** Patient promotion that the store has opted in to. (ex: Cyber Monday, June Sale) */
  storesPatientPromotions?: Maybe<StoresPatientPromotionConnection>;
  /** State of the store's Stripe account. */
  stripeAccountState?: Maybe<StripeAccountState>;
  /** Store's practitioners that are not the store owner */
  subPractitioners: Array<Practitioner>;
  /** List of treatment plans for the store. */
  treatmentPlans: TreatmentPlanConnection;
  /** The total number of patients without an assigned practitioner. */
  unassignedPatientsCount: Scalars['Int']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** List of complete wellevate patient orders for the store. */
  wellevatePatientOrders: WellevateOrderConnection;
  /** Whether the store is eligible for wholesale. (Wholesale checkout also requires that the store is complete) */
  wholesale: Scalars['Boolean']['output'];
  /** List of placed wholesale orders for the store. */
  wholesaleOrders: WholesaleOrderConnection;
  /** List of years with wholesale orders. */
  wholesaleOrdersActiveYears: Array<Scalars['String']['output']>;
  /** List of wholesale line items from completed wholesale orders for the store. */
  wholesaleReorderLineItems: WholesaleLineItemConnection;
};


/** Store */
export type StoreAddressesArgs = {
  filterObject?: InputMaybe<StoreAddressFilterObject>;
};


/** Store */
export type StoreBulkEditSummaryArgs = {
  filters?: InputMaybe<PatientBulkEditFilterObject>;
  newDiscount?: InputMaybe<Scalars['Int']['input']>;
  selectAllFilters?: InputMaybe<PatientBulkEditAllFilterObject>;
};


/** Store */
export type StoreCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CategorySort>>;
};


/** Store */
export type StoreClerksArgs = {
  filters?: InputMaybe<ClerkFilterObject>;
};


/** Store */
export type StoreCombinedEmersonBackordersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CombinedOrdersFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EmersonClassicCombinedOrdersSort>>;
};


/** Store */
export type StoreCombinedEmersonHistoricOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CombinedOrdersFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EmersonClassicCombinedOrdersSort>>;
};


/** Store */
export type StoreCombinedEmersonReorderLineItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CombinedReorderLineItemsFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CombinedReorderLineItemsSort>>;
};


/** Store */
export type StoreCommonlyPrescribedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  sort?: InputMaybe<CommonlyPrescribedProductSort>;
};


/** Store */
export type StoreCommonlyPrescribedVariantsArgs = {
  filters?: InputMaybe<CommonlyPrescribedVariantFilterObject>;
};


/** Store */
export type StoreFavoriteSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<FavoriteSearchSort>>;
};


/** Store */
export type StoreFavoriteVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<FavoriteVariantFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<FavoriteVariantSort>;
};


/** Store */
export type StoreIntegrationAuthorizationConsentArgs = {
  oauthApplicationId: Scalars['ID']['input'];
};


/** Store */
export type StoreMetricsForRangeArgs = {
  rangeInDays?: InputMaybe<Scalars['Int']['input']>;
};


/** Store */
export type StoreNpOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<NpOrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<NpOrderSort>>;
};


/** Store */
export type StorePartnerStoresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PartnerStoreFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Store */
export type StorePatientOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrderSort>>;
};


/** Store */
export type StorePatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PatientsFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Store */
export type StorePersonalizationsArgs = {
  filters?: InputMaybe<PrescribingPersonalizationFilterObject>;
};


/** Store */
export type StorePractitionerSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<PractitionerFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Store */
export type StorePractitionersArgs = {
  filters?: InputMaybe<PractitionerFilterObject>;
};


/** Store */
export type StorePrescribingPersonalizationsArgs = {
  filters?: InputMaybe<PrescribingPersonalizationFilterObject>;
};


/** Store */
export type StoreRecentlyRecommendedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['ID']['input'];
  sort?: InputMaybe<RecentlyRecommendedProductSort>;
};


/** Store */
export type StoreShippingAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ShippingAddressFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ShippingAddressSort>>;
};


/** Store */
export type StoreStoreSetupArgs = {
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};


/** Store */
export type StoreStoresPatientPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<StoresPatientPromotionFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Store */
export type StoreSubPractitionersArgs = {
  filters?: InputMaybe<PractitionerFilterObject>;
};


/** Store */
export type StoreTreatmentPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TreatmentPlanFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TreatmentPlanSort>>;
};


/** Store */
export type StoreWellevatePatientOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrderSort>>;
};


/** Store */
export type StoreWholesaleOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<WholesaleOrderFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WholesaleOrderSort>>;
};


/** Store */
export type StoreWholesaleReorderLineItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<WholesaleLineItemFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WholesaleLineItemSort>>;
};

/** Additional discounts that a store has set up */
export type StoreAdditionalDiscount = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The type of additional discount being given. */
  discountType: StoreAdditionalDiscountTypes;
  /** The discount percentage being given */
  discountValue?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** The store the discount belongs to */
  store: Store;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes for setting store's additional discounts. */
export type StoreAdditionalDiscountAttributes = {
  discountType: StoreAdditionalDiscountTypes;
  discountValue: Scalars['Int']['input'];
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Store Additional Discount Types */
export const StoreAdditionalDiscountTypes = {
  /** Autoship discount */
  Autoship: 'AUTOSHIP'
} as const;

export type StoreAdditionalDiscountTypes = typeof StoreAdditionalDiscountTypes[keyof typeof StoreAdditionalDiscountTypes];
/** Autogenerated error type of StoreAdditionalDiscountsUpdate */
export type StoreAdditionalDiscountsUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of StoreAdditionalDiscountsUpdate */
export type StoreAdditionalDiscountsUpdateInput = {
  additionalDiscounts?: InputMaybe<Array<StoreAdditionalDiscountAttributes>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  discount: Scalars['Int']['input'];
};

/** Autogenerated return type of StoreAdditionalDiscountsUpdate. */
export type StoreAdditionalDiscountsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreAdditionalDiscountsUpdateError;
  store?: Maybe<Store>;
};

/** An address associated with a store, used in wholesale ordering */
export type StoreAddress = Timestamps & {
  /** Address line 1 */
  address1: Scalars['String']['output'];
  /** Address line 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** State of approval for the address (eg. pending, cleared, approved). */
  approvalStatus: StoreAddressApprovalStates;
  /** Name of city */
  city: Scalars['String']['output'];
  /** Full name of country */
  country: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** A value indicating whether the address is set as default for the store */
  default?: Maybe<Scalars['Boolean']['output']>;
  /** First name of the user whose address this is */
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** A value indicating whether the address is a dropship address */
  isDropship?: Maybe<Scalars['Boolean']['output']>;
  /** Last name of the user whose address this is */
  lastname: Scalars['String']['output'];
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** State or province of the address. */
  state: State;
  /** Id of the state or province of the address. */
  stateId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Zipcode or postal code */
  zipcode: Scalars['String']['output'];
};

/** Store address approval states */
export const StoreAddressApprovalStates = {
  /** The address has been approved to receive orders. */
  Cleared: 'cleared',
  /** The address is not eligible to receive orders. */
  Failed: 'failed',
  /** The address has been flagged as held by our system, and hasn't been checked by compliance officers yet. */
  Held: 'held',
  /** Address has gone through the system before and been cleared, next time through will be passed. */
  Passed: 'passed',
  /**
   * Initial state of an address. This indicates there have been no checks
   * whatsoever. Addresses will not stay in this state for long.
   */
  Pending: 'pending',
  /** The address is marked as invalid for use. */
  Rejected: 'rejected',
  /** The address is eligible for an order to take place, but will be held afterward. */
  Skipped: 'skipped'
} as const;

export type StoreAddressApprovalStates = typeof StoreAddressApprovalStates[keyof typeof StoreAddressApprovalStates];
/** Attributes for adding an address to a store */
export type StoreAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  default?: InputMaybe<Scalars['Boolean']['input']>;
  firstname: Scalars['String']['input'];
  isDropship?: InputMaybe<Scalars['Boolean']['input']>;
  lastname: Scalars['String']['input'];
  stateId: Scalars['ID']['input'];
  zipcode: Scalars['String']['input'];
};

/** Autogenerated error type of StoreAddressCreate */
export type StoreAddressCreateError = {
  /** Field errors */
  fields?: Maybe<StoreAddressCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreAddressCreate */
export type StoreAddressCreateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  default?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  isDropship?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreAddressCreate */
export type StoreAddressCreateInput = {
  attributes: StoreAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreAddressCreate. */
export type StoreAddressCreatePayload = {
  address?: Maybe<StoreAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreAddressCreateError;
};

/** Autogenerated error type of StoreAddressDelete */
export type StoreAddressDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of StoreAddressDelete */
export type StoreAddressDeleteInput = {
  addressId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreAddressDelete. */
export type StoreAddressDeletePayload = {
  address?: Maybe<StoreAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreAddressDeleteError;
};

/** An edge in a connection. */
export type StoreAddressEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<StoreAddress>;
};

/** Arguments for store addresses. */
export type StoreAddressFilterObject = {
  includeDropship?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Array<StoreAddressStates>>;
};

/** Autogenerated error type of StoreAddressNewUpdate */
export type StoreAddressNewUpdateError = {
  /** Field errors */
  fields?: Maybe<StoreAddressNewUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreAddressNewUpdate */
export type StoreAddressNewUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  default?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  isDropship?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreAddressNewUpdate */
export type StoreAddressNewUpdateInput = {
  addressId: Scalars['ID']['input'];
  attributes: StoreAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreAddressNewUpdate. */
export type StoreAddressNewUpdatePayload = {
  address?: Maybe<StoreAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreAddressNewUpdateError;
};

/** Store address states */
export const StoreAddressStates = {
  /** Approved addresses (passed, or cleared) */
  Approved: 'APPROVED',
  /** Held addresses (held, pending, or skipped) */
  Held: 'HELD'
} as const;

export type StoreAddressStates = typeof StoreAddressStates[keyof typeof StoreAddressStates];
/** Autogenerated error type of StoreAddressUpdate */
export type StoreAddressUpdateError = {
  /** Field errors */
  fields?: Maybe<StoreAddressUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreAddressUpdate */
export type StoreAddressUpdateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  default?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  isDropship?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreAddressUpdate */
export type StoreAddressUpdateInput = {
  addressId: Scalars['ID']['input'];
  attributes: StoreAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreAddressUpdate. */
export type StoreAddressUpdatePayload = {
  address?: Maybe<StoreAddress>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreAddressUpdateError;
};

/** The revenue data store analytics dashboard */
export type StoreAutorefillAnalyticsData = {
  /** The number of autoship orders for the store */
  autorefillOrders: Scalars['Int']['output'];
  /** Sales amount from autorefills */
  autorefillSales: Scalars['Float']['output'];
  /** The number of new autorefill patients for the store and month */
  firstTimeAutorefillPatients: Scalars['Int']['output'];
  /** Month of the analytics data */
  month: Scalars['String']['output'];
  /** Year of the analytics data */
  year: Scalars['Int']['output'];
};

/** Attributes for saving clerk */
export type StoreClerkAttributes = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

/** Information about the store */
export type StoreContactDetails = {
  /** The store owner's email */
  email: Scalars['String']['output'];
};

/** Attributes for updating the store's current setup page. */
export type StoreCurrentSetupPageAttributes = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  forProfit?: InputMaybe<Scalars['Boolean']['input']>;
  skipToStep?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of StoreCurrentSetupPageUpdate */
export type StoreCurrentSetupPageUpdateError = {
  /** Field errors */
  fields?: Maybe<StoreCurrentSetupPageUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreCurrentSetupPageUpdate */
export type StoreCurrentSetupPageUpdateFieldError = {
  apiClientId?: Maybe<Array<Scalars['String']['output']>>;
  forProfit?: Maybe<Array<Scalars['String']['output']>>;
  skipToStep?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreCurrentSetupPageUpdate */
export type StoreCurrentSetupPageUpdateInput = {
  attributes?: InputMaybe<StoreCurrentSetupPageAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreCurrentSetupPageUpdate. */
export type StoreCurrentSetupPageUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreCurrentSetupPageUpdateError;
  store: Store;
};

/** Attributes for store dispensary info */
export type StoreDispensaryInfoAttributes = {
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  merchantDataAddressAttributes?: InputMaybe<MerchantDataAddressAttributes>;
};

/** Global custom fee for a store */
export type StoreGlobalCustomFee = Node & Timestamps & {
  /** The amount of the custom fee */
  amount: Scalars['Float']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The name of the custom fee */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Information about in-office kit stock for store */
export type StoreIokStockDetails = {
  /** Number of in-office kits available to purchase */
  availableIokStock: Scalars['Int']['output'];
  /** Indicates if store can order more in-office kits */
  canOrder: Scalars['Boolean']['output'];
  /** Current in-office kit stock */
  currentIokStock: Scalars['Int']['output'];
  /** Maximum number of in-office kits the store may stock at once */
  maximumIokStock: Scalars['Int']['output'];
};

/** Autogenerated error type of StoreLabAutosendInterpretationDisable */
export type StoreLabAutosendInterpretationDisableError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of StoreLabAutosendInterpretationDisable */
export type StoreLabAutosendInterpretationDisableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreLabAutosendInterpretationDisable. */
export type StoreLabAutosendInterpretationDisablePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabAutosendInterpretationDisableError;
  store?: Maybe<Store>;
};

/** Attributes for updating a store's autosend interpretation settings. */
export type StoreLabAutosendInterpretationUpdateAttributes = {
  /** The number of days to wait before autosending a store's interpretations (0 right away up to 7 days) */
  delay?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated error type of StoreLabAutosendInterpretationUpdate */
export type StoreLabAutosendInterpretationUpdateError = {
  /** Field errors */
  fields?: Maybe<StoreLabAutosendInterpretationUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreLabAutosendInterpretationUpdate */
export type StoreLabAutosendInterpretationUpdateFieldError = {
  delay?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreLabAutosendInterpretationUpdate */
export type StoreLabAutosendInterpretationUpdateInput = {
  attributes: StoreLabAutosendInterpretationUpdateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreLabAutosendInterpretationUpdate. */
export type StoreLabAutosendInterpretationUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabAutosendInterpretationUpdateError;
  store?: Maybe<Store>;
};

/** Attributes for applying a custom fee to a plan */
export type StoreLabCustomFeeApplyAttributes = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['ID']['input'];
  treatmentPlanId: Scalars['ID']['input'];
};

/** Attributes for adding/removing/updating a custom fee to a store */
export type StoreLabCustomFeeAttributes = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['ID']['input'];
};

/** Autogenerated error type of StoreLabCustomFeesAddToStore */
export type StoreLabCustomFeesAddToStoreError = {
  /** Field errors */
  fields?: Maybe<StoreLabCustomFeesAddToStoreFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreLabCustomFeesAddToStore */
export type StoreLabCustomFeesAddToStoreFieldError = {
  amount?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreLabCustomFeesAddToStore */
export type StoreLabCustomFeesAddToStoreInput = {
  attributes: StoreLabCustomFeeAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreLabCustomFeesAddToStore. */
export type StoreLabCustomFeesAddToStorePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabCustomFeesAddToStoreError;
  store?: Maybe<Store>;
};

/** Autogenerated error type of StoreLabCustomFeesApplyToPlan */
export type StoreLabCustomFeesApplyToPlanError = {
  /** Field errors */
  fields?: Maybe<StoreLabCustomFeesApplyToPlanFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreLabCustomFeesApplyToPlan */
export type StoreLabCustomFeesApplyToPlanFieldError = {
  amount?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
  treatmentPlanId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreLabCustomFeesApplyToPlan */
export type StoreLabCustomFeesApplyToPlanInput = {
  attributes: StoreLabCustomFeeApplyAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreLabCustomFeesApplyToPlan. */
export type StoreLabCustomFeesApplyToPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabCustomFeesApplyToPlanError;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** Autogenerated error type of StoreLabCustomFeesRemoveFromStore */
export type StoreLabCustomFeesRemoveFromStoreError = {
  /** Field errors */
  fields?: Maybe<StoreLabCustomFeesRemoveFromStoreFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreLabCustomFeesRemoveFromStore */
export type StoreLabCustomFeesRemoveFromStoreFieldError = {
  amount?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreLabCustomFeesRemoveFromStore */
export type StoreLabCustomFeesRemoveFromStoreInput = {
  attributes: StoreLabCustomFeeAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreLabCustomFeesRemoveFromStore. */
export type StoreLabCustomFeesRemoveFromStorePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabCustomFeesRemoveFromStoreError;
  store?: Maybe<Store>;
};

/** Autogenerated error type of StoreLabCustomFeesUpdate */
export type StoreLabCustomFeesUpdateError = {
  /** Field errors */
  fields?: Maybe<StoreLabCustomFeesUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreLabCustomFeesUpdate */
export type StoreLabCustomFeesUpdateFieldError = {
  amount?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreLabCustomFeesUpdate */
export type StoreLabCustomFeesUpdateInput = {
  attributes: StoreLabCustomFeeAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreLabCustomFeesUpdate. */
export type StoreLabCustomFeesUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabCustomFeesUpdateError;
  store?: Maybe<Store>;
};

/** Attributes for adding a signing practitioner to a store */
export type StoreLabSigningPractitionerAddToStoreAttributes = {
  practitionerId: Scalars['ID']['input'];
};

/** Attributes for removing a signing practitioner from a store */
export type StoreLabSigningPractitionerRemoveFromStoreAttributes = {
  practitionerId: Scalars['ID']['input'];
};

/** Autogenerated error type of StoreLabSigningPractitionersAddToStore */
export type StoreLabSigningPractitionersAddToStoreError = {
  /** Field errors */
  fields?: Maybe<StoreLabSigningPractitionersAddToStoreFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreLabSigningPractitionersAddToStore */
export type StoreLabSigningPractitionersAddToStoreFieldError = {
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreLabSigningPractitionersAddToStore */
export type StoreLabSigningPractitionersAddToStoreInput = {
  attributes: StoreLabSigningPractitionerAddToStoreAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreLabSigningPractitionersAddToStore. */
export type StoreLabSigningPractitionersAddToStorePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabSigningPractitionersAddToStoreError;
  store?: Maybe<Store>;
};

/** Autogenerated error type of StoreLabSigningPractitionersRemoveFromStore */
export type StoreLabSigningPractitionersRemoveFromStoreError = {
  /** Field errors */
  fields?: Maybe<StoreLabSigningPractitionersRemoveFromStoreFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreLabSigningPractitionersRemoveFromStore */
export type StoreLabSigningPractitionersRemoveFromStoreFieldError = {
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreLabSigningPractitionersRemoveFromStore */
export type StoreLabSigningPractitionersRemoveFromStoreInput = {
  attributes: StoreLabSigningPractitionerRemoveFromStoreAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreLabSigningPractitionersRemoveFromStore. */
export type StoreLabSigningPractitionersRemoveFromStorePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLabSigningPractitionersRemoveFromStoreError;
  store?: Maybe<Store>;
};

/** Store landing page information */
export type StoreLandingPage = {
  /** Url for the store logo */
  logo?: Maybe<Scalars['String']['output']>;
  /** Dispensary Name */
  name: Scalars['String']['output'];
  /** Store's url slug */
  slug: Scalars['String']['output'];
};

/** Attributes for updating a store's landing page */
export type StoreLandingPageUpdateAttributes = {
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['ID']['input'];
};

/** Autogenerated error type of StoreLandingPageUpdate */
export type StoreLandingPageUpdateError = {
  /** Field errors */
  fields?: Maybe<StoreLandingPageUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreLandingPageUpdate */
export type StoreLandingPageUpdateFieldError = {
  logo?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  slug?: Maybe<Array<Scalars['String']['output']>>;
  storeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreLandingPageUpdate */
export type StoreLandingPageUpdateInput = {
  attributes: StoreLandingPageUpdateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreLandingPageUpdate. */
export type StoreLandingPageUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreLandingPageUpdateError;
  store?: Maybe<Store>;
};

/** informational values from the store for a range of days from current day (e.g. last 30 days) */
export type StoreMetrics = {
  /** # of active patients within range */
  activePatientsCount: Scalars['Int']['output'];
  /** total discounts applied within range */
  discountsTotal: Scalars['Float']['output'];
  /** total revenue earned within range */
  earnedTotal: Scalars['Float']['output'];
  /** # of orders placed within range */
  ordersCount: Scalars['Int']['output'];
  /** total # of unarchived patients */
  patientsCount: Scalars['Int']['output'];
  /** # of active patients from previous range */
  previousActivePatientsCount: Scalars['Int']['output'];
  /** total revenue earned from previous range */
  previousEarnedTotal: Scalars['Float']['output'];
  /** # of orders placed from previous range */
  previousOrdersCount: Scalars['Int']['output'];
};

/** Store onboarding */
export type StoreOnboarding = {
  /** Boolean for whether a practitioner has added credentials */
  hasAddedCredentials: Scalars['Boolean']['output'];
  /** Boolean for if practitioner has added staff (method) */
  hasAddedStaff: Scalars['Boolean']['output'];
  /** Boolean for dismissing 'Has added staff' modal */
  hasAddedStaffDismissed: Scalars['Boolean']['output'];
  /** Boolean field for checking if practitioner has answered the Sonic Onboarding Questions */
  hasAnsweredOnboardingQuestions?: Maybe<Scalars['Boolean']['output']>;
  /** Boolean for if practitioner has connected EHR(method) */
  hasConnectedEhr: Scalars['Boolean']['output'];
  /** Boolean for dismissing 'Has connected EHR' modal */
  hasConnectedEhrDismissed: Scalars['Boolean']['output'];
  /** Boolean for if practitioner has profit account (method) */
  hasProfitAccount: Scalars['Boolean']['output'];
  /** Boolean for dismissing 'Has added profit account' modal */
  hasProfitAccountDismissed: Scalars['Boolean']['output'];
  /** Boolean for if practitioner has registered for labs (method) */
  hasRegisteredForLabs: Scalars['Boolean']['output'];
  /** Boolean for dismissing 'Has registered for labs' modal */
  hasRegisteredForLabsDismissed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /**
   * The date at which intentions were last updated by the user. Will be nil if no
   * intentions have been submitted by the store owner
   */
  intentionUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Indicates whether the store owner has indicated interest in using Fullscript for labs purposes */
  labsIntention: Scalars['Boolean']['output'];
  /** String field to record the Practice Size from the onboarding questions */
  onboardingQuestionsPracticeSize?: Maybe<Scalars['String']['output']>;
  /** Indicates whether the store owner has indicated interest in using Fullscript for treatment plan purposes */
  rxIntention: Scalars['Boolean']['output'];
  /** Indicates whether the store stocks items in office for resale */
  stockInOffice?: Maybe<Scalars['Boolean']['output']>;
  /** The associated store */
  store: Store;
  /** Indicates whether the store owner has indicated interest in using Fullscript for wholesale purposes */
  wholesaleIntention: Scalars['Boolean']['output'];
};

/** Attributes for updating a store's onboarding selections */
export type StoreOnboardingAttributes = {
  hasAddedStaffDismissed?: InputMaybe<Scalars['Boolean']['input']>;
  hasAnsweredOnboardingQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  hasConnectedEhrDismissed?: InputMaybe<Scalars['Boolean']['input']>;
  hasProfitAccountDismissed?: InputMaybe<Scalars['Boolean']['input']>;
  hasRegisteredLabsDismissed?: InputMaybe<Scalars['Boolean']['input']>;
  justSeeSoftware?: InputMaybe<Scalars['Boolean']['input']>;
  labsIntention?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingQuestionsPracticeSize?: InputMaybe<Scalars['String']['input']>;
  rxIntention?: InputMaybe<Scalars['Boolean']['input']>;
  stockInOffice?: InputMaybe<Scalars['Boolean']['input']>;
  wholesaleIntention?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of StoreOnboardingUpdate */
export type StoreOnboardingUpdateError = {
  /** Field errors */
  fields?: Maybe<StoreOnboardingUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreOnboardingUpdate */
export type StoreOnboardingUpdateFieldError = {
  hasAddedStaffDismissed?: Maybe<Array<Scalars['String']['output']>>;
  hasAnsweredOnboardingQuestions?: Maybe<Array<Scalars['String']['output']>>;
  hasConnectedEhrDismissed?: Maybe<Array<Scalars['String']['output']>>;
  hasProfitAccountDismissed?: Maybe<Array<Scalars['String']['output']>>;
  hasRegisteredLabsDismissed?: Maybe<Array<Scalars['String']['output']>>;
  justSeeSoftware?: Maybe<Array<Scalars['String']['output']>>;
  labsIntention?: Maybe<Array<Scalars['String']['output']>>;
  onboardingQuestionsPracticeSize?: Maybe<Array<Scalars['String']['output']>>;
  rxIntention?: Maybe<Array<Scalars['String']['output']>>;
  stockInOffice?: Maybe<Array<Scalars['String']['output']>>;
  wholesaleIntention?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreOnboardingUpdate */
export type StoreOnboardingUpdateInput = {
  attributes: StoreOnboardingAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreOnboardingUpdate. */
export type StoreOnboardingUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreOnboardingUpdateError;
  storeOnboarding?: Maybe<StoreOnboarding>;
};

/** The revenue data store analytics dashboard */
export type StoreOrderAnalyticsData = {
  /** The number of first time ordering patients for the store  */
  firstOrderingPatients: Scalars['Int']['output'];
  /** The number of first time orders for the store  */
  firstOrders: Scalars['Int']['output'];
  /** Month of the analytics data */
  month: Scalars['String']['output'];
  /** The number of repeat ordering patients for the store */
  repeatOrderingPatients: Scalars['Int']['output'];
  /** The number of repeat orders for the store */
  repeatOrders: Scalars['Int']['output'];
  /** The total ordering patients for the store */
  totalOrderingPatients: Scalars['Int']['output'];
  /** The total orders for the store */
  totalOrders: Scalars['Int']['output'];
  /** Year of the analytics data */
  year: Scalars['Int']['output'];
};

/** The plan data for the store analytics dashboard */
export type StorePlanAnalyticsData = {
  /** The number of activated treatment plans for the store for the month */
  activatedTreatmentPlans: Scalars['Int']['output'];
  /** Month of the analytics data */
  month: Scalars['String']['output'];
  /** treatment plan conversion rate for the store */
  treatmentPlanConversionRate: Scalars['Float']['output'];
  /** Year of the analytics data */
  year: Scalars['Int']['output'];
};

/** Store platform */
export type StorePlatform = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the store platform */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Store platform type */
export const StorePlatformType = {
  Emerson: 'EMERSON',
  Fullscript: 'FULLSCRIPT'
} as const;

export type StorePlatformType = typeof StorePlatformType[keyof typeof StorePlatformType];
/** Attributes for setting store's profit margin. */
export type StoreProfitMarginAttributes = {
  profitMargin?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated error type of StoreProfitMarginUpdate */
export type StoreProfitMarginUpdateError = {
  /** Field errors */
  fields?: Maybe<StoreProfitMarginUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreProfitMarginUpdate */
export type StoreProfitMarginUpdateFieldError = {
  profitMargin?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreProfitMarginUpdate */
export type StoreProfitMarginUpdateInput = {
  attributes: StoreProfitMarginAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreProfitMarginUpdate. */
export type StoreProfitMarginUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoreProfitMarginUpdateError>;
  store?: Maybe<Store>;
};

/** Autogenerated error type of StoreResetToAccountStepUpdate */
export type StoreResetToAccountStepUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of StoreResetToAccountStepUpdate */
export type StoreResetToAccountStepUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreResetToAccountStepUpdate. */
export type StoreResetToAccountStepUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreResetToAccountStepUpdateError;
  store: Store;
};

/** The revenue data store analytics dashboard */
export type StoreRevenueAnalyticsData = {
  /** Month of the analytics data */
  month: Scalars['String']['output'];
  /** Profit amount from multi-patient plans */
  multiPatientPlanProfit: Scalars['Float']['output'];
  /** Sales amount from multi-patient plans */
  multiPatientPlanSales: Scalars['Float']['output'];
  /** Profit amount from self-serve products */
  selfServeProfit: Scalars['Float']['output'];
  /** Sales amount from self-serve products */
  selfServeSales: Scalars['Float']['output'];
  /** Total profit amount across all categories */
  totalProfit: Scalars['Float']['output'];
  /** Total sales amount across all categories */
  totalSales: Scalars['Float']['output'];
  /** Profit amount from treatment plans */
  treatmentPlanProfit: Scalars['Float']['output'];
  /** Sales amount from treatment plans */
  treatmentPlanSales: Scalars['Float']['output'];
  /** Year of the analytics data */
  year: Scalars['Int']['output'];
};

/** StoreSettings */
export type StoreSettings = {
  /** The store's autosend interpretation value in days. */
  autosendInterpretationDelayDays?: Maybe<Scalars['Int']['output']>;
  /** The store's default patient first order discount. */
  firstOrderDiscountDefault?: Maybe<Scalars['Int']['output']>;
  /** Whether the first order discount field is frozen for new patients. */
  firstOrderDiscountFrozen: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The store's default patient discount. */
  patientDiscountDefault?: Maybe<Scalars['Int']['output']>;
  /** Whether the discount field is frozen for new patients. */
  patientDiscountFrozen: Scalars['Boolean']['output'];
};

/** Attributes for store settings */
export type StoreSettingsAttributes = {
  hasAdvancedSecurity?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Store setup information */
export type StoreSetup = {
  /** Current setup step */
  currentStep: Scalars['String']['output'];
  /** Index of the current setup step */
  currentStepNumber: Scalars['Int']['output'];
  /** Identifier for the StoreSetup */
  id: Scalars['ID']['output'];
  /** Returns whether setup is complete */
  isSetupComplete: Scalars['Boolean']['output'];
  /** Next setup step */
  nextStep: NextStep;
  /** Previous setup step */
  previousStep?: Maybe<Scalars['String']['output']>;
  /** List of step details */
  stepDetails: Array<StepDetails>;
  /** Total number of steps for each account type */
  totalStepCount: TotalStepCount;
};

/** Autogenerated error type of StoreSetupEmersonSubmitAddressInformation */
export type StoreSetupEmersonSubmitAddressInformationError = {
  /** Field errors */
  fields?: Maybe<StoreSetupEmersonSubmitAddressInformationFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreSetupEmersonSubmitAddressInformation */
export type StoreSetupEmersonSubmitAddressInformationFieldError = {
  city?: Maybe<Array<Scalars['String']['output']>>;
  graduationDate?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  postalCode?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress2?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreSetupEmersonSubmitAddressInformation */
export type StoreSetupEmersonSubmitAddressInformationInput = {
  attributes: EmersonMerchantDataAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreSetupEmersonSubmitAddressInformation. */
export type StoreSetupEmersonSubmitAddressInformationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoreSetupEmersonSubmitAddressInformationError>;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
  studentProfile?: Maybe<StudentProfile>;
};

/** Autogenerated error type of StoreSetupEmersonSubmitCertUpload */
export type StoreSetupEmersonSubmitCertUploadError = {
  /** Field errors */
  fields?: Maybe<StoreSetupEmersonSubmitCertUploadFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreSetupEmersonSubmitCertUpload */
export type StoreSetupEmersonSubmitCertUploadFieldError = {
  certificate?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreSetupEmersonSubmitCertUpload */
export type StoreSetupEmersonSubmitCertUploadInput = {
  attributes: PractitionerEmersonCertificationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreSetupEmersonSubmitCertUpload. */
export type StoreSetupEmersonSubmitCertUploadPayload = {
  certification?: Maybe<Certification>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreSetupEmersonSubmitCertUploadError;
  store?: Maybe<Store>;
};

/** Autogenerated error type of StoreSetupMoveSetupToComplete */
export type StoreSetupMoveSetupToCompleteError = {
  /** Field errors */
  fields?: Maybe<StoreSetupMoveSetupToCompleteFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreSetupMoveSetupToComplete */
export type StoreSetupMoveSetupToCompleteFieldError = {
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreSetupMoveSetupToComplete */
export type StoreSetupMoveSetupToCompleteInput = {
  attributes: MoveToSetupCompleteAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreSetupMoveSetupToComplete. */
export type StoreSetupMoveSetupToCompletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoreSetupMoveSetupToCompleteError>;
  store?: Maybe<Store>;
};

/** Autogenerated error type of StoreSetupSubmitAddressInformation */
export type StoreSetupSubmitAddressInformationError = {
  /** Field errors */
  fields?: Maybe<StoreSetupSubmitAddressInformationFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreSetupSubmitAddressInformation */
export type StoreSetupSubmitAddressInformationFieldError = {
  accountType?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountHeldBy?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountHolder?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountNumber?: Maybe<Array<Scalars['String']['output']>>;
  bankAccountType?: Maybe<Array<Scalars['String']['output']>>;
  bankRoutingNumber?: Maybe<Array<Scalars['String']['output']>>;
  businessAddress?: Maybe<Array<Scalars['String']['output']>>;
  businessName?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  directors?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  employerId?: Maybe<Array<Scalars['String']['output']>>;
  equityOwners?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  invoiceAddress?: Maybe<Array<Scalars['String']['output']>>;
  isEquityOwner?: Maybe<Array<Scalars['String']['output']>>;
  jobTitle?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  postalCode?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress2?: Maybe<Array<Scalars['String']['output']>>;
  useBusinessAddressAsInvoiceAddress?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreSetupSubmitAddressInformation */
export type StoreSetupSubmitAddressInformationInput = {
  attributes: MerchantDataAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreSetupSubmitAddressInformation. */
export type StoreSetupSubmitAddressInformationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoreSetupSubmitAddressInformationError>;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
};

/** Autogenerated error type of StoreSetupSubmitCertUploadV2 */
export type StoreSetupSubmitCertUploadV2Error = {
  /** Payload for the error */
  errorPayload?: Maybe<Scalars['JSON']['output']>;
  /** Fields the error occured on */
  fields?: Maybe<StoreSetupSubmitCertUploadV2FieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreSetupSubmitCertUploadV2 */
export type StoreSetupSubmitCertUploadV2FieldError = {
  certificate?: Maybe<Array<Scalars['String']['output']>>;
  graduationDate?: Maybe<Array<Scalars['String']['output']>>;
  npiNumber?: Maybe<Array<Scalars['String']['output']>>;
  overwrites?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreSetupSubmitCertUploadV2 */
export type StoreSetupSubmitCertUploadV2Input = {
  attributes: PractitionerCertificationAttributesV2;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreSetupSubmitCertUploadV2. */
export type StoreSetupSubmitCertUploadV2Payload = {
  certification?: Maybe<Certification>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreSetupSubmitCertUploadV2Error;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
  studentProfile?: Maybe<StudentProfile>;
};

/** Autogenerated error type of StoreSetupSubmitCertUploadV3 */
export type StoreSetupSubmitCertUploadV3Error = {
  /** Payload for the error */
  errorPayload?: Maybe<Scalars['JSON']['output']>;
  /** Fields the error occured on */
  fields?: Maybe<StoreSetupSubmitCertUploadV3FieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreSetupSubmitCertUploadV3 */
export type StoreSetupSubmitCertUploadV3FieldError = {
  certificate?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  graduationDate?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  npiNumber?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreSetupSubmitCertUploadV3 */
export type StoreSetupSubmitCertUploadV3Input = {
  attributes: PractitionerCertificationAttributesV3;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreSetupSubmitCertUploadV3. */
export type StoreSetupSubmitCertUploadV3Payload = {
  certification?: Maybe<Certification>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreSetupSubmitCertUploadV3Error;
  npiData?: Maybe<Scalars['JSON']['output']>;
  store?: Maybe<Store>;
  studentProfile?: Maybe<StudentProfile>;
};

/** Autogenerated error type of StoreSetupSubmitLoyalistCert */
export type StoreSetupSubmitLoyalistCertError = {
  /** Field errors */
  fields?: Maybe<StoreSetupSubmitLoyalistCertFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreSetupSubmitLoyalistCert */
export type StoreSetupSubmitLoyalistCertFieldError = {
  phone?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreSetupSubmitLoyalistCert */
export type StoreSetupSubmitLoyalistCertInput = {
  attributes: PractitionerCertificationLoyalistAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreSetupSubmitLoyalistCert. */
export type StoreSetupSubmitLoyalistCertPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: StoreSetupSubmitLoyalistCertError;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
};

/** Autogenerated error type of StoreSetupSubmitStateAndPhoneInformation */
export type StoreSetupSubmitStateAndPhoneInformationError = {
  /** Field errors */
  fields?: Maybe<StoreSetupSubmitStateAndPhoneInformationFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoreSetupSubmitStateAndPhoneInformation */
export type StoreSetupSubmitStateAndPhoneInformationFieldError = {
  phone?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoreSetupSubmitStateAndPhoneInformation */
export type StoreSetupSubmitStateAndPhoneInformationInput = {
  attributes: MerchantDataAddressConfirmationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoreSetupSubmitStateAndPhoneInformation. */
export type StoreSetupSubmitStateAndPhoneInformationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoreSetupSubmitStateAndPhoneInformationError>;
  merchantData?: Maybe<MerchantData>;
  store?: Maybe<Store>;
};

/** Store Tax Reports Type */
export type StoreTaxReports = {
  /** The active bank account number */
  activeBankAccount: BankAccount;
  /** A URL for the 1099-K form, which has already been filed for the requested year. */
  annual1099kFiledReportUrl?: Maybe<Scalars['String']['output']>;
  /** Array of years for the 1099K form */
  annual1099kFiledReportYears?: Maybe<Array<Scalars['String']['output']>>;
  /** A URL with the requested report, for a specific year */
  annualReportUrl?: Maybe<Scalars['String']['output']>;
  /** Array of years for the annual report (1099K/T4A) */
  annualReportYears: Array<Scalars['String']['output']>;
  /** Array of years for the balance report */
  balanceExportYears: Array<Scalars['String']['output']>;
  /** The store's balance sheet of selected year */
  balanceSheet?: Maybe<BalanceSheet>;
  /** The amount of balance */
  currentBalance?: Maybe<Scalars['String']['output']>;
  /** At a glance review of an earnings report given year and payout periods */
  earningsReport?: Maybe<EarningsReport>;
  /** Array of years for the earnings report */
  earningsReportYears: Array<Scalars['String']['output']>;
  /** Formatted string for total MSRP including the currency (ex: '$123,456.78') */
  msrpAllTimeCurrency: Scalars['Currency']['output'];
  /** Formatted string for total MSRP in the last 30 days including the currency (ex: '$123,456.78') */
  msrpPast30DaysCurrency: Scalars['Currency']['output'];
  /** Formatted string for store's MSRP for the past year including the currency (ex: '$123,456.78') */
  msrpPastYearCurrency: Scalars['Currency']['output'];
  /** The next payout date */
  nextPayoutDate?: Maybe<Scalars['String']['output']>;
  /** Payout date */
  payoutDates: Array<PayoutDate>;
  /** Information about a specific payout report on the store */
  payoutReport?: Maybe<PayoutReportsType>;
  /** Whether or not the store has any 1099K forms to download */
  shouldDisplay1099kFiledDownloads: Scalars['Boolean']['output'];
  /** Whether or not the store has any 1099K reports */
  shouldDisplay1099kReports: Scalars['Boolean']['output'];
  /** Whether or not to display the practitioner earnings report */
  shouldDisplayPractitionerEarningsReports?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the store has any T4A reports */
  shouldDisplayT4aReports: Scalars['Boolean']['output'];
  /** Whether or not to display Wellevate reports for the current practitioner */
  shouldDisplayWellevateReports: Scalars['Boolean']['output'];
};


/** Store Tax Reports Type */
export type StoreTaxReportsAnnual1099kFiledReportUrlArgs = {
  year: Scalars['String']['input'];
};


/** Store Tax Reports Type */
export type StoreTaxReportsAnnualReportUrlArgs = {
  year: Scalars['String']['input'];
};


/** Store Tax Reports Type */
export type StoreTaxReportsBalanceSheetArgs = {
  year: Scalars['Int']['input'];
};


/** Store Tax Reports Type */
export type StoreTaxReportsEarningsReportArgs = {
  payoutIds: Array<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};


/** Store Tax Reports Type */
export type StoreTaxReportsPayoutDatesArgs = {
  year?: InputMaybe<Scalars['Int']['input']>;
};


/** Store Tax Reports Type */
export type StoreTaxReportsPayoutReportArgs = {
  payoutId: Scalars['String']['input'];
};

/** Details of a store's status in a patient promotion */
export type StoresPatientPromotion = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /**
   * Duration of the promotion in days
   * @deprecated Use duration_days on PatientPromotionType instead.
   */
  durationDays: Scalars['String']['output'];
  /**
   * End date of the promotion
   * @deprecated Use end_date on PatientPromotionType instead.
   */
  endDate: Scalars['DateTime']['output'];
  /** If promotion was created as a result of a global patient promotion opt in */
  fromGlobalOptIn?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** If the store has opted into the promotion */
  optIn: Scalars['Boolean']['output'];
  /** Associated patient promotion */
  patientPromotion: PatientPromotion;
  /** Previous store discount that will be reinstated after the promotion has ended */
  previousDiscount?: Maybe<Scalars['Int']['output']>;
  /**
   * Start date of the promotion
   * @deprecated Use start_date on PatientPromotionType instead.
   */
  startDate: Scalars['DateTime']['output'];
  /** Targeted additional discount store gets for the promotion */
  targetedDiscount: Scalars['Int']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes for updating a store's patient promotion. */
export type StoresPatientPromotionAttributes = {
  optIn?: InputMaybe<Scalars['Boolean']['input']>;
  patientPromotionId: Scalars['ID']['input'];
  targetedDiscount?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for StoresPatientPromotion. */
export type StoresPatientPromotionConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StoresPatientPromotionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<StoresPatientPromotion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type StoresPatientPromotionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<StoresPatientPromotion>;
};

/** Arguments for filtering stores patient promotion. */
export type StoresPatientPromotionFilterObject = {
  /** Patient promotions that the store is opted in for and are currently running */
  active?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of StoresPatientPromotionUpdate */
export type StoresPatientPromotionUpdateError = {
  /** Field errors */
  fields?: Maybe<StoresPatientPromotionUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StoresPatientPromotionUpdate */
export type StoresPatientPromotionUpdateFieldError = {
  optIn?: Maybe<Array<Scalars['String']['output']>>;
  patientPromotionId?: Maybe<Array<Scalars['String']['output']>>;
  targetedDiscount?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StoresPatientPromotionUpdate */
export type StoresPatientPromotionUpdateInput = {
  attributes: StoresPatientPromotionAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StoresPatientPromotionUpdate. */
export type StoresPatientPromotionUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StoresPatientPromotionUpdateError>;
  storesPatientPromotion?: Maybe<StoresPatientPromotion>;
};

/** Details of a person associated with a stripe account (equity owners and directors) */
export type StripeAccountPersonType = {
  /** City of the person associated to account */
  city?: Maybe<Scalars['String']['output']>;
  /** Date of birth of the person associated to account */
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  /** Email of the person associated to account */
  email?: Maybe<Scalars['String']['output']>;
  /** First name of the person associated to account */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Whether the person associated to account is a director */
  isDirector?: Maybe<Scalars['Boolean']['output']>;
  /** Last name of the person associated to account */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Postal code of the person associated to account */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** Region of the person associated to account */
  region?: Maybe<Scalars['String']['output']>;
  /** Street address of the person associated to account */
  streetAddress?: Maybe<Scalars['String']['output']>;
  /** Extra Street address info of the person associated to account */
  streetAddress2?: Maybe<Scalars['String']['output']>;
};

/** Details about the store's Stripe account (synched from Stripe) */
export type StripeAccountState = Timestamps & {
  /** The Stripe account's missing fields */
  accountVerifyFieldsNeeded?: Maybe<Scalars['String']['output']>;
  /**
   * State of the stripe's additional documents verification process, can be either
   * null, pending, unverified, verified, or rejected
   */
  additionalVerificationState?: Maybe<StripeAdditionalVerificationStates>;
  /** If charges are enabled on the Stripe account. These are the order payments (on cc or debit cards) */
  chargesEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** If Stripe requires additional documents to verify their account */
  documentVerificationRequired: Scalars['Boolean']['output'];
  /** The Stripe account's missing fields for future requirements */
  futureAccountFieldsNeeded?: Maybe<Scalars['String']['output']>;
  /** Deadline to fill data for future requirements */
  futureRequirementsDeadline?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Whether Stripe requires the store owner's full SSN to verify their account */
  isFullSsnRequired: Scalars['Boolean']['output'];
  /** State of the Stripe account (eg. unverified, verified, pending) */
  legalEntityVerified?: Maybe<Scalars['String']['output']>;
  /** The Stripe account's fields that are currently pending verification */
  pendingVerificationFields?: Maybe<Scalars['String']['output']>;
  /** If Stripe's terms of service need to be accepted */
  tosAcceptanceRequired: Scalars['Boolean']['output'];
  /** If transfers are enabled on the Stripe account (money can be moved to a Stripe account) */
  transfersEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** State of the stripe's additional documents verification process */
export const StripeAdditionalVerificationStates = {
  Pending: 'pending',
  Rejected: 'rejected',
  Unverified: 'unverified',
  Verified: 'verified'
} as const;

export type StripeAdditionalVerificationStates = typeof StripeAdditionalVerificationStates[keyof typeof StripeAdditionalVerificationStates];
/** Attributes for adding directors and owners to a canadian store with business account. */
export type StripePersonAttributes = {
  _delete?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isDirector?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
};

/** Information about a practitioner's student status */
export type StudentProfile = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The practitioner's anticipated graduation date. */
  graduationDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** The practitioner this information is for. */
  practitioner?: Maybe<Practitioner>;
  /** The type of practitioner they are. */
  practitionerType?: Maybe<PractitionerType>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Student profile attributes input object */
export type StudentProfileAttributes = {
  graduationDate?: InputMaybe<Scalars['String']['input']>;
  practitionerTypeId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of StudentProfileCreate */
export type StudentProfileCreateError = {
  /** Field errors */
  fields?: Maybe<StudentProfileCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of StudentProfileCreate */
export type StudentProfileCreateFieldError = {
  graduationDate?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of StudentProfileCreate */
export type StudentProfileCreateInput = {
  attributes: StudentProfileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StudentProfileCreate. */
export type StudentProfileCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<StudentProfileCreateError>;
  studentProfile?: Maybe<StudentProfile>;
};

/** Autogenerated error type of SubscriptionAddressesCreate */
export type SubscriptionAddressesCreateError = {
  /** Field errors */
  fields?: Maybe<SubscriptionAddressesCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of SubscriptionAddressesCreate */
export type SubscriptionAddressesCreateFieldError = {
  address1?: Maybe<Array<Scalars['String']['output']>>;
  address2?: Maybe<Array<Scalars['String']['output']>>;
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  countryId?: Maybe<Array<Scalars['String']['output']>>;
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  phone?: Maybe<Array<Scalars['String']['output']>>;
  shippingAndBilling?: Maybe<Array<Scalars['String']['output']>>;
  stateId?: Maybe<Array<Scalars['String']['output']>>;
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of SubscriptionAddressesCreate */
export type SubscriptionAddressesCreateInput = {
  attributes: PatientAccountAddressAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createBillingAddress: Scalars['Boolean']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of SubscriptionAddressesCreate. */
export type SubscriptionAddressesCreatePayload = {
  /** @deprecated Go through patient type */
  addresses?: Maybe<Array<PatientAddress>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: SubscriptionAddressesCreateError;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

/** Field error type for SubscriptionCreateCreditCardBillingAddressFieldErrors. */
export type SubscriptionCreateCreditCardBillingAddressFieldErrors = {
  /** Billing Address line 1 */
  address1?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address line 2 */
  address2?: Maybe<Array<Scalars['String']['output']>>;
  /** Address type (always 'billing' for this mutation) */
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address city */
  city?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address country identifier */
  countryId?: Maybe<Array<Scalars['ID']['output']>>;
  /** Billing Address recipient first name */
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address recipient last name */
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  /** Patient Address type (always 'billing' for this mutation) */
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address phone number */
  phone?: Maybe<Array<Scalars['String']['output']>>;
  /** Indicator if address is for both shipping and billing (always 'false' for this mutation) */
  shippingAndBilling?: Maybe<Array<Scalars['Boolean']['output']>>;
  /** State identifier */
  stateId?: Maybe<Array<Scalars['ID']['output']>>;
  /** Billing Address zip code */
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** SubscriptionCreateCreditCard error type. */
export type SubscriptionCreateCreditCardError = {
  /** Error fields. */
  fields?: Maybe<SubscriptionCreateCreditCardFieldError>;
  /** Error message. */
  message?: Maybe<Scalars['String']['output']>;
};

/** Field error type for CreateCreditCardFieldError. */
export type SubscriptionCreateCreditCardFieldError = {
  /** Billing Address line 1 */
  address1?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address line 2 */
  address2?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address type (always 'billing' for this mutation) */
  addressType?: Maybe<Array<Scalars['String']['output']>>;
  /** AVS state */
  avsState?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address field errors. */
  billingAddress?: Maybe<SubscriptionCreateCreditCardBillingAddressFieldErrors>;
  /** The kind of Credit Card (ie. Visa, MasterCard, etc.) */
  ccType?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address city */
  city?: Maybe<Array<Scalars['String']['output']>>;
  /** The first name of the Credit Card holder. */
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address recipient first name */
  firstname?: Maybe<Array<Scalars['String']['output']>>;
  /** The Gateway payment profile id. */
  gatewayPaymentProfileId?: Maybe<Array<Scalars['String']['output']>>;
  /** The last 4 digits of the Credit Card. */
  lastDigits?: Maybe<Array<Scalars['String']['output']>>;
  /** The last name of the Credit Card holder. */
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address recipient last name */
  lastname?: Maybe<Array<Scalars['String']['output']>>;
  /** The Credit Card expiry month. */
  month?: Maybe<Array<Scalars['String']['output']>>;
  /** The full name of the Credit Card holder. */
  name?: Maybe<Array<Scalars['String']['output']>>;
  /** The one time use permission of the Credit Card. */
  oneTimeUse?: Maybe<Array<Scalars['String']['output']>>;
  /** Patient Billing Address type (always 'billing' for this mutation) */
  patientAddressType?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address phone number */
  phone?: Maybe<Array<Scalars['String']['output']>>;
  /** Indicator if billing address is for both shipping and billing (always 'false' for this mutation) */
  shippingAndBilling?: Maybe<Array<Scalars['Boolean']['output']>>;
  /** State identifier */
  stateId?: Maybe<Array<Scalars['ID']['output']>>;
  /** The Credit Card expiry year. */
  year?: Maybe<Array<Scalars['String']['output']>>;
  /** Billing Address zip code */
  zipcode?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of SubscriptionCreateCreditCard */
export type SubscriptionCreateCreditCardInput = {
  attributes: SubscriptionCreditCardAttributes;
  billingAddressAttributes?: InputMaybe<SubscriptionCreditCardBillingAddressAttributes>;
  billingAddressId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of SubscriptionCreateCreditCard. */
export type SubscriptionCreateCreditCardPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Go through patient type */
  creditCard?: Maybe<CreditCard>;
  errors: SubscriptionCreateCreditCardError;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

/** Autogenerated input type of SubscriptionCreate */
export type SubscriptionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  subscriptionsVariants?: InputMaybe<Array<SubscriptionsVariantsAttributes>>;
};

/** Autogenerated return type of SubscriptionCreate. */
export type SubscriptionCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

/** Attributes for adding a credit card to a patient through their subscription */
export type SubscriptionCreditCardAttributes = {
  avsState?: InputMaybe<Scalars['String']['input']>;
  ccType?: InputMaybe<Scalars['String']['input']>;
  gatewayPaymentProfileId?: InputMaybe<Scalars['String']['input']>;
  lastDigits?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  oneTimeUse?: InputMaybe<Scalars['Boolean']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for adding shipping or billing addresses to a patient */
export type SubscriptionCreditCardBillingAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  countryId?: InputMaybe<Scalars['ID']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  stateId: Scalars['ID']['input'];
  zipcode: Scalars['String']['input'];
};

/** Autogenerated input type of SubscriptionProp65Swap */
export type SubscriptionProp65SwapInput = {
  /** Only check if Prop65 swapping needed */
  checkOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SubscriptionProp65Swap. */
export type SubscriptionProp65SwapPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<RetailCartProp65SwapError>>;
  patient?: Maybe<Patient>;
};

/** Autogenerated error type of SubscriptionScheduleMultiple */
export type SubscriptionScheduleMultipleError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SubscriptionScheduleMultiple */
export type SubscriptionScheduleMultipleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  scheduleAttributes: Array<AutoshipScheduleAttributes>;
};

/** Autogenerated return type of SubscriptionScheduleMultiple. */
export type SubscriptionScheduleMultiplePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SubscriptionScheduleMultipleError>;
  numberOfScheduledVariants: Scalars['Int']['output'];
};

/** Autogenerated input type of SubscriptionUpdate */
export type SubscriptionUpdateInput = {
  billingAddressId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCardId?: InputMaybe<Scalars['ID']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  preferredShippingMethodId?: InputMaybe<Scalars['ID']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
  subscriptionsVariants?: InputMaybe<Array<SubscriptionsVariantsAttributes>>;
};

/** Autogenerated return type of SubscriptionUpdate. */
export type SubscriptionUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscription?: Maybe<Autoship>;
};

/** Filters for SubscriptionVariant */
export type SubscriptionVariantFilterObject = {
  /** Filters by variants active in subscription */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filters by variants valid for sale */
  validForSale?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of SubscriptionsGroupUpdate */
export type SubscriptionsGroupUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SubscriptionsGroupUpdate */
export type SubscriptionsGroupUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingMethodId: Scalars['ID']['input'];
  subscriptionsGroupId: Scalars['ID']['input'];
};

/** Autogenerated return type of SubscriptionsGroupUpdate. */
export type SubscriptionsGroupUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<SubscriptionsGroupUpdateError>;
  patient?: Maybe<Patient>;
};

/** Details about an autoship variant */
export type SubscriptionsVariant = Node & Timestamps & {
  /** True if the autoship variant will be included in the autoship order. */
  active: Scalars['Boolean']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The frequency the autoship variant belongs to */
  frequency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Line item for the draft autoship variant */
  lineItem?: Maybe<LineItem>;
  /**
   * The next order date for the draft subscription variant coming from either an
   * active subscription or a tentative date based on the draft subscription frequency
   */
  nextOrderDate: Scalars['Date']['output'];
  /** Quantity of this variant they want ordered */
  quantity: Scalars['Int']['output'];
  /** Excludes variant from next autoship order */
  skipOnNextOrder: Scalars['Boolean']['output'];
  /** The autoship this variant belongs to */
  subscription: Autoship;
  /** The variant's unit price after taking the subscriptions discount into account */
  unitPrice?: Maybe<Scalars['Float']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The variant to be ordered */
  variant: Variant;
};

/** Autogenerated input type of SubscriptionsVariantCreate */
export type SubscriptionsVariantCreateInput = {
  /** The state of the subscription variant */
  active: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The amount of a variant you want to add to subscription variant */
  quantity: Scalars['Int']['input'];
  /** When true removes variant from next autoship order but reactivates after that order */
  skipOnNextOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** The source of the subscription variant */
  source?: InputMaybe<Scalars['String']['input']>;
  /** The subscription to create subscription variant */
  subscriptionId: Scalars['ID']['input'];
  /** Variant ID to add to subscription */
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of SubscriptionsVariantCreate. */
export type SubscriptionsVariantCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscriptionsVariant?: Maybe<SubscriptionsVariant>;
};

/** Autogenerated error type of SubscriptionsVariantFrequencyUpdate */
export type SubscriptionsVariantFrequencyUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SubscriptionsVariantFrequencyUpdate */
export type SubscriptionsVariantFrequencyUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The frequency to change the subscriptions variants to */
  frequency: Scalars['String']['input'];
  /** The subscriptions variant */
  subscriptionsVariantId: Scalars['ID']['input'];
};

/** Autogenerated return type of SubscriptionsVariantFrequencyUpdate. */
export type SubscriptionsVariantFrequencyUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors that occurred while running the mutation */
  errors: SubscriptionsVariantFrequencyUpdateError;
  patient?: Maybe<Patient>;
  /** The subscription that the variant belongs to after the update. Can either be an existing or a new subscription. */
  subscription?: Maybe<Autoship>;
};

/** Autogenerated input type of SubscriptionsVariantUpdate */
export type SubscriptionsVariantUpdateInput = {
  /** The state of the subscriptions variant */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The amount of a variant you want to add to subscriptions variant */
  quantity?: InputMaybe<Scalars['Int']['input']>;
  /** When true removes variant from next autoship order but reactivates after that order */
  skipOnNextOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** The subscriptions variant if updating */
  subscriptionsVariantId: Scalars['ID']['input'];
  /** Variant ID to add to subscription */
  variantId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of SubscriptionsVariantUpdate. */
export type SubscriptionsVariantUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  /** @deprecated Go through patient type */
  subscriptionsVariant?: Maybe<SubscriptionsVariant>;
};

/** Attributes for updating subscription subscriptions_variants_attributes */
export type SubscriptionsVariantsAttributes = {
  /** The state of the subscription variant */
  active: Scalars['Boolean']['input'];
  /** The amount of a variant you want to add to subscription variant */
  quantity: Scalars['Int']['input'];
  /** Variant ID to add to subscription */
  variantId: Scalars['ID']['input'];
};

/** Suggested variant based on ingredient/dosage requirements */
export type SuggestedVariant = {
  /** The dosage amount of the suggested variant */
  dosageAmount?: Maybe<Scalars['String']['output']>;
  /** The dosage formant of the suggested variant */
  dosageFormat?: Maybe<Scalars['String']['output']>;
  /** The dosage frequency of the suggested variant */
  dosageFrequency?: Maybe<Scalars['String']['output']>;
  /** Array of tags for this variant */
  tags?: Maybe<Array<Scalars['String']['output']>>;
  /** Suggested variant */
  variant: Variant;
};

/** Group of suggested variants and requested variants */
export type SuggestionGroup = {
  /** requested ingredients with dosage amounts */
  requestedIngredientDetails: Array<RequestedIngredientDetails>;
  /** suggested variants */
  suggestedVariants: Array<SuggestedVariant>;
};

/** Autogenerated input type of SupplementPlanAddCustomMedication */
export type SupplementPlanAddCustomMedicationInput = {
  brandName: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageFormat?: InputMaybe<Scalars['String']['input']>;
  dosageFrequency?: InputMaybe<Scalars['String']['input']>;
  isDefaultTime?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  reminderTimes?: InputMaybe<Array<Scalars['String']['input']>>;
  remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated return type of SupplementPlanAddCustomMedication. */
export type SupplementPlanAddCustomMedicationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  supplementPlan?: Maybe<SupplementPlanType>;
};

/** Autogenerated input type of SupplementPlanAddCustomProduct */
export type SupplementPlanAddCustomProductInput = {
  brandName: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageFormat?: InputMaybe<Scalars['String']['input']>;
  dosageFrequency?: InputMaybe<Scalars['String']['input']>;
  isDefaultTime?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  reminderTimes?: InputMaybe<Array<Scalars['String']['input']>>;
  remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated return type of SupplementPlanAddCustomProduct. */
export type SupplementPlanAddCustomProductPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  supplementPlan?: Maybe<SupplementPlanType>;
};

/** Autogenerated input type of SupplementPlanAddProduct */
export type SupplementPlanAddProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageFrequency?: InputMaybe<Scalars['String']['input']>;
  isDefaultTime?: InputMaybe<Scalars['Boolean']['input']>;
  productId: Scalars['ID']['input'];
  reminderTimes?: InputMaybe<Array<Scalars['String']['input']>>;
  remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated return type of SupplementPlanAddProduct. */
export type SupplementPlanAddProductPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  supplementPlan?: Maybe<SupplementPlanType>;
};

/** Autogenerated input type of SupplementPlanAddProducts */
export type SupplementPlanAddProductsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productIds: Array<Scalars['ID']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SupplementPlanAddProducts. */
export type SupplementPlanAddProductsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated input type of SupplementPlanArchiveProduct */
export type SupplementPlanArchiveProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};

/** Autogenerated return type of SupplementPlanArchiveProduct. */
export type SupplementPlanArchiveProductPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  supplementPlan?: Maybe<SupplementPlanType>;
};

/** Details about a supplement plan custom medication */
export type SupplementPlanCustomMedication = Node & {
  /** Brand name of the supplement plan custom medication */
  brandName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Name of the supplement plan custom medication */
  name: Scalars['String']['output'];
};

/** Details about a supplement plan custom product */
export type SupplementPlanCustomProduct = Node & {
  /** Brand name of the supplement plan custom product */
  brandName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Name of the supplement plan custom product */
  name: Scalars['String']['output'];
};

/** Supplement plan products grouped by time of day */
export type SupplementPlanProductByTimeOfDayType = {
  id: Scalars['ID']['output'];
  /** The supplement plan's products for the time of day */
  supplementPlanProducts: Array<SupplementPlanProductType>;
  /** The time of day the products should be taken */
  timeOfDay?: Maybe<Scalars['String']['output']>;
};

/** Filters for SupplementPlanProduct */
export type SupplementPlanProductFilterObject = {
  /** Filters by products active in supplement plan */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filters a product in the supplement plan by ID */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Filter products based on whether reminders are enabled */
  remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filters products based on a certain time */
  time?: InputMaybe<Scalars['String']['input']>;
};

/** Details for the reminders for a supplement plan product */
export type SupplementPlanProductReminder = {
  id: Scalars['ID']['output'];
  /** Time the reminder will be sent at */
  sendTime: Scalars['String']['output'];
};

/** A product in the patient's My Script */
export type SupplementPlanProductType = Node & Timestamps & {
  /** The base dosage format, eg: tablet. Used for selecting structured dosage iconography */
  baseFormat?: Maybe<Scalars['String']['output']>;
  /** The brand name of the supplement plan product */
  brandName: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The dosage amount of the supplement plan product */
  dosageAmount?: Maybe<Scalars['String']['output']>;
  /** The dosage formant of the supplement plan product */
  dosageFormat: Scalars['String']['output'];
  /** The dosage frequency of the supplement plan product */
  dosageFrequency?: Maybe<Scalars['String']['output']>;
  /** The dosage time of day list for the supplement plan product */
  dosageTimeOfDay?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  /** The url for the image of the supplement plan product */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Ingredients for this supplement plan product */
  ingredients: Array<PlanIngredient>;
  /** The name of the supplement plan product */
  name: Scalars['String']['output'];
  /**
   * Deprecated, perform calculation client side instead
   * @deprecated Cannot account for client time zone.
   */
  nextSendTime?: Maybe<Scalars['String']['output']>;
  /** The product of the supplement plan product */
  productable: SupplementPlanProductableType;
  /**
   * Deprecated
   * @deprecated Use productable instead.
   */
  productableId: Scalars['ID']['output'];
  /**
   * Deprecated
   * @deprecated Use productable instead.
   */
  productableType: Scalars['String']['output'];
  /** Reminder times for this supplement plan product */
  reminders?: Maybe<Array<SupplementPlanProductReminder>>;
  /** Whether or not reminders are enabled for the supplement plan product */
  remindersEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** The take with instructions for the supplement plan product */
  takeWith?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** A product associated to supplement plan product */
export type SupplementPlanProductableType = ExternalProduct | MedicationProduct | Product | SupplementPlanCustomMedication | SupplementPlanCustomProduct;

/** The patient's My Script */
export type SupplementPlanType = Timestamps & {
  /** Aggregated list of ingredients in this supplement plan */
  aggregatedIngredients: Array<PlanIngredient>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Aggregated list of Med DB ingredients in this supplement plan */
  medicationAggregatedIngredients: Array<PlanIngredient>;
  /** A list of Med DB products in this supplement plan without curated ingredients */
  medicationProductsWithoutIngredients: Array<Scalars['String']['output']>;
  /** Grouped supplement plan products by time of day */
  productsByTimeOfDay?: Maybe<Array<SupplementPlanProductByTimeOfDayType>>;
  /** A list of products in this supplement plan without curated ingredients */
  productsWithoutIngredients: Array<Scalars['String']['output']>;
  /** A list of all times for which supplement plan product reminders are to be sent */
  reminderTimes: Array<Scalars['String']['output']>;
  /** The supplement plan's products */
  supplementPlanProducts: Array<SupplementPlanProductType>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** The patient's My Script */
export type SupplementPlanTypeProductsByTimeOfDayArgs = {
  includeMedications?: InputMaybe<Scalars['Boolean']['input']>;
};


/** The patient's My Script */
export type SupplementPlanTypeSupplementPlanProductsArgs = {
  filters?: InputMaybe<SupplementPlanProductFilterObject>;
  includeMedications?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of SupplementPlanUpdateProduct */
export type SupplementPlanUpdateProductInput = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dosageAmount?: InputMaybe<Scalars['String']['input']>;
  dosageFormat?: InputMaybe<Scalars['String']['input']>;
  dosageFrequency?: InputMaybe<Scalars['String']['input']>;
  isDefaultTime?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reminderTimes?: InputMaybe<Array<Scalars['String']['input']>>;
  remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  supplementPlanProductId: Scalars['ID']['input'];
  takeWith?: InputMaybe<Scalars['String']['input']>;
  timeOfDay?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated return type of SupplementPlanUpdateProduct. */
export type SupplementPlanUpdateProductPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  supplementPlan?: Maybe<SupplementPlanType>;
};

/** Supplement type */
export type SupplementType = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the supplement type */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for SupplementType. */
export type SupplementTypeConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SupplementTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SupplementType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SupplementTypeEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SupplementType>;
};

/** Tag */
export type Tag = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Depth of the tag in the hierarchy (0, 1, 2, or 3) */
  depth: Scalars['Int']['output'];
  /** ID of the tag two levels above the current tag */
  grandParentId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** Whether the tag is the root */
  isStub?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the tag */
  name: Scalars['String']['output'];
  /** ID of the tag one level above the current tag */
  parentId?: Maybe<Scalars['ID']['output']>;
  /** Number of the products that have the tag */
  productsCount?: Maybe<Scalars['Int']['output']>;
  /** Whether the tag is the root */
  root: Scalars['Boolean']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for Tag. */
export type TagConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TagEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Tag>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TagEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Tag>;
};

/** Tag filter depth field */
export const TagFilterDepthField = {
  /** Filters for tags with a depth of one (tags for types) */
  One: 'ONE',
  /** Filters for root tag */
  Root: 'ROOT',
  /** Filters for tags with a depth of one (tags for sub-types) */
  Three: 'THREE',
  /** Filters for tags with a depth of one (tags for sub-types categories) */
  Two: 'TWO'
} as const;

export type TagFilterDepthField = typeof TagFilterDepthField[keyof typeof TagFilterDepthField];
/** Arguments for filtering tags. */
export type TagFilterObject = {
  depth: TagFilterDepthField;
  excludeFiltersTag?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Type for a taxonomy record */
export type Taxonomy = Node & Timestamps & {
  /** Children of the taxonomy record */
  children?: Maybe<Array<Taxonomy>>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Depth of the taxonomy record */
  depth: Scalars['Int']['output'];
  /** Hidden status of the taxonomy record */
  hidden: Scalars['Boolean']['output'];
  /** ID of the taxonomy record */
  id: Scalars['ID']['output'];
  /** Name of the taxonomy record */
  name: Scalars['String']['output'];
  /** Parent ID of the taxonomy record */
  parentId?: Maybe<Scalars['ID']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes to create a template draft */
export type TemplateCreateDraftAttributes = {
  attachmentsUploadableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  recommendationsAttributes?: InputMaybe<Array<OfficeRecommendationAttributes>>;
  treatmentPlanTemplateIdToApply?: InputMaybe<Scalars['ID']['input']>;
};

/** A union of the different lab panel/template types: Panel, Template, Fullscript Template */
export type TemplateUnion = LabsPanel | TreatmentPlanTemplate;

/** The connection type for TemplateUnion. */
export type TemplateUnionConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TemplateUnionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TemplateUnion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TemplateUnionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TemplateUnion>;
};

/** Autogenerated input type of TherapeuticSwitchingRequest */
export type TherapeuticSwitchingRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  recommendationId: Scalars['ID']['input'];
};

/** Autogenerated return type of TherapeuticSwitchingRequest. */
export type TherapeuticSwitchingRequestPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  recommendation?: Maybe<Recommendation>;
};

/** Third party certification */
export type ThirdPartyCertification = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The number of items in a search result that are of this third party certification */
  esResultCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** Name of the third party certification */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Arguments for sorting third party certifications. */
export type ThirdPartyCertificationSort = {
  direction: SortDirection;
  field: ThirdPartyCertificationSortField;
};

/** Third party certification sort field */
export const ThirdPartyCertificationSortField = {
  /** How often a third party certification is used to filter search results */
  SearchPopularity: 'SEARCH_POPULARITY'
} as const;

export type ThirdPartyCertificationSortField = typeof ThirdPartyCertificationSortField[keyof typeof ThirdPartyCertificationSortField];
/** 360 degree image filter format field */
export const ThreeSixtyImageFilterFormatField = {
  /** Filters for png images */
  Png: 'PNG',
  /** Filters for web_p images */
  Webp: 'WEBP'
} as const;

export type ThreeSixtyImageFilterFormatField = typeof ThreeSixtyImageFilterFormatField[keyof typeof ThreeSixtyImageFilterFormatField];
/** Arguments for filtering three sixty images for variants. */
export type ThreeSixtyImageFilterObject = {
  format?: InputMaybe<ThreeSixtyImageFilterFormatField>;
  size?: InputMaybe<ThreeSixtyImageFilterSizeField>;
};

/** 360 degree image filter size field */
export const ThreeSixtyImageFilterSizeField = {
  /** Filters for images with size 1000 */
  Large: 'LARGE',
  /** Filters for images with size 400 */
  Medium: 'MEDIUM',
  /** Filters for images with size 225 */
  Small: 'SMALL'
} as const;

export type ThreeSixtyImageFilterSizeField = typeof ThreeSixtyImageFilterSizeField[keyof typeof ThreeSixtyImageFilterSizeField];
/** Wholesale tiered percent item */
export type TieredPercentItem = {
  /** Maximum cost for the discount */
  max?: Maybe<Scalars['Float']['output']>;
  /** Minimum cost for the discount */
  min: Scalars['Float']['output'];
  /** Discount applied */
  percentage: Scalars['Float']['output'];
};

/** Attributes for collecting events for metric tile clicks */
export type TileClickAttributes = {
  pageVersion: Scalars['String']['input'];
  tileName: Scalars['String']['input'];
};

/** TimedSupplyInformation */
export type TimedSupplyInformation = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The string used to display the calculated daily units amount */
  dailyDosageText: Scalars['String']['output'];
  /** The number of units that need to be taken each day based on the dosage information */
  dailyUnits: Scalars['Float']['output'];
  /** The number of units that need to be taken with each dose */
  dosageAmount: Scalars['String']['output'];
  /** How long the doses must be taken */
  dosageDuration?: Maybe<Scalars['String']['output']>;
  /** The form in which the the product is marketed for use */
  dosageFormat: Scalars['String']['output'];
  /** The number of times that the dose must be taken each day */
  dosageFrequency: Scalars['String']['output'];
  /** Personalized dosage instructions */
  dosagePermutations?: Maybe<Scalars['String']['output']>;
  /** The quantity of the product that was recommended, defaults to 1 when there is no recommendation */
  dosageQuantity: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** The line item associated with the timed supply information */
  lineItem: LineItem;
  /** Whether or not the referenced source recommendation has been cancelled */
  sourceRecommendationCancelled?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the dosage amount and frequency differ between timed supply information and the source recommendation */
  sourceRecommendationChanged?: Maybe<Scalars['Boolean']['output']>;
  /** The origin of the dosage information when the line item was added to the cart */
  sourceType: Scalars['String']['output'];
  /** The suggested auto-refill frequency based on the dosage information */
  suggestedAutoRefillFrequency?: Maybe<Scalars['String']['output']>;
  /** The duration of the supply based on the dosage information */
  supplyDuration?: Maybe<Scalars['String']['output']>;
  /**
   * The time period that was chosen by the patient to fulfill the dose for
   * @deprecated No longer used.
   */
  timeSelected: Scalars['String']['output'];
  /** The number of units that are in the product */
  unitsPerContainer: Scalars['Int']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** TimedSupplyOption */
export type TimedSupplyOption = {
  /** The integer representation of the time period */
  duration: Scalars['Int']['output'];
  /** The string representation of the time period unit */
  durationUnit: Scalars['String']['output'];
  /** Identifier for the unique combination of line item and option */
  id: Scalars['ID']['output'];
  /** The string representation of the time period */
  option: Scalars['String']['output'];
  /** The total price (without discounts) for this time period */
  price: Scalars['Float']['output'];
  /** Set to `true` if this time period option was recommended in a treatment plan, otherwise `false` */
  rxRecommendation: Scalars['Boolean']['output'];
  /** The total price (with discounts) for this time period */
  sellPrice: Scalars['Float']['output'];
  /** The number of units this time period requires */
  units: Scalars['Int']['output'];
};

/** Timestamp for records */
export type Timestamps = {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Highlighted brands in catalog. */
export type TopBrand = Node & Timestamps & {
  /**
   * Deprecated. Information about the brand.
   * @deprecated Never used.
   */
  blurb?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The rank within top brands. */
  position: Scalars['Int']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Arguments for sorting top recommended products by modality. */
export type TopRecommendedProductSort = {
  direction: SortDirection;
  field?: InputMaybe<TopRecommendedProductSortField>;
};

/** Fields for sorting top recommended products by modality. */
export const TopRecommendedProductSortField = {
  /** Sorts alphabetically. */
  Alphabetical: 'ALPHABETICAL',
  /** Sorts by price. */
  Price: 'PRICE',
  /** Sorts by the product recommendation rank. */
  Rank: 'RANK'
} as const;

export type TopRecommendedProductSortField = typeof TopRecommendedProductSortField[keyof typeof TopRecommendedProductSortField];
/** Possible count of next steps based on account type */
export type TotalStepCount = {
  /** Total step count for a never-margin store */
  neverMargin?: Maybe<Scalars['Int']['output']>;
  /** Total step count for a variable-margin store */
  variableMargin?: Maybe<Scalars['Int']['output']>;
};

/** Tour */
export type Tour = Timestamps & {
  /** Action for the tour */
  action?: Maybe<Scalars['String']['output']>;
  /** Page, feature or controller for the tour */
  controller?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Unused */
  subAction?: Maybe<Scalars['String']['output']>;
  /** Template name for the tour */
  templateName?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** If current user has seen the tour and when it was last updated */
  userUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Autogenerated error type of ToursUsersCreate */
export type ToursUsersCreateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ToursUsersCreate */
export type ToursUsersCreateInput = {
  action: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controller: Scalars['String']['input'];
};

/** Autogenerated error type of ToursUsersCreateOrUpdate */
export type ToursUsersCreateOrUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ToursUsersCreateOrUpdate */
export type ToursUsersCreateOrUpdateInput = {
  action: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  controller: Scalars['String']['input'];
};

/** Autogenerated return type of ToursUsersCreateOrUpdate. */
export type ToursUsersCreateOrUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ToursUsersCreateOrUpdateError;
  user?: Maybe<User>;
};

/** Autogenerated return type of ToursUsersCreate. */
export type ToursUsersCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: ToursUsersCreateError;
  user?: Maybe<User>;
};

/** Tracking information for a package */
export type TrackingType = Node & {
  /** Package's actual delivery date, if delivered */
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  /** Displayable delivery status */
  deliveryStatus?: Maybe<Scalars['String']['output']>;
  /** Package's estimated delivery date */
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Package's actual delivery date, if delivered, formatted for third party clients. */
  friendlyDeliveredOn?: Maybe<Scalars['String']['output']>;
  /** Package's estimated delivery date, formatted for third party clients. */
  friendlyEstimatedDeliveryDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Tracking number */
  number: Scalars['String']['output'];
  /** Carrier's tracking URL */
  url?: Maybe<Scalars['String']['output']>;
};

/** Recommendation/prescription */
export type TreatmentPlan = Attachable & LegacyId & Node & Timestamps & {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
  /** Patient's feedback on why they haven't fulfilled the treatment plan */
  adherenceFeedback: AdherenceFeedback;
  /** Aggregated list of ingredients in this treatment plan */
  aggregatedIngredients: Array<PlanIngredient>;
  /** Count of orders associated with the treatment plan */
  associatedOrderCount: Scalars['Int']['output'];
  /** Date the treatment plan was made available */
  availableAt?: Maybe<Scalars['DateTime']['output']>;
  /** Grouped biomarkers associated with the tests of the treatment plan */
  biomarkerSummary: Array<LabsBiomarker>;
  /** Indicates if the treatment plan can be cancelled; for now this field is only relevant for treatment plans with labs */
  canCancel: Scalars['Boolean']['output'];
  /** Clerk that created this treatment plan (on behalf of a practitioner) */
  clerk?: Maybe<Clerk>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** State of the treatment plan. Can be template, active, draft, cancelled */
  currentState: Scalars['String']['output'];
  /** A paginated list of document attachments */
  documentAttachments: DocumentAttachmentConnection;
  /** Returns all documents associated with the treatment plan */
  documents: Array<Document>;
  /** Draft dynamic daily pack belonging to this treatment plan, if any. */
  draftDynamicDailyPack?: Maybe<DraftDynamicDailyPack>;
  /** Indicates if the treatment plan has active in-office lab recommendations */
  hasActiveInOfficeLabRecommendations: Scalars['Boolean']['output'];
  /** Indicates if the treatment plan has restricted tests for at least one practitioner of the store */
  hasRestrictedTestsForStore: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Used to identify if there's changes to the treatment plan that haven't been saved yet */
  isDirty: Scalars['Boolean']['output'];
  /** Indicates if the treatment plan is eligible for practitioner pay */
  isPractitionerPayAllowed: Scalars['Boolean']['output'];
  /** Used to identify if there is an invalid daily pack in the treatment plan */
  isValidDailyPacks: Scalars['Boolean']['output'];
  /** Whether all delivery methods for the labs are valid */
  isValidLabsDeliveryMethod: Scalars['Boolean']['output'];
  /** Lab journey subscriptions associated with a given treatment plan */
  labJourneySubscriptions: Array<LabsJourneysSubscription>;
  /** Lab recommendations associated with the treatment plan */
  labRecommendations: Array<LabsRecommendation>;
  /** Signing practitioner associated with the treatment plan */
  labSigningPractitioner?: Maybe<LabsTreatmentPlanSigningPractitioner>;
  /**
   * Custom fees grouped by name, and summed price
   * @deprecated Use pricingBreakdown instead.
   */
  labsCustomFeesSummary?: Maybe<Array<LabsRecommendationCustomFee>>;
  /**
   * Subtotal of all lab recommendations in the treatment plan
   * @deprecated Use pricingBreakdown instead.
   */
  labsSubtotal: Scalars['Float']['output'];
  /** Date the treatment plan was last ordered */
  lastOrderedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Log of actions taken on the treatment plan by the practitioner */
  logs: Array<TreatmentPlanLog>;
  /** The most recent message associated with the treatment plan */
  message?: Maybe<Scalars['String']['output']>;
  /** All messages associated with the treatment plan */
  messages?: Maybe<Array<TreatmentPlanMessage>>;
  /** Patient this treatment plan is for */
  patient?: Maybe<Patient>;
  /** Whether or not the patient has signed in. Can be `ACTIVE` or `INACTIVE` */
  patientStatus?: Maybe<TreatmentPlanPatientStatus>;
  /** Category type of the treatment plan */
  planOptimizationCategories?: Maybe<Array<PlanOptimizationCategory>>;
  /** Name of the plan type eg TreatmentPlan */
  planType: Scalars['String']['output'];
  /** Practitioner that created this treatment plan */
  practitioner: Practitioner;
  /** Breakdown of the price of a treatment plan */
  priceBreakdown: TreatmentPlanPriceBreakdown;
  /** A list of products in this treatment plan without curated ingredients */
  productsWithoutIngredients: Array<Scalars['String']['output']>;
  /** All recommendations for this treatment plan */
  recommendations: Array<Recommendation>;
  /**
   * Indicates if the treatment plan requires a fully licensed practitioner to sign
   * off on any of the lab recommendations. Does not take into account the lab
   * signing practitioner.
   */
  signingAuthorizationRequired: Scalars['Boolean']['output'];
  /** Unique identifier of the treatment plan */
  slug: Scalars['String']['output'];
  /** Indicates the platform where the treatment_plan was originally created */
  sourcePlatform: Scalars['String']['output'];
  /** Count of active recommendations in the treatment plan */
  totalActiveRecommendations: Scalars['Int']['output'];
  /**
   * The discounted price of the treatment plan for the patient
   * @deprecated Use pricingBreakdown instead.
   */
  totalDiscountedPrice: Scalars['Float']['output'];
  /**
   * Total authorization fees for all lab recommendations in the treatment plan
   * @deprecated Use pricingBreakdown instead.
   */
  totalLabAuthorizationFee: Scalars['Float']['output'];
  /**
   * Total company requisition fees for all lab recommendations in the treatment plan
   * @deprecated Use pricingBreakdown instead.
   */
  totalLabCompanyRequisitionFee: Scalars['Float']['output'];
  /**
   * Total custom fees for all lab recommendations in the treatment plan
   * @deprecated Use pricingBreakdown instead.
   */
  totalLabCustomFee: Scalars['Float']['output'];
  /**
   * Combines labs subtotal, authorization fee, company requisition fee, and custom fee.
   * @deprecated Use pricingBreakdown instead.
   */
  totalLabPrice: Scalars['Float']['output'];
  /** Count of not cancelled lab recommendations in the treatment plan */
  totalLabRecommendations: Scalars['Int']['output'];
  /**
   * The total price of the treatment plan without any discounts applied
   * @deprecated Use pricingBreakdown instead.
   */
  totalPrice: Scalars['Float']['output'];
  /**
   * Amount the current patient will save on the price of the treatment plan
   * @deprecated Use pricingBreakdown instead.
   */
  totalSavings: Scalars['Float']['output'];
  /**
   * The total price of the treatment plan without any labs and discounts applied
   * @deprecated Use pricingBreakdown instead.
   */
  totalWithoutLabsAndSavings: Scalars['Float']['output'];
  /**
   * The total price of the treatment plan without any discounts applied
   * @deprecated Use pricingBreakdown instead.
   */
  totalWithoutSavings: Scalars['Float']['output'];
  /** URL of the treatment plan in PDF form */
  treatmentPlanPdfUrl: Scalars['String']['output'];
  /** Type of plan the document was attached to */
  type: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Recommendation/prescription */
export type TreatmentPlanDocumentAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderByAddedAt?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Recommendation/prescription */
export type TreatmentPlanLabRecommendationsArgs = {
  filters?: InputMaybe<LabRecommendationFilterObject>;
  isFullscriptProtocol?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Array<LabRecommendationSort>>;
};


/** Recommendation/prescription */
export type TreatmentPlanLastOrderedAtArgs = {
  variantId?: InputMaybe<Scalars['ID']['input']>;
};


/** Recommendation/prescription */
export type TreatmentPlanMessagesArgs = {
  sort?: InputMaybe<Array<TreatmentPlanMessageSort>>;
};


/** Recommendation/prescription */
export type TreatmentPlanRecommendationsArgs = {
  filterObject?: InputMaybe<RecommendationFilterObject>;
  isFullscriptProtocol?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of TreatmentPlanCancel */
export type TreatmentPlanCancelError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of TreatmentPlanCancel */
export type TreatmentPlanCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of TreatmentPlanCancel. */
export type TreatmentPlanCancelPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanCancelError>;
  treatmentPlan?: Maybe<TreatmentPlan>;
};

/** The connection type for TreatmentPlan. */
export type TreatmentPlanConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TreatmentPlanEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TreatmentPlan>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** The plan conversion rate data to be displayed on the store analytics dashboard */
export type TreatmentPlanConversionRateData = {
  /** The month to date treatment_plan conversion rate for the store */
  monthToDate: Scalars['Float']['output'];
  /** The plan analytics data for the store */
  storePlanAnalyticsData: Array<StorePlanAnalyticsData>;
};

/** Autogenerated error type of TreatmentPlanDelete */
export type TreatmentPlanDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of TreatmentPlanDelete */
export type TreatmentPlanDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of TreatmentPlanDelete. */
export type TreatmentPlanDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanDeleteError>;
};

/** An edge in a connection. */
export type TreatmentPlanEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TreatmentPlan>;
};

/** Arguments for filtering treatment plans. */
export type TreatmentPlanFilterObject = {
  options?: InputMaybe<Array<TreatmentPlanFilters>>;
  ordered?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Treatment plan filters */
export const TreatmentPlanFilters = {
  /** Filters only treatment plans that that are associated with a patient */
  SavedPlansOnly: 'SAVED_PLANS_ONLY',
  /** Filters only active treatment plans */
  ScopeActive: 'SCOPE_ACTIVE',
  /** Filters only active and cancelled treatment plans */
  ScopeActiveAndCancelled: 'SCOPE_ACTIVE_AND_CANCELLED',
  /** Filters only cancelled treatment plans */
  ScopeCancelled: 'SCOPE_CANCELLED',
  /** Filters only drafts treatment plans */
  ScopeDraft: 'SCOPE_DRAFT',
  /** Filters all treatment plans except templates */
  ScopeWithoutTemplates: 'SCOPE_WITHOUT_TEMPLATES'
} as const;

export type TreatmentPlanFilters = typeof TreatmentPlanFilters[keyof typeof TreatmentPlanFilters];
/** Autogenerated error type of TreatmentPlanInsightsLog */
export type TreatmentPlanInsightsLogError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of TreatmentPlanInsightsLog */
export type TreatmentPlanInsightsLogInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  editClick?: InputMaybe<EditClickAttributes>;
  followUpClicked?: InputMaybe<FollowUpClickedAttributes>;
  followUpSent?: InputMaybe<FollowUpSentAttributes>;
  metricTileClick?: InputMaybe<TileClickAttributes>;
  pageView?: InputMaybe<PageViewAttributes>;
  searchFilter?: InputMaybe<SearchFilterAttributes>;
  treatmentPlanTileClick?: InputMaybe<TreatmentPlanTileClickAttributes>;
};

/** Autogenerated return type of TreatmentPlanInsightsLog. */
export type TreatmentPlanInsightsLogPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: TreatmentPlanInsightsLogError;
};

/** Minimal treatment plan details for an unpaginated list */
export type TreatmentPlanList = {
  /** Datetime the treatment plan is available at */
  availableAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
};

/** Arguments for filtering the treatment plan list. */
export type TreatmentPlanListFilterObject = {
  state?: InputMaybe<Array<TreatmentPlanStates>>;
};

/** Log of an action taken on a treatment plan by a practitioner */
export type TreatmentPlanLog = Node & Timestamps & {
  /** The actions associated to this log */
  actions: Array<TreatmentPlanLogAction>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The practitioner performing the action */
  practitioner: Practitioner;
  /** The associated treatment plan */
  treatmentPlan: TreatmentPlan;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Treatment plan log action */
export type TreatmentPlanLogAction = Node & Timestamps & {
  /** The associated object involved in the action */
  actionable?: Maybe<Actionable>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The metadata of this action */
  metadata?: Maybe<Scalars['String']['output']>;
  /** The notes of this action */
  notes?: Maybe<Scalars['String']['output']>;
  /** The subtitle of this action */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** The title of this action */
  title: Scalars['String']['output'];
  /** The associated log entry */
  treatmentPlanLog: TreatmentPlanLog;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The plan_message or treatment_plan_message associated to a treatment plan */
export type TreatmentPlanMessage = Node & Timestamps & {
  /** The actual message */
  contents?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The state of the message. Can be active or draft. */
  currentState?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Date the message was published */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Argument for sorting treatment plan messages. */
export type TreatmentPlanMessageSort = {
  direction: SortDirection;
  field: TreatmentPlanMessageSortField;
};

/** Treatment plan message sort field */
export const TreatmentPlanMessageSortField = {
  /** Date on which treatment plan message was created */
  CreatedAt: 'CREATED_AT',
  /** Date on which treatment plan message was updated */
  UpdatedAt: 'UPDATED_AT'
} as const;

export type TreatmentPlanMessageSortField = typeof TreatmentPlanMessageSortField[keyof typeof TreatmentPlanMessageSortField];
/** Treatment plan patient status */
export const TreatmentPlanPatientStatus = {
  /** The patient has signed in */
  Active: 'ACTIVE',
  /** The patient has not signed in */
  Inactive: 'INACTIVE'
} as const;

export type TreatmentPlanPatientStatus = typeof TreatmentPlanPatientStatus[keyof typeof TreatmentPlanPatientStatus];
/** Breakdown of the price of a treatment plan */
export type TreatmentPlanPriceBreakdown = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Total discount of all supplement and lab products */
  discount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** Custom fees grouped by name, and summed price */
  labsCustomFees: Array<LabsRecommendationCustomFee>;
  /** Total of lab products, including MSRP and fees */
  labsTotal: Scalars['Float']['output'];
  /** Total authorization network fees */
  labsTotalAuthNetworkFees: Scalars['Float']['output'];
  /** Total company requisition fees */
  labsTotalCompanyRequisitionFees: Scalars['Float']['output'];
  /** Total custom fees */
  labsTotalCustomFees: Scalars['Float']['output'];
  /** Total MSRP of lab products */
  labsTotalMsrp: Scalars['Float']['output'];
  /** Total discount of supplement products */
  supplementsDiscount: Scalars['Float']['output'];
  /** Total of supplement products, after discount */
  supplementsTotal: Scalars['Float']['output'];
  /** Total MSRP of supplement products */
  supplementsTotalMsrp: Scalars['Float']['output'];
  /** Total of all supplement and lab products, including fees and discounts */
  total: Scalars['Float']['output'];
  /** Total MSRP of all supplement and lab products, including fees, before discounts */
  totalBeforeDiscount: Scalars['Float']['output'];
  /** Total MSRP of all supplement and lab products, excluding fees and discounts */
  totalMsrp: Scalars['Float']['output'];
  /** Total MSRP of all supplement and lab products, excluding fees, after discounts */
  totalMsrpAfterDiscount: Scalars['Float']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of TreatmentPlanSendReminder */
export type TreatmentPlanSendReminderError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of TreatmentPlanSendReminder */
export type TreatmentPlanSendReminderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isText?: InputMaybe<Scalars['Boolean']['input']>;
  patientNumber?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of TreatmentPlanSendReminder. */
export type TreatmentPlanSendReminderPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanSendReminderError>;
};

/** Reusable message templates for creating treatment plan messages */
export type TreatmentPlanSnippet = Node & Timestamps & {
  /** Text contents of the snippet */
  content: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Whether the snippet is pinned/favorited by the practitioner */
  isPinned: Scalars['Boolean']['output'];
  /** Name used to identify the snippet */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Attributes for saving treatment plan snippets */
export type TreatmentPlanSnippetAttributes = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated error type of TreatmentPlanSnippetDelete */
export type TreatmentPlanSnippetDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of TreatmentPlanSnippetDelete */
export type TreatmentPlanSnippetDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  snippetId: Scalars['ID']['input'];
};

/** Autogenerated return type of TreatmentPlanSnippetDelete. */
export type TreatmentPlanSnippetDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: TreatmentPlanSnippetDeleteError;
};

/** Arguments for filtering treatment plan snippets */
export type TreatmentPlanSnippetFilterObject = {
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of TreatmentPlanSnippetSave */
export type TreatmentPlanSnippetSaveError = {
  /** Field errors */
  fields?: Maybe<TreatmentPlanSnippetSaveFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of TreatmentPlanSnippetSave */
export type TreatmentPlanSnippetSaveFieldError = {
  content?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Array<Scalars['String']['output']>>;
  isPinned?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  practitionerId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of TreatmentPlanSnippetSave */
export type TreatmentPlanSnippetSaveInput = {
  attributes: TreatmentPlanSnippetAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of TreatmentPlanSnippetSave. */
export type TreatmentPlanSnippetSavePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: TreatmentPlanSnippetSaveError;
  treatmentPlanSnippet?: Maybe<TreatmentPlanSnippet>;
};

/** Arguments for sorting treatment plan snippets */
export type TreatmentPlanSnippetSort = {
  direction: SortDirection;
  field: TreatmentPlanSnippetSortField;
};

/** Sortable fields for treatment plan snippets */
export const TreatmentPlanSnippetSortField = {
  /** Whether the snippet is pinned/favorited. Descending will show pinned snippets first. */
  IsPinned: 'IS_PINNED',
  /** Name of the snippet */
  Name: 'NAME'
} as const;

export type TreatmentPlanSnippetSortField = typeof TreatmentPlanSnippetSortField[keyof typeof TreatmentPlanSnippetSortField];
/** Arguments for sorting treatment plans. */
export type TreatmentPlanSort = {
  direction: SortDirection;
  field: TreatmentPlanSortField;
};

/** Treatment plan sort field */
export const TreatmentPlanSortField = {
  /** Date on which treatment plan was made available */
  AvailableAt: 'AVAILABLE_AT',
  /** Date on which treatment plan was last updated */
  UpdatedAt: 'UPDATED_AT'
} as const;

export type TreatmentPlanSortField = typeof TreatmentPlanSortField[keyof typeof TreatmentPlanSortField];
/** Treatment plan states */
export const TreatmentPlanStates = {
  Active: 'ACTIVE',
  Cancelled: 'CANCELLED',
  Draft: 'DRAFT',
  Template: 'TEMPLATE'
} as const;

export type TreatmentPlanStates = typeof TreatmentPlanStates[keyof typeof TreatmentPlanStates];
/** Protocol: Template for the creation of treatment plans. */
export type TreatmentPlanTemplate = LegacyId & Node & Timestamps & {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
  /** Marks the clerk that created this template (on behalf of a practitioner) */
  clerk?: Maybe<Clerk>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** State of the protocol (eg. active, draft, cancelled) */
  currentState: Scalars['String']['output'];
  /**
   * Fullscript Protocol record. This will only return a record if the template
   * belongs to a Fullscript Protocol (curated by IMAT)
   */
  fullscriptProtocol?: Maybe<FullscriptProtocol>;
  /**
   * Whether one or more products in the protocol are prescription-only (aka. can
   * only be ordered if they've been prescribed to the patient)
   */
  hasPrescriptionOnlyProducts: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Whether or not the template has been used to create treatment or multi patient plan(s). */
  isAppliedToPlan: Scalars['Boolean']['output'];
  /** Marks whether the current patient has favorited the protocol */
  isPatientFavorited: Scalars['Boolean']['output'];
  /** Name of the protocol */
  name: Scalars['String']['output'];
  /** URL that can be used to share the protocol with patients */
  patientShareableUrl?: Maybe<Scalars['String']['output']>;
  /** All options for which patients the protocol can be shared with (eg. my_patients, store_patients) */
  patientSharingOptions?: Maybe<Array<Scalars['String']['output']>>;
  /** Name of the plan type eg TreatmentPlanTemplate */
  planType: Scalars['String']['output'];
  /** Practitioner that created the protocol */
  practitioner: Practitioner;
  /** The practitioner sharing level for the protocol (eg. none, specific_practitioners, all) */
  practitionerSharingLevel: Scalars['String']['output'];
  /** True if the template is shared globally as a Fullscript protocol */
  shareable: Scalars['Boolean']['output'];
  /** Field that marks which patients the protocol can be shared with (eg. my_patients, store_patients) */
  shareableWithPatients: Scalars['String']['output'];
  /** Patients this protocol has been shared with */
  sharedPatients?: Maybe<Array<Patient>>;
  /** Count of patients the protocol has been shared with */
  sharedPatientsCount: Scalars['Int']['output'];
  /** Practitioners this protocol has been shared with */
  sharedWithPractitioners?: Maybe<Array<Practitioner>>;
  /**
   * Whether or not to display the 'not in catalog' banner within the wellevate
   * imported protocols and the warning pill on the protocol list
   */
  showUnavailableProducts: Scalars['Boolean']['output'];
  /** Unique identifier of the protocol */
  slug?: Maybe<Scalars['String']['output']>;
  /**
   * The template record this protocol was copied (created) from. It could be a
   * Fullscript Protocol template or a template shared by another practitioner
   */
  sourceTreatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
  /** Treatment plan (with `template` state) that holds the info for this protocol */
  treatmentPlan: TreatmentPlan;
  /** ID of the treatment plan (with `template` state) that holds the info for this protocol */
  treatmentPlanId?: Maybe<Scalars['ID']['output']>;
  /** Unavailable Wellevate products data within the imported protocol */
  unavailableWvProtocolItems?: Maybe<Array<UnavailableWvProtocolItem>>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Protocol: Template for the creation of treatment plans. */
export type TreatmentPlanTemplateIsAppliedToPlanArgs = {
  planId: Scalars['ID']['input'];
};

/** Treatment plan template authors */
export const TreatmentPlanTemplateAuthors = {
  /** Show all treatment plan templates */
  All: 'ALL',
  /** Filters only treatment plan templates created by fullscript */
  FullscriptTemplates: 'FULLSCRIPT_TEMPLATES',
  /** Filters only treatment plan templates shared to or created by current user */
  MyOrSharedTemplates: 'MY_OR_SHARED_TEMPLATES',
  /** Filters only treatment plan templates created by current user */
  MyTemplates: 'MY_TEMPLATES',
  /** Filters only treatment plan templates shared to current user */
  SharedTemplates: 'SHARED_TEMPLATES'
} as const;

export type TreatmentPlanTemplateAuthors = typeof TreatmentPlanTemplateAuthors[keyof typeof TreatmentPlanTemplateAuthors];
/** Autogenerated error type of TreatmentPlanTemplateCancel */
export type TreatmentPlanTemplateCancelError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of TreatmentPlanTemplateCancel */
export type TreatmentPlanTemplateCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

/** Autogenerated return type of TreatmentPlanTemplateCancel. */
export type TreatmentPlanTemplateCancelPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanTemplateCancelError>;
  treatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
};

/** The connection type for TreatmentPlanTemplate. */
export type TreatmentPlanTemplateConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TreatmentPlanTemplateEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TreatmentPlanTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TreatmentPlanTemplateEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TreatmentPlanTemplate>;
};

/** Autogenerated error type of TreatmentPlanTemplateFavoriteCreate */
export type TreatmentPlanTemplateFavoriteCreateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of TreatmentPlanTemplateFavoriteCreate */
export type TreatmentPlanTemplateFavoriteCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

/** Autogenerated return type of TreatmentPlanTemplateFavoriteCreate. */
export type TreatmentPlanTemplateFavoriteCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: TreatmentPlanTemplateFavoriteCreateError;
  treatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
};

/** Autogenerated error type of TreatmentPlanTemplateFavoriteDelete */
export type TreatmentPlanTemplateFavoriteDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of TreatmentPlanTemplateFavoriteDelete */
export type TreatmentPlanTemplateFavoriteDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

/** Autogenerated return type of TreatmentPlanTemplateFavoriteDelete. */
export type TreatmentPlanTemplateFavoriteDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: TreatmentPlanTemplateFavoriteDeleteError;
  treatmentPlanTemplate?: Maybe<TreatmentPlanTemplate>;
};

/** Arguments for filtering treatment plan templates. */
export type TreatmentPlanTemplateFilterObject = {
  /** Filter by the authors of a protocol */
  authors?: InputMaybe<TreatmentPlanTemplateAuthors>;
  /** Filter by whether the template contains labs or not */
  containsLabs?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the current state of the template ('active', 'cancelled', or 'draft') */
  currentState?: InputMaybe<Scalars['String']['input']>;
  /** Filter by fullscript protocol category ID */
  fullscriptProtocolCategoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by whether to hide restricted lab tests or not */
  hideTemplatesWithRestrictedLabTests?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by name - whether the template name matches the given query */
  nameQuery?: InputMaybe<Scalars['String']['input']>;
  /** Filter by patient ID - whether that patient has favorited the template */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter the template by practitioner ID - whether that practitioner authored the template */
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated error type of TreatmentPlanTemplateLogCopyUrl */
export type TreatmentPlanTemplateLogCopyUrlError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of TreatmentPlanTemplateLogCopyUrl */
export type TreatmentPlanTemplateLogCopyUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

/** Autogenerated return type of TreatmentPlanTemplateLogCopyUrl. */
export type TreatmentPlanTemplateLogCopyUrlPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanTemplateLogCopyUrlError>;
};

/** Autogenerated error type of TreatmentPlanTemplateLogView */
export type TreatmentPlanTemplateLogViewError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of TreatmentPlanTemplateLogView */
export type TreatmentPlanTemplateLogViewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  treatmentPlanTemplateId: Scalars['ID']['input'];
};

/** Autogenerated return type of TreatmentPlanTemplateLogView. */
export type TreatmentPlanTemplateLogViewPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<TreatmentPlanTemplateLogViewError>;
};

/** Arguments for treatment plan template sorting. */
export type TreatmentPlanTemplateSort = {
  direction: SortDirection;
  field: TreatmentPlanTemplateSortField;
};

/** Treatment plan template sort field */
export const TreatmentPlanTemplateSortField = {
  /** sort alphabetically */
  Alphabetical: 'ALPHABETICAL',
  /** Date on which treatment plan template was updated */
  UpdatedAt: 'UPDATED_AT'
} as const;

export type TreatmentPlanTemplateSortField = typeof TreatmentPlanTemplateSortField[keyof typeof TreatmentPlanTemplateSortField];
/** Attributes for collecting events for treatment plan tile clicks */
export type TreatmentPlanTileClickAttributes = {
  filterSearchTerms: Scalars['String']['input'];
  pageVersion: Scalars['String']['input'];
  treatmentPlanId: Scalars['ID']['input'];
  treatmentPlanStatus: Scalars['String']['input'];
};

/** Attributes for creating a treatment plan draft */
export type TreatmentPlansCreateDraftAttributes = {
  attachmentsUploadableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  recommendationsAttributes?: InputMaybe<Array<OfficeRecommendationAttributes>>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

/** Attributes for creating a treatment plan draft */
export type TreatmentPlansFindOrCreateDraftAttributes = {
  attachmentsUploadableIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  recommendationsAttributes?: InputMaybe<Array<OfficeRecommendationAttributes>>;
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

/** 2FA settings for a user */
export type TwoFactorAuthentication = Timestamps & {
  /** The 2FA backup code */
  backupCode: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /**
   * The email address if the MFA method is email
   * @deprecated Use email_masked instead; it is obfuscated for privacy.
   */
  email?: Maybe<Scalars['String']['output']>;
  /** The email address if the MFA method is email (with a partial mask for privacy) */
  emailMasked?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Whether 2FA is enabled */
  isEnabled: Scalars['Boolean']['output'];
  /** Whether the user is locked out due to too many wrong attempts */
  isLocked: Scalars['Boolean']['output'];
  /** The authentication method */
  otpMethod: TwoFactorAuthenticationMethods;
  /** The last 4 digits of the phone number used for SMS */
  phoneLast4?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** User belonging to this 2FA record */
  user: User;
};

/** Possible values for the 2FA otpMethod field */
export const TwoFactorAuthenticationMethods = {
  /** User checks authenticator app for code */
  App: 'app',
  /** Code is sent to user's email */
  Email: 'email',
  /** Code is sent to user's phone */
  Sms: 'sms'
} as const;

export type TwoFactorAuthenticationMethods = typeof TwoFactorAuthenticationMethods[keyof typeof TwoFactorAuthenticationMethods];
/** TypeaheadType */
export type Typeahead = Timestamps & {
  brandName?: Maybe<Scalars['String']['output']>;
  categoryProductIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  entityType: Scalars['String']['output'];
  entityTypeId: Scalars['String']['output'];
  highlightedName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  productSlug?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** An edge in a connection. */
export type TypeaheadEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Typeahead>;
};

/** Typeahead entity type filter options */
export const TypeaheadFilterListField = {
  /** Filters for brands */
  Brands: 'BRANDS',
  /** Filters for categories */
  Categories: 'CATEGORIES',
  /** Filters for conditions */
  Conditions: 'CONDITIONS',
  /** Filters for ingredients */
  Ingredients: 'INGREDIENTS',
  /** Filters for Products */
  Products: 'PRODUCTS',
  /** Filters for supplement types */
  SupplementTypes: 'SUPPLEMENT_TYPES',
  /** Filters for Products */
  TaxonomyConditions: 'TAXONOMY_CONDITIONS',
  /** Filters for Products */
  TaxonomySupplementTypes: 'TAXONOMY_SUPPLEMENT_TYPES'
} as const;

export type TypeaheadFilterListField = typeof TypeaheadFilterListField[keyof typeof TypeaheadFilterListField];
/** TypeaheadGroupType */
export type TypeaheadGroupType = {
  /** Grouped typeahead data for the entity type */
  data: Array<Typeahead>;
  /** Entity type */
  entityType: Scalars['String']['output'];
};

/** The connection type for Typeahead. */
export type TypeaheadSearchConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TypeaheadEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Typeahead>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** Unavailable Wellevate protocol item */
export type UnavailableWvProtocolItem = Node & Timestamps & {
  /** The brand of unavailable product. */
  brand: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** ID of the object. */
  id: Scalars['ID']['output'];
  /** The name of unavailable product. */
  name: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of UpdateMigrationPassword */
export type UpdateMigrationPasswordError = {
  /** Field errors */
  fields?: Maybe<UpdateMigrationPasswordFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of UpdateMigrationPassword */
export type UpdateMigrationPasswordFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  newPassword?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of UpdateMigrationPassword */
export type UpdateMigrationPasswordInput = {
  attributes: AuthUpdateMigrationPasswordAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateMigrationPassword. */
export type UpdateMigrationPasswordPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UpdateMigrationPasswordError;
  redirectPath?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

/** The uploadable classes that document attachments can be associated to */
export type Uploadable = {
  /** Attachment url */
  attachment: Scalars['String']['output'];
  /** Name of the file */
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Returns whether it's attached to a plan or not */
  isAttached: Scalars['Boolean']['output'];
  /** Type of the uploadable, Imat::Content or Document */
  type: Scalars['String']['output'];
};


/** The uploadable classes that document attachments can be associated to */
export type UploadableIsAttachedArgs = {
  treatmentPlanId?: InputMaybe<Scalars['ID']['input']>;
};

/** User */
export type User = Node & Timestamps & {
  /** User's avatar */
  avatar?: Maybe<Avatar>;
  /** Password confirmation */
  confirmPassword: Scalars['Boolean']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** User's current role */
  currentUserRole?: Maybe<Scalars['String']['output']>;
  /** User's email address */
  email: Scalars['String']['output'];
  /** Emancipated dependents */
  emancipatedDependents: Array<Dependent>;
  /** Last step completed in Emerson migration */
  emersonMigrationStatus?: Maybe<EmersonMigrationStatus>;
  /** Returns an experiment group for a specific experiment name */
  experimentGroup?: Maybe<Scalars['String']['output']>;
  /** User's first name */
  firstName: Scalars['String']['output'];
  /** User's full name */
  fullName: Scalars['String']['output'];
  /** Whether user has not set a password or ever logged in with SSO */
  hasIncompleteCredentials: Scalars['Boolean']['output'];
  /** Whether the user is associated to multiple StorePlatforms */
  hasMultipleStorePlatforms: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Whether user's account is locked */
  isLocked: Scalars['Boolean']['output'];
  /** Returns signed Iterable JWT of current user */
  iterableJwt?: Maybe<Scalars['String']['output']>;
  /** User's last name */
  lastName: Scalars['String']['output'];
  /** Returns signed mobile Iterable JWT of current user */
  mobileIterableJwt?: Maybe<Scalars['String']['output']>;
  /** User's notification settings */
  notificationSettings: Array<UserNotificationSetting>;
  /** Pre-filled migration data for Emerson Classic to Fullscript migration. */
  prefilledEmersonMigrationData?: Maybe<UserMigrationData>;
  /** User's recent searches */
  recentSearches?: Maybe<Array<SearchHistory>>;
  /** User's roles */
  roles: Array<Roleable>;
  /** Number of sign ins */
  signInCount: Scalars['Int']['output'];
  /** Whether user has set a password */
  systemGeneratedPassword: Scalars['Boolean']['output'];
  /** User's tours */
  tours?: Maybe<Array<Tour>>;
  /** User's 2FA settings */
  twoFactorAuthentication?: Maybe<TwoFactorAuthentication>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Product variant notifications */
  variantNotifications: VariantNotificationConnection;
};


/** User */
export type UserConfirmPasswordArgs = {
  password: Scalars['String']['input'];
};


/** User */
export type UserExperimentGroupArgs = {
  experimentName: Scalars['String']['input'];
};


/** User */
export type UserNotificationSettingsArgs = {
  channel?: InputMaybe<Array<Scalars['String']['input']>>;
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** User */
export type UserRecentSearchesArgs = {
  source?: InputMaybe<ProductSearchSourceType>;
};


/** User */
export type UserRolesArgs = {
  filters?: InputMaybe<RoleFilterObject>;
};


/** User */
export type UserToursArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  controller?: InputMaybe<Scalars['String']['input']>;
};


/** User */
export type UserVariantNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterObject?: InputMaybe<VariantNotificationFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Attributes for updating a user */
export type UserAttributes = {
  confirmNewPassword?: InputMaybe<Scalars['String']['input']>;
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  newPassword: Scalars['String']['input'];
};

/** Attributes for uploading a user avatar. */
export type UserAvatarAttributes = {
  photo: Scalars['Upload']['input'];
};

/** Autogenerated error type of UserAvatarCreate */
export type UserAvatarCreateError = {
  /** Field errors */
  fields?: Maybe<UserAvatarCreateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of UserAvatarCreate */
export type UserAvatarCreateFieldError = {
  photo?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of UserAvatarCreate */
export type UserAvatarCreateInput = {
  attributes: UserAvatarAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserAvatarCreate. */
export type UserAvatarCreatePayload = {
  avatar?: Maybe<Avatar>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UserAvatarCreateError;
};

/** Autogenerated error type of UserAvatarDelete */
export type UserAvatarDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UserAvatarDelete */
export type UserAvatarDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAvatarId: Scalars['ID']['input'];
};

/** Autogenerated return type of UserAvatarDelete. */
export type UserAvatarDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UserAvatarDeleteError;
};

/** Autogenerated error type of UserChangeStore */
export type UserChangeStoreError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UserChangeStore */
export type UserChangeStoreInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of UserChangeStore. */
export type UserChangeStorePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UserChangeStoreError;
};

/** Attributes for user email */
export type UserEmailAttributes = {
  email: Scalars['String']['input'];
};

/** Autogenerated error type of UserForgotPasswordEmail */
export type UserForgotPasswordEmailError = {
  /** Field errors */
  fields?: Maybe<UserForgotPasswordEmailFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of UserForgotPasswordEmail */
export type UserForgotPasswordEmailFieldError = {
  email?: Maybe<Array<Scalars['String']['output']>>;
  storePlatform?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of UserForgotPasswordEmail */
export type UserForgotPasswordEmailInput = {
  attributes: AuthUserForgotPasswordEmailAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserForgotPasswordEmail. */
export type UserForgotPasswordEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  emailSent?: Maybe<Scalars['Boolean']['output']>;
  errors: UserForgotPasswordEmailError;
};

export type UserMigrationData = {
  /** Primary street address */
  address1?: Maybe<Scalars['String']['output']>;
  /** Secondary address (suite, unit, etc.) */
  address2?: Maybe<Scalars['String']['output']>;
  /** City name */
  city?: Maybe<Scalars['String']['output']>;
  /** Country name */
  country?: Maybe<Scalars['String']['output']>;
  /** Name of the dispensary or practice */
  dispensaryName?: Maybe<Scalars['String']['output']>;
  /** User's first name from migration data */
  firstName?: Maybe<Scalars['String']['output']>;
  /** User's last name from migration data */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Contact phone number for the business */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** State or province */
  state?: Maybe<Scalars['String']['output']>;
  /** Name suffix (e.g., Jr., Sr., III) */
  suffix?: Maybe<Scalars['String']['output']>;
  /** User's professional title (e.g., Dr., Mr., Mrs.) */
  title?: Maybe<Scalars['String']['output']>;
  /** Postal/ZIP code */
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UserMobileSignInWithApple */
export type UserMobileSignInWithAppleInput = {
  attributes: AuthUserMobileSingleSignOnAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserMobileSignInWithApple. */
export type UserMobileSignInWithApplePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UserMobileSignInWithSsoError;
  user?: Maybe<User>;
};

/** Autogenerated input type of UserMobileSignInWithGoogle */
export type UserMobileSignInWithGoogleInput = {
  attributes: AuthUserMobileSingleSignOnAttributes;
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserMobileSignInWithGoogle. */
export type UserMobileSignInWithGooglePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: UserMobileSignInWithSsoError;
  user?: Maybe<User>;
};

/** Autogenerated error type of UserMobileSignInWithSSO */
export type UserMobileSignInWithSsoError = {
  /** Field errors */
  fields?: Maybe<UserMobileSignInWithSsoFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of UserMobileSignInWithSSO */
export type UserMobileSignInWithSsoFieldError = {
  accessToken?: Maybe<Array<Scalars['String']['output']>>;
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  idToken?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  nonce?: Maybe<Array<Scalars['String']['output']>>;
};

/** User notification setting */
export type UserNotificationSetting = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Whether or not the user is subscribed. */
  subscribed?: Maybe<Scalars['Boolean']['output']>;
  /** Details for the notification type. */
  type: NotificationSettingType;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The user that this notification setting is for. */
  user: User;
};

/** Autogenerated error type of UserNotificationSettingUpdate */
export type UserNotificationSettingUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UserNotificationSettingUpdate */
export type UserNotificationSettingUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  subscribed?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UserNotificationSettingUpdate. */
export type UserNotificationSettingUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<UserNotificationSettingUpdateError>;
  setting?: Maybe<UserNotificationSetting>;
};

/** User role address type */
export type UserRoleAddress = Timestamps & {
  /** Address line 1 */
  address1: Scalars['String']['output'];
  /** Address line 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** Type of address (ex. Residential, Commercial Business, etc.) */
  addressType?: Maybe<Scalars['String']['output']>;
  /** Name of city */
  city: Scalars['String']['output'];
  /** Full name of country */
  country: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** A value indicating whether the address is set as default for the store */
  default?: Maybe<Scalars['Boolean']['output']>;
  /** First name of the user */
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** A value indicating whether the address is a dropship address */
  isDropship?: Maybe<Scalars['Boolean']['output']>;
  /** Last name of the user */
  lastname: Scalars['String']['output'];
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** State or province of the user */
  state: State;
  /** Id of the state or province of the address. */
  stateId: Scalars['ID']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Role of the user (ex. Patient, Practitioner, etc.) */
  userRole: Roleable;
  /** Zipcode or postal code */
  zipcode: Scalars['String']['output'];
};

/** Attributes for adding an address to a practitioner or clerk */
export type UserRoleAddressAttributes = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  stateId: Scalars['ID']['input'];
  zipcode: Scalars['String']['input'];
};

/** Attributes for updating user security attributes (email and password) */
export type UserSecurityAttributes = {
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated error type of UserSecurityFieldsUpdate */
export type UserSecurityFieldsUpdateError = {
  /** Field errors */
  fields?: Maybe<UserSecurityFieldsUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of UserSecurityFieldsUpdate */
export type UserSecurityFieldsUpdateFieldError = {
  currentPassword?: Maybe<Array<Scalars['String']['output']>>;
  email?: Maybe<Array<Scalars['String']['output']>>;
  password?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of UserSecurityFieldsUpdate */
export type UserSecurityFieldsUpdateInput = {
  attributes: UserSecurityAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserSecurityFieldsUpdate. */
export type UserSecurityFieldsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<UserSecurityFieldsUpdateError>;
  /** The user that is getting their password and email updated */
  user?: Maybe<User>;
};

/** Autogenerated error type of UserVariantNotificationDelete */
export type UserVariantNotificationDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UserVariantNotificationDelete */
export type UserVariantNotificationDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variantNotificationId: Scalars['String']['input'];
};

/** Autogenerated return type of UserVariantNotificationDelete. */
export type UserVariantNotificationDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<UserVariantNotificationDeleteError>;
  variantNotification: VariantNotification;
};

/** UsersMapAgreement */
export type UsersMapAgreement = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The MAP agreement. */
  mapAgreement: MapAgreement;
  /** The date that the MAP agreement was signed. */
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The status of the MAP agreement. */
  status: UsersMapAgreementStatusTypes;
  /** The store associated with the MAP agreement. */
  store: Store;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The user associated with the MAP agreement. */
  user: User;
};

/** Autogenerated error type of UsersMapAgreementCreate */
export type UsersMapAgreementCreateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UsersMapAgreementCreate */
export type UsersMapAgreementCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mapAgreementId: Scalars['ID']['input'];
  status: UsersMapAgreementStatusTypes;
};

/** Autogenerated return type of UsersMapAgreementCreate. */
export type UsersMapAgreementCreatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<UsersMapAgreementCreateError>;
  usersMapAgreement?: Maybe<UsersMapAgreement>;
};

/** User MAP agreement status types */
export const UsersMapAgreementStatusTypes = {
  /** User has accepted a MAP agreement */
  Accepted: 'ACCEPTED',
  /** User has been blocked from adding an item to their cart. */
  Blocked: 'BLOCKED',
  /** User has not yet interacted with this MAP agreement */
  None: 'NONE',
  /** User has rejected a MAP agreement */
  Rejected: 'REJECTED',
  /** User has accepted a MAP agreement that replaces this one */
  Replaced: 'REPLACED'
} as const;

export type UsersMapAgreementStatusTypes = typeof UsersMapAgreementStatusTypes[keyof typeof UsersMapAgreementStatusTypes];
/** Variation of a product */
export type Variant = Actionable & Node & Timestamps & Viewable & {
  /** Accessory variant if this variant is a kit */
  accessory?: Maybe<Variant>;
  /** Price for the current role using the standard discount and the autoship discount. Only used to display autoship prices. */
  autoshipPrice?: Maybe<Scalars['Float']['output']>;
  /** Availability of the variant. (ex: available, unavailable, discontinued, backordered, to be discontinued) */
  availabilityStatus: Scalars['String']['output'];
  /** Brand of the variant */
  brand: Brand;
  /** Brand of the variant with personalization message for daily packs */
  brandName: Scalars['String']['output'];
  /** Number of available items */
  countOnHand: Scalars['Int']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Daily Pack object if variant is a daily pack product */
  dailyPack?: Maybe<DailyPack>;
  /** Default dosage instruction */
  defaultDosageInstruction?: Maybe<DefaultDosageInstruction>;
  /** Default dosage amount for the product */
  defaultProductDosageAmount?: Maybe<Scalars['String']['output']>;
  /** Default dosage frequency for the product */
  defaultProductDosageFrequency?: Maybe<Scalars['String']['output']>;
  /** Date when the variant was deleted */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Descriptor of the variant (ex: 90 capsules) */
  descriptor: Scalars['String']['output'];
  /** The first DosageFormat of the variant */
  dosageFormat?: Maybe<Scalars['String']['output']>;
  /** Emerson retail price */
  emersonRetailPrice?: Maybe<Scalars['Float']['output']>;
  /** The Emerson specific variant ID */
  emersonVariantId?: Maybe<Scalars['String']['output']>;
  /** Expected return date for the variant */
  expectedReturnDate?: Maybe<Scalars['Date']['output']>;
  /** Whether the product with given variant has 2 or more alternative products */
  hasRecommendationAlternatives: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** URL for the variant's image */
  image: Scalars['String']['output'];
  /** URL for the variant's image. Will return the first 360 image if available */
  imageNew: Scalars['String']['output'];
  /** Associated image for the object */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /**
   * Deprecated
   * @deprecated Images is deprecated, please use 'images_new' instead. The return type will soon change from [Viewable] to [String]
   */
  images: Array<Viewable>;
  /** URLs for the variant's images. Will return the 360 images if available */
  imagesNew: Array<Scalars['String']['output']>;
  /** Ingredients contained in the variant */
  ingredients: Array<Ingredient>;
  /** Whether the variant is allowed to be added to a public multi-patient plan */
  isAllowedInPublicMultiPatientPlan: Scalars['Boolean']['output'];
  /** Whether the variant is allowed to be added to a selective multi-patient plan */
  isAllowedInSelectiveMultiPatientPlan: Scalars['Boolean']['output'];
  /** Whether the variant is allowed to be repurchased by the patient */
  isAllowedToRepurchase: Scalars['Boolean']['output'];
  /** Whether the variant is allowed to be showcased on the practitioners external website */
  isAllowedToShowcase: Scalars['Boolean']['output'];
  /** Whether the variant is available (in stock, backordered, etc.) */
  isAvailableForPurchase: Scalars['Boolean']['output'];
  /** Whether you can be notified when the variant is no longer backordered */
  isBackorderNotifiable: Scalars['Boolean']['output'];
  /** Whether the current role on platform is subscribed to the variant's backorder notification */
  isBackorderNotificationSubscribed: Scalars['Boolean']['output'];
  /** Whether the variant is the master variant */
  isMaster: Scalars['Boolean']['output'];
  /** Should display new badge for the variant (marketing start date is within the last 90 days) */
  isNew?: Maybe<Scalars['Boolean']['output']>;
  /** Lab test of the variant */
  labTest?: Maybe<LabsTest>;
  /** Manufacturer's suggested retail price */
  msrp?: Maybe<Scalars['Float']['output']>;
  /** Name of the variant's product concatenated with the descriptor */
  name: Scalars['String']['output'];
  /** Other available variants for the same product */
  otherAvailableVariants?: Maybe<Array<Variant>>;
  /** Whether the variant is a Pill Pack product */
  pillPack: Scalars['Boolean']['output'];
  /** populations */
  populations: Array<Population>;
  /** Product for the variant */
  product: Product;
  /** Get product daily intake with hierarchy */
  productDailyIntake: Array<PlanIngredient>;
  /** Monthly cost of this variant */
  productMonthlyCost: Scalars['Float']['output'];
  /** Name of the variant's product */
  productName: Scalars['String']['output'];
  /** List of suggested alternative products for given variant */
  recommendationAlternatives?: Maybe<VariantWithAlternativesType>;
  /** List of suggested alternative products for given variant without intakes */
  recommendationAlternativesWithoutIntakes?: Maybe<VariantWithAlternativesType>;
  /** Whether the variant is available for retail (patients) */
  retail: Scalars['Boolean']['output'];
  /** Price of the variant for the current role, including any discounts */
  sellPrice: Scalars['Float']['output'];
  /** Formatted string for the sell price including the currency (ex: '$25.90') */
  sellPriceCurrency: Scalars['Currency']['output'];
  /** The number of servings of the variant possible given the units per container */
  servingsPerContainer?: Maybe<Scalars['String']['output']>;
  /** Code used to identify the variant (Stock keeping unit) */
  sku: Scalars['String']['output'];
  /**
   * Deprecated
   * @deprecated three_sixty_images is deprecated, please use 'image' or 'images' instead
   */
  threeSixtyImages: Array<Scalars['String']['output']>;
  /** The date the variant will be discontiued. */
  toBeDiscontinuedDate?: Maybe<Scalars['Date']['output']>;
  /** The type of object being returned. */
  type: Scalars['String']['output'];
  /** Universal product code */
  upc?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** URL of a large-sized image */
  urlLarge: Scalars['String']['output'];
  /** URL of a mini-sized image */
  urlMini: Scalars['String']['output'];
  /** URL of a orignal-sized image */
  urlOriginal: Scalars['String']['output'];
  /** URL of a product-sized (aka default) image */
  urlProduct: Scalars['String']['output'];
  /** URL of a small-sized image */
  urlSmall: Scalars['String']['output'];
  /** URL of an extra-large-sized image */
  urlXlarge: Scalars['String']['output'];
  /** Whether the variant can be sold to the current patient/store */
  validForSale: Scalars['Boolean']['output'];
  /** Whether the variant can be sold to the current store */
  validForSaleWithPrescription: Scalars['Boolean']['output'];
  /** List of suggested alternative products for given variant without intakes */
  variantComparisons?: Maybe<VariantWithAlternativesType>;
  /** variant notifications */
  variantNotifications: VariantNotificationConnection;
  /** The type of Variant */
  variantType: Scalars['String']['output'];
  /** Whether the variant is available for wholesale (practitioners) */
  wholesale: Scalars['Boolean']['output'];
  /** Price of the variant when bought through wholesale */
  wholesalePrice?: Maybe<Scalars['Float']['output']>;
  /** Formatted string for the wholesale price including the currency (ex: '$25.90') */
  wholesalePriceCurrency?: Maybe<Scalars['Currency']['output']>;
  /** Promotion details */
  wholesalePromotionDetails: WholesalePromotionDetails;
};


/** Variation of a product */
export type VariantImageArgs = {
  filters?: InputMaybe<ImageFilterObject>;
};


/** Variation of a product */
export type VariantImageNewArgs = {
  filters?: InputMaybe<ImageFilterObject>;
};


/** Variation of a product */
export type VariantImagesNewArgs = {
  filters?: InputMaybe<ImageFilterObject>;
};


/** Variation of a product */
export type VariantProductDailyIntakeArgs = {
  amount?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
};


/** Variation of a product */
export type VariantProductMonthlyCostArgs = {
  amount: Scalars['String']['input'];
  frequency: Scalars['String']['input'];
};


/** Variation of a product */
export type VariantRecommendationAlternativesArgs = {
  amount: Scalars['String']['input'];
  frequency: Scalars['String']['input'];
};


/** Variation of a product */
export type VariantRecommendationAlternativesWithoutIntakesArgs = {
  amount?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
};


/** Variation of a product */
export type VariantThreeSixtyImagesArgs = {
  filters?: InputMaybe<ThreeSixtyImageFilterObject>;
};


/** Variation of a product */
export type VariantVariantComparisonsArgs = {
  alternatives: Array<AlternativeAttributes>;
  baseVariantAmount?: InputMaybe<Scalars['String']['input']>;
  baseVariantFrequency?: InputMaybe<Scalars['String']['input']>;
};


/** Variation of a product */
export type VariantVariantNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterObject?: InputMaybe<VariantNotificationFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Variant. */
export type VariantConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VariantEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Variant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VariantEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Variant>;
};

/** Arguments for filtering variants. */
export type VariantFilterObject = {
  /** Filter by sku or emerson variant id */
  query?: InputMaybe<Scalars['String']['input']>;
};

/** An alert sent to a user when a variant is back in stock */
export type VariantNotification = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The state of the notification (watching, permanent, not_watching). */
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Date that a notification was sent to the user */
  notificationSentAt?: Maybe<Scalars['DateTime']['output']>;
  /** The patient that is receiving the notification */
  patient?: Maybe<Patient>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The variant that is being watched for changes in stock */
  variant: Variant;
};

/** The connection type for VariantNotification. */
export type VariantNotificationConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VariantNotificationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<VariantNotification>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VariantNotificationEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<VariantNotification>;
};

/** Arguments for filtering variant notifications. */
export type VariantNotificationFilterObject = {
  notified?: InputMaybe<Scalars['Boolean']['input']>;
  watching?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Variant notification filters */
export const VariantNotificationFilters = {
  /** Filters only variant notifications whose notification sent at is not null */
  ScopeNotified: 'SCOPE_NOTIFIED',
  /** Filters only variant notifications whose notification sent at is null */
  ScopeNotNotified: 'SCOPE_NOT_NOTIFIED',
  /** Filters only variant notifications whose current state is not watching */
  ScopeNotWatching: 'SCOPE_NOT_WATCHING',
  /** Filters only variant notifications whose current state is watching */
  ScopeWatching: 'SCOPE_WATCHING'
} as const;

export type VariantNotificationFilters = typeof VariantNotificationFilters[keyof typeof VariantNotificationFilters];
/** A base product's variant with alternative products */
export type VariantWithAlternativesType = {
  /** Array of alternative products for the base product */
  alternativeProducts?: Maybe<Array<VariantWithDosage>>;
  /** Base product that was requested */
  baseProduct: VariantWithDosage;
};

/** A recommended variant with dosage (unrelated to a plan's recommendation) */
export type VariantWithDosage = {
  /** The algorithm version used to calculate the recommendation alternatives */
  algorithmVersion?: Maybe<Scalars['String']['output']>;
  /** Daily dosage of the variant (eg. 2 capsules) */
  dailyDosage: Scalars['Float']['output'];
  /** daily intake of ingredients with hierarchy */
  dailyIntake?: Maybe<Array<PlanIngredient>>;
  /** Feedback for variant's recommendation */
  feedback?: Maybe<RecommendationAlternativeFeedback>;
  /** Priority 5 matches are categorical match for the base product */
  matchType?: Maybe<Scalars['String']['output']>;
  /** Monthly cost of this variant to achieve the target intake */
  monthlyCost: Scalars['Float']['output'];
  /** The daily intake of an alternative's ingredients sorted by those shared and not shared with the base product */
  multiIngredientDailyIntake?: Maybe<MultiIngredientDailyIntake>;
  /** Expected refill rate for this variant as calculated by Data */
  refillRate?: Maybe<Scalars['Float']['output']>;
  /** Array of tags for this variant */
  tags?: Maybe<Array<Scalars['String']['output']>>;
  /** Recommended product */
  variant: Variant;
};

/** The viewable classes that images can be associated to */
export type Viewable = {
  id: Scalars['ID']['output'];
  /** The type of object being returned. */
  type: Scalars['String']['output'];
  /** URL of a large-sized image */
  urlLarge: Scalars['String']['output'];
  /** URL of a mini-sized image */
  urlMini: Scalars['String']['output'];
  /** URL of a orignal-sized image */
  urlOriginal: Scalars['String']['output'];
  /** URL of a product-sized (aka default) image */
  urlProduct: Scalars['String']['output'];
  /** URL of a small-sized image */
  urlSmall: Scalars['String']['output'];
  /** URL of an extra-large-sized image */
  urlXlarge: Scalars['String']['output'];
};

/** Domain for almost everything that isn't user or role-specific */
export type Viewer = {
  /** Account types (individual, LLC - single member, s corporation, etc.) */
  accountTypes: Array<AccountType>;
  /** Specific product spotlight by ID or current active spotlight */
  activeProductSpotlight?: Maybe<ProductSpotlight>;
  /** Alerts for the office */
  alerts: Array<Alert>;
  /** Alert banners */
  alertsBanners: Array<AlertsBanner>;
  /** All allergens that are found in products */
  allergens: Array<Allergen>;
  /** Returns the currently set or newly set analytics id */
  analyticsAnonymousId?: Maybe<Scalars['String']['output']>;
  /** The authentication provider of the current session */
  authenticationProvider: AuthenticationProviders;
  /** Find a brand by slug or np_slug */
  brand: Brand;
  /** Paginated list of brands that the user has access to. */
  brands: BrandConnection;
  /** Full list of brands with active products. */
  brandsList: Array<BrandList>;
  /**
   * Paginated list of categories that the user has access to.
   * @deprecated Use taxonomies instead
   */
  categories: CategoryConnection;
  /** List of medical conditions. (adrenal support, bone health, etc.) */
  conditions: CategoryGroupConnection;
  /** List of current countries */
  countries: Array<Country>;
  /** The ISO country code of the current country */
  countryIso: Scalars['String']['output'];
  /** List of categories for Daily Pack pills. */
  dailyPackPillCategories: Array<PillCategory>;
  /** A list of flippers enabled for the current user */
  enabledFlippers: Array<Scalars['String']['output']>;
  /** All health areas for the explore page. */
  exploreAreas: Explore;
  /** A list of all flippers */
  flippers: Array<Flipper>;
  /** Different forms our products can take. (capsule liquid filled, tablet chewable, liquid, packet, etc.) */
  forms?: Maybe<Array<DosageFormat>>;
  /** Find a Fullscript protocol by slug */
  fullscriptProtocol?: Maybe<FullscriptProtocol>;
  /** Fullscript protocol categories sorted by name. (Cardiovascular Health, Metabolic Health, Blood Sugar, etc.) */
  fullscriptProtocolCategories: Array<FullscriptProtocolCategory>;
  /** Fullscript protocols. (Antioxidant Support, Headaches, Antibiotic Resistance, etc.) */
  fullscriptProtocols: FullscriptProtocolConnection;
  /** Health Conditions. (Sleep Support, Bone Health, Adrenal Support, etc) */
  healthConditions: Array<HealthCondition>;
  /** Categories for Fullscript/IMAT content. (Nutrition, Lifestyle, Supplements, etc.) */
  imatContentCategories: Array<ImatContentCategory>;
  /** Types or formats of Fullscript/IMAT content. (Infographic, Handout, Guide, etc.) */
  imatContentTypes: Array<ImatContentType>;
  /** Content published by our IMAT team. (Mediterranean Diet, Probiotics, Breathing Exercises, etc.) */
  imatContents: ImatContentConnection;
  /** Find ingredients by id. */
  ingredientUnits: Ingredient;
  /** List of ingredients */
  ingredients: IngredientConnection;
  /** Is the user being impersonated by an admin */
  isAdminImpersonating: Scalars['Boolean']['output'];
  /** Is the user signed in */
  isUserSignedIn: Scalars['Boolean']['output'];
  /**
   * Returns signed Iterable JWT of current user
   * @deprecated Use current_user instead
   */
  iterableJwt?: Maybe<Scalars['String']['output']>;
  /** Lab appointment locations */
  labAppointmentLocations: LabsAppointmentLocationConnection;
  /** All active lab biomarkers */
  labBiomarkers: Array<LabsBiomarker>;
  /** All active lab companies */
  labCompanies: Array<LabsCompany>;
  /** All failed lab fulfillment authorizations */
  labFulfillmentFailedAuthorizations: Array<LabFulfillmentsAuthorization>;
  /** All failed lab fulfillment invocations */
  labFulfillmentFailedInvocations: Array<LabFulfillmentsInvocation>;
  /** All active lab health categories */
  labHealthCategories: Array<LabsHealthCategory>;
  /** All Labs Journey Variants */
  labJourneyVariants: Array<LabsJourneysVariant>;
  /**
   * Lab kit typeahead search. Includes test types, sample types, biomarkers,
   * companies, health categories, tests and wholesale lab kit products
   */
  labKitTypeaheadSearch: Array<LabTypeaheadGroupType>;
  /** Search for lab kit products */
  labKitsSearch: ProductSearchConnection;
  /** List of states where lab tests are restricted for patients */
  labPatientRestrictedStates: Array<State>;
  /** All lab sample types */
  labSampleTypes: Array<LabsSampleType>;
  /** Find lab test by id */
  labTest?: Maybe<LabsTest>;
  /** All lab test types (Kit/Marker/Panel) */
  labTestTypes: Array<LabsTestType>;
  /** Lab Tests synced from API partners */
  labTests: LabsTestConnection;
  /** List of lab tests for comparison */
  labTestsComparisons: Array<LabsTest>;
  /** Comparison of biomarkers between 2-4 tests */
  labTestsComparisonsBiomarkers?: Maybe<LabsBiomarkerConnection>;
  /** Lab typeahead search. Includes test types, sample types, biomarkers, companies, health categories and tests */
  labTypeaheadSearch: Array<LabTypeahead>;
  /** Lab typeahead search V2. Returns results grouped by sample types, biomarkers, companies, health categories and tests */
  labTypeaheadSearchV2: Array<LabTypeaheadGroupType>;
  /** List of all active Minimum Advertised Price (MAP) agreements for brands. */
  mapAgreements: Array<MapAgreement>;
  /** List of medication products in a specified medication groups and their medication ingredient composition */
  medicationProductIngredients: Array<MedicationProductIngredients>;
  /**
   * List of medication products in a specified medication group and their medication ingredient composition
   * @deprecated Use medication_product_ingredients instead
   */
  medicationProducts: Array<MedicationProduct>;
  /** List of Medication group based on search query */
  medicationsPaginatedTypeahead: MedicationsTypeaheadConnection;
  /**
   * List of Medication group based on search query
   * @deprecated Use medications_paginated_typeahead instead
   */
  medicationsTypeahead: Array<MedicationsTypeahead>;
  /** All Menu Categories and Sub Categories */
  menuCategories: Array<MenuCategory>;
  /** Search for products in catalog index and external_products index */
  multiSourceProductSearch?: Maybe<MultiSourceProductConnection>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  /** Notification setting types. (New features, Wellness content, Refill reminders, etc.) */
  notificationSettingTypes: Array<NotificationSettingType>;
  /** Find OAuth application by UID. */
  oauthApplication?: Maybe<OauthApplication>;
  /** All Optimized Menu Categories and Sub Categories */
  optimizedMenuCategories: Array<OptimizedMenuCategory>;
  /** Cutoff date for the patient promotion */
  patientPromotionCutOffDate?: Maybe<Scalars['DateTime']['output']>;
  /** Future or active patient promotions. (Cyber Monday, June, Resolution Refresh, etc.) */
  patientPromotions: PatientPromotionConnection;
  /**
   * DEPRECATED
   * @deprecated Use alerts_banners instead.
   */
  patientsAlertsBanners: Array<AlertsBanner>;
  /** Global information about the peer referrals program */
  peerReferralProgramConfiguration: PeerReferralProgramConfiguration;
  /** Demographic groups. (Male, Female, Senior, Prenatal, Children) */
  populations: Array<Population>;
  /** Additional attributes for practitioner sign up */
  practitionerSignUpAdditionalAttributes: AuthPractitionerSignUpAdditionalAttributes;
  /** Find a product by slug or np_product_id */
  product: Product;
  /** Search for products V2 */
  productSearchV2: ProductSearchConnection;
  /** List of filtered and sorted products. (ex: POPULAR_BY_WEEK, NEW_ON_FULLSCRIPT, etc.) */
  products: ProductConnection;
  /** List of available profit margins. */
  profitMarginIncrements: Array<Scalars['Int']['output']>;
  /** All active promotion campaigns */
  promotionCampaigns: Array<PromotionCampaign>;
  /** Find pub med condition by given ID */
  pubMedCondition: PubMedCondition;
  /** Pub med condition typeahead search. */
  pubMedConditionsTypeahead: Array<PubMedConditionTypeahead>;
  /** List of currently active patient promotions visible to public */
  publicActivePatientPromotions?: Maybe<PatientPromotionConnection>;
  /** Recently viewed products by the current role, sorted by most recently viewed. */
  recentlyViewedProducts: ProductConnection;
  /** Convert legacy id and model to encoded graphql id. */
  reference: Scalars['ID']['output'];
  /** Returns the available filters for the current search session. */
  searchSessionFilters: SearchSessionFilters;
  /** Additional attributes for user sign in */
  signInAdditionalAttributes: AuthUserSignInAdditionalAttributes;
  /** List of current country's states or provinces. */
  states: Array<State>;
  /** The stripe public key used by the client (for apple pay) */
  stripeMobileApplePayPublicKey: Scalars['String']['output'];
  /** The stripe public key used by the client */
  stripePublicKey: Scalars['String']['output'];
  /** Supplement types. (vitamins, minerals, herbs, b complex, etc.) */
  supplementTypes: SupplementTypeConnection;
  /** Various tags that are applied to products. (10-24, Iron, Citrate, Powder, Vegan, etc.) */
  tags: TagConnection;
  /** Returns a section of the product taxonomy hierarchy */
  taxonomies: Array<Taxonomy>;
  /** List of third-party certifications. */
  thirdPartyCertifications: Array<ThirdPartyCertification>;
  /** Returns a variety of search results that match the query. Includes ingredients, products, brands, etc. */
  typeaheadSearch: TypeaheadSearchConnection;
  /** Returns a list of typeahead search results grouped by entity_type i.e Ingredient, Product, Brand, etc. */
  typeaheadSearchV2: Array<TypeaheadGroupType>;
  /** Current logged in user */
  user: User;
  /** List of wholesale kits */
  wholesaleKits: ProductSearchConnection;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerAccountTypesArgs = {
  filterObject?: InputMaybe<AccountTypeFilterObject>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerAlertsArgs = {
  filters?: InputMaybe<AlertFilterObject>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerAllergensArgs = {
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<AllergenSort>>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerBrandArgs = {
  npSlug?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<BrandFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerConditionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CategoryGroupFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerFormsArgs = {
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<DosageFormatSort>>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerFullscriptProtocolArgs = {
  slug: Scalars['String']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerFullscriptProtocolsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<FullscriptProtocolFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FullscriptProtocolSort>>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerImatContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ImatContentFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FullscriptContentSort>>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerIngredientUnitsArgs = {
  ingredientId?: Scalars['ID']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerIngredientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<IngredientFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<IngredientSort>>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerLabAppointmentLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  labCompanyId?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  radius?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerLabCompaniesArgs = {
  filters?: InputMaybe<LabCompanyFilterObject>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerLabKitTypeaheadSearchArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: Scalars['String']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerLabKitsSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LabTestSearchFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<LabTestSearchSort>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerLabTestArgs = {
  id: Scalars['ID']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerLabTestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<LabTestSearchFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<LabTestSearchSort>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerLabTestsComparisonsArgs = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  testIds: Array<Scalars['ID']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerLabTestsComparisonsBiomarkersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  testIds: Array<Scalars['ID']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerLabTypeaheadSearchArgs = {
  query?: Scalars['String']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerLabTypeaheadSearchV2Args = {
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  query?: Scalars['String']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerMedicationProductIngredientsArgs = {
  medicationGroupIds: Array<Scalars['ID']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerMedicationProductsArgs = {
  medicationGroupId: Scalars['ID']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerMedicationsPaginatedTypeaheadArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchQuery: Scalars['String']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerMedicationsTypeaheadArgs = {
  searchQuery: Scalars['String']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerMenuCategoriesArgs = {
  kind: MenuCategoryType;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerMultiSourceProductSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isUpc?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerNodeArgs = {
  id: Scalars['ID']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerOauthApplicationArgs = {
  clientId: Array<Scalars['ID']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerOptimizedMenuCategoriesArgs = {
  categoryName?: InputMaybe<Scalars['String']['input']>;
  kind: OptimizedMenuCategoryKind;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerPatientPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerPopulationsArgs = {
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<PopulationSort>>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerPractitionerSignUpAdditionalAttributesArgs = {
  attributes?: InputMaybe<AuthPractitionerSignUpAdditionalAttributesInput>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerProductArgs = {
  npProductId?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerProductSearchV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ProductSearchV2FilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  initialFilterSelectionId?: InputMaybe<Scalars['ID']['input']>;
  initialFilterSelectionQuery?: InputMaybe<Scalars['String']['input']>;
  isUpc?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  origin?: InputMaybe<QueryOriginTypes>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProductSearchSort>>;
  source?: InputMaybe<ProductSearchSourceType>;
  typeaheadSelectionId?: InputMaybe<Scalars['ID']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ProductFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerPubMedConditionArgs = {
  pubMedConditionId: Scalars['ID']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerPubMedConditionsTypeaheadArgs = {
  query?: Scalars['String']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerPublicActivePatientPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  storeId: Scalars['ID']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerRecentlyViewedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerReferenceArgs = {
  id: Scalars['String']['input'];
  resource: Scalars['String']['input'];
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerSearchSessionFiltersArgs = {
  allergenSort: Array<AllergenSort>;
  dosageFormatSort: Array<DosageFormatSort>;
  populationSort: Array<PopulationSort>;
  searchSessionId: Scalars['String']['input'];
  thirdPartyCertificationSort: Array<ThirdPartyCertificationSort>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerStatesArgs = {
  countryName?: InputMaybe<Scalars['String']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerSupplementTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TagFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerTaxonomiesArgs = {
  levels?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  storePlatform?: InputMaybe<Scalars['String']['input']>;
  topLevelName?: InputMaybe<Scalars['String']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerThirdPartyCertificationsArgs = {
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ThirdPartyCertificationSort>>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerTypeaheadSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<SearchFilterObject>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProductSearchSort>>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerTypeaheadSearchV2Args = {
  filters?: InputMaybe<SearchFilterObject>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProductSearchSort>>;
  useSkuIdentifier?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Domain for almost everything that isn't user or role-specific */
export type ViewerWholesaleKitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Common details of an address. */
export type WellevateAddress = {
  /** Address line 1 */
  address1: Scalars['String']['output'];
  /** Address line 2 */
  address2?: Maybe<Scalars['String']['output']>;
  /** Name of city */
  city: Scalars['String']['output'];
  /** Full name of country */
  country: Scalars['String']['output'];
  /** First name for address */
  firstname: Scalars['String']['output'];
  /** id */
  id?: Maybe<Scalars['String']['output']>;
  /** Last name for address */
  lastname: Scalars['String']['output'];
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Full name of state or province */
  state: Scalars['String']['output'];
  /** Zipcode or postal code */
  zipcode: Scalars['String']['output'];
};

/** An inventory unit for orders. */
export type WellevateInventoryUnit = {
  /** The line item associated with the inventory unit */
  lineItem: WellevateLineItem;
};

/** Wellevate LineItem */
export type WellevateLineItem = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** MSRP at that time for this item */
  msrpPrice?: Maybe<Scalars['Float']['output']>;
  /** Retail cost of one item */
  price: Scalars['Float']['output'];
  /** Number of units of the item */
  quantity: Scalars['Int']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The matching fullscript variant for this line item, if it exists. */
  variant?: Maybe<Variant>;
  /** The wellevate product if it's not in fullscript catalog */
  wellevateProduct?: Maybe<WellevateProduct>;
};

/** Data from the Wellevate records we failed to convert. */
export type WellevateMigrationErrorProperties = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fsProtocolId?: Maybe<Scalars['String']['output']>;
  fsProtocolName?: Maybe<Scalars['String']['output']>;
  fsProtocolUrl?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

/** Wellevate migration error data for each record unable to convert */
export type WellevateMigrationErrorType = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The error message thrown when the record was unable to convert */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** Attributes from the failed record for more context (ex: patient's firstname, lastname, and email). */
  properties?: Maybe<WellevateMigrationErrorProperties>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique identifier of the record that failed to convert */
  wellevateRecordIdentifier: Scalars['String']['output'];
  /** Class name of the record that failed to convert */
  wellevateRecordType: Scalars['String']['output'];
};

/** Historical log of orders placed in Wellevate */
export type WellevateOrder = Node & Timestamps & {
  /** Billing address */
  billingAddress: WellevateAddress;
  /** Order completion date */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Consolidated status based on order, payment and delivery statuses */
  consolidatedStatus: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Is the order linked to an autoship subscription? */
  isAutoship: Scalars['Boolean']['output'];
  /** Is the order trackable */
  isTrackable: Scalars['Boolean']['output'];
  /** Array with line items */
  lineItems: Array<WellevateLineItem>;
  /** Minimum recommended selling price total */
  msrpTotal?: Maybe<Scalars['Float']['output']>;
  /** Order number */
  number: Scalars['String']['output'];
  /** Patient of this order */
  patient: Patient;
  /** State of the payment */
  paymentState?: Maybe<Scalars['String']['output']>;
  /** Product shipment total */
  productShipmentTotal?: Maybe<Scalars['Float']['output']>;
  /** Total saved amount */
  savingsTotal?: Maybe<Scalars['Float']['output']>;
  /** State of the shipment */
  shipmentState?: Maybe<Scalars['String']['output']>;
  /** Shipment total */
  shipmentTotal: Scalars['Float']['output'];
  /** Array with shipments */
  shipments?: Maybe<Array<WellevateShipment>>;
  /** Shipping address */
  shippingAddress?: Maybe<WellevateAddress>;
  /** Indicates the platform where the order was originally created */
  sourcePlatform: Scalars['String']['output'];
  /** Order state */
  state: Scalars['String']['output'];
  /** Subtotal */
  subTotal: Scalars['Float']['output'];
  /** Tax total */
  taxTotal?: Maybe<Scalars['Float']['output']>;
  /** Total */
  total: Scalars['Float']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for WellevateOrder. */
export type WellevateOrderConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WellevateOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WellevateOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WellevateOrderEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<WellevateOrder>;
};

/** Wellevate patient migration status data for each patient */
export type WellevatePatientMigrationStatus = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Whether the patient has autoship order on Wellevate */
  hasAutoshipOrder: Scalars['Boolean']['output'];
  /** Whether the patient has mobile order on Wellevate */
  hasMobileOrder: Scalars['Boolean']['output'];
  /** Json object with the visibility value for each banner (ONBOARDING_MODAL) */
  migrationBannersVisibility?: Maybe<PatientMigrationBannersVisibility>;
  /** Patient's practitioner migrated from Wellevate */
  practitioner: Practitioner;
  /** Practitioner full name */
  practitionerFullName: Scalars['String']['output'];
  /** Status of the Migration (eg. PENDING, COMPLETED or OPTED_OUT) */
  status: WellevatePatientMigrationStatuses;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** All supported migration statuses. */
export const WellevatePatientMigrationStatuses = {
  /** Migration successfully completed. */
  Completed: 'COMPLETED',
  /** Migration opted out by patient. */
  OptedOut: 'OPTED_OUT',
  /** Migration pending for patient to be initiated. */
  Pending: 'PENDING'
} as const;

export type WellevatePatientMigrationStatuses = typeof WellevatePatientMigrationStatuses[keyof typeof WellevatePatientMigrationStatuses];
/** Attributes associated with a Wellevate practitioner */
export type WellevatePractitioner = {
  /** Name given to their store */
  dispensaryName: Scalars['String']['output'];
  /** Practitioner's email */
  email?: Maybe<Scalars['String']['output']>;
  /** Practitioner's first name */
  firstname?: Maybe<Scalars['String']['output']>;
  /** Practitioner's last name */
  lastname?: Maybe<Scalars['String']['output']>;
  /** Indicates whether the practitioner makes a profit on sold products. (ex: never, variable) */
  marginType: Scalars['String']['output'];
  /** Practitioner's phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  practitionerId: Scalars['ID']['output'];
  /** Practitioner profit margin value */
  profitMargin?: Maybe<Scalars['Float']['output']>;
  /** Practitioner's state */
  state?: Maybe<Scalars['String']['output']>;
};

/** Wellevate Product */
export type WellevateProduct = {
  /** The brand of the product */
  brand: Scalars['String']['output'];
  /** The description of the product */
  description: Scalars['String']['output'];
};

/** Recommendations created in Wellevate, used to display historical records */
export type WellevateRecommendation = Node & Timestamps & {
  /** Any additional information from the practitioner */
  additionalInfo?: Maybe<Scalars['String']['output']>;
  /** Date on which the recommendation was made available */
  availableAt: Scalars['DateTime']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** State of the recommendation */
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Wellevate treatment plan the recommendation belongs to */
  treatmentPlan: WellevateTreatmentPlan;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Variant for the recommendation */
  variant?: Maybe<Variant>;
  /** The wellevate product if it's not in fullscript catalog */
  wellevateProduct?: Maybe<WellevateProduct>;
};

/** Shipment of a wellevate order */
export type WellevateShipment = {
  /** All the inventory units belonging to the shipment */
  inventoryUnits: Array<WellevateInventoryUnit>;
};

/** Treatment plans created in Wellevate, used to display historical records */
export type WellevateTreatmentPlan = LegacyId & Node & Timestamps & {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
  /** Date the treatment plan was made available. */
  availableAt: Scalars['DateTime']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** State of the treatment plan. */
  currentState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** All lab recommendations associated with the treatment plan */
  labRecommendations: Array<LabsRecommendation>;
  /** The message associated with the treatment plan */
  message?: Maybe<Scalars['String']['output']>;
  /**
   * All messages associated with the treatment plan
   * @deprecated A Wellevate Treatment Plan will only have one message in the ERP database. Use message instead.
   */
  messages?: Maybe<Array<WellevateTreatmentPlanMessage>>;
  /** Name given to the treatment plan by the practitioner. */
  name: Scalars['String']['output'];
  /** Patient this treatment plan is for */
  patient?: Maybe<Patient>;
  /** Practitioner that created this treatment plan */
  practitioner: Practitioner;
  /** All recommendations for this treatment plan */
  recommendations: Array<WellevateRecommendation>;
  /** Indicates the platform where the treatment plan was originally created */
  sourcePlatform: Scalars['String']['output'];
  /** The discounted price of the treatment plan for the patient */
  totalDiscountedPrice: Scalars['Float']['output'];
  /** The total price of the treatment plan without any discounts applied */
  totalPrice: Scalars['Float']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Treatment plans created in Wellevate, used to display historical records */
export type WellevateTreatmentPlanMessagesArgs = {
  sort?: InputMaybe<Array<TreatmentPlanMessageSort>>;
};


/** Treatment plans created in Wellevate, used to display historical records */
export type WellevateTreatmentPlanRecommendationsArgs = {
  filterObject?: InputMaybe<RecommendationFilterObject>;
};

/** The connection type for WellevateTreatmentPlan. */
export type WellevateTreatmentPlanConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WellevateTreatmentPlanEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WellevateTreatmentPlan>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WellevateTreatmentPlanEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<WellevateTreatmentPlan>;
};

/** The message associated to a treatment plan */
export type WellevateTreatmentPlanMessage = Node & Timestamps & {
  /** The actual message */
  contents?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The state of the message. Can be active or draft. */
  currentState?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Date the message was published */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of WholePersonPlanAcceptAgreement */
export type WholePersonPlanAcceptAgreementError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholePersonPlanAcceptAgreement */
export type WholePersonPlanAcceptAgreementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WholePersonPlanAcceptAgreement. */
export type WholePersonPlanAcceptAgreementPayload = {
  agreement?: Maybe<PractitionerAgreement>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: WholePersonPlanAcceptAgreementError;
  practitioner?: Maybe<Practitioner>;
};

/** Pubmed conditions with their ingredient recommendations */
export type WholePersonPlanPubMedCondition = {
  /** The health area the condition falls under */
  healthArea: Scalars['String']['output'];
  /** The unique identifier for the pub_med_condition */
  id: Scalars['ID']['output'];
  /** Name of the condition */
  name: Scalars['String']['output'];
  /** Array of ingredients recommended for the condition */
  recommendedIngredients: Array<RecommendedIngredient>;
};

/** Autogenerated error type of WholesaleAddressUnlink */
export type WholesaleAddressUnlinkError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleAddressUnlink */
export type WholesaleAddressUnlinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WholesaleAddressUnlink. */
export type WholesaleAddressUnlinkPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleAddressUnlinkError>;
};

/** Autogenerated error type of WholesaleAddressUpdate */
export type WholesaleAddressUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleAddressUpdate */
export type WholesaleAddressUpdateInput = {
  billingAttributes?: InputMaybe<AddressAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingAttributes?: InputMaybe<AddressAttributes>;
};

/** Autogenerated return type of WholesaleAddressUpdate. */
export type WholesaleAddressUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleAddressUpdateError>;
};

/** Monetary adjustment made to part of a wholesale order such as taxes or promotions */
export type WholesaleAdjustment = Timestamps & {
  /** Amount the order was adjusted by */
  amount: Scalars['Float']['output'];
  /** Code for the associated promotion */
  code?: Maybe<Scalars['String']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the associated promotion */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Whether the promotion or promotion campaign associated with the adjustment is expired */
  isExpired: Scalars['Boolean']['output'];
  /** Label describing the adjustment */
  label: Scalars['String']['output'];
  /** Type of Spree::PromotionAction, e.g. Spree::Promotion::Actions::FreeShipping */
  promotionActionType?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Add to cart errors */
export type WholesaleCartAddToCartError = {
  /** Field errors */
  fields?: Maybe<WholesaleCartAddToCartFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Wholesale add to cart field errors */
export type WholesaleCartAddToCartFieldError = {
  /** Minimum advertised price (MAP) agreements */
  mapAgreements?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of WholesaleCartAddToCart */
export type WholesaleCartAddToCartInput = {
  cart: Array<CartItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  searchSessionId?: InputMaybe<Scalars['String']['input']>;
  storePlatform?: InputMaybe<StorePlatformType>;
};

/** Autogenerated return type of WholesaleCartAddToCart. */
export type WholesaleCartAddToCartPayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartAddToCartError>;
  storePlatform?: Maybe<WholesaleOrder>;
};

/** Autogenerated error type of WholesaleCartAdminCreate */
export type WholesaleCartAdminCreateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleCartAdminCreate */
export type WholesaleCartAdminCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WholesaleCartAdminCreate. */
export type WholesaleCartAdminCreatePayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartAdminCreateError>;
};

/** Autogenerated error type of WholesaleCartAdminDelete */
export type WholesaleCartAdminDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleCartAdminDelete */
export type WholesaleCartAdminDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
};

/** Autogenerated return type of WholesaleCartAdminDelete. */
export type WholesaleCartAdminDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartAdminDeleteError>;
  order?: Maybe<WholesaleOrder>;
};

/** Autogenerated error type of WholesaleCartAdminSelect */
export type WholesaleCartAdminSelectError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleCartAdminSelect */
export type WholesaleCartAdminSelectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
};

/** Autogenerated return type of WholesaleCartAdminSelect. */
export type WholesaleCartAdminSelectPayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartAdminSelectError>;
};

/** Autogenerated error type of WholesaleCartCbdNonShippableStateUpdate */
export type WholesaleCartCbdNonShippableStateUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleCartCbdNonShippableStateUpdate */
export type WholesaleCartCbdNonShippableStateUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WholesaleCartCbdNonShippableStateUpdate. */
export type WholesaleCartCbdNonShippableStateUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartCbdNonShippableStateUpdateError>;
  removedVariants?: Maybe<Array<Variant>>;
};

/** Autogenerated error type of WholesaleCartConfirm */
export type WholesaleCartConfirmError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleCartConfirm */
export type WholesaleCartConfirmInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  placeOrder: Scalars['Boolean']['input'];
};

/** Autogenerated return type of WholesaleCartConfirm. */
export type WholesaleCartConfirmPayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartConfirmError>;
};

/** Autogenerated error type of WholesaleCartNonShippableStateUpdate */
export type WholesaleCartNonShippableStateUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleCartNonShippableStateUpdate */
export type WholesaleCartNonShippableStateUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WholesaleCartNonShippableStateUpdate. */
export type WholesaleCartNonShippableStateUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartNonShippableStateUpdateError>;
  removedVariants?: Maybe<Array<Variant>>;
};

/** Autogenerated error type of WholesaleCartPoNumberUpdate */
export type WholesaleCartPoNumberUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleCartPoNumberUpdate */
export type WholesaleCartPoNumberUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  poNumber: Scalars['String']['input'];
};

/** Autogenerated return type of WholesaleCartPoNumberUpdate. */
export type WholesaleCartPoNumberUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartPoNumberUpdateError>;
};

/** Prop65 Swap Error */
export type WholesaleCartProp65SwapError = {
  /** Error type */
  type?: Maybe<Scalars['String']['output']>;
  /** variant */
  variant?: Maybe<Variant>;
};

/** Autogenerated input type of WholesaleCartProp65Swap */
export type WholesaleCartProp65SwapInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  storePlatform?: InputMaybe<StorePlatformType>;
};

/** Autogenerated return type of WholesaleCartProp65Swap. */
export type WholesaleCartProp65SwapPayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<WholesaleCartProp65SwapError>>;
};

/** Autogenerated error type of WholesaleCartReset */
export type WholesaleCartResetError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleCartReset */
export type WholesaleCartResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WholesaleCartReset. */
export type WholesaleCartResetPayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartResetError>;
};

/** Autogenerated error type of WholesaleCartUpdate */
export type WholesaleCartUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleCartUpdate */
export type WholesaleCartUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lineItemId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

/** Autogenerated return type of WholesaleCartUpdate. */
export type WholesaleCartUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleCartUpdateError>;
};

/** Wholesale inventory unit */
export type WholesaleInventoryUnit = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Line item associated to the inventory unit */
  lineItem: WholesaleLineItem;
  /** Number of actual units of the product */
  quantity: Scalars['Int']['output'];
  /** Indicates whether the customer has requested to cancel the inventory unit. */
  requestedCancellation: Scalars['Boolean']['output'];
  /** State of the inventory unit (ex. on hand, shipped, etc.) */
  state: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Variant associated with the inventory unit */
  variant: Variant;
};

export type WholesaleInventoryUnitFilterObject = {
  notShipped?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Line item in a wholesale order */
export type WholesaleLineItem = Node & Timestamps & {
  /** Adjustments made to items. Usually taxes and reimbursements. */
  adjustmentTotal: Scalars['Float']['output'];
  /** Total price of items. */
  amount: Scalars['Float']['output'];
  /** Unit cost of the item. */
  costPrice?: Maybe<Scalars['Float']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Order's completed_at date. */
  orderedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Unit price of the item. */
  price: Scalars['Float']['output'];
  /** Total promotion amount. */
  promoTotal: Scalars['Float']['output'];
  /** Number of items of this kind. */
  quantity: Scalars['Int']['output'];
  /** Total taxes on items. (additional_tax_total) */
  taxTotal: Scalars['Float']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** Product variant. */
  variant: Variant;
};

/** The connection type for WholesaleLineItem. */
export type WholesaleLineItemConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WholesaleLineItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WholesaleLineItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WholesaleLineItemEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<WholesaleLineItem>;
};

/** Arguments for filtering wholesale line items. */
export type WholesaleLineItemFilterObject = {
  orderedAt?: InputMaybe<DateRangeFilter>;
  /** @deprecated Use current_store_platform instead */
  storePlatform?: InputMaybe<Scalars['String']['input']>;
};

/** Arguments for sorting wholesale line items. */
export type WholesaleLineItemSort = {
  direction: SortDirection;
  field: WholesaleLineItemSortField;
};

/** Wholesale reorder sort field */
export const WholesaleLineItemSortField = {
  /** Date on which the order was completed */
  CompletedAt: 'COMPLETED_AT',
  /** Name of the line item */
  Name: 'NAME',
  /** Total price of the order */
  Price: 'PRICE'
} as const;

export type WholesaleLineItemSortField = typeof WholesaleLineItemSortField[keyof typeof WholesaleLineItemSortField];
/** Wholesale order or wholesale cart */
export type WholesaleOrder = LegacyId & Node & Timestamps & {
  /** ID that comes directly from the database. Only used for legacy reasons and should not be exposed to the client normally. */
  _legacyId: Scalars['Int']['output'];
  /** The sum of all adjustments on this order. Usually taxes and reimbursements. */
  adjustmentTotal: Scalars['Float']['output'];
  /** The billing address information. */
  billingAddress?: Maybe<Address>;
  /** Comment added to the order by an admin */
  commentary?: Maybe<Commentary>;
  /** The timestamp of when the order was completed. */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** The fee for dropship orders */
  dropshipFee?: Maybe<Scalars['Float']['output']>;
  /** The total of confirmed items. (without shipping until shipping state) */
  estimatedTotal: Scalars['Float']['output'];
  /** Percent discount on this order from promotions */
  firstOrderPromotionPercentage: Scalars['Float']['output'];
  /** Whether the order has a lab kit */
  hasLabKit: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Whether the order is created by practitioner */
  isCreatedByPractitioner: Scalars['Boolean']['output'];
  /** Whether the order is a dropship order */
  isDropship?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the first-order promotion is applied to the order */
  isFirstOrderPromotionApplied: Scalars['Boolean']['output'];
  /** Whether a Cyber Monday promotion is applied to the order */
  isFromCyberMonday: Scalars['Boolean']['output'];
  /** Is the order net terms */
  isNetTerms: Scalars['Boolean']['output'];
  /** Number of items */
  itemCount: Scalars['Int']['output'];
  /** The sum of all the line items for this order. (subtotal) */
  itemTotal: Scalars['Float']['output'];
  /** The sum of all the lab kit line items for this order */
  labKitSubtotal: Scalars['Float']['output'];
  /** Items in this order. Includes quantity */
  lineItems: Array<WholesaleLineItem>;
  /** The unique identifier for this order. This number is shown to the users */
  number: Scalars['String']['output'];
  /** URL for a payment receipt for the order */
  orderReceiptUrl: Scalars['String']['output'];
  /** Payment for the order */
  payment?: Maybe<WholesalePayment>;
  /** The current payment state of the order. (ex: paid, balance_due, void, failed, credit_owed) */
  paymentState?: Maybe<Scalars['String']['output']>;
  /** The total value of all finalized payments. */
  paymentTotal: Scalars['Float']['output'];
  /** Optional customer provided identifier for the wholesale order */
  poNumber?: Maybe<Scalars['String']['output']>;
  /** The sum of all non-lab kit line items for this order */
  productSubtotal: Scalars['Float']['output'];
  /** The sum of all shipments’, line items’ and promotions’ promo_total. */
  promoTotal: Scalars['Float']['output'];
  /** Promotions applied to this order */
  promotions: Array<WholesaleAdjustment>;
  /** The total value of all savings costs. */
  savingsTotal: Scalars['Float']['output'];
  /** The current shipment state of the order. (ex: pending, ready, partial, shipped, canceled) */
  shipmentState?: Maybe<Scalars['String']['output']>;
  /** The total value of all shipments’ costs. */
  shipmentTotal: Scalars['Float']['output'];
  /** Shipments for this order. There may be many if items are in separate boxes. */
  shipments: Array<WholesaleShipment>;
  /** The shipping address information. */
  shippingAddress?: Maybe<Address>;
  /** The total value of all shipments’ costs including its respective shipping adjustments */
  shippingTotal?: Maybe<Scalars['Float']['output']>;
  /**
   * The current state of the order. (ex: cart, address, confirm, payment,
   * delivery, complete, canceled, settle, returned, etc.)
   */
  state: Scalars['String']['output'];
  /** The platform from which the order was placed */
  storePlatform?: Maybe<StorePlatform>;
  /** The taxes for this order. (additional_tax_total) */
  taxTotal: Scalars['Float']['output'];
  /** The sum of item total and adjustment total. */
  total: Scalars['Float']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** The user that the order belongs to. Wholesale orders are assigned to users, not roles. */
  user?: Maybe<User>;
};


/** Wholesale order or wholesale cart */
export type WholesaleOrderShipmentsArgs = {
  filters?: InputMaybe<WholesaleShipmentFilterObject>;
};

/** The connection type for WholesaleOrder. */
export type WholesaleOrderConnection = RecordCounts & {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WholesaleOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WholesaleOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of a collection of records */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type WholesaleOrderEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<WholesaleOrder>;
};

/** Arguments for filtering wholesale orders. */
export type WholesaleOrderFilterObject = {
  completedAt?: InputMaybe<DateRangeFilter>;
  completedWithinDays?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use `query` instead. */
  number?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  storePlatforms?: InputMaybe<Array<StorePlatformType>>;
  unfulfilled?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Arguments for sorting wholesale orders. */
export type WholesaleOrderSort = {
  direction: SortDirection;
  field: WholesaleOrderSortField;
};

/** Wholesale order sort field */
export const WholesaleOrderSortField = {
  /** Date on which the order was placed */
  CompletedAt: 'COMPLETED_AT',
  /** Total price of the order */
  Total: 'TOTAL'
} as const;

export type WholesaleOrderSortField = typeof WholesaleOrderSortField[keyof typeof WholesaleOrderSortField];
/** Wholesale payment */
export type WholesalePayment = Node & Timestamps & {
  /** Payment amount */
  amount: Scalars['Float']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Identification code for the payment */
  number: Scalars['String']['output'];
  /**
   * Credit card used for the payment
   * @deprecated Use sourceUnion instead
   */
  source: CreditCard;
  /** Credit card or Net Terms used for the payment */
  sourceUnion: PaymentSourceUnion;
  /** Processing state of the payment. (complete, processing, invalid, failed, checkout) */
  state: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of WholesalePaymentUpdate */
export type WholesalePaymentUpdateError = {
  /** Field errors */
  fields?: Maybe<WholesalePaymentUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of WholesalePaymentUpdate */
export type WholesalePaymentUpdateFieldError = {
  defaultCard?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Array<Scalars['String']['output']>>;
  oneTimeUse?: Maybe<Array<Scalars['String']['output']>>;
  token?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of WholesalePaymentUpdate */
export type WholesalePaymentUpdateInput = {
  attributes?: InputMaybe<CreditCardAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCardId?: InputMaybe<Scalars['ID']['input']>;
  netTermId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of WholesalePaymentUpdate. */
export type WholesalePaymentUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesalePaymentUpdateError>;
};

/** Wholesale percent on line item */
export type WholesalePercentOnLineItem = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Percentage for the line item */
  percentage: Scalars['Int']['output'];
  /** Promotion types associated to the calculator (ex. variant, brand, etc.) */
  promotionTypes?: Maybe<Scalars['String']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of WholesalePromotionApply */
export type WholesalePromotionApplyError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesalePromotionApply */
export type WholesalePromotionApplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

/** Autogenerated return type of WholesalePromotionApply. */
export type WholesalePromotionApplyPayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesalePromotionApplyError>;
};

/** Variant wholesale promotion details */
export type WholesalePromotionDetails = Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the promotion */
  descriptions: Array<Scalars['String']['output']>;
  /** Details about the promotion */
  metadata: Array<PromotionTypeUnion>;
  /** Percentage of the price that is discounted. */
  percentageOff?: Maybe<Scalars['Float']['output']>;
  /** Discounted price */
  price?: Maybe<Scalars['Float']['output']>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated error type of WholesalePromotionRemove */
export type WholesalePromotionRemoveError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesalePromotionRemove */
export type WholesalePromotionRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

/** Autogenerated return type of WholesalePromotionRemove. */
export type WholesalePromotionRemovePayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesalePromotionRemoveError>;
};

/** Autogenerated error type of WholesaleRequestBackorderCancellation */
export type WholesaleRequestBackorderCancellationError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleRequestBackorderCancellation */
export type WholesaleRequestBackorderCancellationInput = {
  classicBackorderedItem?: InputMaybe<EmersonClassicBackorderedItemInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  wholesaleInventoryUnitId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of WholesaleRequestBackorderCancellation. */
export type WholesaleRequestBackorderCancellationPayload = {
  classicBackorderedItem?: Maybe<EmersonClassicOrderLineItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: WholesaleRequestBackorderCancellationError;
  wholesaleInventoryUnit?: Maybe<WholesaleInventoryUnit>;
};

/** Wholesale shipment */
export type WholesaleShipment = Node & Timestamps & {
  /** Adjustments made to shipping. Usually taxes and reimbursements. */
  adjustmentTotal: Scalars['Float']['output'];
  /** Cost for shipping. */
  cost: Scalars['Float']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** Price of items + shipping. Does not include taxes. */
  finalPriceWithItems: Scalars['Float']['output'];
  /** Total price of shipment including shipping, promos, adjustments and taxes. */
  grandTotal: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** Inventory units for a specific shipment */
  inventoryUnits: Array<WholesaleInventoryUnit>;
  /** All the line items belonging to the shipment */
  lineItems: Array<WholesaleLineItem>;
  /** Typically the only shipment with a cost associated to it. */
  master: Scalars['Boolean']['output'];
  /** Order number */
  number: Scalars['String']['output'];
  /** Cost saved by a promotion */
  promoTotal: Scalars['Float']['output'];
  /** URL where the shipment receipt is stored */
  shipmentReceiptUrl: Scalars['String']['output'];
  /** DateTime the shipment was shipped at */
  shippedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Available shipping rates for the shipment */
  shippingRates?: Maybe<Array<ShippingRate>>;
  /** Current state of the shipment. (ex: pending, ready, shipped, canceled) */
  state: Scalars['String']['output'];
  /** Total taxes on shipping. (additional_tax_total) */
  taxTotal: Scalars['Float']['output'];
  /** Tracking object */
  trackingInstance?: Maybe<TrackingType>;
  /** Name of tracking service */
  trackingService: Scalars['String']['output'];
  /** Tracking number */
  trackings?: Maybe<Array<WholesaleTracking>>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};


/** Wholesale shipment */
export type WholesaleShipmentInventoryUnitsArgs = {
  filters?: InputMaybe<WholesaleInventoryUnitFilterObject>;
};

/** Arguments for filtering wholesale order shipments. */
export type WholesaleShipmentFilterObject = {
  /** Filter by shipped status */
  shipped?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated error type of WholesaleShippingAddressSelect */
export type WholesaleShippingAddressSelectError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleShippingAddressSelect */
export type WholesaleShippingAddressSelectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  expectedAvrId: Scalars['ID']['input'];
  ignore?: InputMaybe<Scalars['Boolean']['input']>;
  selectedOption: Scalars['String']['input'];
};

/** Autogenerated return type of WholesaleShippingAddressSelect. */
export type WholesaleShippingAddressSelectPayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleShippingAddressSelectError>;
};

/** Autogenerated error type of WholesaleShippingRateUpdate */
export type WholesaleShippingRateUpdateError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of WholesaleShippingRateUpdate */
export type WholesaleShippingRateUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingMethodId?: InputMaybe<Scalars['ID']['input']>;
  shippingRateId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of WholesaleShippingRateUpdate. */
export type WholesaleShippingRateUpdatePayload = {
  cart?: Maybe<WholesaleOrder>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<WholesaleShippingRateUpdateError>;
  warnings?: Maybe<Scalars['String']['output']>;
};

/** Wholesale tiered percent item quantity */
export type WholesaleTieredPercentItemQuantity = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Promotion types associated to the calculator (ex. variant, brand, etc.) */
  promotionTypes?: Maybe<Scalars['String']['output']>;
  /** Tiered promotional discounts */
  tiers?: Maybe<Array<TieredPercentItem>>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Wholesale tiered percent item total */
export type WholesaleTieredPercentItemTotal = Node & Timestamps & {
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Promotion types associated to the calculator (ex. variant, brand, etc.) */
  promotionTypes?: Maybe<Scalars['String']['output']>;
  /** Tiered promotional discounts */
  tiers?: Maybe<Array<TieredPercentItem>>;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Tracking details for a wholesale shipment */
export type WholesaleTracking = {
  /** Tracking number (also used as ID) */
  id: Scalars['ID']['output'];
  /** Tracking number provided by shipping company */
  trackingNumber: Scalars['String']['output'];
  /** External URL to tracking service */
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated error type of ZendeskPatientDelete */
export type ZendeskPatientDeleteError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ZendeskPatientDelete */
export type ZendeskPatientDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ZendeskPatientDelete. */
export type ZendeskPatientDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<ZendeskPatientDeleteError>;
  patient?: Maybe<Patient>;
};

/** Autogenerated error type of labsPractitionersReviewResult */
export type LabsPractitionersReviewResultError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of labsPractitionersReviewResult */
export type LabsPractitionersReviewResultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labResultId: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of labsPractitionersReviewResult. */
export type LabsPractitionersReviewResultPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: LabsPractitionersReviewResultError;
  labRecommendationEvent?: Maybe<LabsRecommendationEvent>;
};

/** Autogenerated error type of labsPractitionersSettingsUpdate */
export type LabsPractitionersSettingsUpdateError = {
  /** Field errors */
  fields?: Maybe<LabsPractitionersSettingsUpdateFieldError>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated field error type of labsPractitionersSettingsUpdate */
export type LabsPractitionersSettingsUpdateFieldError = {
  firstName?: Maybe<Array<Scalars['String']['output']>>;
  lastName?: Maybe<Array<Scalars['String']['output']>>;
  licenseExpiryDate?: Maybe<Array<Scalars['String']['output']>>;
  licenseNumber?: Maybe<Array<Scalars['String']['output']>>;
  licenseState?: Maybe<Array<Scalars['String']['output']>>;
  npi?: Maybe<Array<Scalars['String']['output']>>;
  practitionerTypeId?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of labsPractitionersSettingsUpdate */
export type LabsPractitionersSettingsUpdateInput = {
  attributes: LabPractitionerSettingsUpdateAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of labsPractitionersSettingsUpdate. */
export type LabsPractitionersSettingsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: LabsPractitionersSettingsUpdateError;
  practitioner?: Maybe<Practitioner>;
};

/** Oauth Application */
export type OauthApplication = Timestamps & {
  /** Client ID of the ouath application */
  clientId: Scalars['String']['output'];
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  /** External type of the oauth application */
  externalType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Name of the oauth application */
  name: Scalars['String']['output'];
  /** Patch version of the oauth application */
  patchVersion: Scalars['String']['output'];
  /** Redirect URI of the oauth application */
  redirectUri: Scalars['String']['output'];
  /** Description of the scopes of the oauth application */
  scopeDescriptions: Array<OauthScopeDescription>;
  /** Scopes of the oauth application eg: patients:write */
  scopes: Scalars['String']['output'];
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
};

/** Partner store */
export type PartnerStore = Node & Timestamps & {
  /** Datetime the partner store was activated at */
  activatedAt?: Maybe<Scalars['Date']['output']>;
  /** Created at timestamp of the record */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Key for the partner store */
  partnerKey: Scalars['String']['output'];
  /** Datetime the key was revoked at */
  revokedAt?: Maybe<Scalars['Date']['output']>;
  /** Store associated with the partner store */
  store: Store;
  /** Updated at timestamp of the record */
  updatedAt: Scalars['DateTime']['output'];
  /** UUID for the partner store */
  uuid: Scalars['String']['output'];
};

/** An edge in a connection. */
export type PartnerStoreEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PartnerStore>;
};

/** Autogenerated input type of practitionerSignUp */
export type PractitionerSignUpInput = {
  apiClientId?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  practitionerTypeId: Scalars['String']['input'];
  signupSource: Scalars['String']['input'];
};

/** Autogenerated return type of practitionerSignUp. */
export type PractitionerSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of userPasswordUpdate */
export type UserPasswordUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userAttributes: UserAttributes;
};

/** Autogenerated return type of userPasswordUpdate. */
export type UserPasswordUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Errors if the action was unsuccessful */
  errors: Array<Scalars['String']['output']>;
  /** The user that is getting their password updated */
  user?: Maybe<User>;
};

/** Autogenerated input type of userSignIn */
export type UserSignInInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  isOauth?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  prioritizedRole?: InputMaybe<RoleTypes>;
};

/** Autogenerated return type of userSignIn. */
export type UserSignInPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  user?: Maybe<User>;
};

/** Autogenerated error type of wellevateNewAccountEmail */
export type WellevateNewAccountEmailError = {
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of wellevateNewAccountEmail */
export type WellevateNewAccountEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of wellevateNewAccountEmail. */
export type WellevateNewAccountEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: WellevateNewAccountEmailError;
};
